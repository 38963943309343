import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Field from "ui/components/Input/Field";
import { useForm } from "react-hook-form";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Row, Col } from "antd";
import { isValidEmail } from "@brazilian-utils/brazilian-utils";
import colors from "core/resources/theme/colors";
import InputPassword from "ui/components/Input/InputPassword";
import { useAuthStore } from "core/features/auth/auth.store";

export default function Signin() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { control, handleSubmit, watch } = useForm<{
    email: string;
    password: string;
  }>({
    reValidateMode: "onChange",
  });
  const form = watch();
  const auth = useAuthStore();

  const handleLogin = async () => {
    if (form.email && form.password) {
      const isLogged = await auth.signin(form.email, form.password);
      if (isLogged) {
        navigate("/");
      } else {
        console.log("Erro no login");
      }
    }
  };

  return (
    <Box w="417px" margin="auto auto auto 80px">
      <form onSubmit={(ev) => handleSubmit(handleLogin)(ev)}>
        <Text fontSize={24} fontWeight={500} mb={4}>
          Entrar
        </Text>

        <Box as={Row}>
          <Box as={Col} md={24} mb={4}>
            <Field
              name="email"
              label="Usuário"
              rules={{
                required: true,
                validate: (v) => isValidEmail(v) || "Email inválido",
              }}
              inputProps={{
                type: "email",
              }}
              control={control}
            />
          </Box>
          <Box as={Col} md={24} mb={4}>
            <Field
              name="password"
              label="Senha"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <InputPassword
                  showPassword={showPassword}
                  onClick={handleClickShowPassword}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </Box>
          <Box as={Col} md={24} mb={4}>
            <PrimaryButton isLoading={auth.isLoading} w="100%" type="submit">
              Entrar
            </PrimaryButton>
          </Box>
        </Box>

        <Flex align="center" justify="center">
          <Text
            fontSize={14}
            color={colors.primary}
            as={Link}
            to={"/auth/reset"}
          >
            Esqueci minha senha?
          </Text>
        </Flex>
      </form>
    </Box>
  );
}
