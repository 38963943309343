import colors from "core/resources/theme/colors";
import { Menu } from "./typings";
import { PiFileTextFill } from "react-icons/pi";
import { BsLayersFill } from "react-icons/bs";
import { RxStack } from "react-icons/rx";
import { MdPerson } from "react-icons/md";
import { GoHome } from "react-icons/go";
import { PerfilTabsRoutes } from "ui/pages/Admin/EditarPerfil/typings";
import { checkPermission } from "core/infra/helpers/permission";
import { PermissaoEnum } from "core/resources/enums/permissao";

const { Administrativo, Processo } = PermissaoEnum.Fluir;

const navigateMenu = (url: string) => {
  window.open(url);
};

const menu = (): Menu[] => {
  return [
    {
      title: "Início",
      icon: <GoHome size={20} />,
      visible: true,
      href: "/",
      children: [],
    },
    {
      title: "Perfil",
      icon: <MdPerson size={25} />,
      visible: true,
      isMobile: true,
      children: [
        {
          title: "Perfil",
          color: colors.primary,
          bg: colors.porple50,
          visible: true,
          children: [
            {
              title: "Editar meu perfil",
              href: `/editar-perfil?opcao=${PerfilTabsRoutes.InformacoesBasicas}`,
              visible: true,
              children: [],
            },
            {
              title: "Trocar senha",
              href: `/editar-perfil?opcao=${PerfilTabsRoutes.TrocarSenha}`,
              visible: true,
              action: () => navigateMenu("/financeiro/retorno-bancario"),
              children: [],
            },
            {
              title: "Sair",
              href: "sair",
              visible: true,
              children: [],
            },
          ],
        },
      ],
    },
    {
      title: "Cadastro",
      icon: <PiFileTextFill size={20} />,
      visible: true,
      children: [
        {
          title: "Cadastros",
          color: colors.primary,
          icon: <PiFileTextFill color={colors.primary} size={25} />,
          bg: colors.porple50,
          visible: true,
          children: [
            {
              title: "Organizações",
              visible: checkPermission(Administrativo.Tenant.Visualizar),
              href: "/organizacao",
              children: [],
            },
            {
              title: "Setores",
              visible: checkPermission(Administrativo.Setor.Visualizar),
              href: "/setor",
              children: [],
            },
            {
              title: "Cargos",
              visible: checkPermission(Administrativo.Perfil.Visualizar),
              href: "/cargo",
              children: [],
            },
            {
              title: "Usuários",
              visible: checkPermission(Administrativo.Usuario.Visualizar),
              href: "/usuario",
              children: [],
            },
            {
              title: "Natureza do processo",
              visible: true,
              href: "/natureza-processo",
              children: [],
            },
            {
              title: "Tipo do documento",
              visible: true,
              href: "/tipo-documento",
              children: [],
            },
            {
              title: "Assunto do processo",
              visible: true,
              href: "/assunto-processo",
              children: [],
            },
          ],
        },
      ],
    },

    {
      title: "Processos",
      icon: <RxStack size={20} />,
      visible: true,
      children: [
        {
          title: "Processos",
          color: colors.primary,
          icon: <BsLayersFill color={colors.primary} size={25} />,
          bg: colors.porple50,
          visible: true,
          children: [
            {
              title: "Processos",
              visible: checkPermission(Processo.Processo.Visualizar),
              href: "/processos",
              children: [],
            },
            {
              title: "Fluxos de Processo",
              visible: checkPermission(Processo.FluxoProcesso.Visualizar),
              href: "/fluxo-processo",
              children: [],
            },
          ],
        },
      ],
    },
  ];
};

export function useMenu() {
  return {
    menu,
  };
}
