import { Box, Textarea } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import useSaveObservacao from "core/features/processos/hooks/useSaveObservacao";
import { ProcessoObservacaoResponse } from "core/features/processos/typings";
import { showErrorAxios, showSuccess } from "core/infra/helpers/alerta";
import { useForm } from "react-hook-form";
import FooterModalDefault from "ui/components/FooterModal";
import Field from "ui/components/Input/Field";

import Toolbar from "ui/components/Toolbar";

interface ModalObservacaoProps {
  isOpen: boolean;
  onClose: () => any;
  id?: string;
  refetch: any;
}

const defaultValues: ProcessoObservacaoResponse = {
  observacao: "",
};

export default function ModalObservacao({
  isOpen,
  onClose,
  id,
  refetch,
}: ModalObservacaoProps) {
  const { mutate: handleSave, isLoading: isLoadingSave } =
    useSaveObservacao(id);
  const { control, handleSubmit } = useForm<ProcessoObservacaoResponse>({
    defaultValues,
  });

  const onSubmit = (data: ProcessoObservacaoResponse) => {
    handleSave(data, {
      onSuccess: () => {
        showSuccess().then(() => {
          onClose();
          refetch();
        });
      },
      onError: (err) => {
        showErrorAxios(err);
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Salvar"
          onclickPrimaryButton={() => handleSubmit(onSubmit)()}
          saveLoading={isLoadingSave}
        />
      }
      title={<Toolbar title="Observação" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
    >
      <form>
        <Box as={Row} gutter={16} mb={6}>
          <Box as={Col} md={24}>
            <Field
              name="observacao"
              label="Observação"
              control={control}
              rules={{ required: true }}
              render={({ field, isInvalid }) => (
                <Textarea
                  isInvalid={isInvalid}
                  className="chakra-input"
                  rows={4}
                  resize="none"
                  placeholder="Digite..."
                  {...field}
                />
              )}
            />
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
