import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  components: {
    Text: {
      baseStyle: {
        fontFamily: "Poppins,sans-serif",
        fontSize: [12, 12, 14, 14, 14],
      },
    },

    Button: {
      baseStyle: {
        fontFamily: "Poppins,sans-serif",
        fontSize: [12, 12, 14, 14, 14],
      },
    },
    Box: {
      baseStyle: {
        fontFamily: "Poppins,sans-serif",
        fontSize: [12, 12, 14, 14, 14],
      },
    },
  },

  breakpoints: {
    sm: "320px",
    xmd: "540px",
    md: "768px",
    lg: "900px",
    xl: "1200px",
    "2xl": "1536px",
  },

  colors: {},
});

function ChakraUiProvider({ children }: any) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}

export default ChakraUiProvider;
