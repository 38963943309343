import { Box } from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import PageHeader from "ui/components/Page/PageHeader";
import { useBreadcrumb } from "core/features/breadcrumbs";
import SearchHeaderBar from "ui/components/SearchHeaderBar/index";
import { useNavigate } from "react-router-dom";
import useFiltroNaturezaProcesso from "./hooks/useFiltroNaturezaProcesso";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { checkPermission } from "core/infra/helpers/permission";
import NaturezaProcessoTable from "./components/NaturezaProcessoTable";
import useListarNaturezasProcesso from "core/features/natureza-processo/hooks/useListarNaturezasProcesso";

export default function ListagemNaturezaProcessoPage() {
  const { filtro, onChangeFiltro } = useFiltroNaturezaProcesso();
  const { data, isLoading, refetch } = useListarNaturezasProcesso(filtro);

  const navigate = useNavigate();

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Natureza do Processo", url: "/natureza-processo" },
  ]);

  const createPermission = [
    PermissaoEnum.Fluir.Administrativo.NaturezaProcesso.Criar,
  ];

  const hasPermission = checkPermission(createPermission ?? []);

  return (
    <Box>
      <Box mb={10}>
        <PageHeader
          title="Natureza do Processo"
          titleButton="Adicionar"
          onClickButton={() => navigate("/natureza-processo/novo")}
          iconButton={<BsPlus size={25} />}
          buttonDisabled={!hasPermission}
        />

        <SearchHeaderBar
          defaultValue={filtro.search || ""}
          onSearch={(v) => onChangeFiltro({ search: v })}
          maxWInputSearch={700}
          placeholder="Buscar"
        />
      </Box>

      <NaturezaProcessoTable
        data={data}
        isLoading={isLoading}
        onChangeFiltro={onChangeFiltro}
        refetch={refetch}
      />
    </Box>
  );
}
