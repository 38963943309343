
import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { UserProfileEnderecoResponse } from "../types";

function useProfileEndereco() {
    return useQuery(
        `/v1/auth/profile/endereco`,
        async () => {
            const res = await http.get<UserProfileEnderecoResponse>(`/v1/auth/profile/endereco`);

            return res.data;
        },
    );
}

export default useProfileEndereco;
