import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import { GlobalHistory } from "ui/components/GlobalHistory";
import "core/infra/boot";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <GlobalHistory />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement
);

reportWebVitals();
