import { Select, SelectProps } from "antd";
import SelectBaseFiltro from "ui/components/Input/SelectBaseFiltro/index";

const { Option } = Select;

export interface SeletctStatusFiltroDocumentoProps<T = any>
  extends SelectProps<T> {
  value: any;
  onChange: (v: any) => void;
}

function SeletctStatusFiltroDocumento({
  value,
  ...rest
}: SeletctStatusFiltroDocumentoProps) {
  return (
    <SelectBaseFiltro
      size="large"
      getPopupContainer={(node) => node.parentNode}
      placeholder="Status"
      value={value}
      {...rest}
    >
      <Option value={"false"} key={"p"}>
        Para assinar
      </Option>
      <Option value={"true"} key={"a"}>
        Assinado
      </Option>
    </SelectBaseFiltro>
  );
}

export default SeletctStatusFiltroDocumento;
