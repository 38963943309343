import { Box, Textarea } from "@chakra-ui/react";
import { Row, Col } from "antd";
import { ProcessoResponse } from "core/features/processos/typings";
import { Control, FieldErrors } from "react-hook-form";
import Field from "ui/components/Input/Field";
import SelectAssunto from "ui/components/Input/SelectAssunto";
import SelectPrioridade from "ui/components/Input/SelectPrioridade";

export default function FormDadosProcesso({
  control,
  errors,
}: {
  control: Control<ProcessoResponse, any>;
  errors: FieldErrors<ProcessoResponse>;
}) {
  return (
    <Box>
      <Box as={Row} gutter={32} mb={4}>
        <Box as={Col} md={24}>
          <Field
            name="assuntoId"
            label="Assunto"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <SelectAssunto
                isAdicionar
                isInvalid={!!errors.assuntoId}
                {...field}
              />
            )}
          />
        </Box>
      </Box>

      <Box as={Row} gutter={32} mb={4}>
        <Box as={Col} md={24}>
          <Field
            name="resumo"
            label="Resumo"
            control={control}
            render={({ field, isInvalid }) => (
              <Textarea
                isInvalid={isInvalid}
                className="chakra-input"
                rows={4}
                resize="none"
                placeholder="Digite..."
                {...field}
              />
            )}
          />
        </Box>
      </Box>

      <Box as={Row} gutter={32} mb={4}>
        <Box as={Col} md={6}>
          <Field
            name="prioridadeId"
            label="Prioridade"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <SelectPrioridade isInvalid={!!errors.prioridadeId} {...field} />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}
