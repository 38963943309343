import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { PalavraChaveResponse } from "../typings";

function useSavePalavraChave(idProcesso?: string, id?: string) {
  return useMutation(
    (data: PalavraChaveResponse) => {
      if (id) {
        return http.put<{ response: string }>(
          `/v1/processo/${idProcesso}/palavra-chave/${id}`,
          data
        );
      }
      return http.post<{ response: string }>(
        `/v1/processo/${idProcesso}/palavra-chave`,
        data
      );
    },
    { retry: false }
  );
}

export default useSavePalavraChave;
