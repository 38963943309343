import { useQuery } from "react-query";

import http from "../auth/hooks/useApi";

function useGetCidades(estadoId?: string | null) {
  return useQuery(
    `/v1/estado/${estadoId}/cidades`,
    async () => {
      const { data } = await http.get<{ nome: string; id: string }[]>(
        `/v1/estado/${estadoId}/cidades`
      );

      return data;
    },

    {
      enabled: !!estadoId,
    }
  );
}

export default useGetCidades;
