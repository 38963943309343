import { Box, Flex, Link } from "@chakra-ui/layout";
import { Image, Text, Spinner } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { AiOutlineClose } from "react-icons/ai";
import { MdError } from "react-icons/md";
import { UseMutationResult } from "react-query";
import { AxiosResponse } from "axios";
import { useRef, useEffect } from "react";
import { Row } from "antd";
import { Col } from "antd";
import { showConfirm3 } from "core/infra/helpers/alerta";
import { getIconForFileExt, isImage, isSvg } from "core/infra/helpers/file";
import { PhotoView } from "react-photo-view";

export interface AnexoResponse {
  id: string;
  name: string;
  nome?: string;
  referencia: string;
  mimetype: string;
  tipoAnexoId?: string;
}

interface AnexosProps {
  setValue: (anexos?: (File | AnexoResponse)[]) => any;
  anexos?: (File | AnexoResponse)[];
  trackRemove?: (id?: string) => any;
  autoUploadMutation?: (
    ...args: any[]
  ) => UseMutationResult<AxiosResponse<AnexoResponse>, unknown, File, unknown>;
  autoUploadMutationArgs?: any[];
  selectTipoAnexoConta?: boolean;
  onRemove?: (id: string) => Promise<any>;
  onStartUploadMutation?: () => any;
  onFinishUploadMutation?: () => any;
  numColumns?: number;
  containerHeight?: number | string;
}

export const AnexoItem = ({
  url,
  nome,
  id,
  onRemove,
  autoUploadMutation,
  autoUploadMutationArgs = [],
  file,
  onFinishUploadMutation,
  onStartUploadMutation,
}: {
  url: string;
  nome: string;
  id?: string;
  onRemove?: (id?: string) => any;
  autoUploadMutation?: (
    ...args: any[]
  ) => UseMutationResult<AxiosResponse<AnexoResponse>, unknown, File, unknown>;
  autoUploadMutationArgs?: any[];
  file?: File;
  onFinishUploadMutation?: (result: AnexoResponse) => any;
  onStartUploadMutation?: () => any;
}) => {
  const mutation = autoUploadMutation?.(...autoUploadMutationArgs);
  const error = !!mutation?.error;
  const uploadedRef = useRef(false);
  const splits = nome?.split(".");
  const ext = splits.pop();
  const _isImage = isImage(ext!);
  const _isSvg = isSvg(ext!);

  useEffect(() => {
    if (file && !uploadedRef.current && mutation) {
      onStartUploadMutation?.();
      mutation.mutate?.(file, {
        onSuccess: ({ data }) => {
          onFinishUploadMutation?.({ ...data, name: data.nome || data.name });
        },
      });

      uploadedRef.current = true;
    }
  }, [file, onStartUploadMutation, onFinishUploadMutation, mutation]);

  return (
    <Flex
      rounded="6px"
      borderColor={colors.gray100}
      borderWidth={1}
      position="relative"
      flexDir="column"
      h="120px"
    >
      <Box w="100%" h="85px">
        {_isImage || _isSvg ? (
          <PhotoView src={url}>
            <Image
              w="100%"
              h="85px"
              cursor="pointer"
              src={url}
              objectFit="cover"
              roundedTop="4px"
            />
          </PhotoView>
        ) : (
          <Link
            w="100%"
            h="100%"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            outline={0}
          >
            <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
              {getIconForFileExt(ext)}
            </Flex>
          </Link>
        )}
      </Box>
      <Flex
        w="100%"
        p={2}
        bg={colors.gray100}
        alignItems="center"
        roundedBottom="4px"
      >
        <Box w="100%" h="17px" overflow="hidden">
          <Text noOfLines={1} fontSize={12} lineHeight={"17px"}>
            {nome}
          </Text>
        </Box>
      </Flex>

      {typeof onRemove === "function" ? (
        <Box
          aria-label="Remover anexo"
          position="absolute"
          top="2px"
          right="3px"
          cursor="pointer"
          p={0.5}
          onClick={() => onRemove(id)}
          color={colors.red400}
          w="fit-content"
          h="fit-content"
          rounded="full"
          bg="#00000030"
          _hover={{
            opacity: 0.7,
          }}
        >
          <AiOutlineClose color="inherit" fontSize={18} />
        </Box>
      ) : null}

      {mutation?.isLoading || (error && file) ? (
        <Flex
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          margin="auto"
          alignItems="center"
          justifyContent="center"
          w="100%"
          h="100%"
          bg="rgba(0,0,0,0.25)"
        >
          {mutation?.isLoading ? (
            <Flex w="30px" h="30px" alignItems="center" justifyContent="center">
              <Spinner size="md" color="white" />
            </Flex>
          ) : null}
          {error && file ? (
            <Flex w="30px" h="30px" alignItems="center" justifyContent="center">
              <MdError size="md" color="red" />
            </Flex>
          ) : null}
        </Flex>
      ) : null}
    </Flex>
  );
};

function Anexos({
  setValue,
  anexos = [],
  trackRemove,
  autoUploadMutation,
  autoUploadMutationArgs,
  onRemove,
  selectTipoAnexoConta = false,
  onStartUploadMutation,
  onFinishUploadMutation,
  numColumns = 4,
  containerHeight,
}: AnexosProps) {
  const files = anexos ?? [];

  return (
    <Box
      p={2}
      as={Row}
      gutter={[8, 8]}
      overflowX="auto"
      alignSelf="center"
      className="custom-scroll-bar"
      w={"100%"}
      h={containerHeight || (selectTipoAnexoConta ? 180 : 150)}
    >
      {files.map((file, indexFile) => {
        const url =
          file instanceof File ? URL.createObjectURL(file) : file.referencia;

        const id = file instanceof File ? undefined : file.id;

        return (
          <Box
            as={Col}
            xs={Math.floor(24 / numColumns)}
            key={
              file instanceof File
                ? indexFile + file.name + file.lastModified
                : indexFile + file.id + file.name
            }
          >
            <AnexoItem
              id={id}
              key={
                file instanceof File
                  ? indexFile + file.name + file.lastModified
                  : indexFile + file.id + file.name
              }
              url={url}
              nome={
                file instanceof File || Reflect.has(file, "name")
                  ? file.name
                  : file.nome!
              }
              onRemove={async (id) => {
                const { isConfirmed } = await showConfirm3({
                  mensagem: "Deseja realmente remover esse arquivo?",
                });

                if (isConfirmed) {
                  const handleLocalRemove = () => {
                    const newFiles = files.filter(
                      (f, indexNewFile) =>
                        !(f.name === file.name && indexFile === indexNewFile)
                    );
                    setValue(newFiles);
                    trackRemove?.(id);
                  };

                  if (onRemove && id) {
                    onRemove(id).then(handleLocalRemove);
                  } else {
                    handleLocalRemove();
                  }
                }
              }}
              file={file instanceof File ? file : undefined}
              autoUploadMutation={autoUploadMutation}
              autoUploadMutationArgs={autoUploadMutationArgs}
              onFinishUploadMutation={(result) => {
                const copy = [...files];
                copy.splice(indexFile, 1, result);
                setValue(copy);
                onFinishUploadMutation?.();
              }}
              onStartUploadMutation={onStartUploadMutation}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default Anexos;
