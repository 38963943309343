
import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { OrganizacaoEnderecoResponse } from "../typings";


function useOrganizacaoEndereco(id?: string) {
    return useQuery(
        `/v1/organizacao/${id}/endereco`,
        async () => {
            const res = await http.get<OrganizacaoEnderecoResponse>(`/v1/organizacao/${id}/endereco`);

            return res.data;
        },
    );
}

export default useOrganizacaoEndereco;
