import { Box, Flex, Tag } from "@chakra-ui/react";
import { ProcessoPaginateResponse } from "core/features/processos/typings";
import DividerDefault from "ui/components/DividerDefault";

interface StatusBarProps {
  item: ProcessoPaginateResponse;
}

export default function StatusBar({ item }: StatusBarProps) {
  return (
    <Box px={6} mb={4}>
      <DividerDefault />
      <Flex gap={2}>
        <Tag colorScheme={item.prioridade.cor}>{item.prioridade.nome}</Tag>

        {item.isEmAtraso ? <Tag colorScheme="orange">Em atraso</Tag> : null}
      </Flex>
    </Box>
  );
}
