export enum TipoContatoEnum {
    Telefone = "74089AE4-E071-4C0D-91EE-7D31B7BA2BB6",
    Celular = "2A3B769B-FE39-4F53-96A8-43A5F9141D39",
    Email = "97393EF8-E4B8-4DDA-972E-02F7AA0E0916",
}

export enum TipoNaturezaProcessoEnum {
    Tipo1 = "099391BD-4EB6-400A-99DF-6BC1BFB7AD6F",
    Tipo2 = "099391BD-4EB6-400A-99DF-6BC1BFB7AD7F",
    Tipo3 = "099391BD-4EB6-400A-99DF-6BC1BFB7AD8F",
}