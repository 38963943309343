import http from "core/features/auth/hooks/useApi";
import { useMutation } from "react-query";

export default function useDeletePalavraChave() {
  return useMutation(
    `/v1/processo/:id/palavra-chave`,
    (id: string) => http.delete(`/v1/processo/${id}/palavra-chave`),
    { retry: false }
  );
}
