export function formatMoney(value: any) {
  return Intl.NumberFormat(`pt-BR`, {
    style: `currency`,
    currency: `BRL`,
  }).format(value || 0);
}

export function formatPhone(value: string) {
  if (!value) {
    return "";
  }

  if (value.length === 11) {
    return value.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  } else if (value.length === 10) {
    return value.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }

  return value;
}

export function formatValuePercentage(percent?: number, value?: number) {
  if (!percent || !value) {
    return "";
  }
  return `(${formatMoney((percent / 100) * value)})`;
}
