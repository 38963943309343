import http from "core/features/auth/hooks/useApi";
import { useMutation } from "react-query";
import { OrganizacaoEnderecoResponse } from "../typings";




function useSaveOrganizacaoEndereco(id: string) {
    return useMutation(
        `/v1/organizacao/${id}/endereco`,
        (data: OrganizacaoEnderecoResponse) => {
            return http.put(`/v1/organizacao/${id}/endereco`, data);
        },
        { retry: false }
    );
}

export default useSaveOrganizacaoEndereco;
