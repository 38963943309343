import { Box } from "@chakra-ui/react";
import { ProcessoDocumentoResponse } from "core/features/processos-documento/typings";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { UseFormSetValue } from "react-hook-form";
import EmptyDefault from "ui/components/Empty";
import CardAssinante from "ui/pages/Admin/Processos/Detalhes/components/ProcessosDocumento/components/TableAssinantes/CardAssinante";

interface TableAssinantesProps {
  form: ProcessoDocumentoResponse;
  setValue: UseFormSetValue<ProcessoDocumentoResponse>;
  tenantId: string;
}

export default function TableAssinantes({
  form,
  setValue,
  tenantId,
}: TableAssinantesProps) {
  const assinantes = (form?.assinantes ?? []).slice();
  assinantes.sort((a, b) => a.ordem - b.ordem);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(assinantes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, {
      ...reorderedItem,
      ordem: result.destination.index,
    });

    items.forEach((item, index) => {
      item.ordem = index;
    });

    setValue("assinantes", items);
  };

  const onRemoveItem = (assinanteId: string) => {
    const assinantesIdsExcluidos = form.assinantesIdsExcluidos || [];
    const updatedAssinantes = assinantes.filter(
      (item) => item.assinanteId !== assinanteId
    );

    const removedItem = assinantes.find(
      (item) => item.assinanteId === assinanteId
    );
    if (removedItem && removedItem.assinanteId) {
      assinantesIdsExcluidos.push(removedItem.assinanteId);
    }

    setValue("assinantes", updatedAssinantes);
    setValue("assinantesIdsExcluidos", assinantesIdsExcluidos);
  };

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {assinantes.map((item, index) => (
                <Draggable
                  key={index}
                  draggableId={`item-${item.id}`}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <CardAssinante
                        tenantId={tenantId}
                        item={item}
                        index={index}
                        onRemoveItem={onRemoveItem}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      {!assinantes.length && <EmptyDefault size="sm" fontSize="12px" />}
    </Box>
  );
}
