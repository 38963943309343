import { Box, Flex } from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import PageHeader from "ui/components/Page/PageHeader";
import { useBreadcrumb } from "core/features/breadcrumbs";
import SearchHeaderBar from "ui/components/SearchHeaderBar/index";
import { useNavigate } from "react-router-dom";
import SetorTable from "./components/SetorTable/index";
import useListarSetores from "core/features/setor/hooks/useListarSetores";
import useFiltroSetores from "./hooks";
import SeletctStatusFiltro from "ui/components/Input/SeletctStatusFiltro";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { checkPermission } from "core/infra/helpers/permission";

export default function ListagemSetorPage() {
  const { filtro, onChangeFiltro } = useFiltroSetores();
  const { data, isLoading } = useListarSetores(filtro);

  const navigate = useNavigate();

  useBreadcrumb([{ name: "Cadastro" }, { name: "Setor", url: "/setor" }]);

  const createPermission = [PermissaoEnum.Fluir.Administrativo.Setor.Criar];

  const hasPermission = checkPermission(createPermission ?? []);

  return (
    <Box>
      <Box mb={10}>
        <PageHeader
          title="Setor"
          titleButton="Adicionar"
          onClickButton={() => navigate("/setor/novo")}
          iconButton={<BsPlus size={25} />}
          buttonDisabled={!hasPermission}
        />

        <SearchHeaderBar
          defaultValue={filtro.search || ""}
          onSearch={(v) => onChangeFiltro({ search: v })}
          maxWInputSearch={700}
          placeholder="Buscar"
          filterStatus={
            <Flex w="100px">
              <SeletctStatusFiltro
                onChange={(ativo) => {
                  onChangeFiltro({ ativo: ativo });
                }}
                value={filtro.ativo}
              />
            </Flex>
          }
        />
      </Box>

      <SetorTable
        data={data}
        isLoading={isLoading}
        onChangeFiltro={onChangeFiltro}
      />
    </Box>
  );
}
