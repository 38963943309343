import axios, { AxiosError } from "axios";
import config from "core/infra/config";
import { useCurrentTenant } from "../tenant.store";
import { isAuthenticated } from "./IsAuthenticated";

const http = axios.create({
  baseURL: config.urlApi,
});

http.interceptors.request.use(
  async (config) => {
    const { tenant } = useCurrentTenant.getState();
    console.log("tenant", tenant);
    if (tenant.id && !config.headers?.tenant) {
      config.headers!.tenant = tenant.id;
    }

    config.headers!.locale = "pt-BR";

    try {
      const session = await isAuthenticated();

      if (session && !config.headers?.authorization) {
        const token = session.getIdToken().getJwtToken();
        config.headers!.authorization = `Bearer ${token}`;
      }
    } catch {}

    return config;
  },
  (error: AxiosError) => {
    Promise.reject(error);
  }
);

export default http;
