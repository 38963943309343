import { Box, Flex, Text } from "@chakra-ui/react";
import { Modal } from "antd";
import moment from "moment";
import CardPerson3 from "ui/components/Cards/CardPerson3";
import ContentItem from "ui/components/ContantItem";
import FooterModalDefault from "ui/components/FooterModal";
import Toolbar from "ui/components/Toolbar";

interface ModalObservacoesDetalhesProps {
  isOpen: boolean;
  onClose: () => any;
  items?: {
    id: string;
    createdAt: string;
    autor: {
      nome: string;
      imagemUrl?: string | undefined;
    };
    observacao: string;
  }[];
  id?: string;
}

export default function ModalObservacoesDetalhes({
  isOpen,
  onClose,
  items,
  id,
}: ModalObservacoesDetalhesProps) {
  const item = items ? items?.filter((item) => !id || item.id === id) : [];
  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Fechar"
          onclickOutlineButton={onClose}
        />
      }
      title={<Toolbar title="Observações" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
    >
      <Box>
        <ContentItem
          titulo="AUTOR"
          descricao={
            item ? (
              <Flex align="center" gap={2}>
                <CardPerson3
                  nome={item[0]?.autor.nome}
                  avatarUrl={item[0]?.autor.imagemUrl}
                  avatarProps={{ width: "40px", height: "40px" }}
                />
                <Text fontSize="14px">{item[0]?.autor.nome}</Text>
              </Flex>
            ) : (
              "--"
            )
          }
        />

        <ContentItem
          titulo="DATA E HORA"
          descricao={
            item
              ? moment(item[0]?.createdAt).format("DD/MM/YYYY [às] HH:mm")
              : "--"
          }
        />

        <ContentItem
          titulo="OBSERVAÇÃO"
          descricao={item ? <Text>{item[0]?.observacao}</Text> : "--"}
        />
      </Box>
    </Modal>
  );
}
