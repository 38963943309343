import useProcessoHistorico from "core/features/processo-historico/hooks/useProcessoHistorico";
import TimelineHistorico from "./components/TimelineHistorico/index";
import { useLoading } from "core/infra/helpers/alerta";
import EmptyDefault from "ui/components/Empty/index";

interface HistoricoEventosProps {
  id: string;
}

export default function HistoricoEventos({ id }: HistoricoEventosProps) {
  const { data, isLoading } = useProcessoHistorico(id);

  useLoading(isLoading);
  return (
    <>
      {!data?.length && !isLoading ? (
        <EmptyDefault description="Nenhum histórico para esse processo" />
      ) : null}

      {data?.length && !isLoading ? <TimelineHistorico data={data} /> : null}
    </>
  );
}
