import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TenantMeResponse } from "./types";


const tenantFixo: TenantMeResponse = {
  id: "5ebb7712-999e-4b4f-9eb5-87fdf2cd75df",
  ativo: true,
  pessoaId: "9df18ea9-c547-4c9e-8e94-ff5808f227cb",
};

interface TenantStore {
  tenant: TenantMeResponse;
  selecionar: () => void;
  reset: () => void;
}

export const useCurrentTenant = create<TenantStore>(
  persist(
    (set) => ({
      tenant: tenantFixo,
      selecionar: () => {

      },
      reset: () => {

      },
    }),
    {
      name: "@fluir-tenant",
      getStorage: () => localStorage,
    }
  ) as any
);
