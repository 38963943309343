import { Box, Text, Flex } from "@chakra-ui/react";
import { Row, Col } from "antd";
import Field from "ui/components/Input/Field";
import { useForm } from "react-hook-form";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { showErrorAxios } from "core/infra/helpers/alerta";
import useEmailConfirm from "core/features/auth/hooks/useEmailConfirm";
import { GoCheckCircleFill } from "react-icons/go";
import colors from "core/resources/theme/colors";
import InputPassword from "ui/components/Input/InputPassword";
import { useState } from "react";
import { Link } from "react-router-dom";

interface EmailConfirmProps {
  email: string;
  setSend: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EmailConfirm({ email, setSend }: EmailConfirmProps) {
  const { isLoading: isLoadingSave, mutate: handleSave } = useEmailConfirm();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowshowPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);

  const { control, handleSubmit, watch } = useForm<{
    email: string;
    code: string;
    newPassword: string;
    confirm: string;
  }>({
    reValidateMode: "onChange",
  });

  const form = watch();

  const onSubmit = async (data: {
    email: string;
    code: string;
    newPassword: string;
  }) => {
    handleSave(
      { ...data, email: email },
      {
        onSuccess: () => {},
        onError: (err) => {
          showErrorAxios(err);
        },
      }
    );
  };

  return (
    <Box w="417px" margin="auto auto auto 80px">
      <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
        <Text fontSize={24} fontWeight={500} mb={4}>
          Redefinir senha
        </Text>
        <Box as={Row}>
          <Flex
            as={Col}
            md={24}
            w="420px"
            h="60px"
            fontSize="14px"
            bg="#F0FFF4"
            gap={2}
            px={4}
            py={2}
            mb={4}
          >
            <GoCheckCircleFill color={colors.green500} size={20} />
            <Text color={colors.green600}>
              Verifique no seu e-mail o código de redefinição de senha.
            </Text>
          </Flex>
          <Box as={Col} md={24} mb={4}>
            <Field
              name="code"
              label="Codigo"
              rules={{
                required: true,
              }}
              control={control}
            />
          </Box>

          <Box as={Col} md={24} mb={4}>
            <Field
              name="newPassword"
              label="Nova senha"
              rules={{
                required: "Este campo é obrigatório",
                minLength: {
                  value: 8,
                  message: "A senha deve ter no mínimo 8 caracteres",
                },
                pattern: {
                  value: /^(?=.*[A-Z])(?=.*\d)/,
                  message:
                    "A senha deve conter pelo menos uma letra maiúscula e um número",
                },
              }}
              control={control}
              render={({ field }) => (
                <InputPassword
                  showPassword={showPassword}
                  onClick={handleClickShowPassword}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </Box>
          <Box as={Col} md={24} mb={4}>
            <Field
              name="confirm"
              label="Repetir nova senha"
              rules={{
                required: "Campo obrigatório",
                validate: (v) =>
                  v === form.newPassword
                    ? undefined
                    : "As senhas devem ser iguais",
              }}
              control={control}
              render={({ field }) => (
                <InputPassword
                  showPassword={showPasswordConfirm}
                  onClick={handleClickShowshowPasswordConfirm}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </Box>
          <Box as={Col} md={24} mb={4}>
            <PrimaryButton w="100%" type="submit" isLoading={isLoadingSave}>
              Redefinir senha
            </PrimaryButton>
          </Box>
        </Box>
        <Flex align="center" justify="center">
          <Text
            fontSize={14}
            color={colors.primary}
            as={Link}
            onClick={() => setSend(false)}
          >
            voltar
          </Text>
        </Flex>
      </form>
    </Box>
  );
}
