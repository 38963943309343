import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { ProcessoDocumentoResponse } from "../typings";

function useProcessoDocumento(id?: string, idDocumento?: string) {
  return useQuery(
    `/v1/processo-documento/${id}/${idDocumento}`,
    async () => {
      const res = await http.get<ProcessoDocumentoResponse>(
        `/v1/processo-documento/${id}/${idDocumento}`
      );

      return res.data;
    },
    {
      enabled: !!idDocumento,
    }
  );
}

export default useProcessoDocumento;
