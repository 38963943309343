import { Flex, Tag, Text } from "@chakra-ui/react";
import moment from "moment";
import { Container } from "./styles";
import { ProcessoHistoricoResponse } from "core/features/processo-historico/typings";

export default function TimelineHistorico({
  data,
}: {
  data?: ProcessoHistoricoResponse[];
}) {
  // const { data } = useSetores();

  // const setor = data?.filter((v: { id: string }) => v.id === item.setorId);

  return (
    <>
      <Flex
        bg="#FFFFFF"
        w="100%"
        h="500px"
        p={4}
        rounded="16px"
        flexDir="column"
        overflow="auto"
      >
        <Text fontSize="16px" fontWeight="500" mb={4}>
          Histórico
        </Text>
        {data?.map((item, index) => (
          <Container key={index}>
            <Flex mb={"8px"}>
              <Flex>
                <Text
                  fontSize="16px"
                  color={"#8291A9"}
                  fontWeight={"500"}
                  fontStyle={"italic"}
                  mr={"32px"}
                >
                  {moment(item?.createdAt).format("DD/MM/YYYY [às] HH:mm")}
                </Text>
                <Tag colorScheme="gray">{item?.mensagem}</Tag>
              </Flex>
            </Flex>
            <Flex mb={"8px"}>
              <Text fontSize={"14px"} color={"#8291A9"}>
                Documento:
              </Text>
              <Text ml={2} fontWeight={"medium"} fontSize={"14px"}>
                {item?.nome}
              </Text>
            </Flex>
            <Flex mb={"8px"}>
              <Text fontSize={"14px"} color={"#8291A9"}>
                Autor:
              </Text>
              <Text ml={2} fontWeight={"medium"} fontSize={"14px"}>
                {item?.usuario}
              </Text>
            </Flex>
          </Container>
        ))}
      </Flex>

      {/* 

      <Flex w="100%">
        <Flex
          bg="#FFFFFF"
          w="100%"
          h="500px"
          mt={4}
          ml="150px"
          p={4}
          rounded="16px"
          flexDir="column"
        >
          <Flex w="100%" justify="space-between">
            <Text fontSize="16px" fontWeight="500">
              Historico assinatura
            </Text>

            <Tag h="18px" colorScheme="linkedin" gap={2} px={4}>
            <FaClock />
          </Tag>
          </Flex>

          <Flex mb={"8px"}>
            <Box>
              <Text
                fontSize="16px"
                color={"#8291A9"}
                fontWeight={"500"}
                fontStyle={"italic"}
                mr={"32px"}
              >
                {moment(item?.createdAt).format("DD/MM/YYYY HH:mm")}
              </Text>
            </Box>

            <Text
              fontSize="12px"
              color={"#C4C4C4"}
              fontWeight={"500"}
              fontStyle={"italic"}
            >
              {item?.usuario}
            </Text>
          </Flex>

          <Flex
            flexDir="column"
            ml={2}
            position="relative"
            py={2}
            mt={4}
          ></Flex>
        </Flex>
      </Flex> */}
    </>
  );
}
