import { Grid, Box, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import MenuDetalhes from "ui/components/Menu/MenuDetalhes";
import InformacoesBasicas from "./components/InformacoesBasicas/index";
import Endereco from "./components/Endereco/index";
import { useBreadcrumb } from "core/features/breadcrumbs";
import { TabMenuOptions, TabsUsuarioRoutes } from "./typings";
import { useWindowSize } from "core/infra/helpers/window";

export default function CadastroUsuarioDetalhes() {
  const { id } = useParams() as {
    id: string;
  };

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Usuários", url: "usuario" },
    { name: id ? "Editar usuário" : "Cadastro de usuário" },
  ]);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get("opcao") as TabsUsuarioRoutes | null;
  const [selectedTab, setSelectedTab] = useState<TabsUsuarioRoutes>(
    tab || TabsUsuarioRoutes.InformacaoesBasicas
  );

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 992;

  return (
    <Flex flexDir="column" w="100%">
      <Text fontWeight="500" fontSize="24px" color="black" flexWrap="wrap">
        {id ? "Editar " : "Cadastro de "}
        usuário
      </Text>

      <Grid templateColumns={isMobile ? "1fr" : "1fr 4fr"} gridGap={4} mt={8}>
        <Box h="fit-content">
          <MenuDetalhes
            options={TabMenuOptions}
            selectedOption={selectedTab}
            onChangeOption={(tab) => {
              setSelectedTab(tab);
            }}
            disabledOptions={!id ? [TabsUsuarioRoutes.Endereco] : []}
          />
        </Box>

        <Box>
          {selectedTab === TabsUsuarioRoutes.InformacaoesBasicas ? (
            <InformacoesBasicas id={id} />
          ) : null}

          {selectedTab === TabsUsuarioRoutes.Endereco ? (
            <Endereco id={id} />
          ) : null}
        </Box>
      </Grid>
    </Flex>
  );
}
