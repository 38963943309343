import { Tag } from "@chakra-ui/react";

interface TagStatusProps {
  title: string;
  colorScheme:
    | (string & {})
    | "green"
    | "whiteAlpha"
    | "blackAlpha"
    | "gray"
    | "red"
    | "orange"
    | "yellow"
    | "teal"
    | "blue"
    | "cyan"
    | "purple"
    | "pink"
    | "linkedin"
    | "facebook"
    | "messenger"
    | "whatsapp"
    | "twitter"
    | "telegram"
    | undefined;
}
export default function TagStatus({ title, colorScheme }: TagStatusProps) {
  return (
    <Tag rounded={5} px={3} gap={2.5} colorScheme={colorScheme} size="sm">
      {title}
    </Tag>
  );
}
