import { Flex } from "@chakra-ui/react";

export default function HeaderDefaultRowTable({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Flex ml={4} mb="16px" fontWeight="500">
      {children}
    </Flex>
  );
}
