import { Flex } from "@chakra-ui/react";

export default function HeaderTableRow() {
  return (
    <>
      <Flex flex={1.6}>Cargo</Flex>
      <Flex flex={1.05}>Pessoas nesse cargo</Flex>
      <Flex flex={0.75}>Permissões</Flex>
      <Flex flex={0.44}>Status</Flex>
    </>
  );
}
