import { Box } from "@chakra-ui/react";
import { ProcessoDocumentoResponse } from "core/features/processos-documento/typings";
import { useCallback } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import Field from "ui/components/Input/Field";
import MessageEditor from "ui/components/MessageEditor";

interface EscreverDocumentoProps {
  setValue: UseFormSetValue<ProcessoDocumentoResponse>;
  control: Control<ProcessoDocumentoResponse, any>;
}

export default function EscreverDocumento({
  control,
  setValue,
}: EscreverDocumentoProps) {
  const onChange = useCallback(
    (html: string | undefined, plainText?: string) => {
      setValue("texto", html || "");
      setValue("conteudoPlain", plainText);
    },
    [setValue]
  );
  return (
    <Box minH="150px">
      <Field
        name="texto"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <MessageEditor
            value={field.value}
            onChange={onChange}
            heightType={1}
          />
        )}
      />
    </Box>
  );
}
