import { AxiosError } from "axios";
import { useInfiniteQuery } from "react-query";
import { PaginateResult } from "core/resources/types/shared";
import http from "core/features/auth/hooks/useApi";
import { NotificacaoPaginateResponse } from "./../types/index";

export function useNotificacoes(enabled: boolean = true) {
  return useInfiniteQuery<
    PaginateResult<NotificacaoPaginateResponse>,
    AxiosError
  >(
    `/v1/notificacao`,
    async ({ pageParam }) => {
      const { data } = await http.get<
        PaginateResult<NotificacaoPaginateResponse>
      >("/v1/notificacao", {
        params: {
          page: pageParam ?? 1,
          limit: 10,
        },
      });
      return data;
    },
    {
      enabled: enabled,
      refetchInterval: 15 * 1000,

      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage === lastPage.meta.totalPages ||
        lastPage.meta.totalPages === 0
          ? undefined
          : lastPage.meta.currentPage + 1,
    }
  );
}
