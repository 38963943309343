import React from "react";
import iconePdf from "assets/images/svg/icone-pdf.svg";
import { Image } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/layout";
import { AiOutlineFile, AiOutlineFileImage } from "react-icons/ai";
import colors from "core/resources/theme/colors";

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <Flex
    borderColor={colors.gray200}
    borderWidth={2}
    w="40px"
    h="40px"
    rounded={8}
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </Flex>
);

export default function DocumentoIcone({ mimetype }: { mimetype?: any }) {
  if (mimetype === "application/pdf") {
    return (
      <Wrapper>
        <Image src={iconePdf} w="30px" h="30px" ml={-1} />
      </Wrapper>
    );
  }

  if (mimetype?.startsWith("image")) {
    return (
      <Wrapper>
        <AiOutlineFileImage size={"30px"} color={colors.blue300} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <AiOutlineFile size={"30px"} color={colors.blue300} />
    </Wrapper>
  );
}
