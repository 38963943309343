const isLocalHost = window.location.hostname.includes("localhost");
const isEmptyEnv = !process.env.REACT_APP_BASE_URL_API?.trim();

const url =
  isEmptyEnv && isLocalHost
    ? "http://localhost:2000"
    : `https://api.${window.location.host.split(".").slice(1).join(".")}`;

const api = process.env.REACT_APP_BASE_URL_API?.trim() || url;

const config = {
  urlApi: api,
  cognito: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    clientId:  process.env.REACT_APP_CLIENT_ID,
  },
};

export default config;