import { Box, Flex, Text } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { useBreadcrumb } from "core/features/breadcrumbs";
import { useForm } from "react-hook-form";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import CardDefault from "ui/components/Cards/CardDefault";
import Field from "ui/components/Input/Field";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { useCurrentTenant } from "core/features/auth/tenant.store";
import {
  showErrorAxios,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import useNaturezaProcesso from "core/features/natureza-processo/hooks/useNaturezaProcesso";
import { NaturezaProcessoResponse } from "core/features/natureza-processo/types";
import useSaveNaturezaProcesso from "core/features/natureza-processo/hooks/useSaveNaturezaProcesso";

export default function NaturezaProcessoDetalhes() {
  const { id } = useParams() as {
    id: string;
  };

  const navigate = useNavigate();

  const { tenant } = useCurrentTenant();

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Natureza do Processo", url: "/natureza-processo" },
    { name: `${id ? "Editar " : "Adicionar "}natureza do processo` },
  ]);

  const { isLoading: isLoadingSave, mutate: handleSave } =
    useSaveNaturezaProcesso();

  const { data, isLoading } = useNaturezaProcesso(id);

  const { control, handleSubmit, reset } = useForm<NaturezaProcessoResponse>();

  const onSubmit = async (data: NaturezaProcessoResponse) => {
    handleSave(
      data,

      {
        onSuccess: async (response) => {
          await showSuccess();
          if (!id && response.data) {
            window.location.replace(`/natureza-processo/${response.data}`);
          }
        },
        onError: (err) => {
          showErrorAxios(err);
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  useLoading(isLoading);

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit(onSubmit)(ev);
      }}
    >
      <Box>
        <Text
          fontWeight="semibold"
          fontSize="30px"
          color="black"
          flexWrap="wrap"
          mb={4}
        >
          {id ? "Editar " : "Adicionar "} natureza do processo
        </Text>

        <CardDefault mb={4}>
          <Box as={Row} gutter={16}>
            <Box as={Col} md={24}>
              <Field
                name="nome"
                label="Nome da natureza do processo"
                control={control}
                rules={{ required: true }}
              />
            </Box>
          </Box>
        </CardDefault>

        <CardDefault>
          <Flex flex={1} justify="end" gap={4}>
            <OutlineButton onClick={() => navigate("/natureza-processo")}>
              Cancelar
            </OutlineButton>
            <PrimaryButton type="submit" isLoading={isLoadingSave}>
              Salvar
            </PrimaryButton>
          </Flex>
        </CardDefault>
      </Box>
    </form>
  );
}
