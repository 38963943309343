import { Avatar, AvatarProps, Flex } from "@chakra-ui/react";

interface CardPersonProps {
  avatarUrl?: string;
  nome?: string;
  avatarProps?: AvatarProps;
  backgroundColor?: string;
}

export default function CardPerson({
  avatarUrl,
  nome,
  avatarProps,
  backgroundColor 
}: CardPersonProps) {
  return (
    <Flex >
      <Avatar
        name={nome?.trim()}
        src={avatarUrl?.trim()}
        bg={backgroundColor}
        {...avatarProps}
      />
    </Flex>
  );
}
