import colors from "core/resources/theme/colors";
import styled from "styled-components";

export const ProfileImage = styled.div`
  img {
    margin: auto;
    width: 35px;
    height: 35px;
    margin-top: -4px;
    border-radius: 18px;
  }

  @media all and (max-width: 480px) {
    img {
      width: 30px;
      height: 30px;
      margin-top: -3px;
    }
  }
`;

export const StyledHeader = styled.div<any>`
  background-color: white !important;
  width: 100%;
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.05);

  @media all and (max-width: 768px) {
    & {
      position: relative !important;
    }

    img.logo {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
`;

export const MenuMobileWrapper = styled.div`
  .ant-menu-inline {
    background: white !important;
    padding-left: 8px;
    padding-right: 8px;
  }

  .ant-menu-title-content,
  i {
    color: #555;
  }

  .ant-menu-item-group-title {
    padding-left: 8px !important;

    border-top: 1px solid #eee;
  }

  .ant-menu-item-group-list .ant-menu-item {
    padding-left: 8px !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: "white";
    border-right: 4px solid ${colors.gray400};
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: ${colors.gray400};
  }
`;
