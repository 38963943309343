import { Grid, Box, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { PerfilTabsRoutes, tabs } from "./typings";
import { useBreadcrumb } from "core/features/breadcrumbs";
import MenuDetalhes from "ui/components/Menu/MenuDetalhes";
import InformacoesBasicas from "./components/InformacoesBasicas";
import Endereco from "./components/Endereco";
import TrocarSenha from "./components/TrocarSenha";
import { useWindowSize } from "core/infra/helpers/window";

export default function EditarPerfil() {
  useBreadcrumb([{ name: "Editar perfil", url: "/editar-perfil" }]);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get("opcao") as PerfilTabsRoutes | null;
  const [selectedTab, setSelectedTab] = useState<PerfilTabsRoutes>(
    tab || PerfilTabsRoutes.InformacoesBasicas
  );

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 992;
  return (
    <Flex flexDir="column" w="100%">
      <Text fontWeight="500" fontSize="24px" color="black" flexWrap="wrap">
        Editar meu perfil
      </Text>

      <Grid templateColumns={isMobile ? "1fr" : "1fr 4fr"} gridGap={4} mt={8}>
        <Box h="fit-content">
          <MenuDetalhes
            options={tabs}
            selectedOption={selectedTab}
            onChangeOption={(tab) => {
              setSelectedTab(tab);
            }}
          />
        </Box>
        <Box>
          {selectedTab === PerfilTabsRoutes.InformacoesBasicas ? (
            <InformacoesBasicas />
          ) : null}

          {selectedTab === PerfilTabsRoutes.Endereco ? <Endereco /> : null}

          {selectedTab === PerfilTabsRoutes.TrocarSenha ? (
            <TrocarSenha />
          ) : null}
        </Box>
      </Grid>
    </Flex>
  );
}
