import { Box, Collapse, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import CardDefault from "ui/components/Cards/CardDefault";
import HeaderTable from "ui/components/HeaderTable";
import { checkPermission } from "core/infra/helpers/permission";

interface CollapseDefaultProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  title: string;
  addButton?: boolean;
  icon?: any;
  children?: React.ReactNode;
  checkbox?: React.ReactNode;
  dislabledButton?: boolean;
  permissionShow?: string[];
  permissionCreate?: string[];
}

export default function CollapseDefault({
  title,
  icon,
  onClick,
  addButton,
  children,
  checkbox,
  dislabledButton = false,
  permissionShow,
  permissionCreate,
}: CollapseDefaultProps) {
  const [collapsed, setCollapsed] = useState(true);

  const visiblePermission = checkPermission(permissionShow ?? []);

  const createPermission = checkPermission(permissionCreate ?? []);

  return (
    <>
      {visiblePermission || permissionShow === undefined ? (
        <CardDefault rounded="12px" p={0} mb={4} overflow="visible">
          <Flex flexDir="column" w="100%" p="16px">
            <Flex
              cursor="pointer"
              onClick={() => setCollapsed((state) => !state)}
            >
              <Flex w="100%" justify="space-between" align="center">
                <HeaderTable
                  title={title}
                  dislabledButton={
                    dislabledButton ||
                    (permissionCreate !== undefined && !createPermission)
                  }
                  addButton={collapsed == true ? false : addButton}
                  icon={icon}
                  onClick={onClick}
                />
                {!collapsed ? checkbox : null}
              </Flex>

              <Flex align="center">
                {collapsed ? (
                  <BsChevronDown fontSize={20} />
                ) : (
                  <BsChevronUp fontSize={20} />
                )}
              </Flex>
            </Flex>

            <Collapse in={!collapsed}>
              <Box position="relative" pt="16px">
                {children}
              </Box>
            </Collapse>
          </Flex>
        </CardDefault>
      ) : null}
    </>
  );
}
