import { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import { UseFormSetValue } from "react-hook-form";
import { SetorResponse } from "core/features/setor/typings/index";
import Toolbar from "ui/components/Toolbar";
import FooterModalDefault from "ui/components/FooterModal";
import SelectUsuarios from "ui/components/Input/SelectUsuarios";

interface ModalVincularPessoasProps {
  isOpen: boolean;
  onClose: () => any;
  setValue: UseFormSetValue<SetorResponse>;
  form: SetorResponse;
}

function ModalVincularPessoas({
  isOpen,
  onClose,
  setValue,
  form,
}: ModalVincularPessoasProps) {
  const [tempValues, setTempValues] = useState({
    pessoasVinculadasIds: form.pessoasVinculadasIds || [],
  });

  useEffect(() => {
    setTempValues((prevValues) => ({
      ...prevValues,
      pessoasVinculadasIds: form.pessoasVinculadasIds || [],
    }));
  }, [form.pessoasVinculadasIds, setTempValues]);

  const handleSaveClick = () => {
    setValue("pessoasVinculadasIds", tempValues.pessoasVinculadasIds);

    onClose();
  };

  const handleSelectPessoasChange = (values: any) => {
    setTempValues({ ...tempValues, pessoasVinculadasIds: values });
  };

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Salvar"
          onclickPrimaryButton={handleSaveClick}
        />
      }
      title={<Toolbar title="Vincular pessoa" onClose={onClose} />}
      destroyOnClose
      onCancel={onClose}
      maskClosable={false}
      closable={false}
      width="700px"
    >
      <Box as={Row} gutter={16} mb={8}>
        <Box as={Col} sm={24}>
          <Text mb={2}>Vincular pessoa*</Text>
          <SelectUsuarios
            form={form.pessoasVinculadasIds}
            value={tempValues.pessoasVinculadasIds}
            onChange={handleSelectPessoasChange}
            mode="multiple"
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalVincularPessoas;
