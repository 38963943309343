import { Flex } from "@chakra-ui/react";
import {
  CriterioEnum,
  CriteriosResponse,
} from "core/features/tipoprocesso/typings";
import colors from "core/resources/theme/colors";
import { FaEquals } from "react-icons/fa";

interface CardCriterioProps {
  item: CriteriosResponse;
}

export default function CardCriterio({ item }: CardCriterioProps) {
  const getCriterioLabel = () => {
    switch (item.criterioId) {
      case CriterioEnum.Assinatura:
        return "Assinatura";
      case CriterioEnum.AnexoDocumento:
        return "Anexo de documento";
      default:
        return "Visualização do departamento administrativo";
    }
  };

  return (
    <Flex bg={colors.labelBg} rounded="8px" align="center">
      <Flex
        borderLeftRadius="8px"
        align="center"
        justify="center"
        w="25px"
        bg="#CBD5E0"
        mr={4}
        h="35px"
      >
        <FaEquals />
      </Flex>

      <Flex fontSize="14px">{getCriterioLabel()}</Flex>
    </Flex>
  );
}
