import http from "core/features/auth/hooks/useApi";
import { PaginateResult } from "core/resources/types/shared";
import { useQuery } from "react-query";
import { FiltrosProcessos, ProcessoPaginateResponse } from "../typings";


function useListarProcessos(query: FiltrosProcessos) {
    return useQuery([`/v1/processo`, query], async () => {
        const { data } = await http.get<PaginateResult<ProcessoPaginateResponse>>(
            `/v1/processo`,
            {
                params: query,
            }
        );

        return data;
    });
}

export default useListarProcessos;