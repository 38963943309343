import { Row, Col } from "antd";
import Field from "ui/components/Input/Field";
import { Control, UseFormSetValue } from "react-hook-form";
import SelectTipoContato from "ui/components/Input/SelectTipoContato";
import { TipoContatoEnum } from "core/resources/enums";
import DeleteButton from "ui/components/Buttons/DeleteButton";
import { ContatoForm, UserProfileResponse } from "core/features/auth/types";

interface ContatoItemProps {
    control: Control<UserProfileResponse, any>;
    setValue: UseFormSetValue<UserProfileResponse>;
    contatos: ContatoForm[];
    index: number;
}

function ContatoItem({ control, index, contatos, setValue }: ContatoItemProps) {
    const onRemove = (index: number) => {
        const copy = [...contatos];

        copy.splice(index, 1);

        setValue("pessoa.contatos", copy);

    };

    const isEmail = contatos[index].tipoContatoId === TipoContatoEnum.Email;
    const isCellphonne = contatos[index].tipoContatoId === TipoContatoEnum.Celular;

    return (
        <Row gutter={16}>
            <Col sm={8}>
                <Field
                    label="Tipo"
                    control={control}
                    name={`pessoa.contatos.${index}.tipoContatoId`}
                    rules={{ required: true }}
                    render={({ field }) => <SelectTipoContato {...field} />}
                />
            </Col>

            {contatos[index].tipoContatoId ? (
                <Col sm={13}>
                    <Field
                        label="Contato"
                        control={control}
                        name={`pessoa.contatos.${index}.conteudo`}
                        rules={{ required: true }}
                        inputProps={{
                            type: isEmail ? "email" : isCellphonne ? "cellphone" : "phone",
                        }}
                    />
                </Col>
            ) : null}

            <Col sm={3}>
                <DeleteButton mt="20px" onClick={() => onRemove(index)} />
            </Col>
        </Row>
    );
}

export default ContatoItem;
