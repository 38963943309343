import { Box, Textarea, Flex, Text, Tag } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import FooterModalDefault from "ui/components/FooterModal";
import Toolbar from "ui/components/Toolbar";
import { useForm } from "react-hook-form";
import Field from "ui/components/Input/Field";
import { showConfirm3 } from "core/infra/helpers/alerta";
import { showSuccess, showErrorAxios } from "core/infra/helpers/alerta";
import useSaveDeleteProcessoDocumento from "core/features/processos-documento/hooks/useSaveDeleteProcessoDocumento";
import { ProcessoDocumentoDeleteResponse } from "core/features/processos-documento/typings";
import { MdInfo } from "react-icons/md";
import Anexos from "ui/components/Anexos/index";

interface ModalDeleteProcessoDocumentoProps {
  onClose: () => void;
  deleteIds?: string[];
  visible: boolean;
  idProcesso: string;
  setSelectedDocumentosIds: React.Dispatch<React.SetStateAction<string[]>>;
  refetch: any;
}

export default function ModalDeleteProcessoDocumento({
  onClose,
  visible,
  deleteIds,
  idProcesso,
  setSelectedDocumentosIds,
  refetch,
}: ModalDeleteProcessoDocumentoProps) {
  const { isLoading: isLoadingSave, mutate: handleSave } =
    useSaveDeleteProcessoDocumento(idProcesso);

  const { control, handleSubmit } = useForm<ProcessoDocumentoDeleteResponse>({
    defaultValues: {},
  });

  const close = () => {
    setSelectedDocumentosIds([]);
    onClose();
  };

  const onSubmit = (data: ProcessoDocumentoDeleteResponse) => {
    handleSave(
      { ...data, documentosIdsExcluidos: deleteIds! },
      {
        onSuccess: () => {
          showSuccess("Seu registro foi excluido com sucesso!").then(() => {
            close();
            refetch();
          });
        },
        onError: (err) => {
          showErrorAxios(err);
        },
      }
    );
  };

  const handleDelete = async () => {
    const { isConfirmed } = await showConfirm3({
      mensagem:
        "Você tem certeza que deseja excluir o registro definitivamente?",
    });

    if (isConfirmed) {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <Modal
      destroyOnClose
      closable={false}
      maskClosable={false}
      centered
      width="700px"
      open={visible}
      className="antd-custom-header"
      title={<Toolbar title="Excluir arquivo" onClose={close} />}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={close}
          titlePrimaryButton="Excluir"
          onclickPrimaryButton={handleDelete}
          saveLoading={isLoadingSave}
        />
      }
    >
      <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
        <Box>
          <Box as={Row} mb={4}>
            <Box as={Col} md={24}>
              <Field
                name="motivoExclusao"
                label="Motivo da exclusão"
                control={control}
                rules={{ required: true }}
                render={({ field, isInvalid }) => (
                  <Textarea
                    isInvalid={isInvalid}
                    className="chakra-input"
                    rows={4}
                    resize="none"
                    placeholder="Digite..."
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
