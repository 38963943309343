import { Flex } from "@chakra-ui/react";
import Tag from "./Tag";
import EmptyDefault from "ui/components/Empty";
import useDeletePalavraChave from "core/features/processos/hooks/useDeletePalavraChave";
import {
  showConfirm3,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import { Spin } from "antd";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { checkPermission } from "core/infra/helpers/permission";

interface ContentTagsProps {
  item?: {
    id: string;
    nome: string;
  }[];
  refetch?: any;
  setIdPalavrasChave: React.Dispatch<React.SetStateAction<string | undefined>>;
  isFetching: boolean;
  isLoading: boolean;
}

export default function ContentTags({
  item,
  refetch,
  isFetching,
  isLoading,
  setIdPalavrasChave,
}: ContentTagsProps) {
  const { mutate: deletePalavraChave, isLoading: isLoadingDelete } =
    useDeletePalavraChave();

  const handleDelete = async (id: string) => {
    const { isConfirmed } = await showConfirm3({
      mensagem:
        "Você tem certeza que deseja excluir o registro definitivamente?",
    });

    if (isConfirmed) {
      deletePalavraChave(id, {
        onSuccess: () => {
          refetch();
          setTimeout(() => {
            showSuccess("Seu registro foi excluido com sucesso!");
          }, 200);
        },
      });
    }
  };

  const permissaoPalavraChaveDelete = [
    PermissaoEnum.Fluir.Processo.PalavraChave.Excluir,
  ];

  const permissaoPalavraChaveEdit = [
    PermissaoEnum.Fluir.Processo.PalavraChave.Editar,
  ];

  const hasPermissionDelete = checkPermission(
    permissaoPalavraChaveDelete ?? []
  );

  const hasPermissionEdit = checkPermission(permissaoPalavraChaveEdit ?? []);

  useLoading(isLoadingDelete);

  return (
    <>
      {isFetching || isLoading || isLoadingDelete ? (
        <Flex alignItems="center" justifyContent="center">
          <Spin size="large" spinning />
        </Flex>
      ) : (
        <Flex gap={2} wrap="wrap">
          {item?.length && !isLoading && !isFetching && !isLoadingDelete ? (
            item?.map((v, index) => (
              <Flex key={v.id}>
                <Tag
                  hasPermissionDelete={hasPermissionDelete}
                  hasPermissionEdit={hasPermissionEdit}
                  onClickDelete={() => handleDelete(v.id)}
                  onClickEdit={() => setIdPalavrasChave(v.id)}
                  key={index}
                  title={v.nome}
                />
              </Flex>
            ))
          ) : (
            <Flex m="auto">
              <EmptyDefault size="sm" fontSize="12px" />
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
}
