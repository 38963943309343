import { FiltrosProcessos } from "core/features/processos/typings";
import { BaseFiltroPaginate } from "core/resources/enums/paginate";
import moment from "moment";
import { useCallback, useState } from "react";

export const filtroDefaultUsuarios: FiltrosProcessos = {
  limit: BaseFiltroPaginate.Limit,
  search: "",
  page: 1,
  creatorId: undefined,
  interessadoIds: undefined,
  assuntoId: undefined,
  dataInicio: moment("2022-01-01").format("YYYY-MM-DD"),
  dataFim: moment().add(2, "days").format("YYYY-MM-DD"),
  numeroProcesso: "",
  resumo: "",
  orderBy: "createdAt",
  orderDirection: "DESC",
  setorId: "",
  perfilId: "",
  tenantId: "",
  assinar: false,
  vinculado: false,
};

function useFiltroProcessos() {
  const [filtro, setFiltro] = useState<FiltrosProcessos>({
    ...filtroDefaultUsuarios,
  });

  const onChangeFiltro = useCallback((_filtro: Partial<FiltrosProcessos>) => {
    if (
      _filtro.search ||
      _filtro.creatorId ||
      _filtro.assuntoId !== undefined ||
      _filtro.assinar ||
      _filtro.vinculado
    ) {
      _filtro.page = 1;
    }
    setFiltro((state) => ({ ...state, ..._filtro }));
  }, []);

  const onResetFiltro = useCallback(() => {
    setFiltro(filtroDefaultUsuarios);
  }, []);

  return {
    filtro,
    setFiltro,
    onChangeFiltro,
    onResetFiltro,
  };
}

export default useFiltroProcessos;
