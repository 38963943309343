import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";

function useTipoDocumento() {
  return useQuery(
    ["/v1/shared/processo-documento/tipo-documento"],
    async () => {
      const { data } = await http.get<{ id: string; nome: string }[]>(
        "/v1/shared/processo-documento/tipo-documento"
      );

      return data;
    }
  );
}

export default useTipoDocumento;
