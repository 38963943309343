import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { UserProfileResponse } from "../types";
import { createFormData } from "core/infra/helpers/createFormData";

function useSaveUsuario() {
  return useMutation(
    (form: UserProfileResponse) => {
      const formData = createFormData(
        form.certificado || [],
        form,
        "certificado"
      );

      return http.put(`/v1/auth/profile`, formData);
    },

    { retry: false }
  );
}

export default useSaveUsuario;
