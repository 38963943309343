import http from "core/features/auth/hooks/useApi";
import { FiltrosProcessos } from "core/features/processos/typings";
import { PaginateResult } from "core/resources/types/shared";
import { useQuery } from "react-query";
import { ProcessoDocumentoPaginateResponse } from "../typings";

function useListarDocumentos(id: string, query: FiltrosProcessos) {
  return useQuery([`/v1/processo-documento/${id}`, query], async () => {
    const { data } = await http.get<
      PaginateResult<ProcessoDocumentoPaginateResponse>
    >(`/v1/processo-documento/${id}`, {
      params: query,
    });

    return data;
  });
}

export default useListarDocumentos;
