import { Box, Flex, Text } from "@chakra-ui/react";
import { Spin } from "antd";
import useDeleteAssociado from "core/features/processos/hooks/useDeleteAssociado";
import {
  showConfirm3,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import colors from "core/resources/theme/colors";
import moment from "moment";
import { IoMdTrash } from "react-icons/io";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu";
import CardDefault from "ui/components/Cards/CardDefault";
import EmptyDefault from "ui/components/Empty";
import { PermissaoEnum } from "core/resources/enums/permissao";

interface TableProcessosAssociadosProps {
  items?: {
    id: string;
    createdAt: string;
    numeroProcesso: string;
    assunto: string;
  }[];
  refetch?: any;
  isFetching: boolean;
  isLoading: boolean;
}

export default function TableProcessosAssociados({
  items,
  isFetching,
  isLoading,
  refetch,
}: TableProcessosAssociadosProps) {
  const { mutate: deleteAssociado, isLoading: isLoadingDelete } =
    useDeleteAssociado();

  const handleDelete = async (id: string) => {
    const { isConfirmed } = await showConfirm3({
      mensagem:
        "Você tem certeza que deseja excluir o registro definitivamente?",
    });

    if (isConfirmed) {
      deleteAssociado(id, {
        onSuccess: () => {
          refetch();
          setTimeout(() => {
            showSuccess("Seu registro foi excluido com sucesso!");
          }, 200);
        },
      });
    }
  };

  useLoading(isLoadingDelete);
  return (
    <>
      {isFetching || isLoading || isLoadingDelete ? (
        <Flex alignItems="center" justifyContent="center">
          <Spin size="large" spinning />
        </Flex>
      ) : (
        <Flex flexDir="column" gap={4}>
          {items?.length ? (
            <Box>
              <Flex fontWeight={500} fontSize="16px" mb={4} px=" 20px">
                <Flex flex={1}>Criado</Flex>
                <Flex flex={1}>N° do processo</Flex>
                <Flex flex={3.06} mr={6}>
                  Assunto
                </Flex>
              </Flex>
              {items?.map((item, index) => (
                <CardDefault
                  rounded="8px"
                  padding="6px 20px"
                  mb={2}
                  key={item.id}
                >
                  <Flex align="center">
                    <Text flex={1}>
                      {moment(item.createdAt).format("DD/MM/YYYY")}
                    </Text>
                    <Text flex={1}>{item.numeroProcesso}</Text>
                    <Text
                      flex={3}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      mr={6}
                    >
                      {item.assunto}
                    </Text>
                    <Flex h="fit-content" py={4}>
                      <ActionsKebabMenu
                        indexItem={index}
                        item={item}
                        actions={[
                          {
                            title: "Excluir",
                            permission:
                              PermissaoEnum.Fluir.Processo.ProcessoAssociado
                                .Excluir,
                            textColor: `${colors.red500}`,
                            onClick: (v) => handleDelete(v.id),
                            icon: () => <IoMdTrash />,
                          },
                        ]}
                      />
                    </Flex>
                  </Flex>
                </CardDefault>
              ))}
            </Box>
          ) : (
            <EmptyDefault size="sm" fontSize="12px" />
          )}
        </Flex>
      )}
    </>
  );
}
