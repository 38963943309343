export enum TabsUsuarioRoutes {
  InformacaoesBasicas = "informacoes-basicas",
  Endereco = "endereco",
}

export const TabMenuOptions: {
  label: string;
  route: TabsUsuarioRoutes;
  permission?: string;
}[] = [
    {
      label: "Informações básicas",
      route: TabsUsuarioRoutes.InformacaoesBasicas,
    },
    {
      label: "Endereço",
      route: TabsUsuarioRoutes.Endereco,
    },
  ];
