import { FiltrosSetor } from "core/features/setor/typings";
import { BaseFiltroPaginate } from "core/resources/enums/paginate";
import { useCallback, useState } from "react";

export const filtroDefaultSetores: FiltrosSetor = {
  limit: BaseFiltroPaginate.Limit,
  search: "",
  ativo: undefined,
  page: 1,
};

function useFiltroSetores() {
  const [filtro, setFiltro] = useState<FiltrosSetor>(filtroDefaultSetores);

  const onChangeFiltro = useCallback((_filtro: Partial<FiltrosSetor>) => {
    if ((_filtro.search || _filtro.ativo) !== undefined) {
      _filtro.page = 1;
    }
    setFiltro((state) => ({ ...state, ..._filtro }));
  }, []);

  return {
    filtro,
    setFiltro,
    onChangeFiltro,
  };
}

export default useFiltroSetores;
