import { Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import useHasPermission from "core/features/permissao/hooks/useHasPermission";

interface Option {
  label: string;
  route: any;
  permissions: string;
}

interface MenuRouteButtonProps {
  onChangeOption: (option: string) => any;
  option: Option;
  isSelected?: boolean;
}

function MenuRouteButton({
  option,
  isSelected,
  onChangeOption,
}: MenuRouteButtonProps) {
  const hasPermission = useHasPermission(option.permissions);

  if (!hasPermission) {
    return null;
  }
  return (
    <Flex
      fontSize="14px"
      key={option.route}
      onClick={() => onChangeOption(option.route)}
      cursor="pointer"
      color={isSelected ? "white" : colors.gray400}
      borderBottom={isSelected ? `3px solid ${colors.primary}` : ""}
      bg={isSelected ? colors.primary : ""}
      rounded="12px"
      px="16px"
      py="16.5px"
    >
      <Flex>{option.label}</Flex>
    </Flex>
  );
}

export default MenuRouteButton;
