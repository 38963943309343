import { Flex, Text } from "@chakra-ui/react";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu/index";
import { useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AssuntoProcessoPaginateResponse } from "core/features/assunto-processo/types";
import colors from "core/resources/theme/colors";
import { IoMdTrash } from "react-icons/io";
import { showConfirm3 } from "core/infra/helpers/alerta";
import { showSuccess } from "core/infra/helpers/alerta";
import { useLoading } from "core/infra/helpers/alerta";
import useDeleteAssuntoProcesso from "core/features/assunto-processo/hooks/useDeleteAssuntoProcesso";
import { PermissaoEnum } from "core/resources/enums/permissao";

interface TableItemRowProps {
  item: AssuntoProcessoPaginateResponse;
  indexItem: number;
  refetch?: any;
}

export default function TableItemRow({
  item,
  indexItem,
  refetch,
}: TableItemRowProps) {
  const navigate = useNavigate();

  const { mutate: deleteAlerta, isLoading: isLoadingDelete } =
    useDeleteAssuntoProcesso();

  const handleDelete = async (id: string) => {
    const { isConfirmed } = await showConfirm3({
      mensagem:
        "Você tem certeza que deseja excluir o registro definitivamente?",
    });

    if (isConfirmed) {
      deleteAlerta(id, {
        onSuccess: () => {
          setTimeout(() => {
            showSuccess("Seu registro foi excluido com sucesso!").then(() => {
              refetch();
            });
          }, 200);
        },
      });
    }
  };

  useLoading(isLoadingDelete);
  return (
    <Flex
      rounded={8}
      px={5}
      py={2}
      mb={2}
      bg="#FFFFFF"
      boxShadow="base"
      align="center"
      onClick={() => navigate(`/assunto-processo/${item.id}`)}
      cursor="pointer"
    >
      <Flex flex={1.98}>
        <Text fontSize="16px">{item.nome}</Text>
      </Flex>

      <Flex h="fit-content" py={4}>
        <ActionsKebabMenu
          indexItem={indexItem}
          item={item}
          actions={[
            {
              title: "Editar",
              icon: () => <MdEdit />,
              onClick: (v) => navigate(`/assunto-processo/${v.id}`),
            },
            {
              title: "Excluir",
              textColor: `${colors.red500}`,
              onClick: (v) => handleDelete(v.id!),
              icon: () => <IoMdTrash />,
              permission:
                PermissaoEnum.Fluir.Administrativo.AssuntoProcesso.Excluir,
            },
          ]}
        />
      </Flex>
    </Flex>
  );
}
