import { Box, Flex, Text, Switch, Tag } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { useForm } from "react-hook-form";
import { BsPlusLg } from "react-icons/bs";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import CardDefault from "ui/components/Cards/CardDefault";
import Field from "ui/components/Input/Field";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { useEffect, useRef, useState } from "react";
import ContatoItem from "./components/ContatoItem";
import { useNavigate } from "react-router-dom";
import UploadImagem from "ui/components/Input/UploadImagem";
import logoAdd from "assets/images/svg/add-foto.svg";
import useSaveUsuario from "core/features/usuario/hooks/useSaveUsuario";
import {
  showErrorAxios,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import { UsuarioResponse, ContatoForm } from "core/features/usuario/typings";
import useUsuario from "core/features/usuario/hooks/useUsuario";
import SelectSetor from "ui/components/Input/SelectSetor";
import SelectCargo from "ui/components/Input/SelectCargo";
import { isValidCPF, isValidEmail } from "@brazilian-utils/brazilian-utils";
import SelectStatus from "ui/components/Input/SelectStatus/SelectStatus";
import AnexarCertificado from "./components/AnexarCertificado";
import InputPassword from "ui/components/Input/InputPassword";
import { useWindowSize } from "core/infra/helpers/window";

export default function InformacoesBasicas({ id }: { id?: string }) {
  const { isLoading: isLoadingSave, mutate: handleSave } = useSaveUsuario();

  const { data, isLoading, refetch } = useUsuario(id);

  const [handleToggle, setHandleToggle] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const navigate = useNavigate();
  const tempIdIncrement = useRef(0);

  const defaultValues: UsuarioResponse = {
    ativo: undefined,
    senhaCertificado: "",
    pessoa: {
      nome: "",
      login: "",
      dataNascimento: "",
      documento: "",
      arquivoImagemId: "",
      matricula: "",
      perfilId: "",
      setorIds: [],
      contatos: [],
    },
  };

  const { control, setValue, watch, handleSubmit, reset } =
    useForm<UsuarioResponse>({ defaultValues });
  const contatos = watch("pessoa.contatos");
  const form = watch();

  const onSubmit = (data: UsuarioResponse) => {
    handleSave(
      {
        ...data,
        ativo: data.ativo === "ativo" ? true : false,
        pessoa: {
          ...data.pessoa,
        },
      },
      {
        onSuccess: (response) => {
          showSuccess().then(() => {
            if (!id && response) {
              window.location.replace(`/usuario/${response}`);
            }
            refetch();
          });
        },
        onError: (err) => {
          showErrorAxios(err);
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 992;

  useLoading(isLoading);

  return (
    <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
      <Box>
        <CardDefault mb={4} minHeight={"327px"} height={"fit-content"}>
          <Text fontWeight="semibold" fontSize="18px">
            Informações básicas
          </Text>

          <Flex gap={4} mt={8} flexDir={isMobile ? "column" : "row"}>
            <Flex m={isMobile ? "auto" : ""}>
              <Field
                control={control}
                name="pessoa.arquivoImagem"
                render={({ field }) => {
                  return (
                    <UploadImagem
                      {...field}
                      logoAdd={logoAdd}
                      setValue={setValue}
                      stringSetValue={"pessoa.arquivoImagemId"}
                    />
                  );
                }}
              />
            </Flex>
            <Box w="100%">
              <Box as={Row} gutter={16} mb={4}>
                <Box as={Col} md={14}>
                  <Field
                    name="pessoa.nome"
                    label="Nome"
                    control={control}
                    rules={{ required: true }}
                  />
                </Box>
                <Box as={Col} md={10}>
                  <Field
                    name="pessoa.documento"
                    label="CPF"
                    control={control}
                    rules={{
                      required: true,
                      validate: (v) => isValidCPF(v) || "CPF inválido",
                    }}
                    inputProps={{
                      type: "cpf",
                    }}
                  />
                </Box>
              </Box>

              <Box as={Row} gutter={16} mb={4}>
                <Box as={Col} md={14}>
                  <Field
                    name="pessoa.matricula"
                    label="Matrícula"
                    control={control}
                  />
                </Box>
                <Box as={Col} md={10}>
                  <Field
                    name="pessoa.dataNascimento"
                    label="Data de nascimento"
                    control={control}
                    inputProps={{ type: "date" }}
                  />
                </Box>
              </Box>

              <Box as={Row} gutter={16} mb={4}>
                <Box as={Col} md={14}>
                  <Field
                    name="pessoa.setorIds"
                    label="Setor"
                    control={control}
                    render={({ field, isInvalid }) => (
                      <SelectSetor
                        mode="multiple"
                        isInvalid={isInvalid}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box as={Col} md={10}>
                  <Field
                    name="pessoa.perfilId"
                    label="Cargo"
                    control={control}
                    render={({ field }) => <SelectCargo {...field} />}
                  />
                </Box>
              </Box>
            </Box>
          </Flex>
        </CardDefault>
        <CardDefault mb={4}>
          <Flex gap={10} align="center">
            <Text fontWeight="semibold" fontSize="18px">
              Acesso ao sistema
            </Text>
          </Flex>

          <Box as={Row} gutter={16} my={8}>
            <Box as={Col} md={16}>
              <Field
                name="pessoa.login"
                label="Usuário"
                control={control}
                rules={{
                  required: true,
                  validate: (v) => isValidEmail(v) || "Email inválido",
                }}
                inputProps={{
                  type: "email",
                }}
              />
            </Box>
            <Box as={Col} md={8}>
              <Field
                label="Status"
                control={control}
                name={"ativo"}
                rules={{ required: true }}
                render={({ field }) => <SelectStatus {...field} />}
              />
            </Box>
          </Box>
        </CardDefault>
        <CardDefault mb={4}>
          <Flex gap={10} align="center" mb={4}>
            <Text fontWeight="semibold" fontSize="18px">
              Contatos
            </Text>
            <OutlineButton
              onClick={() => {
                setValue("pessoa.contatos", [
                  ...(contatos ?? []),
                  {
                    added: true,
                    tempId: `${new Date().getTime().toString()}${
                      tempIdIncrement.current
                    }`,
                  } as ContatoForm,
                ]);

                tempIdIncrement.current += 1;
              }}
            >
              <BsPlusLg size={20} />
              Adicionar
            </OutlineButton>
          </Flex>

          <Flex flexDir="column" gap={4}>
            {contatos?.map((contato: ContatoForm, index: number) => (
              <ContatoItem
                setValue={setValue}
                contatos={contatos}
                key={contato.added ? contato.tempId : contato.id}
                index={index}
                control={control}
              />
            ))}
          </Flex>
        </CardDefault>
        <CardDefault mb={4}>
          <Flex justify="space-between" mb={8}>
            <Flex align="center" gap={2}>
              <Text fontWeight="semibold" fontSize="18px">
                Certificado digital
              </Text>
              <Tag
                colorScheme={
                  data?.certificadoDigital === true ? "green" : "red"
                }
                h={5}
              >
                {data?.certificadoDigital === true ? "Ativo" : "Ausente"}
              </Tag>
            </Flex>
            <Flex gap={2}>
              <Text>
                {id && data?.certificadoDigital === true
                  ? "Adicionar um novo certificado digital"
                  : "Adicionar um certificado digital"}{" "}
                ?
              </Text>
              <Switch
                isDisabled={isLoading}
                onChange={() => {
                  setHandleToggle(!handleToggle);
                }}
                isChecked={handleToggle}
              />
            </Flex>
          </Flex>

          {handleToggle ? (
            <Box as={Row} gutter={16} mb={4}>
              <Box as={Col} md={14} mb={4}>
                <Field
                  name="senhaCertificado"
                  label="Senha do certificado"
                  control={control}
                  render={({ field }) => (
                    <InputPassword
                      showPassword={showPassword}
                      onClick={handleClickShowPassword}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
              </Box>
              <Box as={Col} md={14}>
                <AnexarCertificado setValue={setValue} form={form} />
              </Box>
            </Box>
          ) : null}
        </CardDefault>
        <CardDefault>
          <Flex flex={1} justifyContent="flex-end" gap={4}>
            <OutlineButton onClick={() => navigate("/usuario")}>
              Cancelar
            </OutlineButton>
            <PrimaryButton type="submit" isLoading={isLoadingSave}>
              Salvar
            </PrimaryButton>
          </Flex>
        </CardDefault>
      </Box>
    </form>
  );
}
