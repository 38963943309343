import { Flex, Radio, RadioGroup } from "@chakra-ui/react";
import { Modal, Row } from "antd";
import colors from "core/resources/theme/colors";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FooterModalDefault from "ui/components/FooterModal";
import Toolbar from "ui/components/Toolbar";

interface ModalAdicionarDocumentosProps {
  onClose: () => void;
  visible: boolean;
  id: string;
}

export default function ModalAdicionarDocumentos({
  onClose,
  visible,
  id,
}: ModalAdicionarDocumentosProps) {
  const [radio, setRadio] = useState("");
  const navigate = useNavigate();
  return (
    <Modal
      destroyOnClose
      closable={false}
      maskClosable={false}
      centered
      width="700px"
      open={visible}
      className="antd-custom-header"
      title={<Toolbar title="Documentos" onClose={onClose} />}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Próximo"
          disabledPrimaryButton={!radio}
          onclickPrimaryButton={() => {
            if (radio === "anexar")
              return navigate(`documento/novo?opcao=anexar`);
            return navigate(`documento/novo?opcao=escrever`);
          }}
        />
      }
    >
      <Flex ml={4}>
        <RadioGroup
          value={radio}
          onChange={setRadio}
          as={Row}
          gutter={32}
          mb={6}
        >
          <Flex gap={4}>
            <Radio
              _checked={{
                background: "white",
                border: "5px solid",
                borderColor: colors.primary,
              }}
              _active={{ background: colors.primary }}
              value={"anexar"}
            >
              Anexar um documento
            </Radio>

            <Radio
              _checked={{
                background: "white",
                border: "5px solid",
                borderColor: colors.primary,
              }}
              value={"ecrever"}
            >
              Escrever um documento
            </Radio>
          </Flex>
        </RadioGroup>
      </Flex>
    </Modal>
  );
}
