import { Button, ButtonProps, Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Flex, useMediaQuery } from "@chakra-ui/react";

export default function DeleteButton({ ...rest }: ButtonProps) {
  const [isSmallScreen] = useMediaQuery("(max-width: 992px)");

  return (
    <Button
      alignItems="center"
      cursor="pointer"
      _hover={{ bg: colors.red50 }}
      gap={1}
      bg={colors.red50}
      px={isSmallScreen ? 1 : 2}
      py={isSmallScreen ? 1 : 2}
      rounded="8px"
      {...rest}
    >
      <RiDeleteBin6Fill size={isSmallScreen ? 18 : 25} color={colors.red500} />

      <Text
        fontWeight="medium"
        fontSize={isSmallScreen ? "12px" : "14px"}
        color={colors.red500}
      >
        Excluir
      </Text>
    </Button>
  );
}
