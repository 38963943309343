import { Flex, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import CardDefault from "ui/components/Cards/CardDefault";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import FormEndereco from "ui/components/FormEndereco/index";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { showSuccess, useLoading } from "core/infra/helpers/alerta";
import useOrganizacaoEndereco from "core/features/organizacao/hooks/useOrganizacaoEndereco";
import { OrganizacaoEnderecoResponse } from "core/features/organizacao/typings";
import useSaveOrganizacaoEndereco from "core/features/organizacao/hooks/useSaveOrganizacaoEndereco";

export default function Endereco({ id }: { id: string }) {
  const { mutate: saveEndereco, isLoading: isLoadingSave } =
    useSaveOrganizacaoEndereco(id);

  const { data, isLoading, refetch } = useOrganizacaoEndereco(id);

  const navigate = useNavigate();

  const { control, handleSubmit, reset, watch, setValue } =
    useForm<OrganizacaoEnderecoResponse>();

  const onSubmit = (data: OrganizacaoEnderecoResponse) => {
    saveEndereco(data, {
      onSuccess: () => {
        showSuccess();
        refetch();
      },
    });
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  useLoading(isLoading);

  const form = watch();

  return (
    <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
      <CardDefault mb={4}>
        <Text fontWeight="semibold" fontSize="18px" mb={4}>
          Endereço
        </Text>

        <FormEndereco control={control} form={form} setValue={setValue} />
      </CardDefault>
      <CardDefault>
        <Flex flex={1} justifyContent="flex-end" gap={4}>
          <OutlineButton onClick={() => navigate("/organizacao")}>
            Cancelar
          </OutlineButton>
          <PrimaryButton type="submit" isLoading={isLoadingSave}>
            Salvar
          </PrimaryButton>
        </Flex>
      </CardDefault>
    </form>
  );
}
