import CardDefault from "ui/components/Cards/CardDefault";
import Pagination from "ui/components/Pagination";
import { PaginateResult } from "core/resources/types/shared";
import { Box } from "@chakra-ui/react";
import HeaderTableRow from "../HeaderTableRow";
import {
  FiltroFluxoProcesso,
  FluxoProcessoPaginateResponse,
} from "core/features/tipoprocesso/typings/index";
import SkeletonDefault from "ui/components/SkeletonDefault";
import EmptyDefault from "ui/components/Empty";
import TableItemRow from "../TableItemRow";
import useDeleteFluxoProcesso from "core/features/tipoprocesso/hooks/useDeleteFluxoProcesso";
import HeaderDefaultRowTable from "ui/components/HeaderDefaultRowTable";

interface FluxoProcessoTableProps {
  data?: PaginateResult<FluxoProcessoPaginateResponse>;
  isLoading: boolean;
  onChangeFiltro: (_filtro: Partial<FiltroFluxoProcesso>) => void;
  refetch: any;
}

export default function FluxoProcessoTable({
  data,
  isLoading,
  onChangeFiltro,
  refetch,
}: FluxoProcessoTableProps) {
  const { isLoading: isLoadingDelete, mutate: handleDelete } =
    useDeleteFluxoProcesso();

  const _isLoading = isLoading || isLoadingDelete;

  return (
    <>
      <CardDefault>
        <HeaderDefaultRowTable children={<HeaderTableRow />} />

        {_isLoading ? (
          <SkeletonDefault h="76px" rounded="8px" mb={2} amount={7} />
        ) : null}

        {data?.items?.map((item, index) => (
          <TableItemRow
            key={item.id}
            item={item}
            indexItem={index}
            refetch={refetch}
            handleDelete={handleDelete}
          />
        ))}

        {!data?.items.length && !_isLoading ? <EmptyDefault /> : null}
        {data?.items.length ? (
          <Box m={4}>
            <Pagination
              style={{ marginLeft: "auto", marginBottom: 16 }}
              current={data?.meta?.currentPage ?? 1}
              total={data?.meta?.totalItems ?? 0}
              pageSize={data?.meta?.itemsPerPage ?? 0}
              onChange={(page, limit) => {
                onChangeFiltro({ page, limit });
              }}
            />
          </Box>
        ) : null}
      </CardDefault>
    </>
  );
}
