import { Grid, Box, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import MenuDetalhes from "ui/components/Menu/MenuDetalhes";
import InformacoesBasicas from "./components/InformacoesBasicas/index";
import Endereco from "./components/Endereco/index";
import { useBreadcrumb } from "core/features/breadcrumbs";
import { TabsOrganzacaoRoutes, tabs } from "./typings";
import { useWindowSize } from "core/infra/helpers/window";

export default function OrganizacaoDetalhes() {
  const { id } = useParams() as {
    id: string;
  };

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Organizações", url: "organizacao" },
    { name: id ? "Editar Organização " : "Cadastro de organizacão" },
  ]);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get("opcoes") as TabsOrganzacaoRoutes | null;
  const [selectedTab, setSelectedTab] = useState<TabsOrganzacaoRoutes>(
    tab || TabsOrganzacaoRoutes.InformacaoesBasicas
  );

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 992;
  return (
    <Flex flexDir="column" w="100%">
      <Text fontWeight="semibold" fontSize="30px" color="black" flexWrap="wrap">
        {id ? "Editar Organização " : "Cadastro de organizacão"}
      </Text>

      <Grid templateColumns={isMobile ? "1fr" : "1fr 4fr"} gridGap={4} mt={8}>
        <Box h="fit-content">
          <MenuDetalhes
            options={tabs}
            selectedOption={selectedTab}
            onChangeOption={(tab) => {
              setSelectedTab(tab);
            }}
            disabledOptions={!id ? [TabsOrganzacaoRoutes.Endereco] : []}
          />
        </Box>

        <Box>
          {selectedTab === TabsOrganzacaoRoutes.InformacaoesBasicas ? (
            <InformacoesBasicas id={id} />
          ) : null}

          {selectedTab === TabsOrganzacaoRoutes.Endereco ? (
            <Endereco id={id} />
          ) : null}
        </Box>
      </Grid>
    </Flex>
  );
}
