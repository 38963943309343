import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { ProcessoDocumentoDeleteResponse } from "../typings";

function useSaveDeleteProcessoDocumento(id?: string) {
  return useMutation(
    async (form: ProcessoDocumentoDeleteResponse) => {
      const { data } = await http.post(
        `/v1/processo-documento/${id}/delete`,
        form
      );

      return data;
    },
    { retry: false }
  );
}

export default useSaveDeleteProcessoDocumento;
