import { useEffect } from "react";
import Swal, { SweetAlertOptions } from "sweetalert2";
import confirmDelet from "assets/images/svg/confirmDelet.svg";
import colors from "core/resources/theme/colors";
import "./style.css";

export function showError(
  message = "Houve um problema ao realizar esta operação",
  config: SweetAlertOptions = {}
) {
  const textHtml = `<div class='textHtml'>
  <h2 class='h2'>${message}</h2>
  </div>`;
  const titleHtml = `<div class='header'>
  <button ></button>
  </div>`;

  const instance = Swal.fire({
    cancelButtonColor: colors.gray200,
    cancelButtonText: "Fechar",
    showCancelButton: true,
    showConfirmButton: false,
    buttonsStyling: true,
    title: titleHtml,
    html: textHtml,
    imageWidth: "600px",
    imageHeight: "200px",
    padding: 0,
    background: ` red ${confirmDelet}`,
    ...config,
  });

  return instance;
}

export function showErrorAxios(error: any, fallbackMessage?: string) {
  let message: any = undefined;

  if (!error) {
    return message ?? fallbackMessage;
  }

  if (
    Number(error.response?.status) >= 400 &&
    Number(error.response?.status) <= 499
  ) {
    if (typeof error.response?.data?.message === "string") {
      message = error.response.data.message;
    } else if (
      Array.isArray(error.response?.data?.message) &&
      error.response.data.message.length
    ) {
      message = error.response.data.message[0];
    }
  } else if (Number(error.response?.status) === 500) {
    message = "Está havendo uma inconsistência no sistema no momento.";
  }

  setTimeout(() => {
    showError(message ?? fallbackMessage);
  }, 150);

  return;
}

export function showSuccess(
  message = "Seu registro foi salvo com sucesso!",
  config: SweetAlertOptions = {}
) {
  const textHtml = `<div class='textHtml'>
  <h1>Sucesso!</h1>
  <h2 class='h2'>${message}</h2>
  </div>`;
  const headerShowConfirm = `<div class='headerShowConfirm'>
  <button ></button>
  </div>`;

  const closeButtonHtml = `<div>x
  </div>`;

  const instance = Swal.fire({
    title: headerShowConfirm,
    showCloseButton: true,
    closeButtonHtml: closeButtonHtml,
    html: textHtml,
    showConfirmButton: false,
    cancelButtonColor: colors.gray200,
    cancelButtonText: "Fechar",
    buttonsStyling: true,
    showCancelButton: true,

    ...config,
  });

  return instance;
}

export function showConfirm2(config: {
  message?: string;
  onOk?: () => void;
  onCancel?: () => void;
}) {
  config.message = config.message || "Deseja realmente realizar esta operação?";

  const instance = Swal.fire({
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    title: "Aviso",
    icon: "info",
    text: config.message ?? "Deseja realmente realizar esta operação?",
    ...config,
  });

  instance.then(({ isConfirmed }) => {
    if (isConfirmed && config.onOk) {
      setTimeout(() => {
        config.onOk!();
      }, 250);
    } else if (!isConfirmed && config.onCancel) {
      config.onCancel();
    }
  });

  return instance;
}

interface ShowConfirm3Props extends SweetAlertOptions {
  mensagem?: string;
  titulo?: string;
  tituloButtonConfirm?: string;
}

export function showConfirm3({
  mensagem,
  titulo,
  tituloButtonConfirm,
  ...config
}: ShowConfirm3Props) {
  const confirmButtonText = tituloButtonConfirm
    ? tituloButtonConfirm
    : "Excluir";
  const message = mensagem
    ? mensagem
    : "Você tem certeza que deseja excluir o registro definitivamente?";
  const title = titulo ? titulo : "Excluir registro?";
  const textHtml = `<div class='textHtml'>
  <h1>${title}</h1>
  <h2 class='h2'>${message}</h2>
  </div>`;
  const titleHtml = `<div class='header'>
  <button ></button>
  </div>`;

  const closeButtonHtml = `<div>x
  </div>`;
  const instance = Swal.fire({
    showCancelButton: true,
    buttonsStyling: true,
    cancelButtonText: "Cancelar",
    title: titleHtml,
    showCloseButton: true,
    closeButtonHtml: closeButtonHtml,
    cancelButtonColor: colors.gray200,
    confirmButtonColor: colors.red600,
    confirmButtonAriaLabel: "red",
    confirmButtonText: confirmButtonText,
    html: textHtml,
    imageWidth: "600px",
    imageHeight: "200px",
    padding: 0,
    background: ` red ${confirmDelet}`,
    reverseButtons: true,
    ...config,
  });

  return instance;
}

export function showLoading() {
  if (Swal.isVisible()) {
    Swal.close();
    setTimeout(() => Swal.showLoading(), 100);
    return;
  }

  Swal.showLoading();
}

export function hideLoading() {
  Swal.close();
}

export function useLoading(bool: boolean) {
  useEffect(() => {
    requestAnimationFrame(() => {
      if (bool) {
        showLoading();
      } else {
        hideLoading();
      }
    });

    return () => hideLoading();
  }, [bool]);
}
