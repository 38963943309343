import { Flex } from "@chakra-ui/react";

export default function HeaderTableRow() {
  return (
    <>
      <Flex flex={2}>Nome do setor</Flex>
      <Flex flex={1}>Pessoas vinculadas</Flex>
      <Flex flex={0.44}>Status</Flex>
    </>
  );
}
