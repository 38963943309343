import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { CargoResponse } from "../typings";
import { useEffect } from "react";
import { useInvalidateQuery } from "core/infra/packages/@chakra-ui/react-query/index";

function useCargo(id?: string) {
  const { doInvalidateQueryWithStringKey } = useInvalidateQuery();

  useEffect(() => {
    return () => {
      doInvalidateQueryWithStringKey(`/v1/cargo/${id}`);
    };
  }, [id, doInvalidateQueryWithStringKey]);

  return useQuery(
    `/v1/cargo/${id}`,
    async () => {
      const res = await http.get<CargoResponse>(`/v1/cargo/${id}`);
      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useCargo;
