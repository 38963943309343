import { Flex } from "@chakra-ui/react";

export default function HeaderTableRow() {
  return (
    <>
      <Flex flex={1.5}>Nome</Flex>
      <Flex flex={1}>Natureza do processo</Flex>
      <Flex flex={0.8}>Andamentos</Flex>
    </>
  );
}
