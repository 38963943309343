import { Flex } from "@chakra-ui/react";
import MenuRouteButton from "./MenuRouteButton";
import colors from "core/resources/theme/colors";

interface MenuRouteProps<T = any> {
  options: {
    label: string;
    route: T;
    permissions?: string[];
  }[];
  selectedOption: T;
  onChangeOption: (option: T) => any;
}

function MenuRoute<T = any>({
  options,
  selectedOption,
  onChangeOption,
}: MenuRouteProps<T>) {
  return (
    <Flex
      border={`solid 1px ${colors.labelBg}`}
      rounded={12}
      w="max-content"
      boxShadow="0 0 24px rgba(0,0,0,.05)"
      gap={4}
      mb={4}
      bg="white"
      px="3px"
    >
      {options.map((option, key) => {
        const isSelected = selectedOption === option.route ? true : false;

        return (
          <MenuRouteButton
            key={key}
            option={option as any}
            isSelected={isSelected}
            onChangeOption={() => onChangeOption(option.route)}
          />
        );
      })}
    </Flex>
  );
}

export default MenuRoute;
