import { Box, Flex, Text } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { useBreadcrumb } from "core/features/breadcrumbs";
import { useForm } from "react-hook-form";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import CardDefault from "ui/components/Cards/CardDefault";
import Field from "ui/components/Input/Field";
import { BsPlusLg } from "react-icons/bs";
import { useEffect, useState } from "react";
import ModalAndamento from "./components/ModalAndamento";
import CardCollapseAndamento from "./components/CardCollapseAndamento/index";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { FluxoProcessoResponse } from "core/features/tipoprocesso/typings";
import useFluxoProcesso from "core/features/tipoprocesso/hooks/useFluxoProcesso";
import {
  showErrorAxios,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import SelectNaturezaProcesso from "ui/components/Input/SelectNaturezaProcesso";
import useSaveFluxoProcesso from "core/features/tipoprocesso/hooks/useSaveFluxoProcesso";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import EmptyDefault from "ui/components/Empty";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { checkPermission } from "core/infra/helpers/permission";
import FluxogramaFluxoProcesso from "./components/FluxogramaFluxoProcesso";

export default function FluxoProcessoDetalhes() {
  const { id } = useParams() as {
    id: string;
  };

  const navigate = useNavigate();
  useBreadcrumb([
    { name: "Fluxo de Processo", url: "/fluxo-processo" },
    { name: id ? "Editar Fluxo de Processo" : "Cadastrar Fluxo de Processo" },
  ]);

  const { data, isLoading, refetch } = useFluxoProcesso(id);

  const { mutate: handleSave, isLoading: isLoadingSave } =
    useSaveFluxoProcesso(id);

  const defaultValues: FluxoProcessoResponse = {
    fluxosAndamento: [],
    naturezaProcessoId: "",
    nome: "",
  };

  const { control, watch, setValue, handleSubmit, reset } =
    useForm<FluxoProcessoResponse>({ defaultValues });

  const form = watch();

  const onSubmit = async (data: FluxoProcessoResponse) => {
    handleSave(
      {
        ...data,
        fluxosAndamento: data.fluxosAndamento.map((andamento, index) => ({
          ...andamento,
          prazo: Number(andamento.prazo),
          setorId: andamento.setorId,
        })),
      },
      {
        onSuccess: (response) => {
          showSuccess().then(() => {
            if (!id && response.data) {
              window.location.replace(`/fluxo-processo/${response.data}`);
            }
            refetch();
          });
        },
        onError: (err) => {
          showErrorAxios(err);
        },
      }
    );
  };

  const [indexEdit, setIndexEdit] = useState<number | undefined>();
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (indexEdit !== undefined) {
      setIsOpenModal(true);
    }
  }, [indexEdit]);

  useEffect(() => {
    if (!isOpenModal) {
      setIndexEdit(undefined);
    }
  }, [isOpenModal]);

  const onRemoveItem = (index: number, id: string) => {
    const copy = [...form.fluxosAndamento];
    const removedItem = copy.splice(index, 1)[0];
    const andamentosIdsExcluidos = form.andamentosIdsExcluidos
      ? [...form.andamentosIdsExcluidos]
      : [];
    if (removedItem && removedItem.id) {
      andamentosIdsExcluidos.push(removedItem.id);
    }
    setValue("fluxosAndamento", copy);
    setValue("andamentosIdsExcluidos", andamentosIdsExcluidos);
  };

  const defaultDataModal =
    indexEdit !== undefined ? form.fluxosAndamento?.[indexEdit] : undefined;

  useEffect(() => {
    if (data) {
      const sortedItems = data.fluxosAndamento?.sort(
        (a, b) => a.ordemAndamento! - b.ordemAndamento!
      );
      reset({ ...data, fluxosAndamento: sortedItems || [] });
    }
  }, [data, reset]);

  useLoading(isLoading);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(form.fluxosAndamento);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      ordemAndamento: index,
    }));

    setValue("fluxosAndamento", updatedItems);
  };

  const permission = PermissaoEnum.Fluir.Processo.FluxoProcesso.Excluir;

  const hasPermission = checkPermission(permission);

  console.log(form);

  return (
    <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
      <Box>
        <Text
          fontWeight="semibold"
          fontSize="30px"
          color="black"
          flexWrap="wrap"
          mb={4}
        >
          {id ? "Editar " : "Cadastro de "} fluxo de processo
        </Text>

        <CardDefault mb={4}>
          <Box as={Row} gutter={32}>
            <Box as={Col} md={12}>
              <Field
                name="nome"
                label="Nome"
                control={control}
                rules={{ required: true }}
              />
            </Box>
            <Box as={Col} md={12}>
              <Field
                name="naturezaProcessoId"
                label="Natureza do processo"
                control={control}
                rules={{ required: true }}
                render={({ field, isInvalid }) => (
                  <SelectNaturezaProcesso isInvalid={isInvalid} {...field} />
                )}
              />
            </Box>
          </Box>
        </CardDefault>

        <CardDefault mb={4}>
          <Flex mb={6} gap={10} align="center">
            <Text fontWeight="semibold" fontSize="18px">
              Fluxos de andamento
            </Text>
            <OutlineButton onClick={() => setIsOpenModal(true)}>
              <BsPlusLg size={20} />
              Adicionar
            </OutlineButton>
          </Flex>

          {!form.fluxosAndamento.length && !isLoading ? <EmptyDefault /> : null}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                  {form.fluxosAndamento
                    ? form.fluxosAndamento.map((item, index) => (
                        <Draggable
                          key={index}
                          draggableId={`item-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CardCollapseAndamento
                                key={index}
                                item={item}
                                index={index}
                                onRemoveItem={onRemoveItem}
                                setIndexEdit={setIndexEdit}
                                hasPermission={hasPermission}
                              />
                            </Box>
                          )}
                        </Draggable>
                      ))
                    : null}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </CardDefault>
        {form.fluxosAndamento.length ? (
          <CardDefault mb={4}>
            <Flex mb={6} gap={10} align="center">
              <Text fontWeight="semibold" fontSize="18px">
                Fluxograma
              </Text>
            </Flex>

            <FluxogramaFluxoProcesso form={form.fluxosAndamento} />
          </CardDefault>
        ) : null}

        <CardDefault>
          <Flex flex={1} justify="end" gap={4}>
            <OutlineButton
              onClick={() => {
                navigate("/fluxo-processo");
              }}
            >
              Cancelar
            </OutlineButton>
            <PrimaryButton type="submit" isLoading={isLoadingSave}>
              Salvar
            </PrimaryButton>
          </Flex>
        </CardDefault>

        {isOpenModal ? (
          <ModalAndamento
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            isEdit={indexEdit !== undefined}
            defaultData={defaultDataModal}
            setValueAndamento={setValue}
            formAndamento={form}
            hasPermission={hasPermission}
            onSubmit={(item) => {
              const items = form.fluxosAndamento || [];

              if (indexEdit !== undefined) {
                const copy = [...items];

                copy[indexEdit] = item;

                setValue("fluxosAndamento", copy);
              } else {
                const newItems = items.concat({
                  ...item,
                  ordemAndamento: items.length,
                });

                setValue("fluxosAndamento", newItems);
              }
              setIsOpenModal(false);
            }}
          />
        ) : null}
      </Box>
    </form>
  );
}
