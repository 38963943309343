import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { ProcessoDetalhesResponse } from "../typings";

function useProcesso(id?: string) {
  return useQuery(
    `/v1/processo/${id}`,
    async () => {
      const res = await http.get<ProcessoDetalhesResponse>(
        `/v1/processo/${id}`
      );

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useProcesso;
