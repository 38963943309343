import { Box, Flex } from "@chakra-ui/react";
import PageHeader from "ui/components/Page/PageHeader";
import UsuariosTable from "./components/UsuariosTable";
import { useBreadcrumb } from "core/features/breadcrumbs";
import SearchHeaderBar from "ui/components/SearchHeaderBar/index";
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { MdAdd, MdGroups, MdKeyboardArrowDown, MdPerson } from "react-icons/md";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import useListarUsuarios from "core/features/usuario/hooks/useListarUsuarios";
import useFiltroUsuarios from "./hooks/useFiltroUsuarios";
import SeletctStatusFiltro from "ui/components/Input/SeletctStatusFiltro";
import SlectFiltroCargos from "ui/components/Input/SelectFiltroCargos";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { checkPermission } from "core/infra/helpers/permission";

export default function ListagemUsuariosPage() {
  const { filtro, onChangeFiltro } = useFiltroUsuarios();
  const { data, isLoading } = useListarUsuarios(filtro);

  const navigate = useNavigate();

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Usuários", url: "cadastro-usuario" },
  ]);

  const createPermission = [PermissaoEnum.Fluir.Administrativo.Usuario.Criar];

  const hasPermission = checkPermission(createPermission ?? []);

  return (
    <Box>
      <Box mb={10}>
        <PageHeader
          title="Usuários"
          midComponent={
            <Box pos="relative">
              <Menu placement="bottom">
                <MenuButton
                  as={PrimaryButton}
                  size="sm"
                  leftIcon={<MdAdd size={20} />}
                  rightIcon={<MdKeyboardArrowDown size={20} />}
                  disabled={!hasPermission}
                >
                  Adicionar
                </MenuButton>
                <MenuList position="absolute" top="38px">
                  <MenuItem
                    onClick={() => navigate("/usuario/novo")}
                    icon={<MdPerson size={20} />}
                    fontSize="14px"
                  >
                    Um usuário
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/usuario/novo")}
                    icon={<MdGroups size={20} />}
                    fontSize="14px"
                  >
                    Vários usuários
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          }
        />

        <SearchHeaderBar
          defaultValue={filtro.search || ""}
          onSearch={(v) => onChangeFiltro({ search: v })}
          maxWInputSearch={700}
          placeholder="Buscar"
          filterStatus={
            <Flex w="100px">
              <SeletctStatusFiltro
                onChange={(ativo) => {
                  onChangeFiltro({ ativo: ativo });
                }}
                value={filtro.ativo}
              />
            </Flex>
          }
          filtroCargos={
            <Flex w="180px">
              <SlectFiltroCargos
                onChange={(cargo) => onChangeFiltro({ cargo: cargo })}
                value={filtro.cargo}
              />
            </Flex>
          }
        />
      </Box>

      <UsuariosTable
        data={data}
        isLoading={isLoading}
        onChangeFiltro={onChangeFiltro}
      />
    </Box>
  );
}
