import { Box, Text } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import { FiltrosProcessos } from "core/features/processos/typings";
import { useEffect, useState } from "react";
import DateRangePick from "ui/components/DateRangePick";
import FooterModalDefault from "ui/components/FooterModal";
import InputDefault from "ui/components/Input/InputDefault";
import SelectAssunto from "ui/components/Input/SelectAssunto";
import SelectUsuarios from "ui/components/Input/SelectUsuarios";
import Toolbar from "ui/components/Toolbar";
import SelectSetor from "ui/components/Input/SelectSetor";
import SelectTenants from "ui/components/Input/SelectTenants/index";
import SelectCargo from "ui/components/Input/SelectCargo";

interface ModalFiltrosProcessosProps {
  onClose: () => void;
  onReset: () => void;
  filtro: FiltrosProcessos;
  visible: boolean;
  onChangeFiltro: (_filtro: Partial<FiltrosProcessos>) => void;
  refetchDashboard: any;
}

export default function ModalFiltrosProcessos({
  onClose,
  visible,
  onChangeFiltro,
  onReset,
  filtro,
  refetchDashboard,
}: ModalFiltrosProcessosProps) {
  const [localFiltro, setLocalFiltro] =
    useState<Partial<FiltrosProcessos>>(filtro);

  useEffect(() => {
    if (!visible) {
      setLocalFiltro(filtro);
    }
  }, [visible, filtro]);

  return (
    <Modal
      destroyOnClose
      closable={false}
      maskClosable={false}
      centered
      width="700px"
      open={visible}
      className="antd-custom-header"
      title={<Toolbar title="Filtrar" onClose={onClose} />}
      footer={
        <FooterModalDefault
          titleOutlineButton="Fechar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Filtrar"
          onclickPrimaryButton={() => {
            onChangeFiltro(localFiltro);
            refetchDashboard();
            onClose();
          }}
          onclickGhostButton={() => {
            onReset();
            onClose();
          }}
          titleGhostButton="Limpar filtros"
        />
      }
    >
      <Box as={Row} gutter={16} mb={4}>
        <Col md={12}>
          <Text mb="2px" fontSize="14px">
            Período
          </Text>

          <DateRangePick
            dataInicio={localFiltro.dataInicio ? localFiltro.dataInicio : null}
            dataFim={localFiltro.dataFim ? localFiltro.dataFim : null}
            onChange={(value: any) => {
              setLocalFiltro({
                ...localFiltro,
                dataInicio: value[0] ? value[0].toISOString() : null,
                dataFim: value[1] ? value[1].toISOString() : null,
              });
            }}
          />
        </Col>

        <Col md={12}>
          <Text mb="2px" fontSize="14px">
            Número do processo
          </Text>

          <InputDefault
            placeholder="0000/0000"
            onChange={(e) => {
              setLocalFiltro((state) => ({
                ...state,
                numeroProcesso: e.target.value,
              }));
            }}
            value={localFiltro.numeroProcesso}
          />
        </Col>
      </Box>

      <Box mb={4}>
        <Col md={24}>
          <Text mb="2px" fontSize="14px">
            Assunto
          </Text>
          <SelectAssunto
            onChange={(assuntoId) => {
              setLocalFiltro((state) => ({
                ...state,
                assuntoId,
              }));
            }}
            value={localFiltro.assuntoId}
          />
        </Col>
      </Box>

      <Box mb={4}>
        <Col md={24}>
          <Text mb="2px" fontSize="14px">
            Resumo
          </Text>
          <InputDefault
            onChange={(e) => {
              setLocalFiltro((state) => ({
                ...state,
                resumo: e.target.value,
              }));
            }}
            value={localFiltro.resumo}
          />
        </Col>
      </Box>
      <Box mb={4}>
        <Col md={24}>
          <Text mb="2px" fontSize="14px">
            Interessados
          </Text>
          <SelectUsuarios
            mode="multiple"
            onChange={(interessadoIds) => {
              setLocalFiltro((state) => ({
                ...state,
                interessadoIds,
              }));
            }}
            value={localFiltro.interessadoIds}
          />
        </Col>
      </Box>

      <Box mb={4}>
        <Col md={24}>
          <Text mb="2px" fontSize="14px">
            Criado por
          </Text>
          <SelectUsuarios
            allowClear
            onChange={(creatorId) => {
              setLocalFiltro((state) => ({
                ...state,
                creatorId,
              }));
            }}
            value={localFiltro.creatorId}
          />
        </Col>
      </Box>

      <Box mb={4}>
        <Col md={24}>
          <Text mb="2px" fontSize="14px">
            Organização
          </Text>
          <SelectTenants
            allowClear
            onChange={(tenantId) => {
              setLocalFiltro((state) => ({
                ...state,
                tenantId,
              }));
            }}
            value={localFiltro.tenantId}
          />
        </Col>
      </Box>

      <Box mb={4}>
        <Col md={24}>
          <Text mb="2px" fontSize="14px">
            Setor
          </Text>
          <SelectSetor
            allowClear
            onChange={(setorId) => {
              setLocalFiltro((state) => ({
                ...state,
                setorId,
              }));
            }}
            value={localFiltro.setorId}
          />
        </Col>
      </Box>

      <Box mb={4}>
        <Col md={24}>
          <Text mb="2px" fontSize="14px">
            Cargo
          </Text>
          <SelectCargo
            allowClear
            onChange={(perfilId) => {
              setLocalFiltro((state) => ({
                ...state,
                perfilId,
              }));
            }}
            value={localFiltro.perfilId}
          />
        </Col>
      </Box>
    </Modal>
  );
}
