import { Box, Flex, Text, Textarea } from "@chakra-ui/react";
import { Col, Row } from "antd";
import colors from "core/resources/theme/colors";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  MdAttachFile,
  MdDraw,
  MdModeEdit,
  MdNotes,
  MdNotificationsActive,
} from "react-icons/md";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import CardDefault from "ui/components/Cards/CardDefault";
import Field from "ui/components/Input/Field";
import CollapseDefault from "ui/pages/Admin/Processos/Detalhes/components/EmAndamento/FichaProcesso/components/TabFichaProcesso/CollapseDefault";
import ModalAssinantes from "./components/ModalAssinantes";
import ModalEncaminhar from "./components/ModalEncaminhar";
import ModalLancarAlertas from "./components/ModalLancarAlertas";
import ModalAnexarDocumentos from "ui/pages/Admin/Processos/Detalhes/components/ProcessosDocumento/components/ModalAnexarDocumentos";
import TableAlertas from "ui/pages/Admin/Processos/Detalhes/components/ProcessosDocumento/components/TableAlertas";
import { useLocation, useParams } from "react-router-dom";
import EscreverDocumento from "ui/pages/Admin/Processos/Detalhes/components/ProcessosDocumento/components/EscreverDocumento";
import SelectTipoDocumento from "ui/components/Input/SelectTipoDocumento";
import SelectTipoAcesso from "ui/components/Input/SelectTipoAcesso";
import TableAssinantes from "ui/pages/Admin/Processos/Detalhes/components/ProcessosDocumento/components/TableAssinantes";
import {
  showErrorAxios,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import { ProcessoDocumentoResponse } from "core/features/processos-documento/typings";
import useSaveProcessoDocumento from "core/features/processos-documento/hooks/useSaveProcessoDocumento";
import { useCurrentTenant } from "core/features/auth/tenant.store";
import TableDocumentos from "ui/pages/Admin/Processos/Detalhes/components/ProcessosDocumento/components/TableDocumentos";
import useProcessoDocumento from "core/features/processos-documento/hooks/useProcessoDocumento";
import useProcesso from "core/features/processos/hooks/useProcesso";
import { TabsAndamentoRoutes } from "../../components/TabsProcessos";
import { useNavigate } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import PDFTexto from "./components/PDFTexto/index";
import { GoPasskeyFill } from "react-icons/go";
import TablePermitirAcesso from "./components/TablePermitirAcesso";
import ModalPermitirAcesso from "./components/ModalPermissaoAcesso";
import { useAuthStore } from "core/features/auth/auth.store";
import {
  countPages,
  addBlankPages,
  compareFiles,
} from "core/infra/helpers/pdfUltils";

export default function ProcessosDocumento() {
  const { search } = useLocation();
  const { tenant } = useCurrentTenant();
  const params = new URLSearchParams(search);
  const isAnexar = params.get("opcao") === "anexar" ?? true;
  const { id, idDocumento } = useParams() as {
    idDocumento: string;
    id: string;
  };

  const { user } = useAuthStore();

  const [isOpenModalAnexarDocumentos, setIsOpenModalAnexarDocumentos] =
    useState(false);
  const [isOpenModalAssinantes, setIsOpenModalAssinantes] = useState(false);
  const [isOpenModalLancarAlerta, setIsOpenModalLancarAlerta] = useState(false);
  const [isOpenModalEncaminhar, setIsOpenModalEncaminhar] = useState(false);
  const [isOpenModalPermitirAcesso, setIsOpenModalPermitirAcesso] =
    useState(false);
  const [idPermitirAcesso, setIdPermitirAcesso] = useState<
    string | undefined
  >();

  const { mutate: handleSave, isLoading: isLoadingSave } =
    useSaveProcessoDocumento(id, idDocumento);

  const [isLoadingViewPermission, setIsLoadingViewPermission] = useState(true);

  const { data: dataProcesso, isLoading } = useProcesso(id);

  const navigate = useNavigate();

  const defaultValues: ProcessoDocumentoResponse = {
    tipoDocumentoId: undefined,
    descricao: "",
    tipoAcessoId: "",
    comentario: "",
    arquivos: [],
    assinantes: [],
    alertas: [],
    setorId: "",
    texto: "",
    assinaturaSequencia: false,
    conteudoPlain: "",
    assinantesIdsExcluidos: [],
    alertasIdsExcluidos: [],
    permitirAcessoPessoaIds: [],
  };

  const { data, isLoading: isLoadingData } = useProcessoDocumento(
    id,
    idDocumento
  );
  const { control, handleSubmit, setValue, watch, reset } =
    useForm<ProcessoDocumentoResponse>({
      defaultValues,
    });

  const form = watch();

  function blankPages(valor: number) {
    if (valor <= 16) {
      return 1;
    }

    return Math.ceil((valor - 16) / 16) + 1;
  }

  const onSubmit = async (form: ProcessoDocumentoResponse) => {
    if (isAnexar && (!form.arquivos || form.arquivos.length === 0)) {
      return showErrorAxios([], "Adicione ao menos um  arquivo");
    }

    if (!isAnexar && !form.texto) {
      return showErrorAxios([], "Adicione um conteudo no ducumento");
    }

    if (!form.descricao) {
      return showErrorAxios([], "Adicione uma descrição");
    }

    let blobPDF = await pdf(<PDFTexto texto={form.texto} />).toBlob();

    var file = new File([blobPDF], "documento.pdf", {
      type: "application/pdf",
      lastModified: new Date().getTime(),
    });

    let updatedFile: File | undefined;
    let pagePdf: number | undefined;

    const validFiles: File[] = [];

    async function processFile(file: File, assinantesLength: number) {
      updatedFile = await addBlankPages(file, blankPages(assinantesLength));
      if (updatedFile) {
        pagePdf = await countPages(updatedFile);
        validFiles.push(updatedFile);
      }
    }

    if (data && isAnexar) {
      const isNewFile = await compareFiles(
        data?.arquivos[0]!,
        form.arquivos[0]
      );

      if (!isNewFile) {
        await processFile(form.arquivos[0], form.assinantes.length);
      } else {
        const blobPDF = new Blob([form.arquivos[0]], {
          type: "application/pdf",
        });

        const file = new File([blobPDF], "documento.pdf", {
          type: "application/pdf",
          lastModified: new Date().getTime(),
        });
        validFiles.push(file);
        pagePdf = data.numeroPaginasPdf;
      }
    } else if (isAnexar) {
      for (const arquivo of form.arquivos) {
        await processFile(arquivo, form.assinantes.length);
      }
    } else {
      await processFile(file, form.assinantes.length);
    }

    handleSave(
      {
        ...form,
        arquivos: validFiles,
        numeroPaginasPdf: pagePdf || 0,
      },
      {
        onSuccess: () => {
          showSuccess().then(() => {
            window.location.replace(`/processos/${id}?tab=documentos`);
          });
        },
        onError: (err: any) => {
          showErrorAxios(err);
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  useEffect(() => {
    if (idPermitirAcesso !== undefined) {
      setIsOpenModalPermitirAcesso(true);
    }
  }, [idPermitirAcesso]);

  useEffect(() => {
    if (!isOpenModalPermitirAcesso) {
      setIdPermitirAcesso(undefined);
    }
  }, [isOpenModalPermitirAcesso]);

  useEffect(() => {
    if (!idDocumento) {
      setIsLoadingViewPermission(false);
      return;
    }

    if (idDocumento && data && user) {
      const viewPermission = data?.permissaoUsuarioIds!.some(
        (v) => user?.id === v
      );
      if (!viewPermission) {
        navigate("/errors/sem-permissao");
      }
      setIsLoadingViewPermission(false);
    }
  }, [idDocumento, data, user, navigate]);

  useLoading(isLoading || isLoadingData || isLoadingViewPermission);

  return (
    <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
      <Box>
        <Flex justify="space-between">
          <Text fontSize="24px" fontWeight="500" mb={4}>
            {`Adicionar Documento Processo N. ${dataProcesso?.numeroProcesso} `}
          </Text>
          <Flex gap={2}>
            <OutlineButton
              onClick={() =>
                navigate(
                  `/processos/${id}?tab=${TabsAndamentoRoutes.Documentos}`
                )
              }
            >
              Cancelar
            </OutlineButton>
            <PrimaryButton type="submit" isLoading={isLoadingSave}>
              Salvar
            </PrimaryButton>
          </Flex>
        </Flex>
        <CardDefault mb={4}>
          <Box as={Row} gutter={16}>
            <Box as={Col} md={8}>
              <Field
                name="tipoDocumentoId"
                label="Tipo do documento"
                control={control}
                rules={{ required: true }}
                render={({ field, isInvalid }) => (
                  <SelectTipoDocumento isInvalid={isInvalid} {...field} />
                )}
              />
            </Box>
            <Box as={Col} md={8}>
              <Field
                name="descricao"
                label="Descrição"
                control={control}
                rules={{ required: true }}
              />
            </Box>
            <Box as={Col} md={8}>
              <Field
                name="tipoAcessoId"
                label="Tipo de acesso"
                control={control}
                rules={{ required: true }}
                render={({ field, isInvalid }) => (
                  <SelectTipoAcesso isInvalid={isInvalid} {...field} />
                )}
              />
            </Box>
          </Box>
        </CardDefault>
        {isAnexar ? (
          <CollapseDefault
            title="Anexar documento"
            addButton
            icon={<MdAttachFile color={colors.gray500} size={20} />}
            onClick={(ev) => {
              setIsOpenModalAnexarDocumentos(true);
              ev.stopPropagation();
            }}
            children={<TableDocumentos form={form} />}
          />
        ) : (
          <CollapseDefault
            title="Escrever documento"
            addButton={false}
            icon={<MdModeEdit color={colors.gray500} size={20} />}
            onClick={(ev) => {
              setIsOpenModalAnexarDocumentos(true);
              ev.stopPropagation();
            }}
            children={
              !isLoadingData &&
              ((data?.texto && !isLoadingData) || !idDocumento) ? (
                <EscreverDocumento setValue={setValue} control={control} />
              ) : null
            }
          />
        )}

        <CollapseDefault
          title="Assinantes"
          addButton
          icon={<MdDraw color={colors.gray500} size={20} />}
          onClick={(ev) => {
            setIsOpenModalAssinantes(true);
            ev.stopPropagation();
          }}
          children={
            <TableAssinantes
              form={form}
              setValue={setValue}
              tenantId={tenant.id}
            />
          }
          // checkbox={
          //   <Flex>
          //     <Field
          //       control={control}
          //       name="assinaturaSequencia"
          //       render={({ field }) => (
          //         <Checkbox
          //           onClick={(ev) => {
          //             ev.stopPropagation();
          //             field.onChange(!field.value);
          //           }}
          //           style={{ fontFamily: "Poppins,sans-serif" }}
          //           value={field.value}
          //           checked={field.value}
          //         >
          //           Assinatura em sequência
          //         </Checkbox>
          //       )}
          //     />
          //   </Flex>
          // }
        />
        {/* <CollapseDefault
          title="Após todos assinarem encaminhar para setor"
          addButton
          dislabledButton={!!form.setorId}
          icon={<TbArrowForwardUpDouble color={colors.gray500} size={20} />}
          onClick={(ev) => {
            setIsOpenModalEncaminhar(true);
            ev.stopPropagation();
          }}
          children={<TableEncaminharSetor form={form} />}
        /> */}
        <CollapseDefault
          title="Comentário"
          icon={<MdNotes color={colors.gray500} size={20} />}
          onClick={(ev) => {
            ev.stopPropagation();
          }}
          children={
            <Box as={Row} px={2}>
              <Box as={Col} md={24}>
                <Field
                  name="comentario"
                  label="Descrição"
                  control={control}
                  render={({ field, isInvalid }) => (
                    <Textarea
                      isInvalid={isInvalid}
                      className="chakra-input"
                      rows={4}
                      resize="none"
                      placeholder="Digite..."
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>
          }
        />

        <CollapseDefault
          title="Lançar alerta"
          addButton
          icon={<MdNotificationsActive color={colors.gray500} size={20} />}
          onClick={(ev) => {
            setIsOpenModalLancarAlerta(true);
            ev.stopPropagation();
          }}
          children={
            <TableAlertas
              form={form}
              tenantId={tenant.id}
              setValue={setValue}
            />
          }
        />

        <CollapseDefault
          title="Permitir Acesso"
          addButton
          icon={<GoPasskeyFill color={colors.gray500} size={20} />}
          onClick={(ev) => {
            setIsOpenModalPermitirAcesso(true);
            ev.stopPropagation();
          }}
          children={<TablePermitirAcesso form={form} setValue={setValue} />}
        />
      </Box>

      {isOpenModalAnexarDocumentos ? (
        <ModalAnexarDocumentos
          isOpen={isOpenModalAnexarDocumentos}
          onClose={() => setIsOpenModalAnexarDocumentos(false)}
          setValue={setValue}
          form={form}
          isEdit={!!idDocumento}
        />
      ) : null}

      {isOpenModalAssinantes ? (
        <ModalAssinantes
          isOpen={isOpenModalAssinantes}
          onClose={() => setIsOpenModalAssinantes(false)}
          form={form}
          setValue={setValue}
        />
      ) : null}

      {isOpenModalEncaminhar ? (
        <ModalEncaminhar
          isOpen={isOpenModalEncaminhar}
          onClose={() => setIsOpenModalEncaminhar(false)}
          form={form}
          setValue={setValue}
        />
      ) : null}

      {isOpenModalLancarAlerta ? (
        <ModalLancarAlertas
          isOpen={isOpenModalLancarAlerta}
          onClose={() => setIsOpenModalLancarAlerta(false)}
          form={form}
          setValue={setValue}
        />
      ) : null}

      {isOpenModalPermitirAcesso ? (
        <ModalPermitirAcesso
          isOpen={isOpenModalPermitirAcesso}
          onClose={() => setIsOpenModalPermitirAcesso(false)}
          id={id}
          setValue={setValue}
          form={form}
        />
      ) : null}
    </form>
  );
}
