import colors from "core/resources/theme/colors";
import { Button, ButtonProps } from "@chakra-ui/react";

function PrimaryButton({ children, ...rest }: ButtonProps) {
  return (
    <Button
      rounded={8}
      px={4}
      h="35px"
      bgColor={colors.primary}
      opacity={rest.disabled ? 0.5 : 1}
      color="white"
      display="flex"
      fontWeight="normal"
      gridGap={2}
      fontSize={14}
      _hover={{
        bg: rest.bgColor || colors.primary,
        opacity: rest.disabled ? 0.7 : 0.9,
      }}
      _active={{
        opacity: 0.6,
      }}
      _disabled={{
        opacity: 0.55,
      }}
      _focus={{
        outline: "none",
      }}
      cursor={rest.disabled ? "not-allowed" : "pointer"}
      isDisabled={rest.disabled}
      disabled={rest.disabled}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
