import { Box } from "@chakra-ui/react";
import { Row, Col } from "antd";
import { ProcessoResponse } from "core/features/processos/typings";
import { Control, FieldErrors, UseFormSetValue } from "react-hook-form";
import Field from "ui/components/Input/Field";
import SelectFluxoProcesso from "ui/components/Input/SelectFluxoProcesso";
import SelectSetor from "ui/components/Input/SelectSetor";
import SelectTipoFluxoProcesso from "ui/components/Input/SelectTipoFluxoProcesso";
import FluxoAndamento from "./FluxoAndamento";

export default function FormFluxosProcessos({
  control,
  form,
  setValue,
  errors,
}: {
  control: Control<ProcessoResponse, any>;
  form: ProcessoResponse;
  setValue: UseFormSetValue<ProcessoResponse>;
  errors: FieldErrors<ProcessoResponse>;
}) {
  const handleTipoFluxoChange = (tipoFluxoProcesso: string) => {
    const campoNaoSelecionado =
      tipoFluxoProcesso === "padrao" ? "setorDestinoId" : "fluxoId";
    setValue(campoNaoSelecionado, null);
  };
  return (
    <Box>
      <Box as={Row} gutter={32} mb={4}>
        <Box as={Col} md={6}>
          <Field
            name="tipoFluxoProcesso"
            label="Fluxo do processo"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <SelectTipoFluxoProcesso
                {...field}
                isInvalid={!!errors.tipoFluxoProcesso}
                onChange={(value) => {
                  field.onChange(value);
                  handleTipoFluxoChange(value);
                }}
              />
            )}
          />
        </Box>
        <Box as={Col} md={18}>
          {form.tipoFluxoProcesso === "padrao" ? (
            <Field
              name="fluxoId"
              label="Selecione o fluxo do processo"
              control={control}
              rules={{ required: true }}
              key={"fluxoId"}
              render={({ field }) => (
                <SelectFluxoProcesso
                  isInvalid={!!errors.fluxoId}
                  isAdicionar
                  {...field}
                />
              )}
            />
          ) : (
            <Field
              name="setorDestinoId"
              label="Selecione o setor de destino"
              control={control}
              key={"setorDestinoId"}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectSetor isInvalid={!!errors.setorDestinoId} {...field} />
              )}
            />
          )}
        </Box>
      </Box>
      {form.fluxoId ? <FluxoAndamento form={form} /> : null}
    </Box>
  );
}
