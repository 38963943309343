import { Flex, Text } from "@chakra-ui/react";
import { Checkbox } from "antd";
import { ProcessoAssociadoPaginateResponse } from "core/features/processos/typings";
import moment from "moment";
import CardDefault from "ui/components/Cards/CardDefault";

interface RowAssociadosProps {
  item: ProcessoAssociadoPaginateResponse;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  selectable?: boolean;
}

export default function RowAssociados({
  item,
  selected,
  setSelected,
  selectable = true,
}: RowAssociadosProps) {
  const onSelect = () => {
    setSelected([...selected, item.id]);
  };

  const onUnselect = () => {
    setSelected(selected.filter((id) => id !== item.id));
  };

  const isSelected = selected.includes(item.id);

  const onCheckAllChange = isSelected ? onUnselect : onSelect;

  return (
    <CardDefault key={item.id} rounded="8px" mb={2}>
      <Flex>
        <Flex flex={0.1}>
          <Checkbox
            onChange={selectable ? onCheckAllChange : undefined}
            checked={isSelected}
            disabled={!selectable}
            onClick={(ev) => ev.stopPropagation()}
          />
        </Flex>
        <Text flex={0.3}>{moment(item.createdAt).format("DD/MM/YYYY")}</Text>
        <Text flex={0.4}>{item.numeroProcesso}</Text>
        <Text
          flex={1}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {item.assunto}
        </Text>
      </Flex>
    </CardDefault>
  );
}
