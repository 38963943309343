import { useState } from "react";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";
import useNaturezasProcesso from "core/features/tipoprocesso/hooks/useNaturezasProcesso";
import { Flex, Text, Button, Box } from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";
import ModalCadastroNaturezaProcesso from "./ModalCadastroNaturezaProcesso";

export interface SelectNaturezaProcessoProps<T = any>
  extends SelectBaseAntdProps<T> {}

function SelectNaturezaProcesso({ ...rest }: SelectNaturezaProcessoProps) {
  const [search, setSearch] = useState<string>();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { isLoading, data, refetch } = useNaturezasProcesso();

  return (
    <>
      {isOpenModal ? (
        <ModalCadastroNaturezaProcesso
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          refetch={refetch}
        />
      ) : null}
      <SelectBaseAntd
        loading={isLoading}
        size="large"
        placeholder="Selecione...."
        searchValue={search}
        showSearch
        onSearch={setSearch}
        optionFilterProp="label"
        options={data?.map((item) => ({
          value: item.id,
          label: item.nome,
        }))}
        {...rest}
        notFoundContent={
          !isLoading ? (
            <Flex px={2} gap={2} align="center">
              <Text>Nenhuma registro encontrado</Text>

              <Button
                isLoading={false}
                leftIcon={<BsPlusLg size={20} />}
                size="sm"
                fontWeight={400}
                colorScheme="gray"
                onClick={() => setIsOpenModal(true)}
              >
                Adicionar
              </Button>
            </Flex>
          ) : (
            <Box>Carregando...</Box>
          )
        }
      />
    </>
  );
}

export default SelectNaturezaProcesso;
