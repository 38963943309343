import { Modal, Row, Col } from "antd";
import { showErrorAxios, showSuccess } from "core/infra/helpers/alerta";
import { Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FooterModalDefault from "ui/components/FooterModal";
import Toolbar from "ui/components/Toolbar";
import Field from "ui/components/Input/Field";
import useSaveTipoDocumento from "core/features/tipo-documento/hooks/useSaveTipoDocumento";
import { TipoDocumentoResponse } from "core/features/tipo-documento/types";

interface ModalCadastroTipoDocumentoProps {
  isOpen: boolean;
  onClose: () => any;
  refetch?: any;
}

export default function ModalCadastroTipoDocumento({
  isOpen,
  onClose,
  refetch,
}: ModalCadastroTipoDocumentoProps) {
  const { isLoading: isLoadingSave, mutate: handleSave } =
    useSaveTipoDocumento();

  const { control, handleSubmit } = useForm<TipoDocumentoResponse>({});

  const onSubmit = (data: TipoDocumentoResponse) => {
    handleSave(data, {
      onSuccess: () => {
        showSuccess().then(() => {
          onClose();
          refetch();
        });
      },
      onError: (err: any) => {
        showErrorAxios(err);
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Salvar"
          onclickPrimaryButton={() => handleSubmit(onSubmit)()}
          saveLoading={isLoadingSave}
        />
      }
      title={<Toolbar title="Adicionar tipo do documento" onClose={onClose} />}
      destroyOnClose
      onCancel={onClose}
      maskClosable={false}
      closable={false}
      width="900px"
      centered
    >
      <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
        <Box as={Row} gutter={16} mb={4}>
          <Box as={Col} md={24} mb={4}>
            <Field name="nome" label="Nome" control={control} />
          </Box>
          <Box as={Col} md={14}></Box>
        </Box>
      </form>
    </Modal>
  );
}
