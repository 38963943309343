import { Flex } from "@chakra-ui/react";
import useFluxosProcessos from "core/features/shared/hooks/useFluxosProcessos";
import colors from "core/resources/theme/colors";
import { GoArrowDown } from "react-icons/go";
import { TbArrowForwardUpDouble } from "react-icons/tb";
import Fluxograma from "./Fluxograma";
import { ProcessoResponse } from "core/features/processos/typings";
import Criterios from "./Criterios";
import { useState } from "react";
import { Spin } from "antd";
import useSetores from "core/features/shared/hooks/useSetores";

export default function FluxoAndamento({ form }: { form: ProcessoResponse }) {
  const { data, isLoading } = useFluxosProcessos();

  const { data: dataSetores, isLoading: isLoadingSetores } = useSetores();

  const [andamentoSelecionadoId, setAndamentoSelecionadoId] = useState<
    string | undefined
  >(undefined);

  const _isLoading = isLoading || isLoadingSetores;

  return (
    <>
      <Flex align="center" fontWeight="500" fontSize="18px" gap={2} mb={6}>
        <TbArrowForwardUpDouble size={25} color={colors.gray600} /> Fluxos de
        andamento
      </Flex>

      {_isLoading ? (
        <Flex w="100%" align="center" m="auto" justify="center">
          <Spin />
        </Flex>
      ) : (
        <Flex>
          <Flex flexDir="column" align="center" gap={2}>
            <Flex
              bg={colors.gray200}
              rounded="50%"
              w="140px"
              h="47px"
              align="center"
              justify="center"
            >
              Inicio
            </Flex>
            <GoArrowDown color={colors.gray500} size={25} />

            {data?.items
              .filter((item) => item.id === form.fluxoId)
              .map((item) =>
                item.fluxosAndamento
                  .sort((a, b) => a.ordemAndamento! - b.ordemAndamento!)
                  .map((andamento) => (
                    <Fluxograma
                      data={dataSetores}
                      key={andamento.id}
                      item={andamento}
                      onClick={() => setAndamentoSelecionadoId(andamento.id)}
                      isSelected={andamento.id === andamentoSelecionadoId}
                    />
                  ))
              )}
            <Flex
              bg={colors.gray200}
              rounded="50%"
              w="140px"
              h="47px"
              align="center"
              justify="center"
            >
              Fim Processo
            </Flex>
          </Flex>

          {data?.items
            .filter((item) => item.id === form.fluxoId)
            .map((item) =>
              item.fluxosAndamento
                .filter((andamento) => andamento.id === andamentoSelecionadoId)
                .map((andamentoFiltrado, index) => (
                  <Criterios key={index} item={andamentoFiltrado} />
                ))
            )}
        </Flex>
      )}
    </>
  );
}
