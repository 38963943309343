import { useQuery } from "react-query";
import http from "../auth/hooks/useApi";

function useGetEstados() {
  return useQuery("/v1/estado", async () => {
    const { data } = await http.get<
      { id: string; nome: string; sigla: string }[]
    >("/v1/estado");

    return data;
  });
}

export default useGetEstados;
