import { FiltroUsuario } from "core/features/usuario/typings";
import { BaseFiltroPaginate } from "core/resources/enums/paginate";
import { useCallback, useState } from "react";

export const filtroDefaultUsuarios: FiltroUsuario = {
  limit: BaseFiltroPaginate.Limit,
  search: "",
  ativo: undefined,
  cargo: undefined,
  page: 1,
};

function useFiltroUsuarios() {
  const [filtro, setFiltro] = useState<FiltroUsuario>(filtroDefaultUsuarios);

  const onChangeFiltro = useCallback((_filtro: Partial<FiltroUsuario>) => {
    if ((_filtro.search || _filtro.ativo || _filtro.cargo) !== undefined) {
      _filtro.page = 1;
    }
    setFiltro((state) => ({ ...state, ..._filtro }));
  }, []);

  return {
    filtro,
    setFiltro,
    onChangeFiltro,
  };
}

export default useFiltroUsuarios;
