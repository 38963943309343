import { Flex, BoxProps } from "@chakra-ui/layout";

interface CardDefaultProps extends BoxProps {
  w?: string;
  rounded?: string;
  padding?: string;
}

function CardDefault({
  onClick,
  children,
  rounded = "16px",
  padding = "16px",
  w,
  ...rest
}: CardDefaultProps) {
  return (
    <Flex
      flexDir="column"
      boxShadow="0 0 24px rgba(0,0,0,.05)"
      onClick={onClick}
      bg={"#FCFCFC"}
      border="1px solid #EDF2F7"
      rounded={rounded}
      p={padding}
      w={w}
      cursor={typeof onClick === "function" ? "pointer" : "default"}
      _hover={{
        boxShadow:
          typeof onClick === "function" ? "0 0 32px rgba(0,0,0,.1)" : undefined,
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
}

export default CardDefault;
