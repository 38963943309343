import { Routes, Route } from "react-router-dom";
import Admin from "./Admin";
import Auth from "./Auth";
import Reset from "./Auth/Reset";
import Signin from "./Auth/Signin";
import SemPermissaoPage from "./Errors/SemPermissao";
import { RequireAuth } from "core/features/auth/RequireAuth";
import ResetPassword from "./Auth/ResetPassword";
import ListagemUsuariosPage from "./Admin/Usuario/Listagem";
import ListagemOrganizacaoPage from "./Admin/Organizacao/Listagem";
import OrganizacaoDetalhes from "./Admin/Organizacao/Detalhes";
import ListagemSetorPage from "./Admin/Setor/Listagem";
import CadastroUsuarioDetalhes from "./Admin/Usuario/Detalhes";
import ListagemCargosPage from "./Admin/Cargo/Listagem";
import SetorDetalhes from "./Admin/Setor/Detalhes";
import CargoDetalhes from "./Admin/Cargo/Detalhes";
import ListagemProcessosPage from "./Admin/Processos/Listagem";
import ProcessosDetalhes from "./Admin/Processos/Detalhes";
import EditarPerfil from "./Admin/EditarPerfil";
import ListagemFluxoProcessosPage from "./Admin/FluxoProcesso/Listagem";
import FluxoProcessoDetalhes from "./Admin/FluxoProcesso/Detalhes";
import ProcessosDocumento from "ui/pages/Admin/Processos/Detalhes/components/ProcessosDocumento";
import AuthRoute from "ui/components/AuthRoute";
import { PermissaoEnum } from "core/resources/enums/permissao";
import ListagemNaturezaProcessoPage from "./Admin/NaturezaProcesso/Listagem/index";
import NaturezaProcessoDetalhes from "./Admin/NaturezaProcesso/Detalhes/index";
import ListagemTipoDocumentoProcessoPage from "./Admin/TipoDocumentoProcesso/Listagem/index";
import TipoDocumentoDetalhes from "./Admin/TipoDocumentoProcesso/Detalhes/index";
import ListagemAssuntoProcessoPage from "./Admin/AssuntoProcesso/Listagem/index";
import AssuntoProcessoDetalhes from "./Admin/AssuntoProcesso/Detalhes/index";

export default function Pages() {
  return (
    <>
      <Routes>
        <Route path="/" element={<RequireAuth children={<Admin />} />}>
          <Route
            path="usuario"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.Usuario.Visualizar,
                ]}
                children={<ListagemUsuariosPage />}
              />
            }
          />
          <Route
            path="usuario/novo"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Administrativo.Usuario.Criar]}
                children={<CadastroUsuarioDetalhes />}
              />
            }
          />
          <Route
            path="usuario/:id"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.Usuario.Editar,
                ]}
                children={<CadastroUsuarioDetalhes />}
              />
            }
          />

          <Route
            path="organizacao"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.Tenant.Visualizar,
                ]}
                children={<ListagemOrganizacaoPage />}
              />
            }
          />
          <Route
            path="organizacao/novo"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Administrativo.Tenant.Criar]}
                children={<OrganizacaoDetalhes />}
              />
            }
          />
          <Route
            path="organizacao/:id"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Administrativo.Tenant.Editar]}
                children={<OrganizacaoDetalhes />}
              />
            }
          />

          <Route
            path="setor"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.Setor.Visualizar,
                ]}
                children={<ListagemSetorPage />}
              />
            }
          />
          <Route
            path="setor/novo"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Administrativo.Setor.Criar]}
                children={<SetorDetalhes />}
              />
            }
          />
          <Route
            path="setor/:id"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Administrativo.Setor.Editar]}
                children={<SetorDetalhes />}
              />
            }
          />

          <Route
            path="cargo"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.Perfil.Visualizar,
                ]}
                children={<ListagemCargosPage />}
              />
            }
          />
          <Route
            path="cargo/novo"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Administrativo.Perfil.Criar]}
                children={<CargoDetalhes />}
              />
            }
          />
          <Route
            path="cargo/:id"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Administrativo.Perfil.Editar]}
                children={<CargoDetalhes />}
              />
            }
          />

          <Route
            path="natureza-processo"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.NaturezaProcesso
                    .Visualizar,
                ]}
                children={<ListagemNaturezaProcessoPage />}
              />
            }
          />
          <Route
            path="natureza-processo/novo"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.NaturezaProcesso.Criar,
                ]}
                children={<NaturezaProcessoDetalhes />}
              />
            }
          />
          <Route
            path="natureza-processo/:id"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.NaturezaProcesso.Editar,
                ]}
                children={<NaturezaProcessoDetalhes />}
              />
            }
          />

          <Route
            path="tipo-documento"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.TipoDocumento.Visualizar,
                ]}
                children={<ListagemTipoDocumentoProcessoPage />}
              />
            }
          />
          <Route
            path="tipo-documento/novo"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.TipoDocumento.Criar,
                ]}
                children={<TipoDocumentoDetalhes />}
              />
            }
          />
          <Route
            path="tipo-documento/:id"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.TipoDocumento.Editar,
                ]}
                children={<TipoDocumentoDetalhes />}
              />
            }
          />

          <Route
            path="assunto-processo"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.AssuntoProcesso.Visualizar,
                ]}
                children={<ListagemAssuntoProcessoPage />}
              />
            }
          />
          <Route
            path="assunto-processo/novo"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.AssuntoProcesso.Criar,
                ]}
                children={<AssuntoProcessoDetalhes />}
              />
            }
          />
          <Route
            path="assunto-processo/:id"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Administrativo.AssuntoProcesso.Editar,
                ]}
                children={<AssuntoProcessoDetalhes />}
              />
            }
          />

          <Route
            path="processos"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Processo.Processo.Visualizar]}
                children={<ListagemProcessosPage />}
              />
            }
          />
          <Route
            path="processos/novo"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Processo.Processo.Criar]}
                children={<ProcessosDetalhes />}
              />
            }
          />
          <Route
            path="processos/:id"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Processo.Processo.Editar]}
                children={<ProcessosDetalhes />}
              />
            }
          />
          <Route
            path="processos/:id/documento/novo"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Processo.Processo.Editar]}
                children={<ProcessosDocumento />}
              />
            }
          />
          <Route
            path="processos/:id/documento/:idDocumento"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Processo.Processo.Editar]}
                children={<ProcessosDocumento />}
              />
            }
          />

          <Route
            path="fluxo-processo"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Processo.FluxoProcesso.Visualizar,
                ]}
                children={<ListagemFluxoProcessosPage />}
              />
            }
          />
          <Route
            path="fluxo-processo/novo"
            element={
              <AuthRoute
                permissions={[PermissaoEnum.Fluir.Processo.FluxoProcesso.Criar]}
                children={<FluxoProcessoDetalhes />}
              />
            }
          />
          <Route
            path="fluxo-processo/:id"
            element={
              <AuthRoute
                permissions={[
                  PermissaoEnum.Fluir.Processo.FluxoProcesso.Editar,
                ]}
                children={<FluxoProcessoDetalhes />}
              />
            }
          />

          <Route path="/editar-perfil" element={<EditarPerfil />} />
        </Route>
        <Route path="auth" element={<Auth />}>
          <Route path="reset" element={<Reset />} />
          <Route path="signin" element={<Signin />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>

        <Route path="/errors/sem-permissao" element={<SemPermissaoPage />} />
      </Routes>
    </>
  );
}
