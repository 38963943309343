import CardDefault from "ui/components/Cards/CardDefault";
import HeaderTableRow from "./components/HeaderTableRow";
import TableItemRow from "./components/TableItemRow/index";
import Pagination from "ui/components/Pagination";
import { PaginateResult } from "core/resources/types/shared";
import { Box } from "@chakra-ui/react";
import {
  FiltrosSetor,
  SetorPaginateResponse,
} from "core/features/setor/typings/index";
import SkeletonDefault from "ui/components/SkeletonDefault";
import HeaderDefaultRowTable from "ui/components/HeaderDefaultRowTable";

interface SetorTableProps {
  data?: PaginateResult<SetorPaginateResponse>;
  isLoading: boolean;
  onChangeFiltro: (_filtro: Partial<FiltrosSetor>) => void;
}

export default function SetorTable({
  data,
  isLoading,
  onChangeFiltro,
}: SetorTableProps) {
  return (
    <>
      <CardDefault>
        <HeaderDefaultRowTable children={<HeaderTableRow />} />

        {isLoading ? (
          <SkeletonDefault h="76px" rounded="8px" mb={2} amount={7} />
        ) : null}

        {data?.items?.map((item, index) => (
          <TableItemRow key={item.id} item={item} indexItem={index} />
        ))}

        {data?.items.length ? (
          <Box m={4}>
            <Pagination
              style={{ marginLeft: "auto", marginBottom: 16 }}
              current={data?.meta?.currentPage ?? 1}
              total={data?.meta?.totalItems ?? 0}
              pageSize={data?.meta?.itemsPerPage ?? 0}
              onChange={(page, limit) => {
                onChangeFiltro({ page, limit });
              }}
            />
          </Box>
        ) : null}
      </CardDefault>
    </>
  );
}
