import { useState } from "react";
import { Flex } from "@chakra-ui/layout";
import HeaderMenuItem from "./../HeaderMenuItem/index";
import { Menu } from "core/features/menu/typings/index";

function HeaderMenu({ menus }: { menus: Menu[] }) {
  const [indexOpen, setIndexOpen] = useState<number | null>(null);

  return (
    <Flex
      gridGap="16px"
      mr="auto"
      ml="48px"
      onMouseLeave={() => {
        setIndexOpen(null);
      }}
    >
      {menus.map((item, key) =>
        !item.isMobile ? (
          <HeaderMenuItem
            submenuOpen={indexOpen === key}
            key={key}
            item={item}
            onCloseSubmenu={() => setIndexOpen(null)}
            onOpenSubmenu={() => setIndexOpen(key)}
          />
        ) : null
      )}
    </Flex>
  );
}

export default HeaderMenu;
