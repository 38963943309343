import { Flex } from "@chakra-ui/react";

export default function HeaderTableRow({ isMobile }: { isMobile: boolean }) {
  return (
    <>
      <Flex flex={isMobile ? 0.46 : 0.3}>Criado em </Flex>
      <Flex flex={isMobile ? 0.4 : 0.3}>N.° processo</Flex>
      <Flex flex={isMobile ? 0.6 : 1} mr={6}>
        Assunto
      </Flex>
      <Flex flex={0.44}>Criado por</Flex>
      <Flex flex={0.44}>Interessados</Flex>
    </>
  );
}
