import { Box } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import { ProcessoDocumentoResponse } from "core/features/processos-documento/typings";
import { useEffect } from "react";
import { UseFormSetValue, useForm } from "react-hook-form";
import FooterModalDefault from "ui/components/FooterModal";
import Field from "ui/components/Input/Field";
import SelectSetor from "ui/components/Input/SelectSetor";
import Toolbar from "ui/components/Toolbar";

interface ModalEncaminharProps {
  isOpen: boolean;
  onClose: () => any;
  form: ProcessoDocumentoResponse;
  setValue: UseFormSetValue<ProcessoDocumentoResponse>;
}
const defaultValues = {
  setorId: "",
};

export default function ModalEncaminhar({
  isOpen,
  onClose,
  setValue,
  form,
}: ModalEncaminharProps) {
  const {
    control,
    handleSubmit,
    setValue: setValueFromHookForm,
    reset,
  } = useForm<{ setorId: string }>({ defaultValues });

  const handleChangeSelect = (selectedValue: string) => {
    setValueFromHookForm("setorId", selectedValue);
  };

  const onSubmit = async (data: { setorId: string }) => {
    setValue("setorId", data.setorId);
    onClose();
  };

  useEffect(() => {
    if (form.setorId) {
      reset(form);
    }
  }, [form.setorId, reset]);

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Adicionar"
          onclickPrimaryButton={handleSubmit(onSubmit)}
        />
      }
      title={<Toolbar title="Encaminhar para" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
    >
      <Box as={Row} gutter={16} mb={8}>
        <Box as={Col} sm={24}>
          <Field
            name="setorId"
            label="Selecione o setor"
            control={control}
            render={({ field, isInvalid }) => (
              <SelectSetor
                {...field}
                isInvalid={isInvalid}
                onChange={(selectedValue) => handleChangeSelect(selectedValue)}
              />
            )}
          />
        </Box>
      </Box>
    </Modal>
  );
}
