import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { ProcessoFichaProcessoResponse } from "../typings";

function useFichaProcesso(id?: string) {
  return useQuery(
    `/v1/processo/${id}/ficha-processo`,
    async () => {
      const res = await http.get<ProcessoFichaProcessoResponse>(
        `/v1/processo/${id}/ficha-processo`
      );

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useFichaProcesso;
