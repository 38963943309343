import { Box } from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import PageHeader from "ui/components/Page/PageHeader";
import { useBreadcrumb } from "core/features/breadcrumbs";
import SearchHeaderBar from "ui/components/SearchHeaderBar/index";
import { useNavigate } from "react-router-dom";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { checkPermission } from "core/infra/helpers/permission";
import TipoDocumentoProcessoTable from "./components/TipoDocumentoProcessoTable";
import useFiltroTipoDocumentoProcesso from "./hooks/useFiltroTipoDocumentoProcesso";
import useListarTipoDocumentoProcesso from "core/features/tipo-documento/hooks/useListarTipoDocumento";
import { useLoading } from "core/infra/helpers/alerta";

export default function ListagemTipoDocumentoProcessoPage() {
  const { filtro, onChangeFiltro } = useFiltroTipoDocumentoProcesso();
  const { data, isLoading, refetch } = useListarTipoDocumentoProcesso(filtro);

  const navigate = useNavigate();

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Tipo do documento", url: "/tipo-documento" },
  ]);

  const createPermission = [
    PermissaoEnum.Fluir.Administrativo.TipoDocumento.Criar,
  ];

  const hasPermission = checkPermission(createPermission ?? []);
  useLoading(isLoading);
  return (
    <Box>
      <Box mb={10}>
        <PageHeader
          title="Tipo do documento"
          titleButton="Adicionar"
          onClickButton={() => navigate("/tipo-documento/novo")}
          iconButton={<BsPlus size={25} />}
          buttonDisabled={!hasPermission}
        />

        <SearchHeaderBar
          defaultValue={filtro.search || ""}
          onSearch={(v) => onChangeFiltro({ search: v })}
          maxWInputSearch={700}
          placeholder="Buscar"
        />
      </Box>

      <TipoDocumentoProcessoTable
        data={data}
        isLoading={isLoading}
        onChangeFiltro={onChangeFiltro}
        refetch={refetch}
      />
    </Box>
  );
}
