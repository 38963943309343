import { Flex, Text } from "@chakra-ui/react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { MdDraw } from "react-icons/md";
import DeleteButton from "ui/components/Buttons/DeleteButton";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { ProcessoDocumentoPaginateResponse } from "core/features/processos-documento/typings";
import colors from "core/resources/theme/colors";

export default function HeaderTableSelected({
  items,
  isIndeterminate,
  checked,
  onChange,
  selecionados,
  setDeleteIds,
  selectedDocumentosIds,
  verificarCertificado,
  permissaoExcluir,
}: {
  items: ProcessoDocumentoPaginateResponse[];
  isIndeterminate: boolean;
  checked: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  selecionados?: number;
  setDeleteIds?: (id: string[]) => any;
  selectedDocumentosIds: string[];
  verificarCertificado: (id: string[]) => void;
  permissaoExcluir?: boolean;
}) {
  const autorizacaoAssinar = items.every(
    (obj) =>
      !selectedDocumentosIds.includes(obj.id) ||
      (obj.isAssinante && !obj.assinaturaUsuario)
  );

  return (
    <Flex justify="space-between" w="100%" height="30px">
      <Flex align="center" gap={4} ml={1}>
        <Checkbox
          onChange={onChange}
          checked={checked}
          indeterminate={isIndeterminate}
        />
        <Flex>
          {selecionados} {selecionados! > 1 ? "selecionados" : "selecionado"}
        </Flex>
      </Flex>

      <Flex gap={6} align="center">
        {autorizacaoAssinar ? (
          <PrimaryButton
            onClick={() => verificarCertificado(selectedDocumentosIds)}
          >
            <MdDraw size={20} />
            Assinar
          </PrimaryButton>
        ) : null}

        {permissaoExcluir ? (
          <DeleteButton onClick={() => setDeleteIds!(selectedDocumentosIds)} />
        ) : null}

        {!autorizacaoAssinar && !permissaoExcluir ? (
          <Text color={colors.red500}>Nenhuma ação permitida</Text>
        ) : null}
      </Flex>
    </Flex>
  );
}
