import { useEffect, useState } from "react";
import { Col, Modal, Row } from "antd";
import { UseFormSetValue } from "react-hook-form";
import { ProcessoDocumentoResponse } from "core/features/processos-documento/typings";
import { Box, Text } from "@chakra-ui/react";
import FooterModalDefault from "ui/components/FooterModal";
import SelectUsuarios from "ui/components/Input/SelectUsuarios";
import Toolbar from "ui/components/Toolbar";

interface ModalLancarAlertasProps {
  isOpen: boolean;
  onClose: () => any;
  form: ProcessoDocumentoResponse;
  setValue: UseFormSetValue<ProcessoDocumentoResponse>;
}

export default function ModalLancarAlertas({
  isOpen,
  onClose,
  form,
  setValue,
}: ModalLancarAlertasProps) {
  const [tempValues, setTempValues] = useState({
    alertas: form.alertas || [],
  });

  useEffect(() => {
    setTempValues((prevValues) => ({
      ...prevValues,
      alertas: form.alertas || [],
    }));
  }, [form.alertas]);

  const handleSaveClick = () => {
    setValue("alertas", tempValues.alertas);
    onClose();
  };

  const handleSelectPessoasChange = (values: string[] | undefined) => {
    const alertas = (values || []).map((pessoaId) => ({
      pessoaId: pessoaId,
    }));

    setTempValues({ ...tempValues, alertas });
  };
  const pessoaIds = (form.alertas || []).map((alerta) => alerta.pessoaId) || [];
  const alertaIds =
    (tempValues.alertas || []).map((alerta) => alerta.pessoaId) || [];

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Adicionar"
          onclickPrimaryButton={handleSaveClick}
        />
      }
      title={<Toolbar title="Lançar alerta" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
    >
      <Box as={Row} gutter={16} mb={8}>
        <Box as={Col} sm={24}>
          <Text mb={2}>Enviar alerta para</Text>
          <SelectUsuarios
            form={pessoaIds}
            value={alertaIds}
            onChange={handleSelectPessoasChange}
            mode="multiple"
          />
        </Box>
      </Box>
    </Modal>
  );
}
