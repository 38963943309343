import { Box } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import useSavePalavraChave from "core/features/processos/hooks/useSavePalavraChave";
import {
  PalavraChaveResponse,
  ProcessoFichaProcessoResponse,
} from "core/features/processos/typings";
import { showErrorAxios, showSuccess } from "core/infra/helpers/alerta";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import FooterModalDefault from "ui/components/FooterModal";
import Field from "ui/components/Input/Field";
import Toolbar from "ui/components/Toolbar";

interface ModalPalavrasChaveProps {
  isOpen: boolean;
  onClose: () => any;
  id?: string;
  idProcesso?: string;
  refetch: any;
  data?: ProcessoFichaProcessoResponse;
}

export default function ModalPalavrasChave({
  isOpen,
  onClose,
  id,
  refetch,
  idProcesso,
  data,
}: ModalPalavrasChaveProps) {
  const { mutate: handleSave, isLoading: isSaveLoading } = useSavePalavraChave(
    idProcesso,
    id
  );

  const { control, reset, handleSubmit } = useForm<PalavraChaveResponse>({
    defaultValues: {
      palavraChave: "",
    },
  });

  const onSubmit = (data: PalavraChaveResponse) => {
    handleSave(data, {
      onSuccess: () => {
        showSuccess().then(() => {
          refetch();
          onClose();
        });
      },
      onError: (err) => {
        showErrorAxios(err);
      },
    });
  };

  useEffect(() => {
    if (data) {
      const palavraChave = data.palavrasChaves.find(
        (objeto) => objeto.id === id
      );
      reset({ ...data, palavraChave: palavraChave?.nome });
    }
  }, [data, reset]);

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Salvar"
          onclickPrimaryButton={() => handleSubmit(onSubmit)()}
          saveLoading={isSaveLoading}
        />
      }
      title={<Toolbar title="Palavras chaves" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
    >
      <form>
        <Box as={Row} gutter={16} mb={8}>
          <Box as={Col} md={24}>
            <Field
              name="palavraChave"
              label="Palavra chave"
              control={control}
            />
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
