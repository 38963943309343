import { Flex, InputGroup, InputRightElement, Input } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

interface InputPasswordProps {
    showPassword: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
    value: any;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
}

export default function InputPassword({ showPassword, onClick, onChange, value }: InputPasswordProps) {
    return (
        <InputGroup size='md'>
            <Input
                onChange={onChange}
                value={value}
                pr='4.5rem'
                type={showPassword ? 'text' : 'password'}
            />
            <InputRightElement width='4.5rem'>
                <Flex color={colors.gray400} cursor='pointer' onClick={onClick}>
                    {showPassword ? <MdVisibilityOff size={20} /> : <MdVisibility size={20} />}
                </Flex>
            </InputRightElement>
        </InputGroup>
    )
}