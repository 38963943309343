import { useMutation } from "react-query";
import { CognitoUser } from "amazon-cognito-identity-js";
import { userPool } from "../auth.store";
import { showError } from "core/infra/helpers/alerta";
import http from "./useApi";

function useRecoverPassword() {
  return useMutation(
    (data: { email: string }) => {
      return new Promise(async (resolve) => {
        const userData = {
          Username: data.email,
          Pool: userPool,
        };

        try {
          console.log("/v1/usuario/force-password");
          await http.post(`/v1/usuario/force-password`, data);
        } catch (error: any) {
          showError(error);
        }
        return new CognitoUser(userData).forgotPassword({
          onSuccess: function (result) {
            resolve(result);
          },
          onFailure: function (err) {
            let errorMessage;
            if (
              err.message ===
              "Attempt limit exceeded, please try after some time."
            ) {
              errorMessage =
                "Limite de tentativas excedido. Tente depois de algum tempo";
            } else {
              errorMessage = err.message;
            }
            showError(errorMessage);
          },
        });
      });
    },
    { retry: false }
  );
}

export default useRecoverPassword;
