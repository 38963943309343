import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import colors from "core/resources/theme/colors";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Value } from "@wojtekmaj/react-daterange-picker/dist/cjs/shared/types";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { FaRegCalendar } from "react-icons/fa";

const StyledDateRangePick = styled.div<any>`
  .react-daterange-picker {
    width: 100%;
    height: 40px;
    font-size: 14px !important;
  }

  .react-daterange-picker__wrapper {
    background-color: ${colors.labelBg} !important;
    border-color: ${colors.labelBg}!important;

    border-radius: 6px;
    padding: 9px 2px;
    align-items: center;
    justify-content: center;
    color: ${colors.gray700};
  }

  .react-daterange-picker__wrapper:hover {
    border-color: ${colors.gray300}!important;
    box-shadow: 0 0 0 1px ${colors.gray300}!important;
    transition: all 0.2s !important;
  }

  .react-daterange-picker__inputGroup {
    flex-grow: 0;
  }

  .react-daterange-picker__calendar-button {
    margin-left: 70px;
  }
`;
interface DateRangePickProps {
  dataInicio: string | null;
  dataFim: string | null;
  onChange?: (value: Value) => void;
}

export default function DateRangePick({
  dataInicio,
  dataFim,
  onChange,
}: DateRangePickProps) {
  return (
    <StyledDateRangePick>
      <DateRangePicker
        onChange={onChange}
        value={[dataInicio, dataFim]}
        calendarIcon={<FaRegCalendar size={18} />}
        clearIcon={false}
        locale="pt"
      />
    </StyledDateRangePick>
  );
}
