import { useQuery } from "react-query";
import { PermissaoResponse } from "../typings";
import http from "core/features/auth/hooks/useApi";
import { PaginateQuery } from "core/resources/types/shared";

function usePermissao(params?: PaginateQuery & { tenantId?: string }, cb?: any) {
  return useQuery("/v1/permissao", async () => {
    const { data } = await http.get<PermissaoResponse[]>("/v1/permissao", {
      params,
      headers: {
        tenant: params?.tenantId,
      },
    });

    cb?.(data);
    return data;
  });
}

export default usePermissao;
