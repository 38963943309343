import { useCallback, useState } from "react";
import InputMask from "../InputMask";
import { Button } from "@chakra-ui/button";
import { Box } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";
import { MdSearch } from "react-icons/md";
import http from "core/features/auth/hooks/useApi";
import { showErrorAxios } from "core/infra/helpers/alerta";

export interface CepResponse {
  rua: string;
  cep: string;
  estadoId: string;
  cidadeId: string;
  sigla: string;
  localidade: string;
  bairro: string;
}

interface InputCepProps {
  value: any;
  onChange: (val: any) => any;
  onLoadCep: (data: CepResponse) => any;
  isInvalid?: boolean;
}
function InputCep({
  value,
  onChange,
  onLoadCep,
  isInvalid,
  ...props
}: InputCepProps & Record<string, any>) {
  const [isLoading, setIsLoading] = useState(false);

  const handleCep = useCallback(async () => {
    if (!value) {
      return;
    }

    try {
      setIsLoading(true);

      const { data } = await http.get(
        `/v1/buscar-cep/${value.replace(/[^0-9]/g, "")}`
      );

      onLoadCep(data);
    } catch (error) {
      showErrorAxios(error);
    } finally {
      setIsLoading(false);
    }
  }, [onLoadCep, value]);

  return (
    <Box position="relative">
      <InputMask
        isInvalid={isInvalid}
        mask="99999-999"
        value={value}
        onChange={(v: any) => {
          onChange(v);
        }}
        onKeyDown={(ev) => {
          if (ev.keyCode === 13 && typeof onLoadCep === "function") {
            handleCep();
          }
        }}
        {...props}
      />
      <Button
        padding={0}
        w="40px"
        h="40px"
        rounded="8px"
        isLoading={isLoading}
        disabled={isLoading}
        position="absolute"
        right="-10px"
        bg={colors.primary}
        color="white"
        _hover={{
          opacity: 0.8,
        }}
        bottom="0px"
        onClick={() => {
          if (typeof handleCep === "function") {
            handleCep();
          }
        }}
      >
        {isLoading ? null : <MdSearch size={20} />}
      </Button>
    </Box>
  );
}

export default InputCep;
