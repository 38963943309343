import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { TipoDocumentoResponse } from "../types";

function useTipoDocumento(id?: string) {
  return useQuery(
    `/v1/tipo-documento/${id}`,
    async () => {
      const res = await http.get<TipoDocumentoResponse>(
        `/v1/tipo-documento/${id}`
      );

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useTipoDocumento;
