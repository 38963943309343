import { Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { MdEdit, MdOutlineClose } from "react-icons/md";

interface TagProps {
  title: string;
  onClickEdit?: React.MouseEventHandler<HTMLDivElement>;
  onClickDelete?: React.MouseEventHandler<HTMLDivElement>;
  hasPermissionDelete: boolean;
  hasPermissionEdit: boolean;
}

export default function Tag({
  title,
  onClickEdit,
  onClickDelete,
  hasPermissionDelete,
  hasPermissionEdit,
}: TagProps) {
  return (
    <Flex
      w="max-content"
      h="37px"
      px="12px"
      py="7px"
      rounded="6px"
      border={`1px solid ${colors.gray400} `}
      fontSize="14px"
      align="center"
      gap={2}
    >
      {title}
      {hasPermissionEdit ? (
        <Flex onClick={onClickEdit} cursor="pointer">
          <MdEdit size={18} />
        </Flex>
      ) : null}

      {hasPermissionDelete ? (
        <Flex onClick={onClickDelete} cursor="pointer">
          <MdOutlineClose size={18} />
        </Flex>
      ) : null}
    </Flex>
  );
}
