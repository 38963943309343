import { Box } from "@chakra-ui/layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import { useRef, useState, useEffect } from "react";
import viewToPlainText from "@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext";
import { Skeleton } from "@chakra-ui/react";

interface MessageEditorProps {
  value?: string;
  onChange?: (html: string, plainText?: string) => void;
  id?: string;
  autoFocus?: boolean;
  heightType?: 1 | 2;
  loading?: boolean;
  onStartTyping?: () => any;
  onEndTyping?: () => any;
  setRef?: (ref: any) => any;
}

/**
 * @onChange tem que ser um callback com referência estável ou vai causar loop no useEffect
 *
 */

function MessageEditor({
  value,
  onChange,
  id,
  autoFocus = false,
  heightType = 1,
  loading,
  onStartTyping,
  onEndTyping,
  setRef,
  ...props
}: MessageEditorProps) {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  const [localHtml, setLocalHtml] = useState(value || "");
  const [isTyping, setIsTyping] = useState(false);
  const _id = id ? `RESPONDER-${id}` : undefined;
  const [ready, setReady] = useState(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ready && !!_id) {
      const editor = ref.current;
      const container = document.getElementById(_id);
      editor.editing?.view?.focus();
      container?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [ready, _id]);

  useEffect(() => {
    if (ref.current) {
      const plainText = viewToPlainText(
        ref.current.editing.view.document.getRoot()
      );

      onChange?.(localHtml, plainText);
    }
  }, [localHtml, onChange]);

  useEffect(() => {
    if (isTyping) {
      onStartTyping?.();
    } else {
      onEndTyping?.();
    }
  }, [isTyping, onEndTyping, onStartTyping]);

  return (
    <Box
      className={`ck-editor-height-${heightType}`}
      w="100%"
      maxW="100%"
      overflow="hidden"
      h="fit-content"
      id={_id}
    >
      {loading ? (
        <Skeleton rounded="lg" className="ck-content" />
      ) : (
        <CKEditor
          editor={ClassicEditor}
          disabled={!ready}
          onReady={(editor) => {
            ref.current = editor;
            setRef?.(editor);
            if (autoFocus) {
              editor.focus();
            }
            setReady(true);
          }}
          config={{
            startupFocus: autoFocus,
            fontSize: {
              options: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            },
            heading: {
              options: [
                {
                  model: "paragraph",
                  title: "Parágrafo",
                  class: "ck-heading_paragraph",
                },
                {
                  model: "heading1",
                  view: "h1",
                  title: "Título 1",
                  class: "ck-heading_heading1",
                },
                {
                  model: "heading2",
                  view: "h2",
                  title: "Título 2",
                  class: "ck-heading_heading2",
                },
                {
                  model: "heading3",
                  view: "h3",
                  title: "Título 3",
                  class: "ck-heading_heading3",
                },
              ],
            },
            toolbar: {
              items: [
                "heading",
                "|",
                "alignment",
                "indent",
                "outdent",
                "|",
                "bold",
                "italic",
                "|",
                "specialCharacters",
                "fontColor",
                "fontBackgroundColor",
                "fontSize",
                "|",
                "underline",
                "strikethrough",
                "|",
                "blockQuote",
                "link",
                "numberedList",
                "bulletedList",
                "|",
                "undo",
                "redo",
              ],
            },
          }}
          data={value}
          onChange={(_, editor) => {
            setIsTyping(true);
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
              timeoutRef.current = undefined;
            }

            timeoutRef.current = setTimeout(() => {
              const html: string = editor?.getData();
              timeoutRef.current = undefined;

              setLocalHtml(html);
              setIsTyping(false);
            }, 250);
          }}
          {...props}
        />
      )}
    </Box>
  );
}

export default MessageEditor;
