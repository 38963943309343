import { Box, Text } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import useSavePermitirAcesso from "core/features/processos/hooks/useSavePermitirAcesso";
import { ProcessoPermitirAcessoResponse } from "core/features/processos/typings";
import { showErrorAxios, showSuccess } from "core/infra/helpers/alerta";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FooterModalDefault from "ui/components/FooterModal";
import SelectUsuarios from "ui/components/Input/SelectUsuarios";
import Toolbar from "ui/components/Toolbar";

interface ModalPermitirAcessoProps {
  isOpen: boolean;
  onClose: () => any;
  id?: string;
  refetch: any;
  permitirAcesso?: {
    id: string;
    usuario: {
      id: string;
      pessoaId: string;
      nome: string;
    };
  }[];
}

export default function ModalPermitirAcesso({
  isOpen,
  onClose,
  id,
  refetch,
  permitirAcesso,
}: ModalPermitirAcessoProps) {
  const { mutate: handleSave, isLoading: isLoadingSave } =
    useSavePermitirAcesso(id);
  const { handleSubmit, setValue, watch } =
    useForm<ProcessoPermitirAcessoResponse>({
      defaultValues: {
        pessoaIds: permitirAcesso?.map((v) => v.usuario.pessoaId),
      },
    });

  const form = watch();

  const [tempValues, setTempValues] = useState({
    pessoaIds: permitirAcesso?.map((v) => v.usuario.pessoaId) || [],
  });

  const onSubmit = (data: ProcessoPermitirAcessoResponse) => {
    handleSave(data, {
      onSuccess: () => {
        showSuccess().then(() => {
          onClose();
          refetch();
        });
      },
      onError: (err) => {
        showErrorAxios(err);
      },
    });
  };

  useEffect(() => {
    setTempValues((prevValues) => ({
      ...prevValues,
      pessoaIds: form.pessoaIds || [],
    }));
  }, [form.pessoaIds]);

  const handleSaveClick = () => {
    setValue("pessoaIds", tempValues.pessoaIds);
    handleSubmit(onSubmit)();
  };

  const handleSelectPessoasChange = (values: string[] | undefined) => {
    const pessoaIds = (values || []).map((pessoaId) => pessoaId);

    setTempValues({ ...tempValues, pessoaIds: pessoaIds });
  };

  const pessoaIds = tempValues.pessoaIds?.map((pessoaId) => pessoaId) || [];

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Salvar"
          onclickPrimaryButton={handleSaveClick}
          saveLoading={isLoadingSave}
        />
      }
      title={<Toolbar title="Permitir acesso" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
    >
      <Box as={Row} gutter={16} mb={8}>
        <Box as={Col} sm={24}>
          <Text mb={2}>Usuários</Text>
          <SelectUsuarios
            form={form.pessoaIds}
            value={pessoaIds}
            onChange={handleSelectPessoasChange}
            mode="multiple"
          />
        </Box>
      </Box>
    </Modal>
  );
}
