export const createFormData = (
  files: any[],
  body = {},
  fileKeyName = 'file',
  filesAccessorName?: string,
) => {

  files = files || [];

  const data = new FormData();

  files.forEach(file => file instanceof File && data.append(fileKeyName,  file))
  
  Object.keys(body).forEach(key => {
    const isNotNull = (body as any)[key as any] !== null;
    const isNotUndefined = (body as any)[key as any] !== undefined;
    const isNotFileKey = key !== fileKeyName;
    const isNotFilesAccessorKey = key !== filesAccessorName;

    const shouldAppendKey = isNotFilesAccessorKey && isNotFileKey && isNotUndefined && isNotNull;

    if (shouldAppendKey) {
      data.append(key, typeof (body as any)[key as any] === 'string' ? (body as any)[key as any] : JSON.stringify((body as any)[key as any]));
    }
  });

  return data;
};