import { useQuery } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { FiltrosProcessos } from "core/features/processos/typings";

function useDashboardProcessos(query: FiltrosProcessos) {
  return useQuery([`/v1/processo/dashboard`, query], async () => {
    const res = await http.get<{
      totalVinculado: number;
      totalAssinar: number;
    }>(`/v1/processo/dashboard`, { params: query });

    return res.data;
  });
}

export default useDashboardProcessos;
