import { FiltroOrganizacoes } from "core/features/organizacao/typings";
import { BaseFiltroPaginate } from "core/resources/enums/paginate";
import { useCallback, useState } from "react";

export const filtroDefaultOrganizacoes: FiltroOrganizacoes = {
    limit: BaseFiltroPaginate.Limit,
    search: "",
    ativo: undefined,

    page: 1,
};

function useFiltroOrganizacoes() {
    const [filtro, setFiltro] = useState<FiltroOrganizacoes>(filtroDefaultOrganizacoes);

    const onChangeFiltro = useCallback((_filtro: Partial<FiltroOrganizacoes>) => {
        if ((_filtro.search || _filtro.ativo) !== undefined) {
            _filtro.page = 1;
        }
        setFiltro((state) => ({ ...state, ..._filtro }));
    }, []);

    return {
        filtro,
        setFiltro,
        onChangeFiltro,
    };
}

export default useFiltroOrganizacoes;
