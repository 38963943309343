import { Box, Flex, Text } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { useForm } from "react-hook-form";
import { BsPlusLg } from "react-icons/bs";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import CardDefault from "ui/components/Cards/CardDefault";
import Field from "ui/components/Input/Field";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { useEffect, useRef } from "react";
import ContatoItem from "./components/ContatoItem";
import { useNavigate } from "react-router-dom";
import InputMask from "ui/components/Input/InputMask";
import UploadImagem from "ui/components/Input/UploadImagem";
import logoAdd from "assets/images/logoAddImage.png";
import {
  ContatoForm,
  OrganizacaoResponse,
} from "core/features/organizacao/typings";
import useOrganizacao from "core/features/organizacao/hooks/useOrganizacao";
import {
  showErrorAxios,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import { isValidCNPJ, isValidEmail } from "@brazilian-utils/brazilian-utils";
import useSaveOrganizacao from "core/features/organizacao/hooks/useSaveOrganizacao";
import SelectStatus from "ui/components/Input/SelectStatus/SelectStatus";

export default function InformacoesBasicas({ id }: { id?: string }) {
  const { isLoading: isLoadingSave, mutate: handleSave } = useSaveOrganizacao();

  const { data, isLoading, refetch } = useOrganizacao(id);

  const navigate = useNavigate();
  const tempIdIncrement = useRef(0);

  const defaultValues: OrganizacaoResponse = {
    ativo: undefined,
    pessoa: {
      nome: "",
      documento: "",
      arquivoImagemId: "",
      inscricaoMunicipal: "",
      nomeFantasia: "",
      contatos: [],
      login: "",
    },
  };

  const { control, setValue, watch, reset, handleSubmit } =
    useForm<OrganizacaoResponse>({ defaultValues });
  const contatos = watch("pessoa.contatos");

  const onSubmit = (data: OrganizacaoResponse) => {
    handleSave(
      {
        ...data,
        ativo: data.ativo === "ativo" ? true : false,
      },
      {
        onSuccess: (response) => {
          showSuccess().then(() => {
            if (!id && response.data) {
              window.location.replace(`/organizacao/${response.data}`);
            }
            refetch();
          });
        },
        onError: (err) => {
          showErrorAxios(err);
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  useLoading(isLoading);

  return (
    <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
      <Box>
        <CardDefault mb={4}>
          <Text fontWeight="semibold" fontSize="18px">
            Informações básicas
          </Text>

          <Flex gap={4} mt={8}>
            <Flex>
              <Field
                control={control}
                name="pessoa.arquivoImagem"
                render={({ field }) => {
                  return (
                    <UploadImagem
                      {...field}
                      logoAdd={logoAdd}
                      setValue={setValue}
                      stringSetValue={"pessoa.arquivoImagemId"}
                    />
                  );
                }}
              />
            </Flex>

            <Box w="100%">
              <Box as={Row} gutter={16} mb={8}>
                <Box as={Col} md={10}>
                  <Field
                    name="pessoa.documento"
                    label="CNPJ"
                    control={control}
                    rules={{
                      required: true,
                      validate: (v) => isValidCNPJ(v) || "CNPJ inválido",
                    }}
                    render={({ field, isInvalid }) => (
                      <InputMask
                        {...field}
                        isInvalid={isInvalid}
                        mask="99.999.999/9999-99"
                        onChange={(v) => {
                          field.onChange(v.replace(/\D/g, ""));
                        }}
                      />
                    )}
                  />
                </Box>
                <Box as={Col} md={14}>
                  <Field
                    name="pessoa.nome"
                    label="Razão social"
                    control={control}
                    rules={{ required: true }}
                  />
                </Box>
              </Box>

              <Box as={Row} gutter={16} mb={8}>
                <Box as={Col} md={10}>
                  <Field
                    name="pessoa.inscricaoMunicipal"
                    label="Inscrição municipal"
                    control={control}
                  />
                </Box>
                <Box as={Col} md={14}>
                  <Field
                    name="pessoa.nomeFantasia"
                    label="Nome fantasia"
                    control={control}
                    rules={{ required: true }}
                  />
                </Box>
              </Box>
              <Box as={Row} gutter={16} mb={8}>
                <Box as={Col} md={10}>
                  <Field
                    label="Status"
                    control={control}
                    name={"ativo"}
                    rules={{ required: true }}
                    render={({ field }) => <SelectStatus {...field} />}
                  />
                </Box>
                <Box as={Col} md={14}>
                  <Field
                    name="pessoa.email"
                    label="E-mail"
                    control={control}
                    rules={{
                      required: true,
                      validate: (v) => isValidEmail(v) || "Email inválido",
                    }}
                    inputProps={{
                      type: "email",
                      // disabled: id ? true : false,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Flex>
        </CardDefault>
        <CardDefault mb={4}>
          <Flex gap={10} align="center" mb={4}>
            <Text fontWeight="semibold" fontSize="18px">
              Contatos
            </Text>
            <OutlineButton
              onClick={() => {
                setValue("pessoa.contatos", [
                  ...(contatos ?? []),
                  {
                    added: true,
                    tempId: `${new Date().getTime().toString()}${
                      tempIdIncrement.current
                    }`,
                  } as ContatoForm,
                ]);

                tempIdIncrement.current += 1;
              }}
            >
              <BsPlusLg size={20} />
              Adicionar
            </OutlineButton>
          </Flex>

          <Flex flexDir="column" gap={4}>
            {contatos?.map((contato: ContatoForm, index: number) => (
              <ContatoItem
                setValue={setValue}
                contatos={contatos}
                key={contato.added ? contato.tempId : contato.id}
                index={index}
                control={control}
              />
            ))}
          </Flex>
        </CardDefault>
        <CardDefault>
          <Flex flex={1} justifyContent="flex-end" gap={4}>
            <OutlineButton onClick={() => navigate("/organizacao")}>
              Cancelar
            </OutlineButton>
            <PrimaryButton type="submit" isLoading={isLoadingSave}>
              Salvar
            </PrimaryButton>
          </Flex>
        </CardDefault>
      </Box>
    </form>
  );
}
