import { Box, Flex, Text } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { useBreadcrumb } from "core/features/breadcrumbs";
import { useForm } from "react-hook-form";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import CardDefault from "ui/components/Cards/CardDefault";
import Field from "ui/components/Input/Field";
import { BsPlusLg } from "react-icons/bs";
import { useEffect, useState } from "react";
import ModalVincularPessoas from "./components/ModalVincularPessoas";
import { SetorResponse } from "core/features/setor/typings";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import SelectStatusUsuario from "ui/components/Input/SelectStatus/SelectStatus";
import { useCurrentTenant } from "core/features/auth/tenant.store";
import useSaveSetor from "core/features/setor/hooks/useSaveSetor";
import {
  showErrorAxios,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import useSetor from "core/features/setor/hooks/useSetor";
import PessoasVinculadasTable from "./components/PessoasVinculadasTable";
import usePessoasVinculadas from "core/features/setor/hooks/usePessoasVinculadas";

export default function SetorDetalhes() {
  const { id } = useParams() as {
    id: string;
  };

  const navigate = useNavigate();

  const { tenant } = useCurrentTenant();

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Setor", url: "/setor" },
    { name: id ? "Editar setor" : "Cadastro de setor" },
  ]);

  const { isLoading: isLoadingSave, mutate: handleSave } = useSaveSetor(
    tenant.id
  );

  const { data, isLoading } = useSetor(id);

  const { control, watch, setValue, handleSubmit, reset } =
    useForm<SetorResponse>();

  const form = watch();

  const pessoasVinculadasIds = form.pessoasVinculadasIds || [];

  const {
    data: dataPessoasVinculadas,
    refetch,
    isLoading: isLoadingPessoasVinculadas,
    isFetching,
  } = usePessoasVinculadas(id, pessoasVinculadasIds);

  const onSubmit = async (data: SetorResponse) => {
    handleSave(
      {
        ...data,
        ativo: data.ativo === "ativo" ? true : false,
      },
      {
        onSuccess: async (response) => {
          await showSuccess();
          if (!id && response.data) {
            window.location.replace(`/setor/${response.data}`);
          }
        },
        onError: (err) => {
          showErrorAxios(err);
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  useLoading(isLoading);

  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit(onSubmit)(ev);
      }}
    >
      <Box>
        <Text
          fontWeight="semibold"
          fontSize="30px"
          color="black"
          flexWrap="wrap"
          mb={4}
        >
          {id ? "Editar setor" : "Cadastro de setor"}
        </Text>

        <CardDefault mb={4}>
          <Text fontWeight="semibold" fontSize="18px" mb={4}>
            Informações básicas
          </Text>
          <Box as={Row} gutter={16}>
            <Box as={Col} md={18}>
              <Field
                name="nome"
                label="Nome do setor"
                control={control}
                rules={{ required: true }}
              />
            </Box>

            <Box as={Col} md={6}>
              <Field
                label="Status"
                control={control}
                name={"ativo"}
                rules={{ required: true }}
                render={({ field }) => <SelectStatusUsuario {...field} />}
              />
            </Box>
          </Box>
        </CardDefault>
        <CardDefault mb={4}>
          <Flex gap={10} align="center" mb={4}>
            <Text fontWeight="semibold" fontSize="18px">
              Pessoas vinculadas
            </Text>
            <OutlineButton onClick={() => setIsOpenModal(true)}>
              <BsPlusLg size={20} />
              Adicionar
            </OutlineButton>
          </Flex>

          <PessoasVinculadasTable
            dataPessoasVinculadas={dataPessoasVinculadas}
            refetch={refetch}
            form={form}
            setValue={setValue}
            isLoading={isLoadingPessoasVinculadas || isFetching}
          />
        </CardDefault>
        <CardDefault>
          <Flex flex={1} justify="end" gap={4}>
            <OutlineButton onClick={() => navigate("/setor")}>
              Cancelar
            </OutlineButton>
            <PrimaryButton type="submit" isLoading={isLoadingSave}>
              Salvar
            </PrimaryButton>
          </Flex>
        </CardDefault>
        {isOpenModal ? (
          <ModalVincularPessoas
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            setValue={setValue}
            form={form}
          />
        ) : null}
      </Box>
    </form>
  );
}
