import colors from "core/resources/theme/colors";
import { createGlobalStyle } from "styled-components";

export const Global = createGlobalStyle<any>`



  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${colors.primary} !important;
      color: ${colors.labelBg} !important;
    }
  

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid ${colors.labelBg} !important;
      box-shadow: 0 0 24px rgba(0,0,0,.05);
      font-weight: 500 !important;
      
  }

  .ant-select-selection-placeholder {
   
    color: ${colors.gray700} !important;
  }
`;
