import { useQuery } from "react-query";
import { FiltroUsuario, UsuarioItemResponse } from "./../typings/index";
import { PaginateResult } from "core/resources/types/shared";
import http from "core/features/auth/hooks/useApi";

function useListarUsuarios(query: FiltroUsuario) {
  return useQuery([`/v1/usuario`, query], async () => {
    const { data } = await http.get<PaginateResult<UsuarioItemResponse>>(
      `/v1/usuario`,
      {
        params: query,
      }
    );

    return data;
  });
}

export default useListarUsuarios;
