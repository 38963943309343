import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { FindUsuariosResponse } from "../typings";

function useFindUsuarios(ids?: string[]) {
  return useQuery(
    ["/v1/shared/usuario/find-usuarios", ids],
    async () => {
      const { data } = await http.get<FindUsuariosResponse[]>(
        "/v1/shared/usuario/find-usuarios",
        {
          params: { ids },
        }
      );

      return data;
    },
    {
      enabled: !!ids && ids.length > 0,
    }
  );
}

export default useFindUsuarios;
