import { PermissaoEnum } from "core/resources/enums/permissao";

export enum TabsAndamentoRoutes {
  FichaProcesso = "fichaProcesso",
  Documentos = "documentos",
  HistoricoEvento = "historicoEvento",
  // Participantes = "participantes",

  // Alteracoes = "alteracoes",
}

export const tabsAndamento: {
  label: string;
  route: TabsAndamentoRoutes;
  permissions: string[];
}[] = [
  {
    label: "Ficha do Processo",
    route: TabsAndamentoRoutes.FichaProcesso,
    permissions: [PermissaoEnum.Fluir.Processo.Processo.Visualizar],
  },
  {
    label: "Documentos",
    route: TabsAndamentoRoutes.Documentos,
    permissions: [PermissaoEnum.Fluir.Processo.Documento.Visualizar],
  },
  {
    label: "Histórico do Evento",
    route: TabsAndamentoRoutes.HistoricoEvento,
    permissions: [PermissaoEnum.Fluir.Processo.Documento.Visualizar],
  },
  // {
  //   label: "Participantes",
  //   route: TabsAndamentoRoutes.Participantes,
  // },

  // {
  //   label: "Alterações",
  //   route: TabsAndamentoRoutes.Alteracoes,
  // },
];
