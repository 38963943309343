import { Flex, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import CardDefault from "ui/components/Cards/CardDefault";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import FormEndereco from "ui/components/FormEndereco/index";
import { useNavigate } from "react-router-dom";
import { UsuarioEnderecoResponse } from "core/features/usuario/typings";
import { showSuccess, useLoading } from "core/infra/helpers/alerta";
import { useEffect } from "react";
import useSaveProfileEndereco from "core/features/auth/hooks/useSaveProfileEndereco";
import useProfileEndereco from "core/features/auth/hooks/useProfileEndereco";

export default function Endereco() {
  const { mutate: saveEndereco, isLoading: isLoadingSave } =
    useSaveProfileEndereco();

  const { data, isLoading } = useProfileEndereco();
  const navigate = useNavigate();

  const { control, handleSubmit, reset, watch, setValue } =
    useForm<UsuarioEnderecoResponse>();

  const onSubmit = (data: UsuarioEnderecoResponse) => {
    saveEndereco(data, {
      onSuccess: () => {
        showSuccess();
      },
    });
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  useLoading(isLoading);

  const form = watch();

  return (
    <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
      <CardDefault mb={4}>
        <Text fontWeight="semibold" fontSize="18px" mb={4}>
          Endereço
        </Text>

        <FormEndereco control={control} form={form} setValue={setValue} />
      </CardDefault>
      <CardDefault>
        <Flex flex={1} justifyContent="flex-end" gap={4}>
          <OutlineButton onClick={() => navigate("/")}>Cancelar</OutlineButton>
          <PrimaryButton type="submit" isLoading={isLoadingSave}>
            Salvar
          </PrimaryButton>
        </Flex>
      </CardDefault>
    </form>
  );
}
