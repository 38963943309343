import { Outlet } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";
import login from "assets/images/svg/login.svg";
import logoLogin from "assets/images/svg/logo-login.svg";
import { Image } from "@chakra-ui/image";
import { useWindowSize } from "core/infra/helpers/window";

export default function Auth() {
  const windowSize = useWindowSize();

  const isMobile = windowSize.width <= 1100;

  const fontSizeText = isMobile ? "14px" : "18px";
  return (
    <Flex>
      <Flex w="45vw" h="100vh" roundedEnd="full" bg={colors.primary}>
        <Flex w="100%" maxW="25vw" maxH="25hv" position="relative">
          <Image
            position="absolute"
            maxW="25vw"
            maxH="25hv"
            src={login}
            bottom={0}
          />
        </Flex>

        <Flex
          w="370px"
          flexDir="column"
          color="white"
          gap={2}
          margin="200px 60px auto auto"
        >
          <Image w="120px" h="50px" src={logoLogin} />
          <Text fontSize={fontSizeText} fontWeight="500">
            A Fluir te ajuda a gerenciar os fluxos processuais do dia a dia
          </Text>

          {/* <Flex align="center" gap={2}>
            <MdCheck />
            <Text fontSize={fontSizeText} fontWeight="400">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Text>
          </Flex>
          <Flex align="center" gap={2}>
            <MdCheck />
            <Text fontSize={fontSizeText} fontWeight="400">
              Duis luctus tellus vitae sem facilisis, quis convallis ipsum
              ultricies.
            </Text>
          </Flex> */}
        </Flex>
      </Flex>

      <Outlet />
    </Flex>
  );
}
