import { Box } from "@chakra-ui/react";
import { Control, UseFormSetValue } from "react-hook-form";
import Field from "ui/components/Input/Field";
import InputCep from "ui/components/Input/InputCep";
import { CepResponse } from "ui/components/Input/InputCep/index";
import SelectEstado from "ui/components/Input/SelectEstado/index";
import SelectCidade from "ui/components/Input/SelectCidade";
import { Row, Col } from "antd";

interface FormEnderecoProps {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  form: any;
}

export default function FormEndereco({
  control,
  setValue,
  form,
}: FormEnderecoProps) {
  const afterLoadCep = (data: CepResponse) => {
    setValue("bairro", data.bairro);
    setValue("logradouro", data.rua);
    setValue("cidadeId", data.cidadeId);
    setValue("estadoId", data.estadoId);
    setValue("cep", data.cep);
  };

  return (
    <Box gap={4}>
      <Box as={Row} gutter={32} mb={4}>
        <Box as={Col} md={5}>
          <Field
            control={control}
            name="cep"
            label="Cep"
            rules={{
              required: {
                message: "Informe seu CEP",
                value: true,
              },
            }}
            render={({ field, isInvalid }) => (
              <InputCep
                {...field}
                isInvalid={isInvalid}
                onLoadCep={afterLoadCep}
              />
            )}
          />
        </Box>
        <Box as={Col} md={7}>
          <Field
            control={control}
            name="logradouro"
            label="Rua"
            rules={{
              required: {
                message: "Informe sua rua",
                value: true,
              },
            }}
          />
        </Box>
        <Box as={Col} md={4}>
          <Field
            control={control}
            name="numero"
            label="Número"
            rules={{
              required: {
                message: "Informe o numedo da sua residência",
                value: true,
              },
            }}
          />
        </Box>
        <Box as={Col} md={8}>
          <Field control={control} name="complemento" label="Complemento" />
        </Box>
      </Box>
      <Box as={Row} gutter={32}>
        <Box as={Col} md={8}>
          <Field
            control={control}
            name="bairro"
            label="Bairro"
            rules={{
              required: {
                message: "Informe seu bairro",
                value: true,
              },
            }}
          />
        </Box>
        <Box as={Col} md={4}>
          <Field
            control={control}
            name="estadoId"
            label="Estado"
            rules={{
              required: {
                message: "Informe seu estado",
                value: true,
              },
            }}
            render={({ field, isInvalid }) => (
              <SelectEstado isInvalid={isInvalid} {...field} />
            )}
          />
        </Box>
        <Box as={Col} md={12}>
          <Field
            control={control}
            name="cidadeId"
            label="Cidade"
            rules={{
              required: {
                message: "Informe sua cidade",
                value: true,
              },
            }}
            render={({ field, isInvalid }) => (
              <SelectCidade
                isInvalid={isInvalid}
                uf={form.estadoId}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}
