import { Select, SelectProps } from "antd";
import SelectBaseAntd from "../SelectBaseAntd";

const { Option } = Select;

export interface SelectOrderDirectionProps<T = any> extends SelectProps<T> {
  value: any;
  onChange: (v: any) => void;
}

function SelectOrderDirection({
  value,
  onChange,
  ...rest
}: SelectOrderDirectionProps) {
  return (
    <SelectBaseAntd
      size="large"
      getPopupContainer={(node) => node.parentNode}
      placeholder="Selecione"
      onChange={onChange}
      value={value}
      {...rest}
    >
      <Option value={"DESC"} key="d">
        Maior para o menor
      </Option>
      <Option value={"ASC"} key="a">
        Menor para o maior
      </Option>
    </SelectBaseAntd>
  );
}

export default SelectOrderDirection;
