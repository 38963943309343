import {
  Container as ContainerChakra,
  ContainerProps,
} from "@chakra-ui/layout";

function Container(props: ContainerProps) {
  return <ContainerChakra maxW="1400px" {...props} />;
}

export default Container;
