import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button, Flex } from "@chakra-ui/react";
import { IoClose } from "react-icons/io5";
import CardPerson from "ui/components/Cards/CardPerson";
import colors from "core/resources/theme/colors";
import http from "core/features/auth/hooks/useApi";
import { UseFormSetValue } from "react-hook-form";
import { Spin } from "antd";

export const Container = styled(Flex)`
  width: 170px;
  height: 170px;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: "none";
  border-radius: 100%;
  border: 2px solid ${colors.gray300};
  box-shadow: "0px 0px 8px 2px rgba(145, 158, 171, 0.2)";
  img {
    margin: 0px 0;
    border-radius: "full";
  }
`;

interface UploadImagemProps {
  value: any;
  onChange: (arg: string | null) => any;
  logoAdd?: string;
  stringSetValue?: string;
  setValue: UseFormSetValue<any>;
}

function UploadImagem({
  value,
  onChange,
  logoAdd,
  stringSetValue,
  setValue,
}: UploadImagemProps) {
  const [uploading, setUploading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);

  const refUploadPerfil = useRef<any>();

  useEffect(() => {
    setFileInfo(value);
  }, [value]);

  const handleUpload = useCallback(
    async (file: File) => {
      const formData = new FormData();

      formData.append("file", file);

      try {
        setUploading(true);
        const { data } = await http.post(
          `/v1/arquivos/upload-usuario`,
          formData
        );
        onChange(data.url);
        setValue(`${stringSetValue}`, data.id);
      } catch (e) {
        onChange(null);
      } finally {
        setUploading(false);
      }
    },
    [onChange,setValue,stringSetValue]
  );

  return (
    <Container position="relative" flexDir="column">
      <Flex
        py={fileInfo ? 0 : "32px"}
        flex={1}
        justifyContent="center"
        alignItems="center"
      >
        <Flex>
          <Flex
            cursor="pointer"
            onClick={() => refUploadPerfil.current.click()}
            _hover={{ opacity: 0.8 }}
          >
            <CardPerson
              avatarUrl={fileInfo ? fileInfo : logoAdd}
              avatarProps={{ w: "150px", h: "150px" }}
            />
          </Flex>

          {fileInfo ? (
            <Button
              position="absolute"
              right="-2px"
              top="-2px"
              isDisabled={uploading}
              borderRadius="50%"
              p="0"
              onClick={() => onChange(null)}
            >
              <IoClose color="black" fontSize="15px" />
            </Button>
          ) : null}
        </Flex>
      </Flex>

      {uploading ? (
        <Flex h="28px" alignSelf="center" justifyContent="center" position='absolute'  >
          <Spin spinning />
        </Flex>
      ) : null}

      <input
        type="file"
        ref={refUploadPerfil}
        style={{ display: "none" }}
        accept="image/*"
        onChange={(ev) => {
          if (ev.target.files) {
            handleUpload(ev.target.files[0]);
          }
        }}
      />
    </Container>
  );
}

export default UploadImagem;
