import { Flex, Text } from "@chakra-ui/react";
import { UsuarioItemResponse } from "core/features/usuario/typings/index";
import TagStatus from "ui/components/TagStatus/index";
import CardPerson2 from "ui/components/Cards/CardPerson2/index";
import colors from "core/resources/theme/colors";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu/index";
import { useNavigate } from "react-router-dom";
import {
  TabMenuOptions,
  TabsUsuarioRoutes,
} from "ui/pages/Admin/Usuario/Detalhes/typings";
import { MdEditLocationAlt, MdPerson } from "react-icons/md";

interface TableItemRowProps {
  item: UsuarioItemResponse;
  indexItem: number;
}

export default function TableItemRow({ item, indexItem }: TableItemRowProps) {
  const navigate = useNavigate();

  return (
    <Flex
      rounded={8}
      px={5}
      py={2}
      mb={2}
      bg="#FFFFFF"
      boxShadow="base"
      align="center"
      onClick={() => navigate(`/usuario/${item.id}`)}
      cursor="pointer"
    >
      <Flex flex={1} align="center" gap={2}>
        <CardPerson2 avatarUrl={item.imagemUrl} />

        <Flex flexDir="column">
          <Text fontSize="16px">{item.nome}</Text>
          <Text fontSize="14px" color={colors.gray600}>
            {item.login}
          </Text>
        </Flex>
      </Flex>
      <Flex fontSize="16px" flex={1}>
        {item.perfil ? item.perfil : "--"}
      </Flex>
      <Flex flex={0.2}>
        {item.ativo ? (
          <TagStatus title="Ativo" colorScheme="whatsapp" />
        ) : (
          <TagStatus title="Inativo" colorScheme="gray" />
        )}
      </Flex>
      <Flex h="fit-content" py={4}>
        <ActionsKebabMenu
          indexItem={indexItem}
          item={item}
          actions={[
            ...TabMenuOptions.map((option) => ({
              title: option.label,
              icon: () => {
                if (option.route === TabsUsuarioRoutes.InformacaoesBasicas) {
                  return <MdPerson size={20} />;
                }
                return <MdEditLocationAlt size={22} />;
              },
              onClick: (v: any) => {
                navigate(`/usuario/${v.id}?opcao=${option.route}`);
              },
            })),
          ]}
        />
      </Flex>
    </Flex>
  );
}
