import { Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { GoArrowDown } from "react-icons/go";
import Fluxograma from "./Fluxograma";
import Criterios from "./Criterios";
import { useState } from "react";
import { Spin } from "antd";
import useSetores from "core/features/shared/hooks/useSetores";
import { AndamentosResponse } from "core/features/tipoprocesso/typings";

export default function FluxogramaFluxoProcesso({
  form,
}: {
  form: AndamentosResponse[];
}) {
  const { data: dataSetores, isLoading: isLoadingSetores } = useSetores();

  const [andamentoSelecionadoId, setAndamentoSelecionadoId] = useState<
    string | undefined
  >(undefined);

  const _isLoading = isLoadingSetores;

  return (
    <>
      {_isLoading ? (
        <Flex w="100%" align="center" m="auto" justify="center">
          <Spin />
        </Flex>
      ) : (
        <Flex mt={6}>
          <Flex flexDir="column" align="center" gap={2}>
            <Flex
              bg={colors.gray200}
              rounded="50%"
              w="140px"
              h="47px"
              align="center"
              justify="center"
            >
              Inicio
            </Flex>
            <GoArrowDown color={colors.gray500} size={25} />

            {form
              .sort((a, b) => a.ordemAndamento! - b.ordemAndamento!)
              .map((andamento) => (
                <Fluxograma
                  data={dataSetores}
                  key={andamento.id}
                  item={andamento}
                  onClick={() => setAndamentoSelecionadoId(andamento.id)}
                  isSelected={andamento.id === andamentoSelecionadoId}
                />
              ))}
            <Flex
              bg={colors.gray200}
              rounded="50%"
              w="140px"
              h="47px"
              align="center"
              justify="center"
            >
              Fim Processo
            </Flex>
          </Flex>

          {form
            .filter((andamento) => andamento.id === andamentoSelecionadoId)
            .map((andamentoFiltrado, index) => (
              <Criterios key={index} item={andamentoFiltrado} />
            ))}
        </Flex>
      )}
    </>
  );
}
