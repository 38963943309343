import { Box, Text } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import { ProcessoDocumentoResponse } from "core/features/processos-documento/typings";
import { useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import FooterModalDefault from "ui/components/FooterModal";
import SelectUsuarios from "ui/components/Input/SelectUsuarios";
import Toolbar from "ui/components/Toolbar";

interface ModalAssinantesProps {
  isOpen: boolean;
  onClose: () => any;
  form: ProcessoDocumentoResponse;
  setValue: UseFormSetValue<ProcessoDocumentoResponse>;
}

export default function ModalAssinantes({
  isOpen,
  onClose,
  form,
  setValue,
}: ModalAssinantesProps) {
  const [tempValues, setTempValues] = useState({
    assinantes: form.assinantes || [],
  });

  useEffect(() => {
    setTempValues((prevValues) => ({
      ...prevValues,
      assinantes: form.assinantes || [],
    }));
  }, [form.assinantes, setTempValues]);

  const handleSaveClick = () => {
    setValue("assinantes", tempValues.assinantes);

    onClose();
  };

  const handleSelectPessoasChange = (values: string[] | undefined) => {
    const assinantes = (values || []).map((assinanteId, index) => ({
      assinanteId: assinanteId,
      ordem: index,
    }));

    setTempValues({ ...tempValues, assinantes: assinantes });
  };

  const formAssinante =
    form.assinantes?.map((assinante) => assinante.assinanteId) || [];

  const assinanteIds =
    tempValues.assinantes?.map((assinante) => assinante.assinanteId) || [];

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Adicionar"
          onclickPrimaryButton={handleSaveClick}
        />
      }
      title={<Toolbar title="Assinantes" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
    >
      <Box as={Row} gutter={16} mb={8}>
        <Box as={Col} sm={24}>
          <Text mb={2}>Assinantes</Text>
          <SelectUsuarios
            form={formAssinante}
            value={assinanteIds}
            onChange={handleSelectPessoasChange}
            mode="multiple"
          />
        </Box>
      </Box>
    </Modal>
  );
}
