import { useMutation } from "react-query";
import { CognitoUser } from "amazon-cognito-identity-js";
import { userPool } from "../auth.store";
import { showError, showSuccess } from "core/infra/helpers/alerta";
import { globalNavigate } from "ui/components/GlobalHistory";

function useEmailConfirm() {
  return useMutation(
    (data: { email: string; code: string; newPassword: string }) => {
      return new Promise(() => {
        const userData = {
          Username: data.email,
          Pool: userPool,
        };

        const user = new CognitoUser(userData);
        return user.confirmPassword(data.code, data.newPassword, {
          onFailure(err) {
            let errorMessage;
            if (
              err.message ===
              "Invalid verification code provided, please try again."
            ) {
              errorMessage = "Código de verificação inválido.";
            } else if (
              err.message ===
              "Attempt limit exceeded, please try after some time."
            ) {
              errorMessage =
                "Limite de tentativas excedido. Tente depois de algum tempo.";
            } else {
              errorMessage = err.message;
            }
            showError(errorMessage);
          },
          onSuccess() {
            showSuccess("Senha redefinida com sucesso").then(() =>
              globalNavigate("/auth/signin")
            );
          },
        });
      });
    },
    { retry: false }
  );
}

export default useEmailConfirm;
