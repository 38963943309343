import CardDefault from "ui/components/Cards/CardDefault";
import TableItemRow from "./TableItemRow";
import {
  PessoaVinculadaResponse,
  SetorResponse,
} from "core/features/setor/typings";
import { UseFormSetValue } from "react-hook-form";
import { Flex, Spinner } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";

interface PessoasVinculadasTableProps {
  dataPessoasVinculadas?: PessoaVinculadaResponse[];
  setValue: UseFormSetValue<SetorResponse>;
  form: SetorResponse;
  refetch: any;
  isLoading: boolean;
}

export default function PessoasVinculadasTable({
  dataPessoasVinculadas,
  setValue,
  form,
  refetch,
  isLoading,
}: PessoasVinculadasTableProps) {
  return (
    <>
      {isLoading ? (
        <Flex align="center" justifyContent="center" my={10}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={colors.primary}
            size="xl"
          />
        </Flex>
      ) : null}

      {!isLoading && dataPessoasVinculadas ? (
        <CardDefault>
          {dataPessoasVinculadas?.map((item, index) => (
            <TableItemRow
              key={item.id}
              item={item}
              indexItem={index}
              setValue={setValue}
              form={form}
              refetch={refetch}
            />
          ))}
        </CardDefault>
      ) : null}
    </>
  );
}
