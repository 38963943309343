import { Box, Flex } from "@chakra-ui/react";
import useListarDocumentos from "core/features/processos-documento/hooks/useListarDocumentos";
import { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import SearchHeaderBar from "ui/components/SearchHeaderBar";
import DocumentosTable from "ui/pages/Admin/Processos/Detalhes/components/TabDocumentos/components/DocumentosTable";
import ModalAdicionarDocumentos from "ui/pages/Admin/Processos/Detalhes/components/TabDocumentos/components/ModalAdicionarDocumentos";
import ModalFiltrosDocumentos from "ui/pages/Admin/Processos/Detalhes/components/TabDocumentos/components/ModalFiltrosDocumentos";
import useFiltroDocumentos from "ui/pages/Admin/Processos/Detalhes/components/TabDocumentos/hooks/useFiltroDocumentos";
import SeletctStatusFiltroDocumento from "./components/SeletctStatusFiltroDocumento/index";
import { checkPermission } from "core/infra/helpers/permission";
import { PermissaoEnum } from "core/resources/enums/permissao";

interface TabDocumentosProps {
  id: string;
  permissaoSetor?: boolean;
}

export default function TabDocumentos({
  id,
  permissaoSetor,
}: TabDocumentosProps) {
  const { filtro, onChangeFiltro, onResetFiltro } = useFiltroDocumentos();
  const { data, isLoading, refetch } = useListarDocumentos(id, filtro);
  const [isOpenModalFiltros, setIsOpenModalFiltros] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const numeroFiltroSelecionados =
    (filtro.descricao ? 1 : 0) + (filtro.dataInicio || filtro.dataFim ? 1 : 0);

  const createPermission = [PermissaoEnum.Fluir.Processo.Documento.Criar];

  const hasPermission = checkPermission(createPermission ?? []);

  return (
    <Box>
      <Box mb="24px">
        <SearchHeaderBar
          defaultValue={filtro.search || ""}
          onSearch={(v) => onChangeFiltro({ search: v })}
          maxWInputSearch={700}
          placeholder="Buscar"
          filtroButton
          filtroButtonOnClick={() => setIsOpenModalFiltros(true)}
          badgeValueExtraButton={numeroFiltroSelecionados}
          badge
          extraButton={
            <OutlineButton
              h={10}
              px={4}
              onClick={() => setIsOpenModal(true)}
              disabled={!hasPermission}
            >
              <BsPlusLg size={20} />
              Adicionar
            </OutlineButton>
          }
          filterStatus={
            <Flex w="150px">
              <SeletctStatusFiltroDocumento
                onChange={(assinado) => {
                  onChangeFiltro({ assinado: assinado });
                }}
                value={filtro.assinado}
              />
            </Flex>
          }
        />
      </Box>

      <DocumentosTable
        data={data}
        isLoading={isLoading}
        onChangeFiltro={onChangeFiltro}
        refetch={refetch}
        idProcesso={id}
        permissaoSetor={permissaoSetor}
      />

      <ModalFiltrosDocumentos
        filtro={filtro}
        visible={isOpenModalFiltros}
        onClose={() => setIsOpenModalFiltros(false)}
        onChangeFiltro={onChangeFiltro}
        onReset={onResetFiltro}
      />

      <ModalAdicionarDocumentos
        visible={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        id={id}
      />
    </Box>
  );
}
