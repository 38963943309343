import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";

function useTipoAcesso() {
  return useQuery(["/v1/shared/processo-documento/tipo-acesso"], async () => {
    const { data } = await http.get<{ id: string; nome: string }[]>(
      "/v1/shared/processo-documento/tipo-acesso"
    );

    return data;
  });
}

export default useTipoAcesso;
