import { Box, Flex, Text } from "@chakra-ui/react";
import MenuRoute from "ui/components/Menu/MenuRoute";
import {
  TabsAndamentoRoutes,
  tabsAndamento,
} from "ui/pages/Admin/Processos/Detalhes/components/TabsProcessos";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import TabFichaProcesso from "ui/pages/Admin/Processos/Detalhes/components/EmAndamento/FichaProcesso/components/TabFichaProcesso";
import DadosProcesso from "ui/pages/Admin/Processos/Detalhes/components/DadosProcesso";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import { MdEdit } from "react-icons/md";
import { useBreadcrumb } from "core/features/breadcrumbs";
import useProcesso from "core/features/processos/hooks/useProcesso";
import { useLoading } from "core/infra/helpers/alerta";
import ModalEncaminhar from "ui/pages/Admin/Processos/Detalhes/components/ModalEncaminhar";
import TabDocumentos from "ui/pages/Admin/Processos/Detalhes/components/TabDocumentos";
// import TabParticipantes from "ui/pages/Admin/Processos/Detalhes/components/TabParticipantes";
import HistoricoEventos from "ui/pages/Admin/Processos/Detalhes/components/HistoricoEventos";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { checkPermission } from "core/infra/helpers/permission";

export default function ProcessosDetalhes() {
  const { id } = useParams() as {
    id: string;
  };

  const [openModalEncaminhar, setOpenModalEncaminhar] = useState(false);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get("tab") as TabsAndamentoRoutes | null;
  const [selectedTab, setSelectedTab] = useState<TabsAndamentoRoutes>(
    tab || TabsAndamentoRoutes.FichaProcesso
  );

  function getTab(event: TabsAndamentoRoutes) {
    switch (event) {
      case TabsAndamentoRoutes.FichaProcesso:
        return "Ficha do Processo";
      case TabsAndamentoRoutes.Documentos:
        return "Documentos";
      case TabsAndamentoRoutes.HistoricoEvento:
        return "Histórico do Evento";
      // case TabsAndamentoRoutes.Participantes:
      //   return "Participantes";
      //   case TabsAndamentoRoutes.Alteracoes:
      //     return "Alterações";
      default:
        return "";
    }
  }

  useBreadcrumb([
    { name: "Processos", url: "/processos" },
    { name: "Vinculadas a mim" },
    { name: `${getTab(selectedTab)}` },
  ]);

  const { data, isLoading, refetch, isFetching } = useProcesso(id);

  // const autorizarPermission = PermissaoEnum.Fluir.Processo.Processo.Autorizar;

  const encaminharPermission = PermissaoEnum.Fluir.Processo.Processo.Encaminhar;

  // const assinarPermission = [PermissaoEnum.Fluir.Processo.Documento.Visualizar];

  // const hasAutorizarPermission = checkPermission(autorizarPermission ?? []);

  const hasEncaminharPermission = checkPermission(encaminharPermission ?? []);

  // const hasAssinarPermission = checkPermission(assinarPermission ?? []);

  useLoading(isLoading || isFetching);

  return (
    <Box>
      <Flex justify="space-between">
        <Text fontSize="24px" fontWeight="500" mb={4}>
          {`Processo N. ${data?.numeroProcesso}`}
        </Text>
        <Flex gap={2}>
          {hasEncaminharPermission ? (
            <OutlineButton onClick={() => setOpenModalEncaminhar(true)}>
              <MdEdit size={20} />
              Encaminhar para
            </OutlineButton>
          ) : null}
          {/* {hasAutorizarPermission ? (
            <PrimaryButton>
              <MdOutlineTaskAlt size={20} />
              Autorizar
            </PrimaryButton>
          ) : null} */}
        </Flex>
      </Flex>
      <DadosProcesso data={data} />

      <MenuRoute
        options={tabsAndamento}
        selectedOption={selectedTab}
        onChangeOption={(tab) => {
          setSelectedTab(tab);
        }}
      />

      <Box>
        {selectedTab === TabsAndamentoRoutes.FichaProcesso ? (
          <TabFichaProcesso id={id} />
        ) : null}
        {selectedTab === TabsAndamentoRoutes.Documentos ? (
          <TabDocumentos id={id} permissaoSetor={data?.permissaoSetor} />
        ) : null}
        {/* {selectedTab === TabsAndamentoRoutes.Participantes ? (
          <TabParticipantes id={id} />
        ) : null} */}
        {selectedTab === TabsAndamentoRoutes.HistoricoEvento ? (
          <HistoricoEventos id={id} />
        ) : null}
        {/* {selectedTab === TabsAndamentoRoutes.Alteracoes ? <></> : null} */}
      </Box>

      <ModalEncaminhar
        isOpen={openModalEncaminhar}
        onClose={() => setOpenModalEncaminhar(false)}
        id={id}
        refetch={refetch}
      />
    </Box>
  );
}
