import colors from "core/resources/theme/colors";
import styled, { createGlobalStyle } from "styled-components";

export const Container = styled.div<any>`
  width: 100%;
  min-height: 40px;

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector,
  .ant-select-selector:hover {
    box-shadow: 0 0 0 1px ${colors.gray300} !important;
    outline-color: ${colors.gray300} !important;
    border: 1px solid ${colors.gray300} !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${colors.labelBg} !important;
  }

  .ant-select-selector {
    border: 1px solid
      ${(props) => (props.isInvalid ? colors.red500 : colors.labelBg)} !important;
    border-radius: 6px !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${colors.labelBg} !important;
  }

  .ant-select-multiple {
    .ant-select-selection-item {
      border: 1px solid ${colors.gray400} !important;
      border-radius: 10px !important;
      font-family: Poppins !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      height: 30px !important;
      line-height: 30px !important;
    }

    .ant-select-selection-item-remove {
      margin-top: -2px;
      height: 5px;
      padding: 0 0 28px 0;
    }
  }

  .ant-select-multiple .ant-select-selection-item {
    background: white !important;
    height: 24px !important;
    color: ${colors.gray600}!important;
    font-family: Poppins !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    margin: 2px 4px;
    border-radius: 8px !important;
    padding: 0, 8px, 0, 8px !important;

    .css-1k9efnl {
      padding: 0 5px;
      height: 14px !important;
      border-right: 1px solid;
      border-color: ${colors.gray400};
    }
    .css-k008qs {
      display: none;
    }
  }

  .ant-select-selection-item.ant-select-selection-item-disabled {
    background: none !important;
    border: none !important;
    .css-1k9efnl {
      border-right: none !important;
    }
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    align-items: center;
    font-size: 14px;
    font-family: Poppins !important;
  }
`;

export const Global = createGlobalStyle<any>`

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${colors.gray100} !important;
      color: ${colors.gray600} !important;
      font-size: 14px !important;
      font-weight: 400  !important;
    }
  
`;
