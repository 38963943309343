import { Flex, Tag, Text } from "@chakra-ui/react";
import useSetores from "core/features/shared/hooks/useSetores";
import { AndamentosResponse } from "core/features/shared/typings";
import { CriterioEnum } from "core/features/tipoprocesso/typings";
import { FaRegCalendar } from "react-icons/fa";

export default function Criterios({ item }: { item: AndamentosResponse }) {
  const { data } = useSetores();

  const setor = data?.filter((v: { id: string }) => v.id === item.setorId);

  const andamentosOrdenados = item.andamentoCriterios?.sort(
    (a, b) => (a.ordemCriterio || 0) - (b.ordemCriterio || 0)
  );
  return (
    <Flex w="100%">
      <Flex
        bg="#F7FAFC"
        w="100%"
        h="500px"
        mt={4}
        ml="120px"
        p={4}
        rounded="16px"
        flexDir="column"
      >
        <Flex w="100%" justify="space-between">
          <Text fontSize="16px" fontWeight="500">
            {setor?.[0].nome}
          </Text>

          <Tag h="18px" colorScheme="linkedin" gap={2} px={4}>
            <FaRegCalendar />
            Prazo de {item.prazo} {item.prazo > 1 ? " dias" : " dia"}
          </Tag>
        </Flex>

        <Flex flexDir="column" ml={2} position="relative" py={2} mt={4}>
          {andamentosOrdenados?.map((criterio) => (
            <Flex align="center" position="relative">
              <Flex
                bg={"#A0AEC0"}
                h="25px"
                w="1px"
                bottom="12px"
                position="absolute"
              />
              <Flex bg={"#A0AEC0"} h="1px" w="20px" mr={2} />
              {renderizarConteudoCriterio(criterio.criterioId)}
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}

function renderizarConteudoCriterio(criterioId: CriterioEnum | undefined) {
  switch (criterioId) {
    case CriterioEnum.Assinatura:
      return "Assinatura";
    case CriterioEnum.AnexoDocumento:
      return "Anexo de documento";
    default:
      return "Visualização do departamento administrativo";
  }
}
