import { useState } from "react";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";
import { useDebounce } from "core/features/shared/hooks/useDebouce";
import CardPerson from "ui/components/Cards/CardPerson";
import { Box, Flex, Text } from "@chakra-ui/react";
import useTenants from "core/features/shared/hooks/useTenants";

export interface SelectTenantsProps<T = any> extends SelectBaseAntdProps<T> {
  form?: string[];
  allowClear?: boolean;
}

function SelectTenants({
  value,
  onChange,
  isInvalid,
  allowClear = false,
  form,
  ...rest
}: SelectTenantsProps) {
  const [search, setSearch] = useState<string>();
  const debouncedSearchTerm = useDebounce(search, 500);

  const { data, isFetching, isLoading } = useTenants({
    page: 1,
    limit: 1000,
    search: debouncedSearchTerm,
    pessoaId: debouncedSearchTerm ? undefined : value,
  });

  const _isLoading = isLoading || isFetching;

  return (
    <>
      <SelectBaseAntd
        loading={_isLoading}
        isInvalid={isInvalid}
        size="large"
        showSearch
        placeholder="Selecione..."
        searchValue={search}
        onSearch={setSearch}
        onChange={onChange}
        value={value}
        filterOption={() => true}
        optionFilterProp="label"
        allowClear={allowClear}
        options={data?.items.map((item) => ({
          value: item.id,
          label: (
            <Flex align="center" gap={2}>
              <CardPerson avatarProps={{ w: "20px", h: "20px" }} />
              {item.nome}
            </Flex>
          ),
          disabled: form?.includes(item.id),
        }))}
        {...rest}
        notFoundContent={
          !isLoading && !isFetching ? (
            <Flex px={2} gap={2} align="center">
              <Text>Nenhuma registro encontrado</Text>
            </Flex>
          ) : (
            <Box>Carregando...</Box>
          )
        }
      ></SelectBaseAntd>
    </>
  );
}

export default SelectTenants;
