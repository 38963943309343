import { Flex, Text } from "@chakra-ui/react";
import TagStatus from "ui/components/TagStatus/index";
import CardPerson2 from "ui/components/Cards/CardPerson2/index";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu/index";
import { useNavigate } from "react-router-dom";
import { OrganizacaoPaginateResponse } from "core/features/organizacao/typings/index";
import { formatCNPJ } from "@brazilian-utils/brazilian-utils";
import { MdEdit } from "react-icons/md";

interface TableItemRowProps {
  item: OrganizacaoPaginateResponse;
  indexItem: number;
}

export default function TableItemRow({ item, indexItem }: TableItemRowProps) {
  const navigate = useNavigate();
  return (
    <Flex
      rounded={8}
      px={5}
      py={2}
      mb={2}
      bg="#FFFFFF"
      boxShadow="base"
      align="center"
      onClick={() => navigate(`/organizacao/${item.id}`)}
      cursor="pointer"
    >
      <Flex flex={1} align="center" gap={2}>
        <CardPerson2 avatarUrl={item.imagemUrl} />

        <Text fontSize="16px">{item.nome}</Text>
      </Flex>
      <Flex fontSize="16px" flex={1}>
        {formatCNPJ(item.documento)}
      </Flex>
      <Flex fontSize="16px" flex={1}>
        {item.localidade}
      </Flex>
      <Flex flex={0.2}>
        {item.ativo ? (
          <TagStatus title="Ativo" colorScheme="whatsapp" />
        ) : (
          <TagStatus title="Inativo" colorScheme="gray" />
        )}
      </Flex>
      <Flex h="fit-content" py={4}>
        <ActionsKebabMenu
          indexItem={indexItem}
          item={item}
          actions={[
            {
              title: "Editar",
              icon: () => <MdEdit />,
              onClick: (v) => navigate(`/organizacao/${v.id}`),
            },
          ]}
        />
      </Flex>
    </Flex>
  );
}
