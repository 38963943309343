import { Box } from "@chakra-ui/react";
import { Modal } from "antd";
import Toolbar from "ui/components/Toolbar";
import Field from "../../Field";
import { useForm } from "react-hook-form";
import FooterModalDefault from "ui/components/FooterModal";
import { showErrorAxios, showSuccess } from "core/infra/helpers/alerta";
import useSaveAssunto from "core/features/processos/hooks/useSaveAssunto";

interface ModalAdicionarAssuntoProps {
  onClose: () => void;
  visible: boolean;
  refetch: any;
}

export default function ModalAdicionarAssunto({
  onClose,
  visible,
  refetch,
}: ModalAdicionarAssuntoProps) {
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveAssunto();
  const { control, handleSubmit } = useForm<{ assunto: string }>({});

  const onSubmit = (data: { assunto: string }) => {
    handleSave(data, {
      onSuccess: () => {
        showSuccess().then(() => {
          refetch();
          onClose();
        });
      },
      onError: (err) => {
        showErrorAxios(err);
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
      open={visible}
      className="antd-custom-header"
      title={<Toolbar title="Assunto" onClose={onClose} />}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Adicionar"
          onclickPrimaryButton={() => handleSubmit(onSubmit)()}
          saveLoading={isLoadingSave}
        />
      }
    >
      <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
        <Box>
          <Field
            name="assunto"
            label="Assunto"
            control={control}
            rules={{ required: true }}
          />
        </Box>
      </form>
    </Modal>
  );
}
