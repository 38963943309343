import { useState } from "react";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";
import { useDebounce } from "core/features/shared/hooks/useDebouce";
import { Box, Flex, Button, Text } from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";
import useFluxosProcessos from "core/features/shared/hooks/useFluxosProcessos";
import { Link } from "react-router-dom";

export interface SelectFluxoProcessoProps<T = any> extends SelectBaseAntdProps<T> {
    tenantId?: string;
    isAdicionar?: boolean
}

function SelectFluxoProcesso({
    value,
    onChange,
    isInvalid,
    tenantId,
    isAdicionar,
    ...rest
}: SelectFluxoProcessoProps) {
    const [search, setSearch] = useState<string>();
    const debouncedSearchTerm = useDebounce(search, 500);


    const { data, isFetching, refetch, isLoading } = useFluxosProcessos({ search: debouncedSearchTerm });

    const _isLoading = isLoading || isFetching;

    return (
        <SelectBaseAntd
            loading={_isLoading}
            isInvalid={isInvalid}
            size="large"
            showSearch
            placeholder="Selecione..."
            searchValue={search}
            onSearch={setSearch}
            onChange={onChange}
            value={value}
            filterOption={() => true}
            optionFilterProp="label"
            allowClear={true}
            options={data?.items.map((item) => ({
                value: item.id,
                label: item.nome
            }))}
            {...rest}
            notFoundContent={
                !isLoading && !isFetching ? (
                    <Flex px={2} gap={2} align="center">
                        <Text>Nenhuma registro encontrado</Text>

                        {isAdicionar ?
                            <Button
                                isLoading={false}
                                leftIcon={<BsPlusLg size={20} />}
                                size="sm"
                                fontWeight={400}
                                color='black'
                                colorScheme="gray"
                                textDecoration='none'
                                as={Link}
                                to={'/fluxo-processo/novo'}
                                target="_blank"
                            >
                                Adicionar
                            </Button> : null}

                    </Flex>
                ) : (
                    <Box>Carregando...</Box>
                )
            }
        >

        </SelectBaseAntd>

    );
}

export default SelectFluxoProcesso;
