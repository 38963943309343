import { Box, Tag, Text } from "@chakra-ui/react";
import { Row, Col } from "antd";
import { ProcessoResponse } from "core/features/processos/typings";
import { Control, FieldErrors, UseFormSetValue } from "react-hook-form";
import { MdInfo } from "react-icons/md";
import Field from "ui/components/Input/Field";
import SelectTipoResponsavel from "ui/components/Input/SelectTipoResponsavel";
import SelectUsuarios from "ui/components/Input/SelectUsuarios";
import SelectSetorUsuario from "ui/components/Input/SelectSetorUsuario/index";

export default function FormResponsavel({
  control,
  form,
  setValue,
  errors,
}: {
  control: Control<ProcessoResponse, any>;
  form: ProcessoResponse;
  setValue: UseFormSetValue<ProcessoResponse>;
  errors: FieldErrors<ProcessoResponse>;
}) {
  const handleTipoFluxoChange = (tipoResponsavel: string) => {
    const campoNaoSelecionado =
      tipoResponsavel === "setor"
        ? "pessoaResponsavelId"
        : "setorResponsavelId";
    setValue(campoNaoSelecionado, null);
  };

  return (
    <Box>
      <Tag
        w="100%"
        rounded={8}
        px={3}
        py={2}
        colorScheme="linkedin"
        gap={2}
        mb={4}
      >
        <MdInfo size={20} />
        <Text fontSize="14px" fontWeight="400">
          Neste passo irá definir o responsável pela abertura do processo.
        </Text>
      </Tag>
      <Box as={Row} gutter={32} mb={4}>
        <Box as={Col} md={6}>
          <Field
            name="tipoResponsavel"
            label="Responsável"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <SelectTipoResponsavel
                {...field}
                isInvalid={!!errors.tipoResponsavel}
                onChange={(value) => {
                  field.onChange(value);
                  handleTipoFluxoChange(value);
                }}
              />
            )}
          />
        </Box>

        <Box as={Col} md={18}>
          {form.tipoResponsavel === "setor" ? (
            <Field
              name="setorResponsavelId"
              label="Selecione o setor"
              control={control}
              key={"setorResponsavelId"}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectSetorUsuario
                  isInvalid={!!errors.setorResponsavelId}
                  {...field}
                />
              )}
            />
          ) : (
            <Field
              name="pessoaResponsavelId"
              label="Selecione o usuário"
              control={control}
              key={"pessoaResponsavelId"}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectUsuarios
                  isInvalid={!!errors.pessoaResponsavelId}
                  {...field}
                />
              )}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
