import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Flex, Portal } from "@chakra-ui/react";
import { IoEllipsisVertical } from "react-icons/io5";
import { checkPermission } from "core/infra/helpers/permission";

interface ActionsKebabMenuProps<T> {
  actions?: {
    invisible?: boolean;
    title: string | ((_v: T) => any);
    icon?: (_v: T) => any;
    onClick: (_v: T, index?: number) => void;
    textColor?: string;
    hidden?: any;
    permission?: string;
  }[];
  actionsContainerRef?: React.RefObject<HTMLElement | null> | undefined;
  item: T;
  indexItem?: number | undefined;
}

export default function ActionsKebabMenu<T>({
  item,
  actions,
  actionsContainerRef,
  indexItem,
}: ActionsKebabMenuProps<T>) {
  actions =
    actions?.filter((item) => {
      if (item.permission) {
        const hasPermission = checkPermission(item.permission);
        if (!hasPermission) {
          return (item.hidden = true);
        }
        return hasPermission;
      }
      return true;
    }) ?? [];
  return (
    <Menu>
      <MenuButton
        onClick={(ev) => {
          ev.stopPropagation();
        }}
        type="button"
      >
        <Flex w="20px" h="20px" rounded="full" align="center">
          <IoEllipsisVertical />
        </Flex>
      </MenuButton>
      <Portal containerRef={actionsContainerRef}>
        <MenuList>
          {actions?.map((action, index) =>
            !action.hidden ? (
              <MenuItem
                key={index}
                fontSize="14px"
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  action.onClick(item, indexItem);
                }}
                type="button"
              >
                <Flex
                  color={action.textColor}
                  alignItems="center"
                  gridGap="8px"
                  flex={1}
                >
                  {typeof action.icon === "function" ? action.icon(item) : null}
                  {typeof action.title === "function"
                    ? action.title(item)
                    : action.title}
                </Flex>
              </MenuItem>
            ) : null
          )}
        </MenuList>
      </Portal>
    </Menu>
  );
}
