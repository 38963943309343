import { useQuery } from "react-query";
import { PaginateResult } from "core/resources/types/shared";
import http from "core/features/auth/hooks/useApi";
import { TipoDocumentoPaginateResponse } from "../types";
import { PaginateQuery } from "core/resources/types/shared";

function useListarTipoDocumento(query: PaginateQuery) {
  return useQuery([`/v1/tipo-documento`, query], async () => {
    const { data } = await http.get<
      PaginateResult<TipoDocumentoPaginateResponse>
    >(`/v1/tipo-documento`, {
      params: query,
    });

    return data;
  });
}

export default useListarTipoDocumento;
