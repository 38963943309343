import { Drawer, Menu as MenuAntd } from "antd";
import { useState } from "react";
import { Button } from "@chakra-ui/react";
import { useWindowSize } from "core/infra/helpers/window";
import { IoMenuOutline } from "react-icons/io5";
import { Menu } from "core/features/menu/typings/index";
import { MenuMobileWrapper } from "./styles";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "core/features/auth/auth.store";

const { SubMenu } = MenuAntd;

function ResponsiveMenu({ items }: { items: Menu[] }) {
  const [open, setOpen] = useState(false);

  const windowSize = useWindowSize();

  const navigate = useNavigate();

  const auth = useAuthStore();

  const handleClick = (item: Menu) => {
    if (item.href === "sair") {
      auth.signout();
    } else {
      navigate(`${item.href}`);
    }

    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        mt="4px"
      >
        <IoMenuOutline />
      </Button>
      <Drawer
        title="Menu"
        width={windowSize.width * 0.8}
        onClose={() => setOpen(false)}
        open={open}
        bodyStyle={{ padding: 0 }}
      >
        <MenuMobileWrapper>
          <MenuAntd mode="inline">
            {items.map((item) =>
              item.visible ? (
                <MenuLevel0
                  key={item.title}
                  handleClick={handleClick}
                  item={item}
                />
              ) : null
            )}
          </MenuAntd>
        </MenuMobileWrapper>
      </Drawer>
    </>
  );
}

function MenuLevel0({
  item,
  handleClick,
}: {
  item: Menu;
  handleClick: (v: Menu) => void;
}) {
  if (!item.children.length) {
    return (
      <MenuAntd.Item
        onClick={() => handleClick(item)}
        icon={item.icon}
        key={item.title}
        style={{
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        {item.title}
      </MenuAntd.Item>
    );
  }

  return (
    <SubMenu
      style={{
        paddingLeft: 8,
        paddingRight: 8,
      }}
      key={item.title}
      icon={item.icon}
      title={item.title}
    >
      {item.children.map((child) => (
        <MenuLevel1 item={child} handleClick={handleClick} key={child.title} />
      ))}
    </SubMenu>
  );
}

function MenuLevel1({
  item,
  handleClick,
}: {
  item: Menu;
  handleClick: (v: Menu) => void;
}) {
  if (!item.children.length) {
    return (
      <>
        {item.visible ? (
          <MenuAntd.Item
            onClick={() => handleClick(item)}
            icon={item.icon}
            key={item.title}
          >
            {item.title}
          </MenuAntd.Item>
        ) : null}
      </>
    );
  }

  return (
    <MenuAntd.ItemGroup key={item.title} title={item.title}>
      {item.children.map((child, i) =>
        child.visible ? (
          <MenuAntd.Item
            icon={child.icon}
            onClick={() => handleClick(child)}
            key={child.title}
          >
            {child.title}
          </MenuAntd.Item>
        ) : null
      )}
    </MenuAntd.ItemGroup>
  );
}

export default ResponsiveMenu;
