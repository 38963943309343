import { Flex, Text } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { DocumentoAssinanteResponse } from "core/features/processos-documento/typings";
import useUsuarios from "core/features/shared/hooks/useUsuarios";
import colors from "core/resources/theme/colors";
import { FaEquals } from "react-icons/fa";
import { IoMdTrash } from "react-icons/io";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu";
import CardPerson3 from "ui/components/Cards/CardPerson3";

interface CardAssinanteProps {
  index: number;
  tenantId: string;
  item: DocumentoAssinanteResponse;
  onRemoveItem: (id: string) => void;
}

export default function CardAssinante({
  item,
  index,
  onRemoveItem,
}: CardAssinanteProps) {
  const { data } = useUsuarios({
    page: 1,
    limit: 1000,
  });

  const assinante = data?.items?.find(
    (usuario) => usuario.id === item.assinanteId
  );

  return (
    <Flex
      mb={2}
      rounded="8px"
      align="center"
      border={`solid 1px ${colors.labelBg}`}
      bg="white"
    >
      <Flex
        borderLeftRadius="8px"
        align="center"
        justify="center"
        w="25px"
        bg={colors.labelBg}
        mr={4}
        h="72px"
      >
        <FaEquals />
      </Flex>
      <Flex as={Row} gutter={16} w="100%">
        <Flex as={Col} md={11} align="center" gap={4}>
          <CardPerson3
            nome={assinante?.nome}
            avatarUrl={assinante?.imagemUrl}
            avatarProps={{ width: "40px", height: "40px" }}
            border={`solid 1px ${colors.gray50}`}
          />
          <Text fontSize="14px">{assinante?.nome}</Text>
        </Flex>
        <Flex as={Col} md={12} align="center">
          <Text fontSize="14px"> {assinante?.setor}</Text>
        </Flex>
        <Flex as={Col} md={1} align="center">
          <ActionsKebabMenu
            indexItem={index}
            item={item}
            actions={[
              {
                title: "Excluir",
                textColor: `${colors.red500}`,
                onClick: (v) => onRemoveItem(v.assinanteId!),
                icon: () => <IoMdTrash />,
              },
            ]}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
