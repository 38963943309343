import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";
import { useCallback } from "react";
import { showError, showErrorAxios } from "core/infra/helpers/alerta";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: "always",
      onError(err: any) {
        if (err.response) {
          showErrorAxios(err);
        } else {
          showError();
        }
      },
    },
    mutations: {
      onError(err: any) {
        showErrorAxios(err);
      },
    },
  },
});

export const ReactQueryProvider: any = ({ children }: any) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  const doInvalidateQueryWithStringKey = useCallback(
    (key: string) => {
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          return typeof queryKey === "string"
            ? queryKey.startsWith(key)
            : false;
        },
      });
    },
    [queryClient]
  );

  return {
    doInvalidateQueryWithStringKey,
  };
};
