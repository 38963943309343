import Pagination from "ui/components/Pagination";
import { PaginateResult } from "core/resources/types/shared";
import { Box, Flex } from "@chakra-ui/react";
import SkeletonDefault from "ui/components/SkeletonDefault";
import HeaderTableRow from "../HeaderTableRow";
import TableItemRow from "../TableItemRow";
import {
  FiltrosProcessos,
  ProcessoPaginateResponse,
} from "core/features/processos/typings";
import StatusBar from "../StatusBar";
import CardDefaultTable from "ui/components/Cards/CardDefaultTable";
import HeaderDefaultRowTable from "ui/components/HeaderDefaultRowTable";
import { useNavigate } from "react-router-dom";
import EmptyDefault from "ui/components/Empty";
import { useWindowSize } from "core/infra/helpers/window";

interface ProcessosTableProps {
  data?: PaginateResult<ProcessoPaginateResponse>;
  isLoading: boolean;
  onChangeFiltro: (_filtro: Partial<FiltrosProcessos>) => void;
}

export default function ProcessosTable({
  data,
  isLoading,
  onChangeFiltro,
}: ProcessosTableProps) {
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 992;
  return (
    <>
      <CardDefaultTable>
        <HeaderDefaultRowTable
          children={<HeaderTableRow isMobile={isMobile} />}
        />

        {isLoading ? (
          <SkeletonDefault h="100px" rounded="8px" mb={2} amount={7} />
        ) : (
          <>
            {data?.items?.map((item, index) => (
              <Flex
                key={index}
                rounded={8}
                bg="#FFFFFF"
                boxShadow="base"
                flexDir="column"
                mb={2}
                w="100%"
                cursor="pointer"
                onClick={() => navigate(`/processos/${item.id}`)}
              >
                <TableItemRow item={item} isMobile={isMobile} />

                {item.prioridade.nome ? <StatusBar item={item} /> : null}
              </Flex>
            ))}
          </>
        )}

        {!data?.items.length && !isLoading ? <EmptyDefault /> : null}
        {data?.items.length ? (
          <Box m={4}>
            <Pagination
              style={{ marginLeft: "auto", marginBottom: 16 }}
              current={data?.meta?.currentPage ?? 1}
              total={data?.meta?.totalItems ?? 0}
              pageSize={data?.meta?.itemsPerPage ?? 0}
              onChange={(page, limit) => {
                onChangeFiltro({ page, limit });
              }}
            />
          </Box>
        ) : null}
      </CardDefaultTable>
    </>
  );
}
