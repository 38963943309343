import { useRef, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";
import { AiOutlineFile } from "react-icons/ai";
import { showError } from "core/infra/helpers/alerta";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import { Button } from "@chakra-ui/react";
import { MdAttachFile } from "react-icons/md";

export interface ButtonAnexarArquivosProps {
  onAnexar: (files: File[]) => any;
  variant?: "ghost" | "outline";
  multiple?: boolean;
  accept?: string;
  fileName?: string;
  maxFileSizeMb?: number;
  maxFiles?: number;
  currentFilesLength?: number;
  buttonIcon?: React.ReactNode;
  buttonText?: string;
  horizontalAlignment?: boolean;
  disabled?: boolean;
  anexoType?: "pdf" | "pfx";
}

const ContentButton = ({
  buttonIcon = <MdAttachFile color={colors.gray900} fontSize={20} />,
  buttonText = "Anexar Arquivos",
}: Pick<ButtonAnexarArquivosProps, "buttonIcon" | "buttonText">) => (
  <Flex flexDir="row" gridGap={4} alignItems="center" color="inherit">
    {buttonIcon}
    {buttonText}
  </Flex>
);

function ButtonAnexarArquivos({
  onAnexar,
  variant = "outline",
  multiple = true,
  accept = "*",
  fileName,
  maxFileSizeMb = 200,
  maxFiles,
  currentFilesLength = 0,
  buttonIcon,
  buttonText,
  horizontalAlignment,
  disabled,
  anexoType = "pdf",
}: ButtonAnexarArquivosProps) {
  const [key, setKey] = useState(new Date().getTime());
  const inputRef = useRef<HTMLInputElement | null>(null);

  const checkMaxFileSize = (files: File[]) => {
    let hasFileAboveSizeLimit = false;
    if (maxFileSizeMb && maxFileSizeMb > 0) {
      hasFileAboveSizeLimit = files.some(
        (file) => file.size > maxFileSizeMb * 10 ** 6
      );
    }

    if (hasFileAboveSizeLimit) {
      showError(`O tamanho máximo para cada arquivo é de ${maxFileSizeMb} Mb`);
    }

    return hasFileAboveSizeLimit;
  };

  const checkMaxFiles = (files: File[]) => {
    let isAboveLimit = false;
    if (maxFiles) {
      isAboveLimit = files.length + currentFilesLength > maxFiles;
    }

    if (isAboveLimit) {
      showError(
        `Você pode selecionar no máximo ${maxFiles} arquivo${
          maxFiles === 1 ? "s" : ""
        }`
      );
    }

    return isAboveLimit;
  };

  const isAtFileLimit = maxFiles ? currentFilesLength >= maxFiles : false;

  return (
    <Box>
      {" "}
      <Flex
        flexDir={horizontalAlignment ? "row" : "column"}
        alignItems={horizontalAlignment ? "center" : undefined}
        gridGap={horizontalAlignment ? 4 : undefined}
      >
        <Box as="span" display="none" maxW="100%">
          <input
            multiple={multiple}
            key={key}
            ref={inputRef}
            type="file"
            accept={accept}
            onChange={(ev) => {
              const files = ev.target.files;

              if (files) {
                let _files: File[] = [];

                if (anexoType === "pdf") {
                  for (let i = 0; i < files?.length; i++) {
                    if (files[i].type === "application/pdf") {
                      _files.push(files[i]);
                    } else {
                      showError("Por favor, selecione apenas arquivos PDF.");
                    }
                  }
                } else {
                  for (let i = 0; i < files?.length; i++) {
                    if (files[i].type === "application/x-pkcs12") {
                      _files.push(files[i]);
                    } else {
                      showError("Por favor, selecione apenas arquivos PFX.");
                    }
                  }
                }

                const hasFileAboveSizeLimit = checkMaxFileSize(_files);

                const isAboveFileLimit = checkMaxFiles(_files);

                if (!hasFileAboveSizeLimit && !isAboveFileLimit) {
                  onAnexar(_files);
                }

                setKey(new Date().getTime());
              }
            }}
          />
        </Box>

        {variant === "outline" ? (
          <OutlineButton
            onClick={() => inputRef?.current?.click()}
            disabled={isAtFileLimit || disabled}
          >
            {isAtFileLimit ? (
              "Número máximo de arquivos anexados"
            ) : (
              <ContentButton buttonIcon={buttonIcon} buttonText={buttonText} />
            )}
          </OutlineButton>
        ) : (
          <Button
            border="none"
            bg={colors.gray200}
            rounded="8px"
            padding="8px 16px"
            color={colors.gray900}
            fontSize="14px"
            fontWeight="400"
            onClick={() => inputRef?.current?.click()}
            disabled={isAtFileLimit || disabled}
          >
            {isAtFileLimit ? (
              "Número máximo de arquivos anexados"
            ) : (
              <ContentButton buttonIcon={buttonIcon} buttonText={buttonText} />
            )}
          </Button>
        )}

        {fileName ? (
          <Flex
            mt={horizontalAlignment ? 0 : 2}
            alignItems="center"
            gridGap={1}
          >
            <AiOutlineFile color={colors.primary} size={20} />
            <Text color={colors.primary} fontSize={16}>
              {fileName}
            </Text>
          </Flex>
        ) : null}
      </Flex>
      {maxFileSizeMb && anexoType ? (
        <Text fontSize="12px">{`* O anexo deve ser do tipo ${anexoType} e ter no máximo ${maxFileSizeMb}Mb`}</Text>
      ) : null}
    </Box>
  );
}

export default ButtonAnexarArquivos;
