import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";
import { useState } from "react";
import useGetCidades from "core/features/hooks/useGetCidades";
export interface SelectCidadeProps<T = any> extends SelectBaseAntdProps<T> {
  value: any;
  onChange: (v: any) => void;
  uf: string;
}

function SelectCidade({ value, onChange, uf, ...rest }: SelectCidadeProps) {
  const [search, setSearch] = useState<string>();
  const { data, isLoading } = useGetCidades(uf);
  return (
    <SelectBaseAntd
      loading={isLoading}
      size="large"
      placeholder="Estado"
      value={value}
      allowClear={false}
      showSearch
      searchValue={search}
      onSearch={setSearch}
      onChange={onChange}
      optionFilterProp="label"
      options={data?.map((item: { nome: string; id: string }) => ({
        value: item.id,
        label: item.nome,

      }))}
      {...rest}
    />
  );
}

export default SelectCidade;
