import { Flex, Text } from "@chakra-ui/react";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu/index";
import { useNavigate } from "react-router-dom";
import { SetorPaginateResponse } from "core/features/setor/typings/index";
import TagStatus from "ui/components/TagStatus";
import { MdEdit } from "react-icons/md";

interface TableItemRowProps {
  item: SetorPaginateResponse;
  indexItem: number;
}

export default function TableItemRow({ item, indexItem }: TableItemRowProps) {
  const navigate = useNavigate();
  return (
    <Flex
      rounded={8}
      px={5}
      py={2}
      mb={2}
      bg="#FFFFFF"
      boxShadow="base"
      align="center"
      onClick={() => navigate(`/setor/${item.id}`)}
      cursor="pointer"
    >
      <Flex flex={1.98}>
        <Text fontSize="16px">{item.nome}</Text>
      </Flex>
      <Flex fontSize="16px" flex={1}>
        {item.pessoasVinculadas}
      </Flex>
      <Flex flex={0.33}>
        {item.ativo ? (
          <TagStatus title="Ativo" colorScheme="whatsapp" />
        ) : (
          <TagStatus title="Inativo" colorScheme="gray" />
        )}
      </Flex>

      <Flex h="fit-content" py={4}>
        <ActionsKebabMenu
          indexItem={indexItem}
          item={item}
          actions={[
            {
              title: "Editar",
              icon: () => <MdEdit />,
              onClick: (v) => navigate(`/setor/${v.id}`),
            },
          ]}
        />
      </Flex>
    </Flex>
  );
}
