import { useQuery } from "react-query";
import { PaginateResult } from "core/resources/types/shared";
import http from "core/features/auth/hooks/useApi";
import { UsuariosPaginatedResponse } from "../typings";

function useUsuarios(
  params?: any,
  onSuccess?: (data: PaginateResult<UsuariosPaginatedResponse>) => any
) {
  return useQuery(
    [`/v1/shared/usuario`, params],
    async () => {
      const { data } = await http.get<
        PaginateResult<UsuariosPaginatedResponse>
      >(`/v1/shared/usuario`, {
        params: {
          ...(params ?? {}),
        },
      });

      return data;
    },
    {
      onSuccess,
    }
  );
}

export default useUsuarios;
