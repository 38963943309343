import { Flex } from "@chakra-ui/react";

export default function HeaderTableRow() {
  return (
    <>
      <Flex flex={1.01}>Nome fantasia</Flex>
      <Flex flex={1}>CNPJ</Flex>
      <Flex flex={1}>Localidade</Flex>
      <Flex flex={0.3}>Status</Flex>
    </>
  );
}
