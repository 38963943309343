import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";


function useCargos() {
  return useQuery(["/v1/cargo/cargos"], async () => {
    const { data } = await http.get<{ id: string; nome: string }[]>(
      "/v1/cargo/cargos"
    );

    return data;
  });
}

export default useCargos;
