import { Flex } from "@chakra-ui/react";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu/index";
import { useNavigate } from "react-router-dom";
import { FluxoProcessoPaginateResponse } from "core/features/tipoprocesso/typings";
import { MdModeEdit } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import colors from "core/resources/theme/colors";
import {
  showConfirm3,
  showErrorAxios,
  showSuccess,
} from "core/infra/helpers/alerta";
import { UseMutateFunction } from "react-query";
import { AxiosResponse } from "axios";
import { PermissaoEnum } from "core/resources/enums/permissao";

interface TableItemRowProps {
  item: FluxoProcessoPaginateResponse;
  indexItem: number;
  refetch: any;
  handleDelete: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    string,
    unknown
  >;
}

export default function TableItemRow({
  item,
  indexItem,
  refetch,
  handleDelete,
}: TableItemRowProps) {
  const navigate = useNavigate();

  return (
    <Flex
      rounded={8}
      px={5}
      py={2}
      mb={2}
      bg="#FFFFFF"
      boxShadow="base"
      align="center"
      cursor="pointer"
      onClick={() => navigate(`/fluxo-processo/${item.id}`)}
    >
      <Flex fontSize="16px" flex={1.5}>
        {item.nome}
      </Flex>
      <Flex fontSize="16px" flex={1}>
        {item.naturezaProcesso}
      </Flex>
      <Flex fontSize="16px" flex={0.7}>
        {item.andamentos}
      </Flex>
      <Flex h="fit-content" py={4}>
        <ActionsKebabMenu
          indexItem={indexItem}
          item={item}
          actions={[
            {
              title: "Editar",
              onClick: () => navigate(`/fluxo-processo/${item.id}`),
              icon: () => <MdModeEdit />,
            },
            {
              title: "Excluir",
              textColor: `${colors.red500}`,
              permission: PermissaoEnum.Fluir.Processo.FluxoProcesso.Excluir,
              onClick: async (v) => {
                const { isConfirmed } = await showConfirm3({});

                if (isConfirmed) {
                  handleDelete(v.id, {
                    onSuccess: () =>
                      showSuccess("Fluxo excluido com sucesso").then(() =>
                        refetch()
                      ),
                    onError: (err) => {
                      showErrorAxios(err);
                    },
                  });
                }
              },
              icon: () => <IoMdTrash />,
            },
          ]}
        />
      </Flex>
    </Flex>
  );
}
