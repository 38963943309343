import { useQuery } from "react-query";
import { PaginateResult } from "core/resources/types/shared";
import http from "core/features/auth/hooks/useApi";
import { TenantsPaginatedResponse } from "../typings";

function useTenants(
  params?: any,
  onSuccess?: (data: PaginateResult<TenantsPaginatedResponse>) => any
) {
  return useQuery(
    [`/v1/shared/tenant`, params],
    async () => {
      const { data } = await http.get<PaginateResult<TenantsPaginatedResponse>>(
        `/v1/shared/tenant`,
        {
          params: {
            ...(params ?? {}),
          },
        }
      );

      return data;
    },
    {
      onSuccess,
    }
  );
}

export default useTenants;
