import { useState } from "react";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";
import { useDebounce } from "core/features/shared/hooks/useDebouce";
import CardPerson from "ui/components/Cards/CardPerson";
import { Box, Flex, Button, Text } from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";
import ModalCadastroUsuario from "./ModalCadastrarUsuario";
import useUsuarios from "core/features/shared/hooks/useUsuarios";

export interface SelectUsuariosProps<T = any> extends SelectBaseAntdProps<T> {
  form?: string[];
  allowClear?: boolean;
}

function SelectUsuarios({
  value,
  onChange,
  isInvalid,
  allowClear = false,
  form,
  ...rest
}: SelectUsuariosProps) {
  const [search, setSearch] = useState<string>();
  const debouncedSearchTerm = useDebounce(search, 500);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { data, isFetching, refetch, isLoading } = useUsuarios({
    page: 1,
    limit: 1000,
    search: debouncedSearchTerm,
    pessoaId: debouncedSearchTerm ? undefined : value,
  });

  const _isLoading = isLoading || isFetching;

  const handleSelectChange = (value: any, option: any) => {
    setSearch("");
    if (onChange) {
      onChange(value, option);
    }
  };
  return (
    <>
      {isOpenModal ? (
        <ModalCadastroUsuario
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          refetch={refetch}
        />
      ) : null}

      <SelectBaseAntd
        loading={_isLoading}
        isInvalid={isInvalid}
        size="large"
        showSearch
        placeholder="Selecione..."
        searchValue={search}
        onSearch={setSearch}
        onChange={handleSelectChange}
        value={value}
        filterOption={() => true}
        optionFilterProp="label"
        allowClear={allowClear}
        options={data?.items.map((item) => ({
          value: item.id,
          label: (
            <Flex align="center" gap={2}>
              <CardPerson
                avatarUrl={item.imagemUrl}
                avatarProps={{ w: "20px", h: "20px" }}
              />
              {item.nome}
            </Flex>
          ),
          disabled: form?.includes(item.id),
        }))}
        {...rest}
        notFoundContent={
          !isLoading && !isFetching ? (
            <Flex px={2} gap={2} align="center">
              <Text>Nenhuma registro encontrado</Text>

              <Button
                isLoading={false}
                leftIcon={<BsPlusLg size={20} />}
                size="sm"
                fontWeight={400}
                colorScheme="gray"
                onClick={() => setIsOpenModal(true)}
              >
                Adicionar
              </Button>
            </Flex>
          ) : (
            <Box>Carregando...</Box>
          )
        }
      ></SelectBaseAntd>
    </>
  );
}

export default SelectUsuarios;
