import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { ProcessoObservacaoResponse } from "../typings";

function useSaveObservacao(id?: string) {
  return useMutation(
    (data: ProcessoObservacaoResponse) => {
      return http.post(`/v1/processo/${id}/observacao`, data);
    },
    { retry: false }
  );
}

export default useSaveObservacao;
