import { Select } from "antd";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";

const { Option } = Select;

export interface SelectTipoFluxoProcessoProps<T = any> extends SelectBaseAntdProps<T> { }

function SelectTipoFluxoProcesso({ value, isInvalid, ...rest }: SelectTipoFluxoProcessoProps) {
    return (
        <SelectBaseAntd
            size="large"
            getPopupContainer={(node) => node.parentNode}
            placeholder="Selecione"
            value={value}
            {...rest}
        >
            <Option value={'padrao'} key={'padrao'}>
                Padrão
            </Option>
            <Option value={'customizado'} key={'customizado'}>
                Customizado
            </Option>
        </SelectBaseAntd>
    );
}

export default SelectTipoFluxoProcesso;
