import http from "core/features/auth/hooks/useApi";
import { useMutation } from "react-query";
import { UsuarioEnderecoResponse } from "../typings";


function useSaveUsuarioEndereco(id: string) {
  return useMutation(
    `/v1/usuario/${id}/endereco`,
    (data: UsuarioEnderecoResponse) => {
      return http.put<{ id: string }>(`/v1/usuario/${id}/endereco`, data);
    },
    { retry: false }
  );
}

export default useSaveUsuarioEndereco;
