import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import Container from "ui/components/Container";
import { FaAngleRight } from "react-icons/fa";
import { useBreadcrumbStore } from "core/features/breadcrumbs";
import { useNavigate } from "react-router-dom";

export default function SubHeader() {
  const navigate = useNavigate();
  const { items } = useBreadcrumbStore();

  return (
    <Flex w="100%" mt='10px' >
      <Container>
        <Breadcrumb
          mt={2}
          spacing="8px"
          separator={<FaAngleRight color={colors.gray400} />}
        >
          {items.map((item, index) => (
            <BreadcrumbItem
              key={index}
              isCurrentPage={items.length - 1 === index}
            >
              <BreadcrumbLink
                fontWeight="normal"
                _hover={{ color: colors.gray300 }}
                fontSize="13px"
                cursor={ "pointer"}
                color={colors.gray400}
                onClick={() => {
                  if (item.url) {
                    navigate(item.url);
                  }
                }}
              >
                {item.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Container>
    </Flex>
  );
}
