import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";
import { useState } from "react";
import useGetEstados from "core/features/hooks/useGetEstados";

export interface SelectEstadoProps<T = any> extends SelectBaseAntdProps<T> {
  value: any;
  onChange: (v: any) => void;
}

function SelectEstado({ value, onChange, ...rest }: SelectEstadoProps) {
  const [search, setSearch] = useState<string>();

  const { data, isLoading } = useGetEstados();

  return (
    <SelectBaseAntd
      loading={isLoading}
      size="large"
      placeholder="Estado"
      value={value}
      allowClear={false}
      showSearch
      searchValue={search}
      onSearch={setSearch}
      onChange={onChange}
      optionFilterProp="label"
      options={data?.map((item: any) => ({
        value: item.id,
        label: item.sigla,
      }))}
      {...rest}
    />
  );
}

export default SelectEstado;
