import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { ProcessoHistoricoResponse } from "../typings";

function useProcessoHistorico(id?: string) {
  return useQuery(
    `/v1/processo-historico/${id}`,
    async () => {
      const res = await http.get<ProcessoHistoricoResponse[]>(
        `/v1/processo-historico/${id}`
      );

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useProcessoHistorico;
