import { Box, Flex } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { UseFormSetValue } from "react-hook-form";
import Anexos from "ui/components/Anexos";
import ButtonAnexarArquivos from "ui/components/Anexos/ButtonAnexarArquivos";

interface AnexarCertificadoProps {
  setValue: UseFormSetValue<any>;
  form: any;
}

export default function AnexarCertificado({
  setValue,
  form,
}: AnexarCertificadoProps) {
  return (
    <Box as={Row} gutter={16} mb={8}>
      <Box as={Col} sm={24}>
        <Flex flex={1}>
          <ButtonAnexarArquivos
            buttonText="Anexar Certificado"
            anexoType="pfx"
            maxFileSizeMb={200}
            variant="ghost"
            maxFiles={1}
            onAnexar={(files) => {
              if (files) {
                const currentAnexos = form.certificado;
                setValue(
                  "certificado",
                  currentAnexos ? [...currentAnexos, ...files] : files
                );
              }
            }}
          />
        </Flex>
      </Box>
      <Box as={Col} sm={24}>
        <Anexos
          numColumns={1}
          setValue={(anexos) => setValue("certificado", anexos as File[])}
          anexos={form.certificado}
        />
      </Box>
    </Box>
  );
}
