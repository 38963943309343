import { Box, Skeleton, SkeletonProps } from "@chakra-ui/react";

interface SkeletonDefaultProps extends SkeletonProps{
    amount:number;
}

export default function SkeletonDefault({amount ,...props}:SkeletonDefaultProps){
    const array = Object.keys(new Array(amount).fill(null))
    return(
        <Box>
            {array.map((_,index) => <Skeleton key={index} {...props} /> )}
          </Box>
    )
}