import { SelectProps } from "antd";
import SelectBaseAntd from "../SelectBaseAntd";
export interface SelectOrderByProps<T = any> extends SelectProps<T> {
  value: any;
  options: {
    value: string;
    label: string;
  }[];
  onChange: (v: any) => void;
}

function SelectOrderBy({
  value,
  onChange,
  options,
  ...rest
}: SelectOrderByProps) {
  return (
    <SelectBaseAntd
      size="large"
      getPopupContainer={(node) => node.parentNode}
      placeholder="Selecione"
      onChange={onChange}
      value={value}
      options={options}
      {...rest}
    />
  );
}

export default SelectOrderBy;
