import http from "core/features/auth/hooks/useApi";
import { useMutation } from "react-query";

export default function useDeleteAlerta() {
  return useMutation(
    `/v1/processo/:id/alerta`,
    (id: string) => http.delete(`/v1/processo/${id}/alerta`),
    { retry: false }
  );
}
