import { useQuery } from "react-query";
import { PaginateResult } from "core/resources/types/shared";
import http from "core/features/auth/hooks/useApi";
import { SetorPaginateResponse,FiltrosSetor } from "../typings";


function useListarSetores(query: FiltrosSetor) {
  return useQuery([`/v1/setor`, query], async () => {
    const { data } = await http.get<PaginateResult<SetorPaginateResponse>>(
      `/v1/setor`,
      {
        params: query,
      }
    );

    return data;
  });
}

export default useListarSetores;
