import { Box } from "@chakra-ui/react";
import SubHeader from "./SubHeader";
import Header from "./Header/index";

export default function Layout({ children }: any) {
  return (
    <Box minH="100vh" bg={"#F9FAFB"} maxW="100vw">
      <Box>
        <Header />
        </Box>
        <Box>
          <SubHeader />
          <Box pt="32px" pb={"72px"}>
            {children}
          </Box>
        </Box>
      </Box>
  );
}
