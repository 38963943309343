import { useQuery } from "react-query";
import { PaginateResult } from "core/resources/types/shared";
import http from "core/features/auth/hooks/useApi";
import { FluxoProcessoResponse } from "../typings";


function useFluxosProcessos(
    params?: any,
    onSuccess?: (data: PaginateResult<FluxoProcessoResponse>) => any
) {
    return useQuery(
        [`/v1/shared/fluxo-processo`, params],
        async () => {
            const { data } = await http.get<PaginateResult<FluxoProcessoResponse>>(
                `/v1/shared/fluxo-processo`,
                {
                    params: params ?? {},
                }
            );

            return data;
        },
        {
            onSuccess,
        }
    );
}

export default useFluxosProcessos;
