import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";

function useEncaminhar(idProcesso?: string) {
  return useMutation(
    (data: { setorDestinoId: string }) => {
      return http.post<{ response: string }>(
        `/v1/processo/${idProcesso}/encaminhar`,
        data
      );
    },
    { retry: false }
  );
}

export default useEncaminhar;
