import { Flex } from "@chakra-ui/react";
import InputSearch from "../Input/InputSearch";
import OutlineButton from "../Buttons/OutlineButton";
import colors from "core/resources/theme/colors";
import { IoFilter } from "react-icons/io5";
import BadgeButton from "../BadgeButton";
import OrderByButton from "./OrderByButton/index";

interface SearchHeaderBarProps {
  onSearch: (q?: string) => void;
  defaultValue: string;
  placeholder?: string;
  maxWInputSearch?: number | string;
  children?: any;
  filtroTipoProcesso?: boolean;
  filterStatus?: React.ReactNode;
  filtroCargos?: React.ReactNode;
  filtroButton?: boolean;
  filtroButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  badgeValueExtraButton?: number;
  badge?: boolean;
  extraButton?: React.ReactNode;
  showOrderByButton?: boolean;
  onOrderDirectionChange?: (q?: string) => void;
  orderDirection?: any;
  onOrderByChange?: (q?: any) => void;
  orderBy?: string | undefined;
  orderByOptions?: {
    value: string;
    label: string;
  }[];
}

export default function SearchHeaderBar({
  maxWInputSearch = 350,
  defaultValue,
  onSearch,
  placeholder,
  children,
  filterStatus = false,
  filtroCargos = false,
  filtroButton,
  badgeValueExtraButton,
  filtroButtonOnClick,
  badge,
  extraButton,
  showOrderByButton,
  onOrderDirectionChange = () => {},
  onOrderByChange = () => {},
  orderBy,
  orderDirection,
  orderByOptions = [],
}: SearchHeaderBarProps) {
  return (
    <Flex>
      <InputSearch
        defaultValue={defaultValue}
        onSearch={onSearch}
        maxW={maxWInputSearch}
        placeholder={placeholder}
      />
      <Flex ml={4} align="center">
        {extraButton ? extraButton : null}
      </Flex>

      <Flex ml={4} gap={4}>
        {filtroCargos ? filtroCargos : null}

        {filterStatus ? filterStatus : null}
      </Flex>

      {filtroButton ? (
        <Flex ml={4} justifyContent="end">
          <OutlineButton
            bgColor="white"
            borderColor={colors.labelBg}
            onClick={filtroButtonOnClick}
            px={4}
            h={10}
          >
            <IoFilter size={20} /> Filtrar
          </OutlineButton>

          {badge ? <BadgeButton badgeValue={badgeValueExtraButton} /> : null}
        </Flex>
      ) : null}

      {showOrderByButton ? (
        <Flex ml={4} gap={4}>
          <OrderByButton
            onOrderDirectionChange={onOrderDirectionChange}
            orderDirection={orderDirection}
            orderBy={orderBy}
            onOrderByChange={onOrderByChange}
            options={orderByOptions}
          />
        </Flex>
      ) : null}

      {children ? <Flex>{children}</Flex> : null}
    </Flex>
  );
}
