import { Box, Flex, Text, Tag, Switch } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import { useForm } from "react-hook-form";
import Field from "ui/components/Input/Field";
import { useRef, useState } from "react";
import Toolbar from "ui/components/Toolbar";
import FooterModalDefault from "ui/components/FooterModal";
import { showErrorAxios, showSuccess } from "core/infra/helpers/alerta";
import { ContatoForm, UsuarioResponse } from "core/features/usuario/typings";
import useSaveUsuario from "core/features/usuario/hooks/useSaveUsuario";
import CardDefault from "ui/components/Cards/CardDefault";
import SelectSetor from "../SelectSetor";
import SelectCargo from "../SelectCargo";
import { isValidCPF, isValidEmail } from "@brazilian-utils/brazilian-utils";
import SelectStatusUsuario from "../SelectStatus/SelectStatus";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import { BsPlusLg } from "react-icons/bs";
import ContatoItem from "ui/pages/Admin/Usuario/Detalhes/components/InformacoesBasicas/components/ContatoItem";
import UploadImagem from "../UploadImagem";
import logoAdd from "assets/images/svg/add-foto.svg";
import InputPassword from "ui/components/Input/InputPassword";
import AnexarCertificado from "./../../../pages/Admin/Usuario/Detalhes/components/InformacoesBasicas/components/AnexarCertificado";

interface ModalCadastroUsuarioProps {
  isOpen: boolean;
  onClose: () => any;
  refetch: any;
}

function ModalCadastroUsuario({
  isOpen,
  onClose,
  refetch,
}: ModalCadastroUsuarioProps) {
  const { isLoading: isLoadingSave, mutate: handleSave } = useSaveUsuario();

  const tempIdIncrement = useRef(0);

  const defaultValues: UsuarioResponse = {
    ativo: undefined,
    senhaCertificado: "",
    pessoa: {
      nome: "",
      login: "",
      dataNascimento: "",
      documento: "",
      arquivoImagemId: "",
      matricula: "",
      perfilId: "",
      setorIds: [],
      contatos: [],
    },
  };
  const [handleToggle, setHandleToggle] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { control, setValue, watch, handleSubmit } = useForm<UsuarioResponse>({
    defaultValues,
  });
  const contatos = watch("pessoa.contatos");

  const form = watch();
  const onSubmit = (data: UsuarioResponse) => {
    handleSave(
      {
        ...data,
        ativo: data.ativo === "ativo" ? true : false,
        pessoa: {
          ...data.pessoa,
        },
      },
      {
        onSuccess: () => {
          showSuccess().then(() => {
            onClose();
            refetch();
          });
        },
        onError: (err) => {
          showErrorAxios(err);
        },
      }
    );
  };

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Salvar"
          onclickPrimaryButton={() => handleSubmit(onSubmit)()}
          saveLoading={isLoadingSave}
        />
      }
      title={<Toolbar title="Cadastro de usuário" onClose={onClose} />}
      destroyOnClose
      onCancel={onClose}
      maskClosable={false}
      closable={false}
      width="900px"
      centered
    >
      <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
        <Box>
          <CardDefault mb={4}>
            <Text fontWeight="semibold" fontSize="18px">
              Informações básicas
            </Text>

            <Flex gap={4} mt={8}>
              <Flex>
                <Field
                  control={control}
                  name="pessoa.arquivoImagem"
                  render={({ field }) => {
                    return (
                      <UploadImagem
                        {...field}
                        logoAdd={logoAdd}
                        setValue={setValue}
                        stringSetValue={"pessoa.arquivoImagemId"}
                      />
                    );
                  }}
                />
              </Flex>
              <Box w="100%">
                <Box as={Row} gutter={16} mb={4}>
                  <Box as={Col} sm={14}>
                    <Field
                      name="pessoa.nome"
                      label="Nome"
                      control={control}
                      rules={{ required: true }}
                    />
                  </Box>
                  <Box as={Col} sm={10}>
                    <Field
                      name="pessoa.documento"
                      label="CPF"
                      control={control}
                      rules={{
                        required: true,
                        validate: (v) => isValidCPF(v) || "CPF inválido",
                      }}
                      inputProps={{
                        type: "cpf",
                      }}
                    />
                  </Box>
                </Box>

                <Box as={Row} gutter={16} mb={4}>
                  <Box as={Col} sm={14}>
                    <Field
                      name="pessoa.matricula"
                      label="Matrícula"
                      control={control}
                    />
                  </Box>
                  <Box as={Col} sm={10}>
                    <Field
                      name="pessoa.dataNascimento"
                      label="Data de nascimento"
                      control={control}
                      inputProps={{ type: "date" }}
                    />
                  </Box>
                </Box>

                <Box as={Row} gutter={16} mb={10}>
                  <Box as={Col} md={14}>
                    <Field
                      name="pessoa.setorIds"
                      label="Setor"
                      control={control}
                      render={({ field, isInvalid }) => (
                        <SelectSetor
                          mode="multiple"
                          isInvalid={isInvalid}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <Box as={Col} sm={10}>
                    <Field
                      name="pessoa.perfilId"
                      label="Cargo"
                      control={control}
                      render={({ field }) => <SelectCargo {...field} />}
                    />
                  </Box>
                </Box>
              </Box>
            </Flex>
          </CardDefault>
          <CardDefault mb={4}>
            <Flex align="center">
              <Text fontWeight="semibold" fontSize="18px">
                Acesso ao sistema
              </Text>
            </Flex>

            <Box as={Row} gutter={16} mt={4}>
              <Box as={Col} sm={16}>
                <Field
                  name="pessoa.login"
                  label="Usuário"
                  control={control}
                  rules={{
                    required: true,
                    validate: (v) => isValidEmail(v) || "Email inválido",
                  }}
                  inputProps={{
                    type: "email",
                  }}
                />
              </Box>
              <Box as={Col} sm={8}>
                <Field
                  label="Status"
                  control={control}
                  name={"ativo"}
                  rules={{ required: true }}
                  render={({ field }) => <SelectStatusUsuario {...field} />}
                />
              </Box>
            </Box>
          </CardDefault>
          <CardDefault mb={4}>
            <Flex gap={10} align="center" mb={4}>
              <Text fontWeight="semibold" fontSize="18px">
                Contatos
              </Text>
              <OutlineButton
                onClick={() => {
                  setValue("pessoa.contatos", [
                    ...(contatos ?? []),
                    {
                      added: true,
                      tempId: `${new Date().getTime().toString()}${
                        tempIdIncrement.current
                      }`,
                    } as ContatoForm,
                  ]);

                  tempIdIncrement.current += 1;
                }}
              >
                <BsPlusLg size={20} />
                Adicionar
              </OutlineButton>
            </Flex>

            <Flex flexDir="column" gap={4}>
              {contatos?.map((contato: ContatoForm, index: number) => (
                <ContatoItem
                  setValue={setValue}
                  contatos={contatos}
                  key={contato.added ? contato.tempId : contato.id}
                  index={index}
                  control={control}
                />
              ))}
            </Flex>
          </CardDefault>
          <CardDefault mb={4}>
            <Flex justify="space-between" mb={8}>
              <Flex align="center" gap={2}>
                <Text fontWeight="semibold" fontSize="18px">
                  Certificado digital
                </Text>
                <Tag colorScheme={"red"} h={5}>
                  {"Ausente"}
                </Tag>
              </Flex>
              <Flex gap={2}>
                <Text>Adicionar um certificado digital?</Text>
                <Switch
                  onChange={() => {
                    setHandleToggle(!handleToggle);
                  }}
                  isChecked={handleToggle}
                />
              </Flex>
            </Flex>

            {handleToggle ? (
              <Box as={Row} gutter={16} mb={4}>
                <Box as={Col} md={14} mb={4}>
                  <Field
                    name="senhaCertificado"
                    label="Senha do certificado"
                    control={control}
                    render={({ field }) => (
                      <InputPassword
                        showPassword={showPassword}
                        onClick={handleClickShowPassword}
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                </Box>
                <Box as={Col} md={14}>
                  <AnexarCertificado setValue={setValue} form={form} />
                </Box>
              </Box>
            ) : null}
          </CardDefault>
        </Box>
      </form>
    </Modal>
  );
}

export default ModalCadastroUsuario;
