import { Box, Flex, Tag, Text } from "@chakra-ui/react";
import CardDefault from "ui/components/Cards/CardDefault";
import { Row, Col } from "antd";
import ContentItem from "ui/components/ContantItem";
import { ProcessoDetalhesResponse } from "core/features/processos/typings/index";
import { MdDescription } from "react-icons/md";
import colors from "core/resources/theme/colors";
import { useState } from "react";
import CardPerson3 from "ui/components/Cards/CardPerson3";
import PoppoverAssunto from "ui/pages/Admin/Processos/Listagem/components/PoppoverAssunto";
import { useWindowSize } from "core/infra/helpers/window";
import { truncateText } from "core/infra/helpers/truncateText";

interface DadosProcessoProps {
  data?: ProcessoDetalhesResponse;
}

export default function DadosProcesso({ data }: DadosProcessoProps) {
  const [isContentExpanded, setIsContentExpanded] = useState(false);

  const handleContentToggle = () => {
    setIsContentExpanded(!isContentExpanded);
  };

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 992;
  return (
    <CardDefault mb={4}>
      <Box>
        <Flex align="center" gap={2} mb={4}>
          <MdDescription color={colors.gray500} size={22} />
          <Text fontSize="18px" fontWeight="500">
            Dados do processo
          </Text>
        </Flex>

        <Box as={Row} mb={2}>
          <Box as={Col} md={8}>
            <ContentItem
              titulo="CRIADO POR"
              descricao={
                (
                  <Flex align="center" gap={2}>
                    <CardPerson3
                      nome={data?.responsavel.nome}
                      avatarUrl={data?.responsavel.imagemUrl}
                      fontSizeAvatar="12px"
                      border="none"
                      avatarProps={{ w: "25px", h: "25px", fontSize: "5px" }}
                    />
                    <Text>{data?.responsavel.nome}</Text>
                  </Flex>
                ) || "--"
              }
            />
          </Box>
          <Box as={Col} md={8}>
            <ContentItem
              titulo="ASSUNTO"
              descricao={
                data?.assunto ? (
                  <Text wordBreak="normal" pr={2}>
                    {truncateText(data.assunto, isMobile ? 30 : 120)}
                    {data.assunto.length > (isMobile ? 30 : 120) ? (
                      <PoppoverAssunto
                        assunto={data.assunto}
                        children={
                          <Text
                            pr={2}
                            fontSize="16px"
                            onClick={(ev) => {
                              handleContentToggle();
                              ev.stopPropagation();
                            }}
                            color={colors.primary}
                            cursor="pointer"
                            display="inline"
                            ml="6px"
                          >
                            ...Ver mais
                          </Text>
                        }
                      />
                    ) : null}
                  </Text>
                ) : (
                  "--"
                )
              }
            />
          </Box>
          <Box as={Col} md={8}>
            <ContentItem
              titulo="RESUMO"
              descricao={
                data?.resumo ? (
                  <Text wordBreak="normal" pr={2}>
                    {truncateText(data.resumo, isMobile ? 30 : 120)}
                    {data.resumo.length > (isMobile ? 30 : 120) ? (
                      <PoppoverAssunto
                        assunto={data.resumo}
                        children={
                          <Text
                            pr={2}
                            fontSize="16px"
                            onClick={(ev) => {
                              handleContentToggle();
                              ev.stopPropagation();
                            }}
                            color={colors.primary}
                            cursor="pointer"
                            display="inline"
                            ml="6px"
                          >
                            ...Ver mais
                          </Text>
                        }
                      />
                    ) : null}
                  </Text>
                ) : (
                  "--"
                )
              }
            />
          </Box>
        </Box>
        <Box as={Row} mb={2}>
          <Box as={Col} md={8}>
            <ContentItem
              titulo="PRIORIDADE"
              descricao={
                (
                  <Tag colorScheme={data?.prioridade?.cor}>
                    {data?.prioridade?.nome}
                  </Tag>
                ) || "--"
              }
            />
          </Box>
          <Box as={Col} md={8}>
            <ContentItem
              titulo="STATUS DO PRAZO"
              descricao={
                data?.isEmAtraso ? (
                  <Tag colorScheme={"red"}>Em atraso</Tag>
                ) : (
                  <Tag colorScheme={"green"}>Em dia</Tag>
                )
              }
            />
          </Box>
          <Box as={Col} md={8}>
            <ContentItem
              titulo="SITUAÇÃO ATUAL"
              descricao={data?.situacao ? <Text>{data?.situacao}</Text> : "--"}
            />
          </Box>
        </Box>
      </Box>
    </CardDefault>
  );
}
