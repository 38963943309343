export enum PerfilTabsRoutes {
    InformacoesBasicas = "informacoes-basicas",
    Endereco = "endereco",
    TrocarSenha = "trocar-senha",
}

export const tabs = [
    {
        label: "Informações básicas",
        route: PerfilTabsRoutes.InformacoesBasicas,
        permissions: [],
    },
    {
        label: "Endereço",
        route: PerfilTabsRoutes.Endereco,
        permissions: [],
    },
    {
        label: "Trocar senha",
        route: PerfilTabsRoutes.TrocarSenha,
        permissions: [],
    },
];