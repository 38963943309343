import { CognitoUserSession } from "amazon-cognito-identity-js";
import { userPool } from "../auth.store";

export const isAuthenticated = async (): Promise<CognitoUserSession | null> => {
  return new Promise(async (success, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject("Não foi possível recuperar o usuário atual");
      return;
    }

    cognitoUser.getSession(
      (err: Error | null, session: CognitoUserSession | null) => {
        if (err) {
          reject("Não foi possivel recuperar a sessão do usuário: ");
          return;
        }

        if (session?.isValid()) {
          success(session);
        } else {
          reject("A sessão não é válida");
        }
      }
    );
  });
};
