import http from "core/features/auth/hooks/useApi";
import { useMutation } from "react-query";
import { UserProfileEnderecoResponse } from "../types";



function useSaveProfileEndereco() {
    return useMutation(
        `/v1/auth/profile/endereco`,
        (data: UserProfileEnderecoResponse) => {
            return http.put(`/v1/auth/profile/endereco`, data);
        },
        { retry: false }
    );
}

export default useSaveProfileEndereco;
