import { useState } from "react";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";
import useSetoresUsuario from "core/features/shared/hooks/useSetoresUsuario";

export interface SelectSetorUsuarioProps<T = any>
  extends SelectBaseAntdProps<T> {}

function SelectSetorUsuario({ ...rest }: SelectSetorUsuarioProps) {
  const [search, setSearch] = useState<string>();

  const { isLoading, data } = useSetoresUsuario();

  return (
    <SelectBaseAntd
      loading={isLoading}
      size="large"
      placeholder="Selecione..."
      searchValue={search}
      showSearch
      onSearch={setSearch}
      optionFilterProp="label"
      options={data?.map((item) => ({
        value: item.id,
        label: item.nome,
      }))}
      {...rest}
    />
  );
}

export default SelectSetorUsuario;
