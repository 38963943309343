import { Box, Flex } from "@chakra-ui/react";
import PageHeader from "ui/components/Page/PageHeader";
import { useBreadcrumb } from "core/features/breadcrumbs";
import SearchHeaderBar from "ui/components/SearchHeaderBar/index";
import { useNavigate } from "react-router-dom";
import CargosTable from "./components/CargoTable/index";
import useListarCargos from "core/features/cargos/hooks/useListarCargos";
import useFiltroCargos from "./hooks/useFiltroCargos";
import SeletctStatusFiltro from "ui/components/Input/SeletctStatusFiltro";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { MdAdd, MdKeyboardArrowDown, MdWork } from "react-icons/md";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { checkPermission } from "core/infra/helpers/permission";

export default function ListagemCargosPage() {
  const { filtro, onChangeFiltro } = useFiltroCargos();
  const { data, isLoading } = useListarCargos(filtro);

  const navigate = useNavigate();

  useBreadcrumb([{ name: "Cadastro" }, { name: "Cargos", url: "cargo" }]);

  const createPermission = [PermissaoEnum.Fluir.Administrativo.Perfil.Criar];

  const hasPermission = checkPermission(createPermission ?? []);

  return (
    <Box>
      <Box mb={10}>
        <PageHeader
          title="Cargos"
          midComponent={
            <Box pos="relative">
              <Menu placement="bottom">
                <MenuButton
                  as={PrimaryButton}
                  size="sm"
                  leftIcon={<MdAdd size={20} />}
                  rightIcon={<MdKeyboardArrowDown size={20} />}
                  disabled={!hasPermission}
                >
                  Adicionar
                </MenuButton>
                <MenuList position="absolute" top="38px">
                  <MenuItem
                    onClick={() => navigate("/cargo/novo")}
                    icon={<MdWork size={20} />}
                    fontSize="14px"
                  >
                    Um cargo
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/cargo/novo")}
                    icon={<MdWork size={20} />}
                    fontSize="14px"
                  >
                    Vários cargos
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          }
        />

        <SearchHeaderBar
          defaultValue={filtro.search || ""}
          onSearch={(v) => onChangeFiltro({ search: v })}
          maxWInputSearch={700}
          placeholder="Buscar"
          filterStatus={
            <Flex w="100px">
              <SeletctStatusFiltro
                onChange={(ativo) => {
                  onChangeFiltro({ ativo: ativo });
                }}
                value={filtro.ativo}
              />
            </Flex>
          }
        />
      </Box>

      <CargosTable
        data={data}
        isLoading={isLoading}
        onChangeFiltro={onChangeFiltro}
      />
    </Box>
  );
}
