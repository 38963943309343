import { PDFDocument } from "pdf-lib";
import { showError } from "./alerta";

export async function countPages(file: File): Promise<number> {
  try {
    const arrayBuffer = await file.arrayBuffer();

    const pdfDoc = await PDFDocument.load(arrayBuffer);

    return pdfDoc.getPageCount();
  } catch (error) {
    showError("Erro ao carregar o PDF");
    console.log(error);
    throw error;
  }
}

export async function addBlankPages(file: any, numPages: number) {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    const headerArray = Array.from(uint8Array.slice(0, 4));
    const header = String.fromCharCode(...headerArray);
    if (header !== "%PDF") {
      throw new Error("O arquivo não é um PDF válido.");
    }

    const pdfDoc = await PDFDocument.load(arrayBuffer);

    for (let i = 0; i < numPages; i++) {
      pdfDoc.addPage();
    }

    const updatedPdfBytes = await pdfDoc.save();

    const blobPDF = new Blob([updatedPdfBytes], { type: "application/pdf" });

    return new File([blobPDF], "documento.pdf", {
      type: "application/pdf",
      lastModified: new Date().getTime(),
    });
  } catch (error) {
    showError("Erro ao adicionar páginas em branco");
    console.log(error);
    throw error;
  }
}

export async function compareFiles(file1: any, file2: any): Promise<boolean> {
  const blob1 = new File([file1], "documento.pdf", {
    type: "application/pdf",
  });
  const blob2 = new File([file2], "documento.pdf", {
    type: "application/pdf",
  });

  const [buffer1, buffer2] = await Promise.all([
    blob1.arrayBuffer(),
    blob2.arrayBuffer(),
  ]);
  return (
    buffer1.byteLength === buffer2.byteLength &&
    new Uint8Array(buffer1).every(
      (byte, index) => byte === new Uint8Array(buffer2)[index]
    )
  );
}
