import { Box, Button, Collapse, Flex } from "@chakra-ui/react";
import CardDefault from "ui/components/Cards/CardDefault";
import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import colors from "core/resources/theme/colors";
import { FaEquals } from "react-icons/fa";
import {
  AndamentosResponse,
  CriterioEnum,
} from "core/features/tipoprocesso/typings";
import DeleteButton from "ui/components/Buttons/DeleteButton";
import useSetores from "core/features/shared/hooks/useSetores";

interface CardCollapseAndamentoProps {
  item: AndamentosResponse;
  index: number;
  onRemoveItem: (index: number, id: string) => void;
  setIndexEdit: React.Dispatch<React.SetStateAction<number | undefined>>;
  hasPermission: boolean;
}

export default function CardCollapseAndamento({
  item,
  onRemoveItem,
  index,
  setIndexEdit,
  hasPermission,
}: CardCollapseAndamentoProps) {
  const [collapsed, setCollapsed] = useState(true);

  const { data } = useSetores();

  const setor = data?.filter((v: { id: string }) => v.id === item.setorId);

  return (
    <CardDefault rounded="8px" p={0} mb={2} overflow="visible">
      <Flex>
        <Flex
          borderLeftRadius="8px"
          align="center"
          justify="center"
          w="25px"
          bg={colors.labelBg}
          mr={4}
        >
          <FaEquals />
        </Flex>
        <Flex flexDir="column" py="20px" w="100%">
          <Flex
            flex={1}
            px={0.5}
            cursor="pointer"
            onClick={() => setCollapsed((state) => !state)}
          >
            <Flex justify="space-between" align="center" w="100%">
              <Flex fontSize="16px">{setor?.[0].nome}</Flex>

              <Flex gap={4} align="center" mr={4}>
                <Flex w="80px">
                  {item.fluxosAndamentoCriterios.length}
                  {item.fluxosAndamentoCriterios.length! === 1
                    ? " critério"
                    : " critérios"}
                </Flex>

                <Button
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setIndexEdit(index);
                  }}
                  p="0"
                  px={4}
                  h="35px"
                  fontSize="14px"
                  fontWeight="400"
                  gap={2}
                >
                  <MdModeEdit size={20} /> Editar
                </Button>
                {hasPermission ? (
                  <DeleteButton
                    h="35px"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      onRemoveItem(index!, item.id!);
                    }}
                  />
                ) : null}
              </Flex>
            </Flex>

            <Flex align="center" mr={4}>
              {collapsed ? (
                <BsChevronDown fontSize={20} />
              ) : (
                <BsChevronUp fontSize={20} />
              )}
            </Flex>
          </Flex>
          <Collapse in={!collapsed}>
            <Box ml={2} position="relative" py={2}>
              {item?.fluxosAndamentoCriterios?.map((v) => (
                <>
                  <Flex align="center" position="relative">
                    <Flex
                      bg={"#A0AEC0"}
                      h="25px"
                      w="1px"
                      bottom="12px"
                      position="absolute"
                    />
                    <Flex bg={"#A0AEC0"} h="1px" w="20px" mr={2} />
                    {v.criterioId === CriterioEnum.Assinatura
                      ? "Assinatura"
                      : v.criterioId === CriterioEnum.AnexoDocumento
                        ? "Anexo de documento"
                        : "Visualização do departamento admininstrativo"}
                  </Flex>
                </>
              ))}
            </Box>
          </Collapse>
        </Flex>
      </Flex>
    </CardDefault>
  );
}
