import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";


function usePrioridades() {
  return useQuery(["/v1/shared/prioridades"], async () => {
    const { data } = await http.get<{ id: string; nome: string }[]>(
      "/v1/shared/prioridades"
    );

    return data;
  });
}

export default usePrioridades;
