import { Avatar, AvatarProps, Flex } from "@chakra-ui/react";

interface CardPerson2Props {
  avatarUrl?: string;
  nome?: string;
  avatarProps?: AvatarProps;
  backgroundColor?: string;
}

export default function CardPerson2({
  avatarUrl,
  nome,
  avatarProps,
  backgroundColor,
}: CardPerson2Props) {
  return (
    <Flex borderRadius="full" p="6px">
      <Avatar
        name={nome?.trim()}
        src={avatarUrl?.trim()}
        bg={backgroundColor}
        {...avatarProps}
      />
    </Flex>
  );
}
