import { Flex, Text } from "@chakra-ui/react";
import { Checkbox } from "antd";
import { ProcessoDocumentoPaginateResponse } from "core/features/processos-documento/typings";
import moment from "moment";
import { MdEdit, MdLocalPrintshop, MdShare, MdDraw } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu";
import DocumentoIcone from "ui/pages/Admin/Processos/Detalhes/components/TabDocumentos/components/DocumentoIcone";
import colors from "core/resources/theme/colors";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { useToast } from "@chakra-ui/react";

import TagStatus from "ui/components/TagStatus/index";
import { truncateText } from "core/infra/helpers/truncateText";
interface TableItemRowProps {
  item: ProcessoDocumentoPaginateResponse;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  selectable?: boolean;
  indexItem: number;
  setDeleteIds?: (id: string[]) => any;
  verificarCertificado: (id: string[]) => void;
  permissaoSetor?: boolean;
  isMobile: boolean;
}

export default function TableItemRow({
  item,
  selected,
  setSelected,
  indexItem,
  selectable = true,
  setDeleteIds,
  verificarCertificado,
  permissaoSetor,
  isMobile,
}: TableItemRowProps) {
  const toast = useToast();

  const assinaturaIniciada = item.totalAssinaturas > 0;

  const apenasMinhaAssinatura =
    item.totalAssinaturas === 1 && item.assinaturaUsuario;

  const permissaoExcluir =
    permissaoSetor && assinaturaIniciada ? apenasMinhaAssinatura : true;

  const onSelect = () => {
    setSelected([...selected, item.id]);
  };

  const onUnselect = () => {
    setSelected(selected.filter((id) => id !== item.id));
  };
  const isSelected = selected.includes(item.id);

  const onCheckAllChange = () => {
    if (item.assinado && !apenasMinhaAssinatura && !permissaoExcluir) {
      return;
    }
    isSelected ? onUnselect() : onSelect();
  };

  const navigate = useNavigate();

  const isEscrever = item.texto ? "escrever" : "anexar";

  const handleCopyClick = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast({
          title: "Link copiado.",
          status: "success",
          duration: 9000,
          isClosable: true,
          colorScheme: "purple",
        });
      })
      .catch((err) => {
        toast({
          title: `"Erro ao copiar texto:", ${err}`,
          status: "success",
          duration: 9000,
          isClosable: true,
          colorScheme: "red",
        });
      });
  };

  return (
    <>
      <Flex
        rounded={8}
        px={5}
        py={2}
        mb={2}
        bg={isSelected ? colors.gray50 : "#FFFFFF"}
        boxShadow="base"
        align="center"
        cursor="pointer"
      >
        <Flex flex={0.1}>
          <Checkbox
            onChange={selectable ? onCheckAllChange : undefined}
            checked={isSelected}
            disabled={
              permissaoExcluir === false && item.assinaturaUsuario === true
            }
            onClick={(ev) => ev.stopPropagation()}
          />
        </Flex>
        <Flex flex={isMobile ? 0.4 : 0.54}>
          <Text fontSize="14px">
            {item.numeroProcessoDocumento ? item.numeroProcessoDocumento : "--"}
          </Text>
        </Flex>
        <Flex fontSize="14px" flex={0.3}>
          <DocumentoIcone mimetype={"application/pdf"} />
        </Flex>
        <Flex flex={isMobile ? 0.9 : 2}>
          <Text
            fontSize="14px"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            mr={6}
          >
            {truncateText(item.descricao, isMobile ? 40 : 70)}
            {item.descricao.length > (isMobile ? 40 : 70) ? "..." : null}
          </Text>
        </Flex>
        <Flex flex={isMobile ? 0.7 : 0.54}>
          <Text fontSize="14px">
            {moment(item.createdAt).format("DD/MM/YYYY")}
          </Text>
        </Flex>

        <Flex flex={isMobile ? 0.4 : 0.54}>
          <Text fontSize="14px">{item.totalAssinados}</Text>
        </Flex>

        <Flex fontSize="14px" flex={isMobile ? 0.41 : 0.44}>
          {!item.isAssinante ? (
            <TagStatus title="Não assinante" colorScheme="gray" />
          ) : item.assinaturaUsuario ? (
            <TagStatus title="Assinado" colorScheme="whatsapp" />
          ) : (
            <TagStatus title="Para Assinar" colorScheme="red" />
          )}
        </Flex>

        <Flex flex={0.04}>
          <Flex h="fit-content" py={4}>
            <ActionsKebabMenu
              indexItem={indexItem}
              item={item}
              actions={[
                {
                  title: "Editar ",
                  icon: () => <MdEdit />,
                  hidden: assinaturaIniciada || !permissaoSetor,
                  permission: PermissaoEnum.Fluir.Processo.Documento.Editar,
                  onClick: (v) => {
                    item.texto
                      ? navigate(`documento/${v.id}?opcao=${isEscrever}`)
                      : navigate(`documento/${v.id}?opcao=anexar`);
                  },
                },
                {
                  title: "Assinar",
                  icon: () => <MdDraw />,
                  hidden: !item.isAssinante || item.assinaturaUsuario,
                  permission: PermissaoEnum.Fluir.Processo.Documento.Editar,
                  onClick: (v) => {
                    verificarCertificado([v.id]);
                  },
                },

                {
                  title: "Imprimir",
                  icon: () => <MdLocalPrintshop />,
                  onClick: (v) => window.open(v.arquivoUrl, "_blank"),
                },
                {
                  title: "Compartilhar",
                  icon: () => <MdShare />,
                  onClick: (v) => handleCopyClick(v.arquivoUrl),
                },
                {
                  title: "Excluir",
                  icon: () => <RiDeleteBin6Fill />,
                  hidden: !permissaoExcluir,
                  permission: PermissaoEnum.Fluir.Processo.Documento.Excluir,
                  onClick: (v) => {
                    setDeleteIds!([v.id]);
                  },
                  textColor: colors.red500,
                },
              ]}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
