import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";

export function useVisualizarNotificacao() {
  return useMutation(
    `/v1/notificacao/visualizar`,
    (id: string) => {
      return http.put<{ id: string }>(`/v1/notificacao/${id}/visualizar`);
    },
    { retry: false }
  );
}
