import { Badge } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";

interface BadgeButtonProps {
  badgeValue?: string | number;
}
function BadgeButton({ badgeValue }: BadgeButtonProps) {
  return (
    <Badge
      display={badgeValue === "0" ? "none" : "flex"}
      position="absolute"
      minW="20px"
      h="20px"
      rounded="full"
      bg={colors.orange400}
      color="white"
      mx={2}
      alignItems="center"
      justifyContent="center"
      mr="-4px"
      mt="-6px"
    >
      {badgeValue}
    </Badge>
  );
}

export default BadgeButton;
