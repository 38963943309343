import { Box, Text } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import { ProcessoDocumentoResponse } from "core/features/processos-documento/typings";
import { useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import FooterModalDefault from "ui/components/FooterModal";
import SelectUsuarios from "ui/components/Input/SelectUsuarios";
import Toolbar from "ui/components/Toolbar";

interface ModalPermitirAcessoProps {
  isOpen: boolean;
  onClose: () => any;
  id?: string;
  setValue: UseFormSetValue<ProcessoDocumentoResponse>;
  form: ProcessoDocumentoResponse;
}

export default function ModalPermitirAcesso({
  isOpen,
  onClose,
  id,
  setValue,
  form,
}: ModalPermitirAcessoProps) {
  const [tempValues, setTempValues] = useState({
    permitirAcessoPessoaIds: form.permitirAcessoPessoaIds || [],
  });

  useEffect(() => {
    setTempValues((prevValues) => ({
      ...prevValues,
      permitirAcessoPessoaIds: form.permitirAcessoPessoaIds || [],
    }));
  }, [form.permitirAcessoPessoaIds, setTempValues]);

  const handleSaveClick = () => {
    setValue("permitirAcessoPessoaIds", tempValues.permitirAcessoPessoaIds);

    const updatedExcluidosIds =
      form.permitirAcessoPessoaExcluidosIds?.filter(
        (id) => !tempValues.permitirAcessoPessoaIds.includes(id)
      ) || [];

    setValue("permitirAcessoPessoaExcluidosIds", updatedExcluidosIds);

    onClose();
  };

  const handleSelectPessoasChange = (values: any) => {
    setTempValues({ ...tempValues, permitirAcessoPessoaIds: values });
  };

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Adicionar"
          onclickPrimaryButton={handleSaveClick}
        />
      }
      title={<Toolbar title="Permitir acesso" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
    >
      <Box as={Row} gutter={16} mb={8}>
        <Box as={Col} sm={24}>
          <Text mb={2}>Usuários</Text>
          <SelectUsuarios
            form={form.permitirAcessoPessoaIds}
            value={tempValues.permitirAcessoPessoaIds}
            onChange={handleSelectPessoasChange}
            mode="multiple"
          />
        </Box>
      </Box>
    </Modal>
  );
}
