import http from "core/features/auth/hooks/useApi";
import { useMutation } from "react-query";

export default function useDeleteFluxoProcesso() {
  return useMutation(
    `/v1/fluxo-processo`,
    (id: string) => http.delete(`/v1/fluxo-processo/${id}`),
    { retry: false }
  );
}
