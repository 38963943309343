import { Flex, Text } from "@chakra-ui/layout";
import { IoIosNotifications } from "react-icons/io";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Badge } from "@chakra-ui/react";
import { useMemo, useCallback, useRef } from "react";
import moment from "moment";
import { Spin, Empty } from "antd";
import colors from "core/resources/theme/colors";
import { useNotificacoes } from "core/features/notificacacao/hooks/useNotificacoes";
import { NotificacaoPaginateResponse } from "core/features/notificacacao/types/index";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { useVisualizarNotificacao } from "core/features/notificacacao/hooks/useVisualizarNotificacao";
import { useNavigate } from "react-router-dom";

function NotificationMenu() {
  const {
    data,
    isLoading,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useNotificacoes();

  const { mutate: handleVisualize } = useVisualizarNotificacao();

  const localVisualized = useRef<Set<string>>(new Set());

  const navigate = useNavigate();

  const _handleClick = useCallback(
    (notificacao: NotificacaoPaginateResponse) => {
      handleVisualize(notificacao.id);
      localVisualized.current.add(notificacao.id);
      navigate(`/${notificacao.urlAction}`);
      refetch();
    },
    [handleVisualize]
  );

  const items = useMemo(() => {
    return (
      data?.pages.reduce(
        (agg: NotificacaoPaginateResponse[], item) =>
          agg.concat(item.items ?? []),
        []
      ) ?? []
    );
  }, [data]);

  const formatTime = (data: string) => {
    if (moment().diff(moment(data), "day") >= 1) {
      return moment(data).format("DD/MM/YYYY HH:mm");
    }
    return `há ${moment().diff(moment(data), "hours")} horas`;
  };

  const notVisualized = items.reduce(
    (acc, next) => (acc += next.visualizado ? 0 : 1),
    0
  );

  return (
    <Menu>
      <MenuButton _hover={{ opacity: 0.8 }}>
        <Flex pr="16px">
          <IoIosNotifications fontSize="32px" color="white" />
          {notVisualized > 9 ? (
            <Badge colorScheme="red" variant="solid" position="absolute">
              9+
            </Badge>
          ) : notVisualized > 0 ? (
            <Badge colorScheme="red" variant="solid" position="absolute">
              {notVisualized}
            </Badge>
          ) : null}
        </Flex>
      </MenuButton>
      <MenuList>
        <Text fontWeight="bold" color={colors.primary} pl="10px">
          Notificações
        </Text>

        <Flex
          alignItems="center"
          flexDir="column"
          pr="4px"
          maxH="400px"
          overflowY="auto"
          className="custom-scroll-bar"
        >
          {items.map((notification, index) => (
            <MenuItem key={index} onClick={() => _handleClick(notification)}>
              {/* <Flex pr="8px">
          <Avatar name="Dan Abrahmov" src="https://bit.ly/dan-abramov" />
        </Flex> */}
              <Flex flexDir="column">
                <Flex align="center" gap={1}>
                  <Text fontWeight="bold" fontSize="12px">
                    {notification.titulo}
                  </Text>
                  {!notification.visualizado &&
                  !localVisualized.current.has(notification.id) ? (
                    <Flex rounded="full" bg={colors.red500} w={1} h={1} />
                  ) : null}
                </Flex>
                <Text fontSize="12px">{notification.descricao || ""}</Text>
                <Text fontWeight="light" fontSize="12px">
                  {formatTime(notification.createdAt)}
                </Text>
              </Flex>
            </MenuItem>
          ))}

          {!isLoading &&
          !isFetchingNextPage &&
          !isRefetching &&
          !items.length ? (
            <Empty description="Sem notificações" />
          ) : null}

          {isLoading || isFetchingNextPage ? (
            <Spin size="small" spinning />
          ) : null}

          {!isLoading && !isRefetching && hasNextPage ? (
            <PrimaryButton
              bgColor="transparent"
              color={colors.primary}
              fontWeight="medium"
              _hover={{ opacity: 0.6, color: colors.gray400 }}
              onClick={() => fetchNextPage()}
            >
              Mostrar mais
            </PrimaryButton>
          ) : null}
        </Flex>
      </MenuList>
    </Menu>
  );
}

export default NotificationMenu;
