import { Box, Flex, Text } from "@chakra-ui/react";
import { Spin } from "antd";
import useDeleteObservacao from "core/features/processos/hooks/useDeleteObservacao";
import {
  showConfirm3,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import colors from "core/resources/theme/colors";
import moment from "moment";
import { IoMdTrash } from "react-icons/io";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu";
import CardDefault from "ui/components/Cards/CardDefault";
import EmptyDefault from "ui/components/Empty";

interface TableObservacoesProps {
  items?: {
    id: string;
    createdAt: string;
    autor: {
      nome: string;
      imagemUrl?: string | undefined;
    };
    observacao: string;
  }[];
  setIIdObservacao: React.Dispatch<React.SetStateAction<string | undefined>>;
  refetch?: any;
  isFetching: boolean;
  isLoading: boolean;
}

export default function TableObservacoes({
  items,
  setIIdObservacao,
  refetch,
  isFetching,
  isLoading,
}: TableObservacoesProps) {
  const { mutate: deleteObservacao, isLoading: isLoadingDelete } =
    useDeleteObservacao();

  const handleDelete = async (id: string) => {
    const { isConfirmed } = await showConfirm3({
      mensagem:
        "Você tem certeza que deseja excluir o registro definitivamente?",
    });

    if (isConfirmed) {
      deleteObservacao(id, {
        onSuccess: () => {
          refetch();
          setTimeout(() => {
            showSuccess("Seu registro foi excluido com sucesso!");
          }, 200);
        },
      });
    }
  };

  useLoading(isLoadingDelete);
  return (
    <>
      {isFetching || isLoading || isLoadingDelete ? (
        <Flex alignItems="center" justifyContent="center">
          <Spin size="large" spinning />
        </Flex>
      ) : (
        <Flex flexDir="column" gap={4}>
          {items?.length ? (
            <Box>
              <Flex fontSize="16px" fontWeight={500} mb={4}>
                <Flex flex={0.4} ml={6}>
                  Data e hora
                </Flex>
                <Flex flex={0.6}>Autor</Flex>
                <Flex flex={2} ml={4} mr={6}>
                  Descrição
                </Flex>
              </Flex>
              {items?.map((item, index) => (
                <CardDefault
                  rounded="8px"
                  padding="6px 20px"
                  mb={4}
                  key={item.id}
                >
                  <Flex align="center">
                    <Text flex={0.43}>
                      {moment(item.createdAt).format("DD/MM/YYYY [às] HH:mm")}
                    </Text>
                    <Text
                      flex={0.63}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {item.autor.nome}
                    </Text>
                    <Text
                      ml={4}
                      flex={2}
                      fontSize="14px"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      mr={6}
                    >
                      {item.observacao}
                    </Text>
                    <Flex h="fit-content" py={4}>
                      <ActionsKebabMenu
                        indexItem={index}
                        item={item}
                        actions={[
                          {
                            title: "Visualizar",
                            onClick: () => setIIdObservacao(item.id),
                            icon: () => <RiInboxUnarchiveFill />,
                          },
                          {
                            title: "Excluir",
                            textColor: `${colors.red500}`,
                            onClick: (v) => handleDelete(v.id),
                            icon: () => <IoMdTrash />,
                          },
                        ]}
                      />
                    </Flex>
                  </Flex>
                </CardDefault>
              ))}
            </Box>
          ) : (
            <EmptyDefault size="sm" fontSize="12px" />
          )}
        </Flex>
      )}
    </>
  );
}
