import { showError } from "core/infra/helpers/alerta";
import { useAuthStore } from "core/features/auth/auth.store";

export function checkPermission(
  permission: string | string[],
  message?: string | true
) {
  const { user } = useAuthStore();

  if (!user) {
    return false;
  }

  if (Array.isArray(permission)) {
    const hasPermission = user.permissoes?.some((p: any) =>
      permission.includes(p)
    );

    if (!hasPermission) {
      if (typeof message === "string") {
        showError(message);
      } else if (message) {
        showError("Sem permissão para realizar esta operação!");
      }

      return false;
    }

    return true;
  }

  const hasPermission = user.permissoes?.includes(permission);

  if (!hasPermission) {
    if (typeof message === "string") {
      showError(message);
    } else if (message) {
      showError("Sem permissão para realizar esta operação!");
    }

    return false;
  }

  return true;
}

export function checkOrPermission(permission: string[]) {
  const { user } = useAuthStore();

  if (!user) {
    return false;
  }

  return user.permissoes?.some((p: any) => permission.includes(p));
}
