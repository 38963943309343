import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { UsuarioResponse } from "../typings";
import { createFormData } from "core/infra/helpers/createFormData";

function useSaveUsuario() {
  return useMutation(
    async (form: UsuarioResponse) => {
      const formData = createFormData(
        form.certificado || [],
        form,
        "certificado"
      );
      if (form.id) {
        const { data } = await http.put<{ response: string }>(
          `/v1/usuario/${form.id}`,
          formData
        );
        return data;
      }
      const { data } = await http.post<{ response: string }>(
        `/v1/usuario`,
        formData
      );
      return data;
    },

    { retry: false }
  );
}

export default useSaveUsuario;
