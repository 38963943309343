import { useEffect, useState } from "react";
import { Box, Flex, Input, Text } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import { UseFormSetValue, useForm } from "react-hook-form";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import Field from "ui/components/Input/Field";
import { BsPlusLg } from "react-icons/bs";
import ModalCriterio from "../ModalCriterio";
import CardCriterio from "../CardCriterio/index";
import Toolbar from "ui/components/Toolbar";
import SelectSetor from "ui/components/Input/SelectSetor";
import FooterModalDefault from "ui/components/FooterModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  AndamentosResponse,
  FluxoProcessoResponse,
} from "core/features/tipoprocesso/typings";
import DeleteButton from "ui/components/Buttons/DeleteButton";
import EmptyDefault from "ui/components/Empty";

interface ModalAndamentoProps {
  isOpen: boolean;
  onClose: () => any;
  isEdit?: boolean;
  onSubmit: (item: AndamentosResponse) => any;
  defaultData?: Partial<AndamentosResponse>;
  setValueAndamento: UseFormSetValue<FluxoProcessoResponse>;
  formAndamento: FluxoProcessoResponse;
  hasPermission: boolean;
}

const defaultValues: AndamentosResponse = {
  fluxosAndamentoCriterios: [],
  setorId: "",
  prazo: null,
};

function ModalAndamento({
  isOpen,
  onClose,
  onSubmit,
  defaultData,
  setValueAndamento,
  formAndamento,
  hasPermission,
}: ModalAndamentoProps) {
  const [indexEdit, setIndexEdit] = useState<number | undefined>();
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (indexEdit !== undefined) {
      setIsOpenModal(true);
    }
  }, [indexEdit]);

  useEffect(() => {
    if (!isOpenModal) {
      setIndexEdit(undefined);
    }
  }, [isOpenModal]);

  const { control, reset, handleSubmit, setValue, watch } =
    useForm<AndamentosResponse>({
      defaultValues,
    });

  const form = watch();

  useEffect(() => {
    if (isOpen) {
      reset({
        ...defaultValues,
        ...defaultData,
      });
      const sortedItems = defaultData?.fluxosAndamentoCriterios?.sort(
        (a, b) => a.ordemCriterio! - b.ordemCriterio!
      );
      setValue("fluxosAndamentoCriterios", sortedItems || []);
    }
  }, [isOpen, reset, defaultData]);

  const onRemoveItem = (index: number, id: string) => {
    const copy = [...form.fluxosAndamentoCriterios];
    const removedItem = copy.splice(index, 1)[0];
    copy.forEach((item, i) => {
      item.ordemCriterio = i;
    });
    const criteriosIdsExcluidos = formAndamento.criteriosIdsExcluidos
      ? [...formAndamento.criteriosIdsExcluidos]
      : [];
    if (removedItem && removedItem.id) {
      criteriosIdsExcluidos.push(removedItem.id);
    }
    setValue("fluxosAndamentoCriterios", copy);
    setValueAndamento("criteriosIdsExcluidos", criteriosIdsExcluidos);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(form.fluxosAndamentoCriterios);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, {
      ...reorderedItem,
      ordemCriterio: result.destination.index,
    });

    items.forEach((item, index) => {
      item.ordemCriterio = index;
    });

    setValue("fluxosAndamentoCriterios", items);
  };

  const defaultDataModal =
    indexEdit !== undefined
      ? form.fluxosAndamentoCriterios?.[indexEdit]
      : undefined;

  return (
    <Modal
      open={isOpen}
      title={<Toolbar title="Andamento" onClose={onClose} />}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Salvar"
          onclickPrimaryButton={() => handleSubmit(onSubmit)()}
        />
      }
      closable={false}
      width="700px"
    >
      <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
        <Box>
          <Box as={Row} gutter={16} mb={8}>
            <Box as={Col} sm={18}>
              <Field
                name="setorId"
                label="Setor"
                control={control}
                rules={{ required: true }}
                render={({ field, isInvalid }) => (
                  <SelectSetor isInvalid={isInvalid} {...field} />
                )}
              />
            </Box>
            <Box as={Col} sm={6}>
              <Field
                name="prazo"
                label="Prazo"
                control={control}
                rules={{ required: true }}
                render={({ field, isInvalid }) => (
                  <Input type="number" isInvalid={isInvalid} {...field} />
                )}
              />
            </Box>
          </Box>

          <Flex mb={4} gap={10} align="center">
            <Text fontWeight="semibold" fontSize="18px">
              Critérios
            </Text>
            <OutlineButton onClick={() => setIsOpenModal(true)}>
              <BsPlusLg size={20} />
              Adicionar
            </OutlineButton>
          </Flex>
          {!form.fluxosAndamentoCriterios.length ? <EmptyDefault /> : null}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="lista-criterios" direction="vertical">
              {(provided) => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                  {form.fluxosAndamentoCriterios.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={`criterio-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Box as={Row} gutter={16} mb={2}>
                            <Box as={Col} sm={hasPermission ? 20 : 24}>
                              <CardCriterio
                                item={{ ...item, ordemCriterio: index }}
                              />
                            </Box>
                            {hasPermission ? (
                              <Box as={Col} sm={4}>
                                <DeleteButton
                                  h="35px"
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    onRemoveItem(index, item.id!);
                                  }}
                                />
                              </Box>
                            ) : null}
                          </Box>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>

          {isOpenModal ? (
            <ModalCriterio
              isOpen={isOpenModal}
              onClose={() => setIsOpenModal(false)}
              isEdit={indexEdit !== undefined}
              defaultData={defaultDataModal}
              onSubmit={(item) => {
                const items = form.fluxosAndamentoCriterios;

                if (indexEdit !== undefined) {
                  const copy = [...items];

                  copy[indexEdit] = item;

                  setValue("fluxosAndamentoCriterios", copy);
                } else {
                  const newItems = items.concat({
                    ...item,
                    ordemCriterio: items.length,
                  });

                  setValue("fluxosAndamentoCriterios", newItems);
                }
                setIsOpenModal(false);
              }}
            />
          ) : null}
        </Box>
      </form>
    </Modal>
  );
}

export default ModalAndamento;
