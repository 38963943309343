import { Select, SelectProps } from "antd";
import SelectBaseFiltro from "../SelectBaseFiltro";

const { Option } = Select;

export interface SeletctStatusFiltroProps<T = any> extends SelectProps<T> {
  value: any;
  onChange: (v: any) => void;
}

function SeletctStatusFiltro({ value, ...rest }: SeletctStatusFiltroProps) {
  return (
    <SelectBaseFiltro
      size="large"
      getPopupContainer={(node) => node.parentNode}
      placeholder="Status"
      value={value}
      {...rest}
    >
      <Option value={'true'} key={"a"}>
        Ativo
      </Option>
      <Option value={'false'} key={"i"}>
        Inativo
      </Option>
    </SelectBaseFiltro>
  );
}

export default SeletctStatusFiltro;
