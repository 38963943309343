import Layout from "ui/components/Layout/index";
import { Outlet } from "react-router-dom";
import Container from "ui/components/Container";

export default function Admin() {
  return (
    <Layout>
      <Container>
        <Outlet />
      </Container>
    </Layout>
  );
}
