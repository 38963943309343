import { useQuery } from "react-query";
import http from "./useApi";
import { UserProfileResponse } from "../types";


export default function useProfile() {
    return useQuery("/v1/auth/profile", async () => {

        const { data } = await http.get<UserProfileResponse>("/v1/auth/profile");

        return data;
    });
}
