import { useQuery } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { PessoaVinculadaResponse } from "../typings";

function usePessoasVinculadas(id: string, ids?: string[]) {
  return useQuery(
    [`/v1/setor/vinculadas`, ids],
    async () => {
      if (ids && ids.length > 0) {
        const res = await http.get<PessoaVinculadaResponse[]>(
          `/v1/setor/${id}/pessoas-vinculadas`,
          {
            params: { ids },
          }
        );
        return res.data;
      }
      return [];
    },
    {
      enabled: !!ids && ids.length > 0,
    }
  );
}

export default usePessoasVinculadas;
