import { Flex } from "@chakra-ui/react";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu/index";
import { MdCancel, MdSyncAlt } from "react-icons/md";
import TagStatus from "ui/components/TagStatus";
import CardPerson from "ui/components/Cards/CardPerson";
import { RiDeleteBin6Fill } from "react-icons/ri";
import colors from "core/resources/theme/colors";
import {
  PessoaVinculadaResponse,
  SetorResponse,
} from "core/features/setor/typings";
import { UseFormSetValue } from "react-hook-form";
import { showConfirm3, showError } from "core/infra/helpers/alerta";

interface TableItemRowProps {
  item: PessoaVinculadaResponse;
  indexItem: number;
  setValue: UseFormSetValue<SetorResponse>;
  form: SetorResponse;
  refetch: any;
}

export default function TableItemRow({
  item,
  indexItem,
  setValue,
  form,
  refetch,
}: TableItemRowProps) {
  const isResponsavel = !!form.responsavelId
    ? form.responsavelId === item.id
    : item.responsavel;

  const toggleResponsavel = async () => {
    const newResponsavelId = isResponsavel ? "" : item.id;
    setValue("responsavelId", newResponsavelId);
    await refetch();
  };

  const RemovePessoaVinculada = async (idRemove: string) => {
    const { isConfirmed } = await showConfirm3({
      mensagem: "Deseja realmente excluir ?",
    });

    if (isConfirmed) {
      const removePessoaVinculada = form.pessoasVinculadasIds.filter(
        (id) => id !== idRemove
      );
      setValue("pessoasVinculadasIds", removePessoaVinculada);
      await refetch();
    }
  };

  return (
    <Flex
      rounded={8}
      px={5}
      py={2}
      mb={2}
      bg="#FFFFFF"
      boxShadow="base"
      align="center"
    >
      <Flex fontSize="14px" flex={1} gap={4} align="center">
        <CardPerson
          avatarUrl={item.imagemUrl}
          avatarProps={{ width: "40px", height: "40px" }}
        />
        {item.nome}
      </Flex>
      <Flex fontSize="14px" flex={1}>
        {item.perfil}
      </Flex>
      <Flex flex={0.3}>
        {isResponsavel ? (
          <TagStatus title="Responsável" colorScheme="whatsapp" />
        ) : null}
      </Flex>
      <Flex h="fit-content" py={4}>
        <ActionsKebabMenu
          indexItem={indexItem}
          item={item}
          actions={
            [
              isResponsavel
                ? null
                : {
                    title: "Tornar responsável",
                    icon: () => (isResponsavel ? <MdCancel /> : <MdSyncAlt />),
                    onClick: toggleResponsavel,
                  },
              {
                title: "Excluir vínculo",
                textColor: colors.red500,
                icon: () => <RiDeleteBin6Fill />,
                onClick: (v: PessoaVinculadaResponse) =>
                  isResponsavel
                    ? showError(
                        "Não é permitido excluir o responsável pelo setor"
                      )
                    : RemovePessoaVinculada(v.id),
              },
            ].filter(Boolean) as {
              title: string;
              icon?: () => JSX.Element;
              onClick: (_v: PessoaVinculadaResponse, index?: number) => void;
              textColor?: string;
            }[]
          }
        />
      </Flex>
    </Flex>
  );
}
