import { Box, Center, Flex, Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { AiOutlineArrowLeft} from "react-icons/ai";
import { MdClose } from "react-icons/md";

function Toolbar({
  title,
  onBack,
  rightElement,
  onClose,
  
  alignItems,
}: {
  title: string;
  onBack?: () => void;
  rightElement?: React.ReactNode;
  onClose?: () => any;
  border?: string;
  alignItems?: "center" | "flex-start" | "flex-end";
}) {
  return (
    <Box>
      <Box
        borderTopRadius="15px"
        backgroundColor={colors.primary}
        padding="14px"
        marginBottom="0px"
      >
        <Flex flexDir="row" gap="10px" alignItems={alignItems}>
          {typeof onBack === "function" ? (
            <Center>
              <AiOutlineArrowLeft
                color="white"
                fontSize="20px"
                onClick={onBack}
                cursor="pointer"
              />
            </Center>
          ) : null}

          <Text fontWeight='medium' fontSize="18px" color="white">
            {title}
          </Text>

          {rightElement}

          {typeof onClose === "function" ? (
            <Center ml="auto">
              <MdClose
                color="white"
                size={25}
                onClick={onClose}
                cursor="pointer"
              />
            </Center>
          ) : null}
        </Flex>
      </Box>
    </Box>
  );
}

export default Toolbar;
