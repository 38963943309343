import { Box, Flex, Image } from "@chakra-ui/react";
import { useWindowSize } from "core/infra/helpers/window";
import Container from "ui/components/Container";
import logo from "assets/images/svg/logo-fluir.svg";
import { useMenu } from "core/features/menu";
import MenuUser from "./components/MenuUser/index";
import HeaderMenu from "./components/HeaderMenu";
import ResponsiveMenu from "./components/ResponsiveMenu";
import colors from "core/resources/theme/colors";
import NotificationMenu from "./components/NotificationMenu/index";

export default function Header() {
  const { menu } = useMenu();

  const windowSize = useWindowSize();
  const isDesktop = windowSize.width > 992;
  const isMobile = windowSize.width <= 992;

  return (
    <Flex bg={colors.primary} h="70px">
      <Container>
        <Flex flex="1" align="center" mt={3}>
          <Flex flex={isMobile ? 1 : undefined} alignItems="center" py={5}>
            <Image className="logo" w="160px" src={logo} position="absolute" />
          </Flex>

          {isDesktop ? (
            <Flex flex="1" justifyContent="center">
              <Box>
                <HeaderMenu menus={menu()} />
              </Box>
            </Flex>
          ) : null}
          <Flex mb={isDesktop ? 3 : 0}>
            <NotificationMenu />
          </Flex>

          {isDesktop ? (
            <Flex mb={3}>
              <MenuUser />
            </Flex>
          ) : null}
          {isMobile ? <ResponsiveMenu items={menu()} /> : null}
        </Flex>
      </Container>
    </Flex>
  );
}
