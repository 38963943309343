import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { ProcessoAssociadoResponse } from "../typings";

function useSaveAssociado(idProcesso?: string) {
  return useMutation(
    (data: ProcessoAssociadoResponse) => {
      return http.post<{ response: string }>(
        `/v1/processo/${idProcesso}/associado`,
        data
      );
    },
    { retry: false }
  );
}

export default useSaveAssociado;
