import { Flex, Text } from "@chakra-ui/react";
import { Select, SelectProps, Spin } from "antd";
import { useEffect, useRef } from "react";

import { Container, Global } from "./styles";
import EmptyDefault from "ui/components/Empty";

const { Option } = Select;

export interface SelectBaseAntdProps<T = any> extends SelectProps<T> {
  isInvalid?: boolean;
  bg?: string;
}

function SelectBaseAntd({ isInvalid, ...props }: SelectBaseAntdProps) {
  const { onChange, defaultValue } = props;
  const options = props.options?.map((d) => (
    <Option
      value={d.value}
      key={d.value}
      disabled={d.disabled ? d.disabled : null}
    >
      {d.label}
    </Option>
  ));
  const init = useRef(false);

  useEffect(() => {
    if (defaultValue && !init.current) {
      init.current = true;
      onChange?.(defaultValue, { value: defaultValue } as any);
    }
  }, [defaultValue, onChange]);

  return (
    <Container bg={props.bg} isInvalid={isInvalid}>
      <Global isInvalid={isInvalid} />
      <Select
        getPopupContainer={(triggerNode: any) => {
          return triggerNode.parentNode;
        }}
        allowClear
        {...props}
        placeholder={props.placeholder ?? "--selecione--"}
        value={props.value || undefined}
        defaultValue={props.value || undefined}
        autoClearSearchValue
        style={{ width: "100%", ...(props.style ?? {}) }}
        onClear={() => {
          if (typeof onChange === "function") {
            if (props.mode === "tags" || props.mode === "multiple") {
              onChange([], [] as any);
            } else {
              onChange(undefined, undefined as any);
            }
          }
        }}
        options={props.options}
        notFoundContent={
          props.loading ? (
            <Flex
              flex={1}
              py="32px"
              justifyContent={"center"}
              alignItems={"center"}
              flexDir={"column"}
            >
              <Spin spinning />
              <Text>Carregando...</Text>
            </Flex>
          ) : props.notFoundContent ? (
            props.notFoundContent
          ) : (
            <EmptyDefault fontSize="14px" />
          )
        }
      >
        {props.children ?? options}
      </Select>
    </Container>
  );
}

export default SelectBaseAntd;
