import { Box, Text } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import { FiltrosProcessoDocumentos } from "core/features/processos-documento/typings";
import { useEffect, useState } from "react";
import DateRangePick from "ui/components/DateRangePick";
import FooterModalDefault from "ui/components/FooterModal";
import InputDefault from "ui/components/Input/InputDefault";
import Toolbar from "ui/components/Toolbar";

interface ModalFiltrosDocumentosProps {
  onClose: () => void;
  onReset: () => void;
  filtro: FiltrosProcessoDocumentos;
  visible: boolean;
  onChangeFiltro: (_filtro: Partial<FiltrosProcessoDocumentos>) => void;
}

export default function ModalFiltrosDocumentos({
  onClose,
  visible,
  onChangeFiltro,
  onReset,
  filtro,
}: ModalFiltrosDocumentosProps) {
  const [localFiltro, setLocalFiltro] =
    useState<Partial<FiltrosProcessoDocumentos>>(filtro);

  useEffect(() => {
    if (!visible) {
      setLocalFiltro(filtro);
    }
  }, [visible, filtro]);

  return (
    <Modal
      destroyOnClose
      closable={false}
      maskClosable={false}
      centered
      width="700px"
      open={visible}
      className="antd-custom-header"
      title={<Toolbar title="Filtrar" onClose={onClose} />}
      footer={
        <FooterModalDefault
          titleOutlineButton="Fechar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Filtrar"
          onclickPrimaryButton={() => {
            onChangeFiltro(localFiltro);
            onClose();
          }}
          onclickGhostButton={() => {
            onReset();
            onClose();
          }}
          titleGhostButton="Limpar filtros"
        />
      }
    >
      <Box as={Row} gutter={16} mb={4}>
        <Col md={12}>
          <Text mb="2px" fontSize="14px">
            Período
          </Text>

          <DateRangePick
            dataInicio={localFiltro.dataInicio ? localFiltro.dataInicio : null}
            dataFim={localFiltro.dataFim ? localFiltro.dataFim : null}
            onChange={(value: any) => {
              setLocalFiltro({
                ...localFiltro,
                dataInicio: value[0] ? value[0].toISOString() : null,
                dataFim: value[1] ? value[1].toISOString() : null,
              });
            }}
          />
        </Col>
      </Box>

      <Box mb={4}>
        <Col md={24}>
          <Text mb="2px" fontSize="14px">
            Descrição
          </Text>
          <InputDefault
            onChange={(e) => {
              setLocalFiltro((state) => ({
                ...state,
                descricao: e.target.value,
              }));
            }}
            value={localFiltro.descricao}
          />
        </Col>
      </Box>
    </Modal>
  );
}
