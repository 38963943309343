import { Flex,Text } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";

interface ContentItemProps {
  titulo: string;
  descricao?: React.ReactNode;
}

export default function ContentItem(props: ContentItemProps) {
  return (
    <Flex direction="column" mb="3">
      <Text color={colors.gray400} fontSize="12px">
        {props.titulo}
      </Text>
      <Text>{props.descricao}</Text>
    </Flex>
  );
}
