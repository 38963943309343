import { createGlobalStyle } from "styled-components";
import colors from "core/resources/theme/colors";

const GlobalStyle = createGlobalStyle`


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    overflow-x: hidden;
  }

body{
    width: 100vw;
    height: 100vh;
    background-color: white !important;
    font-family:'Poppins',sans-serif !important;
}


h1 {
  font-size: 24px;
}
h2 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}


h1,
h2,
h3,
h4,
h5,
h6,b {
    color:${colors.gray500}
}

a{
    font-size: 14px;
    font-weight: 500;
    color:${colors.primary}
}

b{
    font-size: 14px;
    font-weight: 500;  
}


//ant modal
.ant-modal-content {
  border-radius: 15px !important;
  padding: 0 !important;
}

.ant-modal-header {
  border-radius: 15px !important;
}

.ant-modal-body{
  padding: 15px 25px  ;
}

.ant-modal-footer{
  padding-bottom: 15px;
}


//sweetalert2 

.swal2-title{
  height: 170px !important;
  padding: 0px !important;
  border-radius: 15px 15px 0px 0px !important;
}

.swal2-close{
  margin-top: 20px !important;
  margin-right: 20px !important;
  color: black !important;
  height: 30px !important;
  width: 30px !important;
  position: absolute !important;
  align-content: center !important;
  align-items: center !important;
  font-size: 20px !important;
  background-color: white !important; 
  border-radius : 4px  !important;
  font-family:'Monospace',sans-serif !important;
}

.swal2-close:hover{
  background-color: white !important;  
}

.swal2-actions{
  display: flex !important;
  flex: 1 !important;
  justify-content: end !important;
  height: 75px !important;
  width: 100% !important;
  margin:0 !important;
  padding:5px 5px 0px 0px !important;
  border-top: solid 1px #E2E8F0 !important;
}

  .swal2-confirm.swal2-styled{
  height: 38px !important;
  padding: 8px 16px !important;
  border-radius: 8px !important;
  color: black !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 10px 20px 12px 0!important;

}




.swal2-confirm.swal2-styled:focus{
box-shadow: none !important;
}


.swal2-confirm.swal2-styled.swal2-default-outline{
  height: 38px !important;
  font-size: 14px !important;
  border-radius: 8px !important;
  color:white !important;
  padding: 8px 16px !important;
  margin-left: 10px !important;
 
}


.swal2-cancel.swal2-styled.swal2-default-outline{
  height: 38px !important;
  font-size: 14px !important;
  border-radius: 8px !important;
  font-weight: 400 !important;
  color: black !important;
  padding: 8px 16px !important;
  
}
.swal2-popup.swal2-modal.swal2-show{
  padding:0 !important;
  border-radius: 15px !important;
}



.swal2-popup.swal2-modal.swal2-loading.swal2-show{
  padding: 0 !important;
 width: max-content !important;
}



.swal2-actions.swal2-loading{
  padding: 0 !important;
  margin: auto !important;
  justify-content: center !important;
}

.swal2-loader{
  width: 70px !important;
  height: 70px !important;
  border-color:${colors.primary} rgba(0,0,0,0) ${colors.primary} rgba(0,0,0,0) !important;
}



//ANT checkbox

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: ${colors.primary}!important;
}

.ant-checkbox-checked:after {
  border-color: ${colors.primary}!important;
}

.ant-checkbox-wrapper {border-color: ${colors.primary}!important;}

.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  background-color: ${colors.primary}!important;
}

.ant-checkbox-checked 
.ant-checkbox-inner {
  background-color: ${colors.primary}!important;
    border-color: ${colors.primary}!important;
}

.ant-tree 
.ant-tree-checkbox-checked 
.ant-tree-checkbox-inner {
    background-color: ${colors.primary}!important;
    border-color: ${colors.primary}!important;
}

.ant-tree 
.ant-tree-checkbox-checked 
.ant-tree-checkbox-inner {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
}

.ant-tree 
.ant-tree-checkbox-wrapper:not(.ant-tree-checkbox-wrapper-disabled)
:hover .ant-tree-checkbox-inner,.ant-tree .ant-tree-checkbox:not(.ant-tree-checkbox-disabled)
:hover .ant-tree-checkbox-inner {
    border-color: ${colors.primary};
}

.ant-tree-checkbox-wrapper:not(.ant-tree-checkbox-wrapper-disabled):hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:not(.ant-tree-checkbox-disabled):hover .ant-tree-checkbox-inner {
    border-color: ${colors.primary};
}

.ant-tree-checkbox-wrapper-checked:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-checked:hover .ant-tree-checkbox-inner {
  background-color: ${colors.primary};
  border-color: transparent;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: ${colors.primary}!important;
}

//CK EDITOR

.ck-editor-height-1 .ck-content {
  min-height: 300px;
}

.ck-editor-height-2 .ck-content {
  min-height: 150px;
}

.ck-editor__main {
  cursor: text;
}

.ck-editor h1 {
  font-size: 20px;
  font-weight: bold;
}

.ck-editor h2 {
  font-size: 18px;
  font-weight: medium;
}

.ck-editor h3 {
  font-size: 16px;
}

.ck-editor p {
  font-size: 14px;
}

.ck-editor ul {
  padding-left: 16px;
}

.ck-editor ol {
  padding-left: 16px;
}

.ck-editor ol li {
  padding-left: 4px;
}

.ck-editor blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}

.ck-font-size-dropdown > .ck-dropdown__panel {
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
  border-bottom-left-radius:15px ;
  border-bottom-right-radius:15px ;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar{
  border-top-left-radius:15px ;
  border-top-right-radius:15px ;
}


//Switch chakra ui

.chakra-switch__track{
  background:${colors.primary} !important;
}

`;

export default GlobalStyle;
