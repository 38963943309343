import { create } from "zustand";
import { UserResponse } from "./types";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import http from "./hooks/useApi";
import config from "core/infra/config";
import { showError } from "core/infra/helpers/alerta";
import { useResetPasswordStore } from "./hooks/useResetPasswordStore";
import { globalNavigate } from "ui/components/GlobalHistory";

export const userPool = new CognitoUserPool({
  UserPoolId: config.cognito.userPoolId!,
  ClientId: config.cognito.clientId!,
});

interface AuthStore {
  user: UserResponse | null;
  setUser: (user: UserResponse | null) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  signin: (email: string, password: string) => Promise<boolean>;
  signout: () => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  isLoading: true,
  signin: async (email: string, password: string) => {
    set({ isLoading: true });

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const user = new CognitoUser(userData);

    try {
      const userSession = await new Promise<CognitoUserSession>(
        (resolve, reject) => {
          return user.authenticateUser(authenticationDetails, {
            newPasswordRequired: () => {
              useResetPasswordStore.getState().setUserInfo({ user });
              globalNavigate("/auth/reset-password");
              set({ isLoading: false });
            },
            onSuccess: (result) => {
              resolve(result);
            },
            onFailure: async (err) => {
              let errorMessage;
              if (err.message === "Incorrect username or password.") {
                errorMessage = "Usuário ou senha incorretos";
              } else if (err.message === "Password attempts exceeded") {
                errorMessage =
                  "Limite de tentativas excedidas. Aguarde e tente novamente mais tarde.";
              } else if (
                err.message ===
                "Temporary password has expired and must be reset by an administrator."
              ) {
                errorMessage =
                  "A senha temporária expirou. Cliquem em 'Esqueci minha senha' e recupere a sua senha.";
              } else {
                errorMessage = err.message;
              }

              showError(errorMessage);
              reject(err);
            },
          });
        }
      );

      if (userSession.isValid()) {
        const { data } = await http.get<UserResponse>("/v1/auth/me");
        set({
          user: {
            id: data?.id,
            name: data.name,
            email: data.email,
            imagemUrl: data.imagemUrl,
            permissoes: data?.permissoes,
          },
          isLoading: false,
        });
        return true;
      }
    } catch (error: any) {
      let errorMessage;
      if (error.message === "Incorrect username or password.") {
        errorMessage = "Usuário ou senha incorretos";
      } else if (error.message === "Password attempts exceeded") {
        errorMessage =
          "Limite de tentativas excedidas. Aguarde e tente novamente mais tarde.";
      } else if (
        error.message ===
        "Temporary password has expired and must be reset by an administrator."
      ) {
        errorMessage =
          "A senha temporária expirou. Cliquem em 'Esqueci minha senha' e recupere a sua senha.";
      } else {
        errorMessage = error.message;
      }

      showError(errorMessage);
    }

    set({ isLoading: false });
    return false;
  },
  signout: () => {
    const user = userPool.getCurrentUser();
    if (user) {
      user.signOut(() => {
        set({ user: null });
      });
    }
  },
  setUser: (newUser) => {
    set({ user: newUser });
  },
  setIsLoading: (value) => {
    set({ isLoading: value });
  },
}));
