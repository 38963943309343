import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { FluxoProcessoResponse } from "../typings";


function useSaveFluxoProcesso(id?: string) {
  return useMutation(
    (data: FluxoProcessoResponse) => {
      if (id) {
        return http.put<{ response: string }>(
          `/v1/fluxo-processo/${id}`,
          data
        );
      }
      return http.post<{ response: string }>(`/v1/fluxo-processo`, data);
    },
    { retry: false }
  );
}

export default useSaveFluxoProcesso;
