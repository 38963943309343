import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { ProcessoResponse } from "../typings";

function useSaveProcesso() {
  return useMutation(
    (data: ProcessoResponse) => {
      return http.post<{ response: string }>(`/v1/processo`, data);
    },
    { retry: false }
  );
}

export default useSaveProcesso;
