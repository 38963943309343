import { Navigate } from "react-router-dom";
import { useAuthStore } from "./auth.store";
import { Flex } from "@chakra-ui/react";
import lottieLoading from 'assets/lotties/LottieLoading.json';
import Lottie from "lottie-react-web";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user, isLoading } = useAuthStore();


  if (isLoading) {
    return (
      <Flex
        bg='white'
        flex={1}
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        justifyContent="center"
        alignItems="center"
      >
        <Lottie
          width={300}
          options={{
            animationData: lottieLoading,
          }}
        />
      </Flex>)
  }

  if (!user) {
    return <Navigate to={"/auth/signin"} replace />;
  }

  return children;
};
