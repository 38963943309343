import colors from "core/resources/theme/colors";
import styled from "styled-components";
interface FieldContainerProps {
  borderColor?: string;

  invalid: boolean;
}

export const FieldContainer = styled.div<any>`
  width: 100%;
  font-family: "Poppins", sans-serif;

  .chakra-input {
    font-size: ${(props) => props.fontSize}px !important;
    background-color: ${colors.labelBg};
    border-color: ${colors.labelBg};
  }
  .chakra-input:hover,
  .chakra-input:focus {
    color: ${colors.gray700};

    border-color: ${({
      borderColor = colors.gray300,
      invalid,
    }: FieldContainerProps) =>
      invalid ? colors.red200 : borderColor} !important;
    box-shadow: 0 0 0 1px
      ${({ borderColor = colors.gray300, invalid }: FieldContainerProps) =>
        invalid ? colors.red600 : borderColor} !important;
    transition: all 0.2s;
  }

  .chakra-input:disabled {
    opacity: 0.7;
  }
`;
