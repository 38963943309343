import { useState, useEffect } from "react";
import { Modal, Col, Row } from "antd";
import { Box, Text } from "@chakra-ui/react";
import SelectOrderBy from "ui/components/Input/SelectOrderBy";
import SelectOrderDirection from "ui/components/Input/SelectOrderDirection";
import Toolbar from "ui/components/Toolbar";
import FooterModalDefault from "ui/components/FooterModal";

interface ModalOrderByProps {
  onClose: () => void;
  openModal: boolean;
  onOrderDirectionChange: (q?: string) => void;
  orderDirection?: string | undefined;
  onOrderByChange: (q?: string) => void;
  orderBy?: string | undefined;
  options: {
    value: string;
    label: string;
  }[];
}

export default function ModalOrderBy({
  onClose,
  openModal,
  orderDirection,
  onOrderDirectionChange,
  orderBy,
  onOrderByChange,
  options,
}: ModalOrderByProps) {
  const [localFiltro, setLocalFiltro] = useState({
    orderBy,
    orderDirection,
  });

  useEffect(() => {
    if (!openModal) {
      setLocalFiltro({
        orderBy,
        orderDirection,
      });
    }
  }, [openModal, orderBy, orderDirection]);

  return (
    <Modal
      destroyOnClose
      closable={false}
      maskClosable={false}
      centered
      className="antd-custom-header"
      title={<Toolbar title="Ordenar listagem" onClose={onClose} />}
      width="500px"
      open={openModal}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Ordenar"
          onclickPrimaryButton={() => {
            onOrderDirectionChange(localFiltro.orderDirection);
            onOrderByChange(localFiltro.orderBy);
            onClose();
          }}
        />
      }
    >
      <Text mb={4} fontWeight="bold" fontSize="16px">
        Selecione o tipo de ordenação
      </Text>
      <Box as={Row} gutter={16} mb={4}>
        <Col md={12}>
          <SelectOrderBy
            allowClear={false}
            value={localFiltro.orderBy}
            onChange={(v: any) =>
              setLocalFiltro((state) => ({ ...state, orderBy: v }))
            }
            options={options}
          />
        </Col>
        <Col md={12}>
          <SelectOrderDirection
            allowClear={false}
            value={localFiltro.orderDirection}
            onChange={(v) =>
              setLocalFiltro((state) => ({ ...state, orderDirection: v }))
            }
          />
        </Col>
      </Box>
    </Modal>
  );
}
