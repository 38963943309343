import { FiltrosProcessoDocumentos } from "core/features/processos-documento/typings";
import { BaseFiltroPaginate } from "core/resources/enums/paginate";
import moment from "moment";
import { useCallback, useState } from "react";

export const filtroDefaultDocumentos: FiltrosProcessoDocumentos = {
  limit: BaseFiltroPaginate.Limit,
  search: "",
  page: 1,
  dataInicio: moment("2022-01-01").format("YYYY-MM-DD"),
  dataFim: moment().add(2, "days").format("YYYY-MM-DD"),
  descricao: "",
  assinado: undefined,
};

function useFiltroDocumentos() {
  const [filtro, setFiltro] = useState<FiltrosProcessoDocumentos>({
    ...filtroDefaultDocumentos,
  });

  const onChangeFiltro = useCallback(
    (_filtro: Partial<FiltrosProcessoDocumentos>) => {
      if (_filtro.search || _filtro.assinado !== undefined) {
        _filtro.page = 1;
      }

      setFiltro((state) => ({ ...state, ..._filtro }));
    },
    []
  );

  const onResetFiltro = useCallback(() => {
    setFiltro(filtroDefaultDocumentos);
  }, []);

  return {
    filtro,
    setFiltro,
    onChangeFiltro,
    onResetFiltro,
  };
}

export default useFiltroDocumentos;
