import {
  Flex,
  Text,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import CardPerson3 from "../Cards/CardPerson3";
import colors from "core/resources/theme/colors";

interface AvatarListProps {
  item: any;
  listLength: number;
  heigthCard?: string | number;
  widthCard?: string | number;
  fontSizeAvatar?: string;
  top?: string;
  showPopover?: boolean;
}

export default function AvatarList({
  item,
  listLength = 4,
  widthCard = 10,
  heigthCard = 10,
  fontSizeAvatar,
  top,
  showPopover = true,
}: AvatarListProps) {
  return (
    <Flex>
      {item.slice(0, listLength).map((v: any, index: number) => (
        <Flex
          key={index}
          _hover={{ animation: "ease-in-out", transition: "1s", zIndex: 10 }}
          cursor="pointer"
          position="absolute"
          ml={index > 0 ? `${20 * index}px` : 0}
          top={top}
        >
          <CardPerson3
            nome={v.nome}
            avatarProps={{ width: widthCard, height: heigthCard }}
            fontSizeAvatar={fontSizeAvatar}
          />
        </Flex>
      ))}

      {item.length > listLength ? (
        <Popover>
          <PopoverTrigger>
            <Flex
              w={`calc(${widthCard} + 4px)`}
              h={`calc(${heigthCard} + 4px)`}
              cursor="pointer"
              align="center"
              bg={colors.primary}
              color="white"
              fontWeight="bold"
              fontSize={fontSizeAvatar}
              justify="center"
              borderRadius="full"
              position="absolute"
              border={`2px  solid ${colors.gray300}`}
              ml={`${20 * listLength}px`}
              top={top}
            >
              {`+${item.length - listLength}`}
            </Flex>
          </PopoverTrigger>
          {showPopover ? (
            <PopoverContent width="100%" bg={colors.gray700} zIndex={2000}>
              <PopoverArrow bg={colors.gray700} />
              <PopoverBody>
                {item
                  .slice(listLength, item.length)
                  .map((v: any, index: number) => (
                    <Flex
                      flexDir="column"
                      key={index}
                      _hover={{ opacity: 0.8 }}
                      cursor="pointer"
                      mb={2}
                    >
                      <Flex align="center" gap={2}>
                        <CardPerson3
                          nome={v.nome}
                          avatarProps={{ width: 10, height: 10 }}
                        />
                        <Flex flexDir="column">
                          <Text
                            fontSize="14px"
                            fontWeight="medium"
                            color="white"
                          >
                            {v.nome}
                          </Text>
                          {v.cargo ? (
                            <Text
                              fontSize="14px"
                              fontWeight="medium"
                              color="white"
                            >
                              {v.cargo}
                            </Text>
                          ) : null}
                        </Flex>
                      </Flex>
                    </Flex>
                  ))}
              </PopoverBody>
            </PopoverContent>
          ) : null}
        </Popover>
      ) : null}
    </Flex>
  );
}
