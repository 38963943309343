import { Box, Flex, Text } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import CardDefault from "ui/components/Cards/CardDefault";
import Field from "ui/components/Input/Field";
import InputPassword from "ui/components/Input/InputPassword";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { showSuccess } from "core/infra/helpers/alerta";
import { userPool } from "core/features/auth/auth.store";

export default function TrocarSenha() {
  const { control, handleSubmit, watch } = useForm<{
    oldPassoword: string;
    newPassword: string;
    confirm: string;
  }>({
    reValidateMode: "onChange",
  });

  const form = watch();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowshowPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);
  const navigate = useNavigate();

  const handleReset = async () => {
    if (form.newPassword === form.confirm) {
      const userReset = userPool.getCurrentUser();

      if (userReset) {
        userReset.getSession((error: Error) => {
          if (error) {
            console.error("Erro ao alterar a senha:", error);
          } else {
            userReset.changePassword(
              form.oldPassoword,
              form.newPassword,
              (error, result) => {
                if (error) {
                  console.error("Erro ao alterar a senha:", error.message);
                } else {
                  showSuccess("Senha alterada com sucesso!").then(() => {
                    navigate("/");
                  });
                }
              }
            );
          }
        });
      }
    }
  };

  return (
    <form onSubmit={(ev) => handleSubmit(handleReset)(ev)}>
      <CardDefault mb={4}>
        <Text fontWeight="semibold" fontSize="18px" mb={4}>
          Trocar senha
        </Text>

        <Box as={Row}>
          <Box as={Col} md={12} mb={4}>
            <Field
              name="oldPassoword"
              label="Senha atual"
              rules={{
                required: "Este campo é obrigatório",
              }}
              control={control}
              render={({ field }) => (
                <InputPassword
                  showPassword={showOldPassword}
                  onClick={handleClickShowOldPassword}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </Box>
        </Box>
        <Box as={Row}>
          <Box as={Col} md={12} mb={4}>
            <Field
              name="newPassword"
              label="Nova senha"
              rules={{
                required: "Este campo é obrigatório",
                minLength: {
                  value: 8,
                  message: "A senha deve ter no mínimo 8 caracteres",
                },
                pattern: {
                  value: /^(?=.*[A-Z])(?=.*\d)/,
                  message:
                    "A senha deve conter pelo menos uma letra maiúscula e um número",
                },
              }}
              control={control}
              render={({ field }) => (
                <InputPassword
                  showPassword={showPassword}
                  onClick={handleClickShowPassword}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </Box>
        </Box>
        <Box as={Row}>
          <Box as={Col} md={12} mb={4}>
            <Field
              name="confirm"
              label="Repetir a nova senha"
              rules={{
                required: "Campo obrigatório",
                validate: (v) =>
                  v === form.newPassword
                    ? undefined
                    : "As senhas devem ser iguais",
              }}
              control={control}
              render={({ field }) => (
                <InputPassword
                  showPassword={showPasswordConfirm}
                  onClick={handleClickShowshowPasswordConfirm}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </Box>
        </Box>
      </CardDefault>

      <CardDefault>
        <Flex flex={1} justifyContent="flex-end" gap={4}>
          <OutlineButton onClick={() => navigate("/")}>Cancelar</OutlineButton>
          <PrimaryButton type="submit">Salvar</PrimaryButton>
        </Flex>
      </CardDefault>
    </form>
  );
}
