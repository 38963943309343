import { CognitoUser } from "amazon-cognito-identity-js";
import { create } from "zustand";

interface ResetPasswordStore {
    user: CognitoUser | null;
    setUserInfo: (userInfo: {
        user: CognitoUser | null;
    }) => void;
    reset: () => void;
}

export const useResetPasswordStore = create<ResetPasswordStore>((set) => ({
    user: null,
    setUserInfo: (userInfo) => set({ user: userInfo.user }),
    reset: () => set({ user: null }),
}));

