import { View, Document, Page, Text } from "@react-pdf/renderer";
import { Html } from "react-pdf-html";

export default function PDFTexto({ texto }: any) {
  return (
    <Document>
      <Page size="A4">
        <Text
          style={{
            borderTopWidth: 2,
            paddingTop: 2,
            fontWeight: "medium",
          }}
        ></Text>
        <Html>{texto}</Html>
      </Page>
    </Document>
  );
}
