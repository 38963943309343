export enum TabsOrganzacaoRoutes {
    InformacaoesBasicas = "informacoesBasicas",
    Endereco = "endereco",
}

export const tabs = [
    {
        label: "Informações básicas",
        route: TabsOrganzacaoRoutes.InformacaoesBasicas,
        permissions: [],
    },
    {
        label: "Endereço",
        route: TabsOrganzacaoRoutes.Endereco,
        permissions: [],
    },
];