import styled from "styled-components";

interface ContainerProps {}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  position: relative;
  margin-bottom: 37px;

  .contato-btn {
    border-radius: 14px;
    padding: 5px 12px;
    background-color: transparent;
    border: 1px solid #c4c4c4;
    color: #c4c4c4;
    font-size: 12px;
    height: max-content;
  }

  &::before {
    content: "";
    width: 1px;
    background: #dbdbdb;
    position: absolute;
    left: 11px;
    bottom: 0;
    top: 25px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    border: 3px solid #c4c4c4;
    border-radius: 50%;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
