import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { CargoResponse } from "../typings";

function useSaveCargo(tenantId: string | null) {
  return useMutation(
    (data: CargoResponse) => {
      if (data.id) {
        return http.put<{ response: string }>(
          `/v1/cargo/${tenantId}/${data.id}`,
          data
        );
      }
      return http.post<{ response: string }>(`/v1/cargo/${tenantId}`, data);
    },
    { retry: false }
  );
}

export default useSaveCargo;
