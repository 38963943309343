import { Box, Flex } from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import PageHeader from "ui/components/Page/PageHeader";
import { useBreadcrumb } from "core/features/breadcrumbs";
import SearchHeaderBar from "ui/components/SearchHeaderBar/index";
import { useNavigate } from "react-router-dom";
import useListarOrganizacoes from "core/features/organizacao/hooks/useListarOrganizacoes";
import useFiltroOrganizacoes from "./hooks/useFiltroOrganizacoes";
import SeletctStatusFiltro from "ui/components/Input/SeletctStatusFiltro";
import OrganizacaoTable from "./components/OrganizacaoTable";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { checkPermission } from "core/infra/helpers/permission";

export default function ListagemOrganizacaoPage() {
  const { filtro, onChangeFiltro } = useFiltroOrganizacoes();
  const { data, isLoading } = useListarOrganizacoes(filtro);

  const navigate = useNavigate();

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Organizações", url: "organizacao" },
  ]);

  const createPermission = [PermissaoEnum.Fluir.Administrativo.Tenant.Criar];

  const hasPermission = checkPermission(createPermission ?? []);

  return (
    <Box>
      <Box mb={10}>
        <PageHeader
          title="Organizações"
          titleButton="Criar novo"
          onClickButton={() => navigate("/organizacao/novo")}
          iconButton={<BsPlus size={25} />}
          buttonDisabled={!hasPermission}
        />

        <SearchHeaderBar
          defaultValue={filtro.search || ""}
          onSearch={(v) => onChangeFiltro({ search: v })}
          maxWInputSearch={700}
          placeholder="Buscar"
          filterStatus={
            <Flex w="100px">
              <SeletctStatusFiltro
                onChange={(ativo) => {
                  onChangeFiltro({ ativo: ativo });
                }}
                value={filtro.ativo}
              />
            </Flex>
          }
        />
      </Box>

      <OrganizacaoTable
        data={data}
        isLoading={isLoading}
        onChangeFiltro={onChangeFiltro}
      />
    </Box>
  );
}
