import { Flex } from "@chakra-ui/react";

export default function HeaderTableRow() {
  return (
    <>
      <Flex flex={1} ml={2}>
        Nome
      </Flex>
      <Flex flex={1}>Cargo</Flex>
      <Flex flex={0.28}>Status</Flex>
    </>
  );
}
