import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";


function useCriterios() {
  return useQuery(["/v1/shared/criterio"], async () => {
    const { data } = await http.get<{ id: string; nome: string }[]>(
      "/v1/shared/criterio"
    );

    return data;
  });
}

export default useCriterios;
