import { Box, Flex, Text } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import { ProcessoDocumentoResponse } from "core/features/processos-documento/typings";
import { UseFormSetValue } from "react-hook-form";
import Anexos from "ui/components/Anexos";
import ButtonAnexarArquivos from "ui/components/Anexos/ButtonAnexarArquivos";
import FooterModalDefault from "ui/components/FooterModal";
import Toolbar from "ui/components/Toolbar";
import { useState, useEffect } from "react";

interface ModalAnexarDocumentosProps {
  isOpen: boolean;
  onClose: () => any;
  setValue: UseFormSetValue<ProcessoDocumentoResponse>;
  form: ProcessoDocumentoResponse;
  isEdit?: boolean;
}

export default function ModalAnexarDocumentos({
  isOpen,
  onClose,
  setValue,
  form,
  isEdit,
}: ModalAnexarDocumentosProps) {
  const [filesState, setFilesState] = useState<File[]>([]);

  useEffect(() => {
    if (form.arquivos && form.arquivos.length > 0) {
      setFilesState(form.arquivos);
    }
  }, [form.arquivos]);

  const handleSaveClick = () => {
    setValue("arquivos", filesState);

    onClose();
  };
  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Adicionar"
          onclickPrimaryButton={handleSaveClick}
        />
      }
      title={<Toolbar title="Anexar documento" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
    >
      <Box as={Row} gutter={16} mb={8}>
        <Box as={Col} sm={24}>
          {filesState?.length ? (
            <Anexos
              numColumns={4}
              setValue={(anexos) => setFilesState(anexos as File[])}
              anexos={filesState}
            />
          ) : null}

          <Box>
            <Flex flex={1}>
              <ButtonAnexarArquivos
                accept="application/pdf"
                maxFileSizeMb={200}
                currentFilesLength={filesState.length}
                variant="ghost"
                maxFiles={isEdit ? 1 : 100}
                onAnexar={(files) => {
                  if (files) {
                    setFilesState((prevFiles) => [...prevFiles, ...files]);
                  }
                }}
              />
            </Flex>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
