export const PermissaoEnum = {
  Fluir: {
    Administrativo: {
      Setor: {
        toString: () => "Fluir.Administrativo.Setor",
        Visualizar: "Fluir.Administrativo.Setor.Visualizar",
        Criar: "Fluir.Administrativo.Setor.Criar",
        Editar: "Fluir.Administrativo.Setor.Editar",
      },
      Perfil: {
        toString: () => "Fluir.Administrativo.Perfil",
        Visualizar: "Fluir.Administrativo.Perfil.Visualizar",
        Criar: "Fluir.Administrativo.Perfil.Criar",
        Editar: "Fluir.Administrativo.Perfil.Editar",
      },
      Tenant: {
        toString: () => "Fluir.Administrativo.Tenant",
        Visualizar: "Fluir.Administrativo.Tenant.Visualizar",
        Criar: "Fluir.Administrativo.Tenant.Criar",
        Editar: "Fluir.Administrativo.Tenant.Editar",
      },
      Usuario: {
        toString: () => "Fluir.Administrativo.Usuario",
        Visualizar: "Fluir.Administrativo.Usuario.Visualizar",
        Criar: "Fluir.Administrativo.Usuario.Criar",
        Editar: "Fluir.Administrativo.Usuario.Editar",
      },
      NaturezaProcesso: {
        toString: () => "Fluir.Administrativo.NaturezaProcesso",
        Visualizar: "Fluir.Administrativo.NaturezaProcesso.Visualizar",
        Criar: "Fluir.Administrativo.NaturezaProcesso.Criar",
        Editar: "Fluir.Administrativo.NaturezaProcesso.Editar",
        Excluir: "Fluir.Administrativo.NaturezaProcesso.Excluir",
      },
      TipoDocumento: {
        toString: () => "Fluir.Administrativo.TipoDocumento",
        Visualizar: "Fluir.Administrativo.TipoDocumento.Visualizar",
        Criar: "Fluir.Administrativo.TipoDocumento.Criar",
        Editar: "Fluir.Administrativo.TipoDocumento.Editar",
        Excluir: "Fluir.Administrativo.TipoDocumento.Excluir",
      },
      AssuntoProcesso: {
        toString: () => "Fluir.Administrativo.AssuntoProcesso",
        Visualizar: "Fluir.Administrativo.AssuntoProcesso.Visualizar",
        Criar: "Fluir.Administrativo.AssuntoProcesso.Criar",
        Editar: "Fluir.Administrativo.AssuntoProcesso.Editar",
        Excluir: "Fluir.Administrativo.AssuntoProcesso.Excluir",
      },
    },
    Processo: {
      Processo: {
        toString: () => "Fluir.Processo.Processo",
        Visualizar: "Fluir.Processo.Processo.Visualizar",
        Criar: "Fluir.Processo.Processo.Criar",
        Editar: "Fluir.Processo.Processo.Editar",
        Excluir: "Fluir.Processo.Processo.Excluir",
        Autorizar: "Fluir.Processo.Processo.Autorizar",
        Encaminhar: "Fluir.Processo.Processo.Encaminhar",
      },
      FluxoProcesso: {
        toString: () => "Fluir.Processo.FluxoProcesso",
        Visualizar: "Fluir.Processo.FluxoProcesso.Visualizar",
        Criar: "Fluir.Processo.FluxoProcesso.Criar",
        Editar: "Fluir.Processo.FluxoProcesso.Editar",
        Excluir: "Fluir.Processo.FluxoProcesso.Excluir",
      },
      ProcessoAssociado: {
        toString: () => "Fluir.Processo.ProcessoAssociado",
        Visualizar: "Fluir.Processo.ProcessoAssociado.Visualizar",
        Criar: "Fluir.Processo.ProcessoAssociado.Criar",
        Excluir: "Fluir.Processo.ProcessoAssociado.Excluir",
      },
      PalavraChave: {
        toString: () => "Fluir.Processo.PalavraChave",
        Visualizar: "Fluir.Processo.PalavraChave.Visualizar",
        Criar: "Fluir.Processo.PalavraChave.Criar",
        Excluir: "Fluir.Processo.PalavraChave.Excluir",
        Editar: "Fluir.Processo.PalavraChave.Editar",
      },
      Alerta: {
        toString: () => "Fluir.Processo.Alerta",
        Visualizar: "Fluir.Processo.Alerta.Visualizar",
        Criar: "Fluir.Processo.Alerta.Criar",
        Excluir: "Fluir.Processo.Alerta.Excluir",
      },
      Observacao: {
        toString: () => "Fluir.Processo.Observacao",
        Visualizar: "Fluir.Processo.Observacao.Visualizar",
        Criar: "Fluir.Processo.Observacao.Criar",
        Excluir: "Fluir.Processo.Observacao.Excluir",
      },
      Documento: {
        toString: () => "Fluir.Processo.Documento",
        Visualizar: "Fluir.Processo.Documento.Visualizar",
        Criar: "Fluir.Processo.Documento.Criar",
        Editar: "Fluir.Processo.Documento.Editar",
        Excluir: "Fluir.Processo.Documento.Excluir",
      },
    },
  },
};
