import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { useEffect } from "react";
import config from "./../../config/index";

const getEnvironment = () => {
  const isProd = config.urlApi.includes("fluir.com.br");
  const isLocalhost = config.urlApi.includes("localhost");

  if (isProd) {
    return "production";
  } else if (isLocalhost) {
    return "dev";
  }

  return "staging";
};

export function init() {
  Sentry.init({
    dsn: "https://c6887ea8347143dd70f77f6c50660ec0@o4507475768049664.ingest.us.sentry.io/4507475778469888",
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    environment: getEnvironment(),
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/backoffice\.fluirsistema\.com/,
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
  });
}
