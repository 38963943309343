import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { SetorResponse } from "../typings";

function useSetor(id?: string) {
  return useQuery(
    `/v1/setor/${id}`,
    async () => {
      const res = await http.get<SetorResponse>(`/v1/setor/${id}`);

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useSetor;
