
import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { UsuarioEnderecoResponse } from "../typings";


function useUsuarioEndereco(id?: string) {
  return useQuery(
    `/v1/usuario/${id}/endereco`,
    async () => {
      const res = await http.get<UsuarioEnderecoResponse>(`/v1/usuario/${id}/endereco`);

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useUsuarioEndereco;
