import { TipoNaturezaProcessoEnum } from "core/resources/enums";
import { PaginateQuery } from "core/resources/types/shared";

export interface FluxoProcessoPaginateResponse {
  id: string;
  nome: string;
  naturezaProcesso: string;
  andamentos: number;
}

export interface FluxoProcessoResponse {
  id?: string;
  nome: string;
  naturezaProcessoId: string;
  andamentosIdsExcluidos?: string[];
  criteriosIdsExcluidos?: string[];
  fluxosAndamento: AndamentosResponse[];
}
export interface AndamentosResponse {
  id?: string;
  setorId: string;
  prazo: number | null;
  ordemAndamento?: number;
  fluxosAndamentoCriterios: CriteriosResponse[];
}

export interface CriteriosResponse {
  id?: string;
  ordemCriterio?: number;
  criterioId?: CriterioEnum;
}

export enum CriterioEnum {
  Assinatura = "42494EF7-2505-4382-8CA3-BF6AE31C99D6",
  AnexoDocumento = "42494EF7-2505-4382-8CA3-BF6AE31C99D7",
  VisualizacaoDepartamentoAdmininstrativo = "42494EF7-2505-4382-8CA3-BF6AE31C99D8",
}

export interface FiltroFluxoProcesso extends PaginateQuery {}
