import { useState } from "react";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";
import { useDebounce } from "core/features/shared/hooks/useDebouce";
import { Box, Flex, Button, Text } from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";
import useAssuntos from "core/features/shared/hooks/useAssuntos";
import ModalAdicionarAssunto from "./ModalAdicionarAssunto";

export interface SelectAssuntoProps<T = any> extends SelectBaseAntdProps<T> {
    tenantId?: string;
    isAdicionar?: boolean
}

function SelectAssunto({
    value,
    onChange,
    isInvalid,
    tenantId,
    isAdicionar,
    ...rest
}: SelectAssuntoProps) {
    const [search, setSearch] = useState<string>();
    const debouncedSearchTerm = useDebounce(search, 500);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const { data, isFetching, refetch, isLoading } = useAssuntos(
        {
            page: 1,
            limit: 1000,
            search: debouncedSearchTerm
        }
    );

    const _isLoading = isLoading || isFetching;

    return (
        <>
            {isOpenModal ? (
                <ModalAdicionarAssunto
                    visible={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    refetch={refetch}
                />
            ) : null}
            <SelectBaseAntd
                loading={_isLoading}
                isInvalid={isInvalid}
                size="large"
                showSearch
                placeholder="Selecione..."
                searchValue={search}
                onSearch={setSearch}
                onChange={onChange}
                value={value}
                filterOption={() => true}
                optionFilterProp="label"
                allowClear={true}
                options={data?.items.map((item) => ({
                    value: item.id,
                    label: item.nome
                }))}
                {...rest}
                notFoundContent={
                    !isLoading && !isFetching ? (
                        <Flex px={2} gap={2} align="center">
                            <Text>Nenhuma registro encontrado</Text>

                            {isAdicionar ?
                                <Button
                                    isLoading={false}
                                    leftIcon={<BsPlusLg size={20} />}
                                    size="sm"
                                    fontWeight={400}
                                    colorScheme="gray"
                                    onClick={() => setIsOpenModal(true)}
                                >
                                    Adicionar
                                </Button> : null}

                        </Flex>
                    ) : (
                        <Box>Carregando...</Box>
                    )}>
            </SelectBaseAntd>
        </>
    );
}

export default SelectAssunto;
