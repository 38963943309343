import {
  Box,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import { Modal } from "antd";
import colors from "core/resources/theme/colors";
import Toolbar from "ui/components/Toolbar";
import FormDadosProcesso from "./FormDadosProcesso";
import { useForm } from "react-hook-form";
import FormResponsavel from "./FormResponsavel";
import FormFluxosProcessos from "./FormFluxosProcesso";
import FooterModalDefault from "ui/components/FooterModal";
import { ProcessoResponse } from "core/features/processos/typings";
import useSaveProcesso from "core/features/processos/hooks/useSaveProcesso";
import { showErrorAxios, showSuccess } from "core/infra/helpers/alerta";

interface ModalCriarEditarProcessoProps {
  onClose: () => void;
  visible: boolean;
  refetch?: any;
}

export default function ModalCriarEditarProcesso({
  onClose,
  visible,
  refetch,
}: ModalCriarEditarProcessoProps) {
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveProcesso();

  const defaultValues: ProcessoResponse = {
    assuntoId: "",
    resumo: "",
    prioridadeId: "",
    tipoResponsavel: "setor",
    setorResponsavelId: "",
    pessoaResponsavelId: "",
    tipoFluxoProcesso: "padrao",
    fluxoId: "",
    setorDestinoId: "",
  };

  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<ProcessoResponse>({
    defaultValues,
  });

  const form = watch();

  const onSubmit = async (data: ProcessoResponse) => {
    handleSave(data, {
      onSuccess: () => {
        showSuccess().then(() => {
          onClose();
          refetch();
        });
      },
      onError: (e) => {
        showErrorAxios(e);
      },
    });
  };

  const handleSaveForm = () => handleSubmit(onSubmit)();

  const steps = [
    {
      title: "Dados do processo",
      component: <FormDadosProcesso control={control} errors={errors} />,
    },
    {
      title: "Responsável",
      component: (
        <FormResponsavel
          control={control}
          form={form}
          setValue={setValue}
          errors={errors}
        />
      ),
    },
    {
      title: "Fluxos do processo",
      component: (
        <FormFluxosProcessos
          control={control}
          form={form}
          setValue={setValue}
          errors={errors}
        />
      ),
    },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const handleNext = async () => {
    const isValid = await trigger();

    if (activeStep < steps.length - 1 && isValid) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      handleSaveForm();
    }
  };

  const handlePrev = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const activeStepComponent = steps[activeStep].component;

  return (
    <Modal
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="1200px"
      centered
      open={visible}
      className="antd-custom-header"
      title={<Toolbar title="Processos" onClose={onClose} />}
      footer={
        <FooterModalDefault
          titleOutlineButton="Fechar"
          onclickOutlineButton={onClose}
          titleGhostButton={activeStep > 0 ? "Voltar" : null}
          onclickGhostButton={handlePrev}
          titlePrimaryButton={
            activeStep < steps.length - 1 ? "Próximo" : "Salvar"
          }
          onclickPrimaryButton={handleNext}
          saveLoading={isLoadingSave}
        />
      }
    >
      <>
        <Stepper size="md" index={activeStep} colorScheme="purple" px={10}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator
                bg={activeStep === index ? "#805AD5" : colors.gray100}
                color={activeStep === index ? "white" : colors.gray600}
                border={activeStep === index ? "none" : ""}
                fontFamily="Poppins,sans-serif"
                pt="3px"
              >
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <StepTitle>{step.title}</StepTitle>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
        <Box mt={10} minHeight={"60vh"}>
          <form>{activeStepComponent}</form>
        </Box>
      </>
    </Modal>
  );
}
