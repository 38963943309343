import { Flex, Box, Text } from "@chakra-ui/layout";
import { Menu } from "core/features/menu/typings";
import Container from "ui/components/Container";
import Fade from "react-fade-in";
import { Link } from "react-router-dom";

interface HeaderMenuItemProps {
  item: Menu;
  onCloseSubmenu: any;
  onOpenSubmenu: any;
  submenuOpen: boolean;
}

function HeaderMenuItem({
  item,
  onCloseSubmenu,
  submenuOpen,
  onOpenSubmenu,
  ...props
}: HeaderMenuItemProps) {
  return (
    <Flex
      as={Link}
      _hover={{
        cursor: "pointer",
        color: "white",
        rounded: "1px",
        borderBottom: " 3px solid  white",
      }}
      to={item.href}
      transition="linear all .1s"
      fontWeight="light"
      px="8px"
      h="58px"
      fontSize="16px"
      gridGap="8px"
      color="white"
      pos="relative"
      cursor="pointer"
      align="center"
      zIndex="10"
      onMouseOver={() => {
        onCloseSubmenu();

        if (item.children.length) {
          onOpenSubmenu();
        }
      }}
      {...props}
    >
      {item.visible ? (
        <Flex flexDir="column" align="center">
          {item.icon}
          {item.title}
        </Flex>
      ) : null}

      {submenuOpen ? (
        <Flex
          w="max-content"
          h="max-content"
          onMouseLeave={() => {
            onCloseSubmenu();
          }}
          rounded={5}
          pos="absolute"
          top="100%"
          margin="auto"
          transform="translateX(-50%)"
          left="50%"
          flexDir="column"
        >
          <Flex h="30px" w="100%" bg="trasparent" />
          <Flex
            w="100%"
            h="max-content"
            bg="white"
            rounded="16px"
            p={4}
            pb={8}
            boxShadow="rgba(0, 0, 0, 0.15) 0px 2px 8px"
          >
            <Container>
              <Fade visible={submenuOpen}>
                <Flex gridGap="32px" flexWrap="wrap">
                  {item.children.map((submenu, index) => (
                    <Flex w="220px" key={index} flexDir="column">
                      <Box pt="16px" mb="80px" h="35px">
                        <Flex
                          w="40px"
                          h="40px"
                          mb="20px"
                          rounded="20px"
                          bg={submenu.bg}
                          justifyContent="center"
                          alignItems="center"
                          color={submenu.color}
                        >
                          {submenu.icon}
                        </Flex>

                        <Text
                          fontSize="16px"
                          fontWeight="bold"
                          color={submenu.color}
                        >
                          {submenu.title}
                        </Text>
                      </Box>

                      <Flex gridGap="16px" flexDirection="column">
                        {submenu.children.map((child, index2) => (
                          <Flex
                            as={Link}
                            to={child.href}
                            cursor="pointer"
                            key={index2}
                            flexDir="column"
                            fontWeight="bold"
                            onClick={() => {
                              setTimeout(() => onCloseSubmenu(), 150);
                            }}
                          >
                            {child.visible ? (
                              <Flex
                                flex="1"
                                justifyContent="start"
                                opacity="0.6"
                                gap="4px"
                                _hover={{
                                  opacity: 1,
                                  textDecoration: "underline",
                                }}
                                transition="linear all .1s"
                                cursor="pointer"
                              >
                                {child.icon}

                                <Text fontSize="14px" color="black">
                                  {child.title}
                                </Text>
                              </Flex>
                            ) : null}
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Fade>
            </Container>
          </Flex>
          <Flex
            bg="white"
            w="20px"
            h="40px"
            position="absolute"
            top="13px"
            clipPath=" polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)"
            align="center"
            transform="translateX(50%)"
            right="50%"
          />
        </Flex>
      ) : null}
    </Flex>
  );
}

export default HeaderMenuItem;
