import { Box, Flex, Text } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { useBreadcrumb } from "core/features/breadcrumbs";
import { useForm } from "react-hook-form";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import CardDefault from "ui/components/Cards/CardDefault";
import Field from "ui/components/Input/Field";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import useTipoDocumento from "core/features/tipo-documento/hooks/useTipoDocumento";
import {
  showErrorAxios,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import { TipoDocumentoResponse } from "core/features/tipo-documento/types";
import useSaveTipoDocumento from "core/features/tipo-documento/hooks/useSaveTipoDocumento";

export default function TipoDocumentoDetalhes() {
  const { id } = useParams() as {
    id: string;
  };

  const navigate = useNavigate();

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Tipo do documento", url: "/tipo-documento" },
    { name: `${id ? "Editar " : "Adicionar "}tipo do documento` },
  ]);

  const { isLoading: isLoadingSave, mutate: handleSave } =
    useSaveTipoDocumento();

  const { data, isLoading } = useTipoDocumento(id);

  const { control, handleSubmit, reset } = useForm<TipoDocumentoResponse>();

  const onSubmit = async (data: TipoDocumentoResponse) => {
    handleSave(
      data,

      {
        onSuccess: async (response) => {
          await showSuccess();
          if (!id && response.data) {
            window.location.replace(`/tipo-documento`);
          }
        },
        onError: (err) => {
          showErrorAxios(err);
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  useLoading(isLoading);

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit(onSubmit)(ev);
      }}
    >
      <Box>
        <Text
          fontWeight="semibold"
          fontSize="30px"
          color="black"
          flexWrap="wrap"
          mb={4}
        >
          {id ? "Editar " : "Adicionar do "} tipo do documento
        </Text>

        <CardDefault mb={4}>
          <Box as={Row} gutter={16}>
            <Box as={Col} md={24}>
              <Field
                name="nome"
                label="Nome do tipo do documento"
                control={control}
                rules={{ required: true }}
              />
            </Box>
          </Box>
        </CardDefault>

        <CardDefault>
          <Flex flex={1} justify="end" gap={4}>
            <OutlineButton onClick={() => navigate("/tipo-documento")}>
              Cancelar
            </OutlineButton>
            <PrimaryButton type="submit" isLoading={isLoadingSave}>
              Salvar
            </PrimaryButton>
          </Flex>
        </CardDefault>
      </Box>
    </form>
  );
}
