import useTipoAcesso from "core/features/shared/hooks/useTipoAcesso";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";

export interface SelectTipoAcessoProps<T = any>
  extends SelectBaseAntdProps<T> {}

function SelectTipoAcesso({ ...rest }: SelectTipoAcessoProps) {
  const { isLoading, data } = useTipoAcesso();

  return (
    <SelectBaseAntd
      loading={isLoading}
      size="large"
      placeholder="Selecione..."
      optionFilterProp="label"
      options={data?.map((item) => ({
        value: item.id,
        label: item.nome,
      }))}
      {...rest}
    />
  );
}

export default SelectTipoAcesso;
