import { Box } from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import PageHeader from "ui/components/Page/PageHeader";
import { useBreadcrumb } from "core/features/breadcrumbs";
import SearchHeaderBar from "ui/components/SearchHeaderBar/index";
import { useNavigate } from "react-router-dom";
import { useListarFluxosProcessos } from "core/features/tipoprocesso/hooks/useListarFluxosProcessos";
import FluxoProcessoTable from "./components/FluxoProcessoTable";
import useFiltroFluxoProcessos from "./hooks/useFiltroTiposProcessos";

export default function ListagemFluxoProcessosPage() {
  const { filtro, onChangeFiltro } = useFiltroFluxoProcessos();
  const { data, isLoading, refetch } = useListarFluxosProcessos(filtro);

  const navigate = useNavigate();

  useBreadcrumb([{ name: "Fluxo de Processo", url: "fluxo-processo" }]);

  return (
    <Box>
      <Box mb={10}>
        <PageHeader
          title="Fluxo de processo"
          titleButton="Adicionar"
          onClickButton={() => navigate("/fluxo-processo/novo")}
          iconButton={<BsPlus size={25} />}
        />

        <SearchHeaderBar
          defaultValue={filtro.search || ""}
          onSearch={(v) => onChangeFiltro({ search: v })}
          maxWInputSearch={700}
          placeholder="Buscar"
        />
      </Box>

      <FluxoProcessoTable
        data={data}
        isLoading={isLoading}
        onChangeFiltro={onChangeFiltro}
        refetch={refetch}
      />
    </Box>
  );
}
