import http from "core/features/auth/hooks/useApi";
import { PaginateQuery, PaginateResult } from "core/resources/types/shared";
import { useQuery } from "react-query";
import { ProcessoAssociadoPaginateResponse } from "../typings";

function useListarProcessosAssociados(filtro: PaginateQuery, id: string) {
  return useQuery(
    [`/v1/processo/${id}/processo-associado`, filtro],
    async () => {
      const { data } = await http.get<
        PaginateResult<ProcessoAssociadoPaginateResponse>
      >(`/v1/processo/${id}/processo-associado`, {
        params: filtro,
      });

      return data;
    },
    {
      enabled: !!filtro,
    }
  );
}

export default useListarProcessosAssociados;
