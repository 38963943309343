import { Box } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import { useForm } from "react-hook-form";
import Field from "ui/components/Input/Field";
import { useEffect } from "react";
import SelectCriterio from "ui/components/Input/SelectCriterio";
import { CriteriosResponse } from "core/features/tipoprocesso/typings";
import Toolbar from "ui/components/Toolbar";
import FooterModalDefault from "ui/components/FooterModal";

interface ModalCriterioProps {
  isOpen: boolean;
  onClose: () => any;
  isEdit?: boolean;
  onSubmit: (item: CriteriosResponse) => any;
  defaultData?: Partial<CriteriosResponse>;
}

const defaultValues: CriteriosResponse = {
  criterioId: undefined,
};
function ModalCriterio({
  isOpen,
  onClose,
  onSubmit,
  defaultData,
}: ModalCriterioProps) {
  const { control, reset, handleSubmit } = useForm<CriteriosResponse>({
    defaultValues,
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        ...defaultValues,
        ...defaultData,
      });
    }
  }, [isOpen, reset]);

  return (
    <Modal
      open={isOpen}
      title={<Toolbar title="Critérios" onClose={onClose} />}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Salvar"
          onclickPrimaryButton={() => handleSubmit(onSubmit)()}
        />
      }
      closable={false}
      width="700px"
    >
      <Box as={Row} gutter={16} mb={8}>
        <Box as={Col} md={24}>
          <Field
            name="criterioId"
            label="Selecione o critério"
            control={control}
            rules={{ required: true }}
            render={({ field, isInvalid }) => (
              <SelectCriterio isInvalid={isInvalid} {...field} />
            )}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalCriterio;
