import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { ProcessoDocumentoResponse } from "../typings";
import { createFormData } from "core/infra/helpers/createFormData";

function useSaveProcessoDocumento(id?: string, idDocumento?: string) {
  return useMutation(
    async (form: ProcessoDocumentoResponse) => {
      const formData = createFormData(form.arquivos || [], form, "arquivos");
      if (idDocumento) {
        const { data } = await http.put<{ response: string }>(
          `/v1/processo-documento/${id}/${idDocumento}`,
          formData
        );
        return data;
      }

      const { data } = await http.post(
        `/v1/processo-documento/${id}`,
        formData
      );

      return data;
    },
    { retry: false }
  );
}

export default useSaveProcessoDocumento;
