import http from "core/features/auth/hooks/useApi";
import { useMutation } from "react-query";

function useSaveAssunto() {
  return useMutation(
    "/v1/processo/assunto",
    (data: { assunto: string }) => http.post(`/v1/processo/assunto`, data),
    { retry: false }
  );
}

export default useSaveAssunto;
