import { useState } from "react";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";
import useCargos from "core/features/cargos/hooks/useCargos";

export interface SelectCargoProps<T = any> extends SelectBaseAntdProps<T> { }

function SelectCargo({ ...rest }: SelectCargoProps) {
  const [search, setSearch] = useState<string>();

  const { isLoading, data } = useCargos();

  return (
    <SelectBaseAntd
      loading={isLoading}
      size="large"
      placeholder="Selecione..."
      searchValue={search}
      showSearch
      onSearch={setSearch}
      optionFilterProp="label"
      options={data?.map((item) => ({
        value: item.id,
        label: item.nome,
      }))}
      {...rest}
    />
  );
}

export default SelectCargo;
