import { Box, Input } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import useEncaminhar from "core/features/processos/hooks/useEncaminhar";
import { showErrorAxios, showSuccess } from "core/infra/helpers/alerta";
import { useForm } from "react-hook-form";
import FooterModalDefault from "ui/components/FooterModal";
import Field from "ui/components/Input/Field";
import SelectSetor from "ui/components/Input/SelectSetor";
import Toolbar from "ui/components/Toolbar";

interface ModalEncaminharProps {
  isOpen: boolean;
  onClose: () => any;
  id?: string;
  refetch: any;
}

export default function ModalEncaminhar({
  isOpen,
  onClose,
  id,
  refetch,
}: ModalEncaminharProps) {
  const { mutate: handleSave, isLoading: isLoadingSave } = useEncaminhar(id);

  const { control, handleSubmit } = useForm<{
    setorDestinoId: string;
  }>({
    defaultValues: { setorDestinoId: "" },
  });

  const onSubmit = (data: { setorDestinoId: string }) => {
    handleSave(data, {
      onSuccess: () => {
        showSuccess().then(() => {
          onClose();
          setTimeout(() => {
            refetch();
          }, 200);
        });
      },

      onError: (err) => {
        showErrorAxios(err);
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Salvar"
          saveLoading={isLoadingSave}
          onclickPrimaryButton={() => handleSubmit(onSubmit)()}
        />
      }
      title={<Toolbar title="Encaminhar" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
    >
      <form>
        <Box as={Row} gutter={16} mb={8}>
          <Box as={Col} md={18}>
            <Field
              name="setorDestinoId"
              label="Setor"
              control={control}
              rules={{ required: true }}
              render={({ field, isInvalid }) => (
                <SelectSetor isInvalid={isInvalid} {...field} />
              )}
            />
          </Box>
          <Box as={Col} md={6}>
            <Field
              name="prazo"
              label="Prazo (em dias)"
              control={control}
              rules={{ required: true }}
              render={({ field, isInvalid }) => (
                <Input type="number" isInvalid={isInvalid} {...field} />
              )}
            />
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
