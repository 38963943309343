import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { useForm } from "react-hook-form";
import Field from "ui/components/Input/Field";
import { Box, Text } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showError } from "core/infra/helpers/alerta";
import InputPassword from "ui/components/Input/InputPassword";
import { useResetPasswordStore } from "core/features/auth/hooks/useResetPasswordStore";

export default function ResetPassword() {
  const { control, handleSubmit, watch } = useForm<{
    newPassword: string;
    confirm: string;
  }>({
    reValidateMode: "onChange",
  });

  const navigate = useNavigate();

  const form = watch();

  const { user, reset } = useResetPasswordStore();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowshowPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);

  const handleReset = async () => {
    if (form.newPassword === form.confirm && user) {
      user.completeNewPasswordChallenge(
        form.newPassword,
        {},
        {
          onFailure: () => {
            showError("Algo deu errado na recuperação da senha");
          },
          onSuccess: () => {
            navigate("/auth/signin");
          },
        }
      );
    }
  };

  useEffect(() => {
    return reset;
  }, [reset]);

  return (
    <Box w="417px" margin="auto auto auto 80px">
      <form onSubmit={(ev) => handleSubmit(handleReset)(ev)}>
        <Text fontSize={24} fontWeight={500} mb={4}>
          Digite sua nova senha
        </Text>
        <Box as={Row}>
          <Box as={Col} md={24} mb={4}>
            <Field
              name="newPassword"
              label="Nova senha"
              rules={{
                required: "Este campo é obrigatório",
                minLength: {
                  value: 8,
                  message: "A senha deve ter no mínimo 8 caracteres",
                },
                pattern: {
                  value: /^(?=.*[A-Z])(?=.*\d)/,
                  message:
                    "A senha deve conter pelo menos uma letra maiúscula e um número",
                },
              }}
              control={control}
              render={({ field }) => (
                <InputPassword
                  showPassword={showPassword}
                  onClick={handleClickShowPassword}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </Box>
          <Box as={Col} md={24} mb={4}>
            <Field
              name="confirm"
              label="Repetir nova senha"
              rules={{
                required: "Campo obrigatório",
                validate: (v) =>
                  v === form.newPassword
                    ? undefined
                    : "As senhas devem ser iguais",
              }}
              control={control}
              render={({ field }) => (
                <InputPassword
                  showPassword={showPasswordConfirm}
                  onClick={handleClickShowshowPasswordConfirm}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </Box>
          <Box as={Col} md={24} mb={4}>
            <PrimaryButton w="100%" type="submit">
              Redefinir senha
            </PrimaryButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
}
