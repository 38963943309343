import { BaseFiltroPaginate } from "core/resources/enums/paginate";
import { useCallback, useState } from "react";
import { PaginateQuery } from "core/resources/types/shared";

export const filtroDefaultNaturezaProcesso: PaginateQuery = {
  limit: BaseFiltroPaginate.Limit,
  search: "",
  page: 1,
};

function useFiltroNaturezaProcesso() {
  const [filtro, setFiltro] = useState<PaginateQuery>(
    filtroDefaultNaturezaProcesso
  );

  const onChangeFiltro = useCallback((_filtro: Partial<PaginateQuery>) => {
    if (_filtro.search !== undefined) {
      _filtro.page = 1;
    }
    setFiltro((state: any) => ({ ...state, ..._filtro }));
  }, []);

  return {
    filtro,
    setFiltro,
    onChangeFiltro,
  };
}

export default useFiltroNaturezaProcesso;
