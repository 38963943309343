import { Box, Flex, Text } from "@chakra-ui/react";
import { Spin } from "antd";
import { ProcessoDocumentoResponse } from "core/features/processos-documento/typings";
import useUsuarios from "core/features/shared/hooks/useUsuarios";
import colors from "core/resources/theme/colors";
import { IoMdTrash } from "react-icons/io";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu";
import CardDefault from "ui/components/Cards/CardDefault";
import CardPerson3 from "ui/components/Cards/CardPerson3";
import EmptyDefault from "ui/components/Empty";
import { UseFormSetValue } from "react-hook-form";

interface TableAlertasProps {
  tenantId: string;
  form: ProcessoDocumentoResponse;
  setValue: UseFormSetValue<ProcessoDocumentoResponse>;
}

export default function TableAlertas({ form, setValue }: TableAlertasProps) {
  const { data, isLoading } = useUsuarios({
    page: 1,
    limit: 1000,
  });

  const alertas = (form.alertas || [])
    .map((alerta) => {
      const itemCorrespondente = data?.items?.find(
        (item) => item.id === alerta.pessoaId
      );
      if (itemCorrespondente) {
        return {
          id: alerta.id,
          nome: itemCorrespondente.nome,
          imagemUrl: itemCorrespondente.imagemUrl,
          setor: itemCorrespondente.setor,
        };
      }
      return null;
    })
    .filter(Boolean);

  form.alertasIdsExcluidos = form.alertasIdsExcluidos || [];

  const handleExcluirAlerta = (id: string) => {
    const index = form.alertas.findIndex((alerta) => alerta.id === id);

    if (index !== -1) {
      const novosAlertas = [...form.alertas];
      novosAlertas.splice(index, 1);

      setValue("alertas", novosAlertas);
      setValue("alertasIdsExcluidos", [
        ...(form.alertasIdsExcluidos || []),
        id,
      ]);
    }
  };

  return (
    <>
      {isLoading ? (
        <Flex alignItems="center" justifyContent="center">
          <Spin size="large" spinning />
        </Flex>
      ) : (
        <Flex flexDir="column" gap={4}>
          {alertas?.length ? (
            <Box>
              {alertas?.map((item, index) => (
                <CardDefault
                  rounded="8px"
                  padding="6px 20px"
                  mb={4}
                  key={index}
                >
                  <Flex w="100%" align="center">
                    <Flex flex={1} align="center" gap={2}>
                      <CardPerson3
                        nome={item?.nome}
                        avatarUrl={item?.imagemUrl}
                      />
                      <Text>{item?.nome}</Text>
                    </Flex>
                    <Flex flex={1}>{item?.setor}</Flex>
                    <Flex flex={0.02} h="fit-content" py={4}>
                      <ActionsKebabMenu
                        indexItem={index}
                        item={item}
                        actions={[
                          {
                            title: "Excluir",
                            textColor: `${colors.red500}`,
                            onClick: (v) => handleExcluirAlerta(v?.id!),
                            icon: () => <IoMdTrash />,
                          },
                        ]}
                      />
                    </Flex>
                  </Flex>
                </CardDefault>
              ))}
            </Box>
          ) : (
            <EmptyDefault size="sm" fontSize="12px" />
          )}
        </Flex>
      )}
    </>
  );
}
