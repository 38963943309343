import { Modal, Row, Col } from "antd";
import Toolbar from "./../Toolbar/index";
import FooterModalDefault from "./../FooterModal/index";
import { showErrorAxios, showSuccess } from "core/infra/helpers/alerta";
import { Box } from "@chakra-ui/react";
import Field from "./../Input/Field/index";
import InputPassword from "./../Input/InputPassword/index";
import { useState } from "react";
import AnexarCertificado from "./../../pages/Admin/Usuario/Detalhes/components/InformacoesBasicas/components/AnexarCertificado";
import { useForm } from "react-hook-form";
import useSaveCertificadoDigital from "core/features/certificado-digital/hooks/useSaveCertificadoDigital";
import { CertificadoDigitalResponse } from "core/features/certificado-digital/typings/index";

interface ModalCertificadoDigitalProps {
  isOpen: boolean;
  onClose: () => any;
  refetch?: any;
}

export default function ModalCertificadoDigital({
  isOpen,
  onClose,
  refetch,
}: ModalCertificadoDigitalProps) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { isLoading: isLoadingSave, mutate: handleSave } =
    useSaveCertificadoDigital();

  const { control, setValue, watch, handleSubmit } =
    useForm<CertificadoDigitalResponse>({});

  const form = watch();

  const onSubmit = (data: CertificadoDigitalResponse) => {
    handleSave(data, {
      onSuccess: () => {
        showSuccess().then(() => {
          onClose();
          refetch();
        });
      },
      onError: (err: any) => {
        showErrorAxios(err);
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Salvar"
          onclickPrimaryButton={() => handleSubmit(onSubmit)()}
          saveLoading={isLoadingSave}
        />
      }
      title={
        <Toolbar title="Adicionar Certificado Digital" onClose={onClose} />
      }
      destroyOnClose
      onCancel={onClose}
      maskClosable={false}
      closable={false}
      width="900px"
      centered
    >
      <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
        <Box as={Row} gutter={16} mb={4}>
          <Box as={Col} md={14} mb={4}>
            <Field
              name="senhaCertificado"
              label="Senha do certificado"
              control={control}
              render={({ field }) => (
                <InputPassword
                  showPassword={showPassword}
                  onClick={handleClickShowPassword}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </Box>
          <Box as={Col} md={14}>
            <AnexarCertificado setValue={setValue} form={form} />
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
