import { SelectProps } from "antd";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";

import { useState } from "react";
import useCriterios from "core/features/shared/hooks/useCriterios";

export interface SelectCriterioProps<T = any> extends SelectBaseAntdProps<T> { }

function SelectCriterio({ ...rest }: SelectCriterioProps) {
  const [search, setSearch] = useState<string>();

  const { isLoading, data } = useCriterios();

  return (
    <SelectBaseAntd
      loading={isLoading}
      size="large"
      placeholder="Selecione..."
      searchValue={search}
      showSearch
      onSearch={setSearch}
      optionFilterProp="label"
      options={data?.map((item) => ({
        value: item.id,
        label: item.nome,
      }))}
      {...rest}
    />
  );
}

export default SelectCriterio;
