import Pagination from "ui/components/Pagination";
import { Box } from "@chakra-ui/react";
import SkeletonDefault from "ui/components/SkeletonDefault";
import CardDefaultTable from "ui/components/Cards/CardDefaultTable";
import HeaderDefaultRowTable from "ui/components/HeaderDefaultRowTable";
import HeaderTableRow from "ui/pages/Admin/Processos/Detalhes/components/TabDocumentos/components/HeaderTableRow";
import TableItemRow from "ui/pages/Admin/Processos/Detalhes/components/TabDocumentos/components/TableItemRow";
import { useMemo, useState, useCallback } from "react";
import { PaginateResult } from "core/resources/types/shared";
import EmptyDefault from "ui/components/Empty";
import {
  FiltrosProcessoDocumentos,
  ProcessoDocumentoPaginateResponse,
} from "core/features/processos-documento/typings";
import HeaderTableSelected from "ui/pages/Admin/Processos/Detalhes/components/TabDocumentos/components/HeaderTableSelected";
import ModalDeleteProcessoDocumento from "./../ModalDeleteProcessoDocumento/index";
import useAssinarDocumento from "core/features/processos-documento/hooks/useAssinarDocumento";
import { showErrorAxios, useLoading } from "core/infra/helpers/alerta";
import { showSuccess, showConfirm3 } from "core/infra/helpers/alerta";
import ModalCertificadoDigital from "ui/components/ModalCertificadoDigital/index";
import { useWindowSize } from "core/infra/helpers/window";

interface DocumentosTableProps {
  data?: PaginateResult<ProcessoDocumentoPaginateResponse>;
  isLoading: boolean;
  onChangeFiltro: (_filtro: Partial<FiltrosProcessoDocumentos>) => void;
  refetch: any;
  idProcesso: string;
  permissaoSetor?: boolean;
}

export default function DocumentosTable({
  data,
  isLoading,
  onChangeFiltro,
  refetch,
  idProcesso,
  permissaoSetor,
}: DocumentosTableProps) {
  const [deleteIds, setDeleteIds] = useState<string[] | undefined>(undefined);
  const [openModalCertificadoDigital, setOpenModalCertificadoDigital] =
    useState(false);
  const [selectedDocumentosIds, setSelectedDocumentosIds] = useState<string[]>(
    []
  );

  const allDocumentosIds = useMemo(
    () => data?.items.map((item) => item.id) ?? [],
    [data]
  );
  const documentosNaoAssinadosIds =
    data?.items
      .filter((item) => item.assinaturaUsuario !== true)
      .map((item) => item.id) ?? [];

  const documentosComPermissaoExcluirIds = useMemo(
    () =>
      data?.items
        .filter((item) => {
          const assinaturaIniciada = item.totalAssinaturas > 0;
          const umAssinante = item.totalAssinaturas === 1;
          const assinadoPorUsuario = item.assinaturaUsuario;
          const apenasMinhaAssinatura = umAssinante && assinadoPorUsuario;
          return permissaoSetor && assinaturaIniciada
            ? apenasMinhaAssinatura
            : true;
        })
        .map((item) => item.id) ?? [],
    [data, permissaoSetor]
  );

  const [assinaturaIniciada, umAssinante, assinadoPorUsuario] = useMemo(() => {
    const assinaturaIniciada = data?.items.some(
      (obj) =>
        selectedDocumentosIds.includes(obj.id) && obj.totalAssinaturas > 0
    );

    const umAssinante = data?.items.some(
      (obj) =>
        selectedDocumentosIds.includes(obj.id) && obj.totalAssinaturas === 1
    );

    const assinadoPorUsuario = data?.items.some(
      (obj) => selectedDocumentosIds.includes(obj.id) && obj.assinaturaUsuario
    );

    return [assinaturaIniciada, umAssinante, assinadoPorUsuario];
  }, [selectedDocumentosIds, data]);

  const permissaoExcluir = useMemo(() => {
    const algumDocumentoSelecionadoSemAssinatura =
      selectedDocumentosIds.length === 0 ||
      selectedDocumentosIds.every(
        (id) =>
          documentosComPermissaoExcluirIds.includes(id) &&
          umAssinante &&
          assinadoPorUsuario
      );

    return permissaoSetor && assinaturaIniciada
      ? algumDocumentoSelecionadoSemAssinatura
      : true;
  }, [
    permissaoSetor,
    assinaturaIniciada,
    umAssinante,
    assinadoPorUsuario,
    selectedDocumentosIds,
    documentosComPermissaoExcluirIds,
  ]);

  const isIndeterminate =
    selectedDocumentosIds.length > 0 &&
    selectedDocumentosIds.length < allDocumentosIds.length;

  const isChecked = selectedDocumentosIds.length === allDocumentosIds.length;

  const selectAll = useCallback(() => {
    if (selectedDocumentosIds.length) {
      setSelectedDocumentosIds([]);
    } else {
      const combinedIds = Array.from(
        new Set([
          ...documentosNaoAssinadosIds,
          ...documentosComPermissaoExcluirIds,
        ])
      );
      setSelectedDocumentosIds(combinedIds);
    }
  }, [isChecked, documentosNaoAssinadosIds, documentosComPermissaoExcluirIds]);

  const { mutate: handleSave, isLoading: isLoadingSaveAssinar } =
    useAssinarDocumento(idProcesso);

  const onSubmitAssinar = async (documentoIds: string[]) => {
    handleSave(
      { documentoIds },
      {
        onSuccess: () => {
          setTimeout(() => {
            showSuccess("Assinatura realizada com sucesso!").then(() => {
              setSelectedDocumentosIds([]);
              refetch();
            });
          }, 100);
        },
        onError: (e) => {
          showErrorAxios(e);
        },
      }
    );
  };

  const verificarCertificado = async (id: string[]) => {
    if (data?.items[0].certificadoDigital) {
      const { isConfirmed } = await showConfirm3({
        titulo: "Assinar ?",
        mensagem: "Você deseja realmente assinar esse documento?",
        tituloButtonConfirm: "Sim",
      });
      if (isConfirmed) {
        setTimeout(() => {
          onSubmitAssinar(id);
        }, 100);
      }
    } else {
      setOpenModalCertificadoDigital(true);
    }
  };

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 992;

  useLoading(isLoadingSaveAssinar);

  return (
    <>
      <CardDefaultTable>
        {data?.items.length && !isLoading ? (
          <HeaderDefaultRowTable
            children={
              !selectedDocumentosIds.length ? (
                <HeaderTableRow
                  isIndeterminate={isIndeterminate}
                  checked={isChecked}
                  onChange={() => selectAll()}
                  isMobile={isMobile}
                />
              ) : (
                <HeaderTableSelected
                  items={data.items}
                  isIndeterminate={isIndeterminate}
                  checked={isChecked}
                  onChange={() => selectAll()}
                  selecionados={selectedDocumentosIds.length}
                  setDeleteIds={setDeleteIds}
                  selectedDocumentosIds={selectedDocumentosIds}
                  verificarCertificado={verificarCertificado}
                  permissaoExcluir={permissaoExcluir}
                />
              )
            }
          />
        ) : null}

        {isLoading ? (
          <SkeletonDefault h="72px" rounded="8px" mb={2} amount={7} />
        ) : (
          <>
            {data?.items?.map((item, index) => (
              <TableItemRow
                key={item.id}
                item={item}
                indexItem={index}
                selected={selectedDocumentosIds}
                setSelected={setSelectedDocumentosIds}
                setDeleteIds={setDeleteIds}
                verificarCertificado={verificarCertificado}
                permissaoSetor={permissaoSetor}
                isMobile={isMobile}
              />
            ))}
          </>
        )}
        {!data?.items.length && !isLoading ? <EmptyDefault /> : null}
        {data?.items.length ? (
          <Box m={4}>
            <Pagination
              style={{ marginLeft: "auto", marginBottom: 16 }}
              current={data?.meta?.currentPage ?? 1}
              total={data?.meta?.totalItems ?? 0}
              pageSize={data?.meta?.itemsPerPage ?? 0}
              onChange={(page, limit) => {
                onChangeFiltro({ page, limit });
              }}
            />
          </Box>
        ) : null}
      </CardDefaultTable>

      {!!deleteIds ? (
        <ModalDeleteProcessoDocumento
          onClose={() => setDeleteIds(undefined)}
          visible={!!deleteIds}
          deleteIds={deleteIds}
          idProcesso={idProcesso}
          setSelectedDocumentosIds={setSelectedDocumentosIds}
          refetch={refetch}
        />
      ) : null}

      <ModalCertificadoDigital
        isOpen={openModalCertificadoDigital}
        onClose={() => setOpenModalCertificadoDigital(false)}
        refetch={refetch}
      />
    </>
  );
}
