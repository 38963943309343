import colors from "core/resources/theme/colors";
import styled from "styled-components";

export const InputContainer = styled.div<any>`
  .chakra-input:hover,
  .chakra-input:focus {
    border-color: ${(props) =>
      props.isInvalid
        ? "red"
        : !props.disabled
        ? "white"
        : "#f2f6f9"} !important;
    box-shadow: 0 0 0 1px
      ${(props) =>
        props.isInvalid
          ? "red"
          : !props.disabled
          ? "white"
          : "#f2f6f9"} !important;
    outline-color: ${(props) =>
      props.isInvalid
        ? "red"
        : !props.disabled
        ? "white"
        : "#f2f6f9"} !important;
    transition: all 0.2s;
  }

  .chakra-input {
    border-radius: 6px;
    padding: 9px 16px;
    width: 100%;
    border: 1px solid
      ${(props) => (props.isInvalid ? "red" : colors.primary)}!important;
    opacity: ${(props) => (props.disabled ? 0.7 : 1)};
    background-color: ${(props) =>
      props.isInvalid
        ? "white"
        : props.backgroundColor || colors.primary} !important;
  }
`;
