import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { createFormData } from "core/infra/helpers/createFormData";
import { CertificadoDigitalResponse } from "./../typings/index";

function useSaveCertificadoDigital() {
  return useMutation(
    (form: CertificadoDigitalResponse) => {
      const formData = createFormData(
        form.certificado || [],
        form,
        "certificado"
      );

      return http.put(`/v1/assinatura-digital/certificado`, formData);
    },

    { retry: false }
  );
}

export default useSaveCertificadoDigital;
