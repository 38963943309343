import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { UsuarioResponse } from "../typings";


function useUsuario(id?: string) {
  return useQuery(
    `/v1/usuario/${id}`,
    async () => {
      const res = await http.get<UsuarioResponse>(`/v1/usuario/${id}`);

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useUsuario;
