import { Flex } from "@chakra-ui/react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export default function HeaderTableRow({
  isIndeterminate,
  checked,
  onChange,
  isMobile,
}: {
  isIndeterminate: boolean;
  checked: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  isMobile: boolean;
}) {
  return (
    <Flex w="100%" height="30px" align="center">
      <Flex flex={0.1} ml={1} align="center">
        <Checkbox
          onChange={onChange}
          checked={checked}
          indeterminate={isIndeterminate}
        />
      </Flex>
      <Flex flex={isMobile ? 0.4 : 0.54}>N.° documento</Flex>
      <Flex flex={isMobile ? 0.4 : 0.3}>Tipo </Flex>
      <Flex flex={isMobile ? 1.1 : 2}>Descrição</Flex>
      <Flex flex={isMobile ? 0.8 : 0.54}>Adicionado em</Flex>
      <Flex flex={isMobile ? 0.6 : 0.54}>Assinaturas</Flex>
      <Flex flex={0.52} mr={4}>
        Status
      </Flex>
    </Flex>
  );
}
