import { Box, Divider, Flex } from "@chakra-ui/react";
import OutlineButton from "../Buttons/OutlineButton";
import PrimaryButton from "../Buttons/PrimaryButton";

interface FooterModalDefaultProps {
  onclickOutlineButton?: React.MouseEventHandler<HTMLButtonElement>;
  titleOutlineButton?: string;
  onclickPrimaryButton?: React.MouseEventHandler<HTMLButtonElement>;
  titlePrimaryButton?: string;
  saveLoading?: boolean;
  titleGhostButton?: string | null;
  onclickGhostButton?: React.MouseEventHandler<HTMLButtonElement>;
  disabledPrimaryButton?: boolean;
}

export default function FooterModalDefault({
  onclickOutlineButton,
  onclickPrimaryButton,
  titleOutlineButton,
  titlePrimaryButton,
  saveLoading,
  titleGhostButton,
  onclickGhostButton,
  disabledPrimaryButton,
}: FooterModalDefaultProps) {
  return (
    <Box>
      <Divider mt={10} mb={4} />
      <Flex flex={1} justifyContent="end" gap={4} px={15}>
        {titleOutlineButton ? (
          <OutlineButton onClick={onclickOutlineButton}>
            {titleOutlineButton}
          </OutlineButton>
        ) : null}

        {titleGhostButton ? (
          <OutlineButton variant="ghost" onClick={onclickGhostButton}>
            {titleGhostButton}
          </OutlineButton>
        ) : null}

        {titlePrimaryButton ? (
          <PrimaryButton
            onClick={onclickPrimaryButton}
            isLoading={saveLoading}
            disabled={disabledPrimaryButton}
          >
            {titlePrimaryButton}
          </PrimaryButton>
        ) : null}
      </Flex>
    </Box>
  );
}
