import colors from "core/resources/theme/colors";
import { Button, ButtonProps } from "@chakra-ui/react";

interface OutlineButtonProps extends ButtonProps {
  color?: string;
  borderColor?: string;
  variant?: "ghost" | "outline";
  bgColor?: string;
}
function OutlineButton({
  children,
  color,
  borderColor = colors.gray200,
  bgColor = colors.gray200,
  variant = "outline",
  ...rest
}: OutlineButtonProps) {
  return (
    <Button
      rounded={8}
      px={4}
      h="35px"
      bgColor={variant === "outline" ? bgColor : "white"}
      color={variant === "outline" ? color : colors.primary}
      borderColor={variant === "outline" ? borderColor : colors.primary}
      borderWidth={1}
      display="flex"
      gridGap={2}
      fontSize={14}
      fontWeight="normal"
      _hover={{
        bg: bgColor || colors.primary,
        opacity: rest.disabled ? 0.7 : 0.9,
      }}
      _disabled={{
        opacity: 0.6,
      }}
      cursor={rest.disabled ? "not-allowed" : "pointer"}
      isDisabled={rest.disabled}
      disabled={rest.disabled}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default OutlineButton;
