import { Box, Flex, Text, Textarea } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { useBreadcrumb } from "core/features/breadcrumbs";
import { useForm } from "react-hook-form";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import CardDefault from "ui/components/Cards/CardDefault";
import Field from "ui/components/Input/Field";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import useSaveAssuntoProcesso from "core/features/assunto-processo/hooks/useSaveAssuntoProcesso";
import useAssuntoProcesso from "core/features/assunto-processo/hooks/useAssuntoProcesso";
import {
  showErrorAxios,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import { AssuntoProcessoResponse } from "core/features/assunto-processo/types";

export default function AssuntoProcessoDetalhes() {
  const { id } = useParams() as {
    id: string;
  };

  const navigate = useNavigate();

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Assunto do Processo", url: "/assunto-processo" },
    { name: `${id ? "Editar " : "Adicionar "}assunto do processo` },
  ]);

  const { isLoading: isLoadingSave, mutate: handleSave } =
    useSaveAssuntoProcesso();

  const { data, isLoading } = useAssuntoProcesso(id);

  const { control, handleSubmit, reset } = useForm<AssuntoProcessoResponse>();

  const onSubmit = async (data: AssuntoProcessoResponse) => {
    handleSave(data, {
      onSuccess: async (response) => {
        await showSuccess();
        if (!id && response.data) {
          window.location.replace(`/assunto-processo`);
        }
      },
      onError: (err) => {
        showErrorAxios(err);
      },
    });
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  useLoading(isLoading);

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit(onSubmit)(ev);
      }}
    >
      <Box>
        <Text
          fontWeight="semibold"
          fontSize="30px"
          color="black"
          flexWrap="wrap"
          mb={4}
        >
          {id ? "Editar " : "Adicionar "} assunto do processo
        </Text>

        <CardDefault mb={4}>
          <Box as={Row} gutter={16}>
            <Box as={Col} md={24}>
              <Field
                name="nome"
                label="Assunto do processo"
                control={control}
                rules={{ required: true }}
                render={({ field, isInvalid }) => (
                  <Textarea
                    isInvalid={isInvalid}
                    className="chakra-input"
                    rows={4}
                    resize="none"
                    placeholder="Digite..."
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
        </CardDefault>

        <CardDefault>
          <Flex flex={1} justify="end" gap={4}>
            <OutlineButton onClick={() => navigate("/assunto-processo")}>
              Cancelar
            </OutlineButton>
            <PrimaryButton type="submit" isLoading={isLoadingSave}>
              Salvar
            </PrimaryButton>
          </Flex>
        </CardDefault>
      </Box>
    </form>
  );
}
