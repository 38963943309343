import { useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { ProcessoPaginateResponse } from "core/features/processos/typings";
import moment from "moment";
import colors from "core/resources/theme/colors";
import CardPerson3 from "ui/components/Cards/CardPerson3";
import TooltipResponsavel from "./TooltipResponsavel";
import PoppoverAssunto from "ui/pages/Admin/Processos/Listagem/components/PoppoverAssunto";
import { truncateText } from "core/infra/helpers/truncateText";

interface TableItemRowProps {
  item: ProcessoPaginateResponse;
  isMobile: boolean;
}

export default function TableItemRow({ item, isMobile }: TableItemRowProps) {
  const [isContentExpanded, setIsContentExpanded] = useState(false);

  const handleContentToggle = () => {
    setIsContentExpanded(!isContentExpanded);
  };

  return (
    <Flex px={5} py={2} align="center" justify="center">
      <Flex flex={isMobile ? 0.46 : 0.28}>
        <Text fontSize="16px">
          {moment(item.createdAt).format("DD/MM/YYYY")}
        </Text>
      </Flex>
      <Flex fontSize="16px" flex={isMobile ? 0.4 : 0.3}>
        {item.numeroProcesso}
      </Flex>
      <Flex flex={isMobile ? 0.6 : 1} mr={6}>
        <Flex>
          <Text fontSize="16px" wordBreak="normal">
            {truncateText(item.assunto, isMobile ? 30 : 100)}
            {item.assunto.length > (isMobile ? 30 : 100) && (
              <PoppoverAssunto
                assunto={item.assunto}
                children={
                  <Text
                    fontSize="16px"
                    onClick={(ev) => {
                      handleContentToggle();
                      ev.stopPropagation();
                    }}
                    color={colors.primary}
                    cursor="pointer"
                    display="inline"
                    ml="6px"
                  >
                    ...Ver mais
                  </Text>
                }
              />
            )}
          </Text>
        </Flex>
      </Flex>

      <Flex flex={0.45}>
        <TooltipResponsavel
          item={item}
          children={
            <Flex cursor="pointer" align="center" gap={2}>
              <CardPerson3
                nome={item.criador.nome}
                avatarUrl={item.criador.imagemUrl}
                avatarProps={{ w: "40px", h: "40px" }}
              />
              <Text textOverflow="ellipsis" fontSize="16px">
                {isMobile
                  ? item.criador.nome.split(" ").slice(0, 2).join(" ")
                  : item.criador.nome}
              </Text>
            </Flex>
          }
        />
      </Flex>
      <Flex flex={0.4}></Flex>
    </Flex>
  );
}
