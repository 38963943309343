import usePrioridades from "core/features/shared/hooks/usePrioridades";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";

import { useState } from "react";


export interface SelectPrioridadeProps<T = any> extends SelectBaseAntdProps<T> { }

function SelectPrioridade({ ...rest }: SelectPrioridadeProps) {
    const [search, setSearch] = useState<string>();

    const { isLoading, data } = usePrioridades();

    return (
        <SelectBaseAntd
            loading={isLoading}
            size="large"
            placeholder="Selecione..."
            searchValue={search}
            showSearch
            onSearch={setSearch}
            optionFilterProp="label"
            options={data?.map((item) => ({
                value: item.id,
                label: item.nome,
            }))}
            {...rest}
        />
    );
}

export default SelectPrioridade;
