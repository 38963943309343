import colors from "core/resources/theme/colors";
import styled from "styled-components";

export const InputContainer = styled.div<any>`
  .chakra-input:hover,
  .chakra-input:focus {
    color: ${colors.gray600};
    border-color: ${colors.gray300} !important;
    box-shadow: 0 0 0 1px ${colors.gray300} !important;
    outline-color: ${colors.gray300};
    transition: all 0.2s;
  }

  .chakra-input {
    border-radius: 6px;
    padding: 9px 16px;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) =>
    props.isInvalid ? colors.red400 : colors.labelBg} !important;
  }
`;
