import { Flex, Text } from "@chakra-ui/react";
import { Pagination as AntdPagination, PaginationProps, Select } from "antd";
import colors from "core/resources/theme/colors";
import { useState } from "react";
import styled from "styled-components";

function Pagination(props: PaginationProps) {
  if (props.total === 0) return null;

  const [pageSize, setPageSize] = useState<number>(10);

  const customTotal = (total: number, range: any) => {
    return `${range[0]}-${range[1]} de ${total}`;
  }

  const handlePageSizeChange = (value: number) => {
    setPageSize(value);
    props.onChange && props.onChange(1, value);
  };

  const { Option } = Select;

  return (
    <Container>
      {props.total! > 10 ?
        <Flex align='center' mr={2}>

          <Text mr={1}>Linhas por página:</Text>
          <Select
            value={props.pageSize}
            onSelect={handlePageSizeChange}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
            <Option value={40}>40</Option>
            <Option value={50}>50</Option>
          </Select>
        </Flex> : null}
      <Flex align='center' justify='center' pt='14px'>
        <AntdPagination
          showTotal={(total, range) => customTotal(total, range)}
          pageSize={pageSize}
          {...props}
        />
      </Flex>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  
  .ant-pagination-total-text{
    font-family: "Poppins", sans-serif !important;
  }

  .ant-pagination-item-link{
    background-color: ${colors.gray100}!important;
    border: 1px solid  ${colors.gray200}!important;
    margin-right: 2px !important;
    margin-left: 3px !important;

  }
  .ant-pagination-item {
    background-color: ${colors.gray100}!important;
    border: 1px solid  ${colors.gray200}!important;
    border-radius: 8px !important;
    margin-top: -2px !important;
    margin-right: 2px !important;
    font-family: "Poppins", sans-serif !important;
    align-items: center!important;
  }

  .ant-pagination-item-active {
    background-color: ${colors.gray200} !important;
   
  }
  
`;

export default Pagination;
