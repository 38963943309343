import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { AssuntoProcessoResponse } from "../types";

function useSaveAssuntoProcesso() {
  return useMutation(
    async (data: AssuntoProcessoResponse) => {
      if (data.id) {
        const response = await http.put<{ response: string }>(
          `/v1/assunto-processo/${data.id}`,
          data
        );

        return response;
      }
      const response = await http.post<{ response: string }>(
        `/v1/assunto-processo`,
        data
      );
      return response;
    },
    { retry: false }
  );
}

export default useSaveAssuntoProcesso;
