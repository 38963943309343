import { Box } from "@chakra-ui/react";
import PageHeader from "ui/components/Page/PageHeader";
import { useBreadcrumb } from "core/features/breadcrumbs";
import SearchHeaderBar from "ui/components/SearchHeaderBar";
import useFiltroProcessos from "./hooks/useFiltroProcessos";
import { BsPlus } from "react-icons/bs";
import ProcessosTable from "./components/ProcessosTable";
import useListarProcessos from "core/features/processos/hooks/useListarProcessos";
import { useState } from "react";
import ModalFiltrosProcessos from "./components/ModalFiltrosProcessos";
import ModalCriarEditarProcesso from "./components/ModalCriarEditarProcesso";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { checkPermission } from "core/infra/helpers/permission";
import DashboardProcessos from "./components/TabMenu/index";
import useDashboardProcessos from "core/features/processos/hooks/useDashboardProcessos";

export default function ListagemProcessosPage() {
  const { filtro, onChangeFiltro, onResetFiltro, setFiltro } =
    useFiltroProcessos();
  const { data, isLoading, refetch, isFetching } = useListarProcessos(filtro);
  const [isOpenModalFiltros, setIsOpenModalFiltros] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    data: dataDashboard,
    isLoading: isLoadingDashboard,
    refetch: refetchDashboard,
  } = useDashboardProcessos(filtro);

  useBreadcrumb([{ name: "Processos", url: "processos" }]);

  const numeroFiltroSelecionados =
    (filtro.assuntoId ? 1 : 0) +
    (filtro.numeroProcesso ? 1 : 0) +
    (filtro.resumo ? 1 : 0) +
    (filtro.interessadoIds ? 1 : 0) +
    (filtro.creatorId ? 1 : 0) +
    (filtro.dataInicio || filtro.dataFim ? 1 : 0);

  const createPermission = [PermissaoEnum.Fluir.Processo.Processo.Criar];

  const hasPermission = checkPermission(createPermission ?? []);

  return (
    <Box>
      <Box mb={10}>
        <PageHeader
          title="Processos"
          titleButton="Adicionar"
          onClickButton={() => setIsOpenModal(true)}
          iconButton={<BsPlus size={25} />}
          buttonDisabled={!hasPermission}
        />

        <DashboardProcessos
          setFiltro={setFiltro}
          data={dataDashboard}
          isLoading={isLoadingDashboard}
        />

        <SearchHeaderBar
          defaultValue={filtro.search || ""}
          onSearch={(v) => onChangeFiltro({ search: v })}
          maxWInputSearch={700}
          placeholder="Buscar"
          filtroButton
          filtroButtonOnClick={() => setIsOpenModalFiltros(true)}
          badgeValueExtraButton={numeroFiltroSelecionados}
          badge
          showOrderByButton
          orderByOptions={[
            {
              value: "createdAt",
              label: "Criado em",
            },
          ]}
          orderBy={filtro.orderBy}
          onOrderByChange={(orderBy) => {
            onChangeFiltro({
              orderBy,
            });
          }}
          orderDirection={filtro.orderDirection}
          onOrderDirectionChange={(orderDirection) => {
            onChangeFiltro({
              orderDirection,
            });
          }}
        />
      </Box>

      <ProcessosTable
        data={data}
        isLoading={isLoading || isFetching}
        onChangeFiltro={onChangeFiltro}
      />

      <ModalFiltrosProcessos
        filtro={filtro}
        visible={isOpenModalFiltros}
        onClose={() => setIsOpenModalFiltros(false)}
        onChangeFiltro={onChangeFiltro}
        onReset={onResetFiltro}
        refetchDashboard={refetchDashboard}
      />

      {isOpenModal ? (
        <ModalCriarEditarProcesso
          onClose={() => setIsOpenModal(false)}
          visible={isOpenModal}
          refetch={refetch}
        />
      ) : null}
    </Box>
  );
}
