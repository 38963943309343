import { Flex, BoxProps } from "@chakra-ui/layout";

interface CardDefaultTableProps extends BoxProps {
  w?: string;
  rounded?: string;
  px?: string;
  py?: string;
}

function CardDefaultTable({
  onClick,
  children,
  rounded = "16px",
  px = "14px",
  py = "16px",
  w,
  ...rest
}: CardDefaultTableProps) {
  return (
    <Flex
      flexDir="column"
      boxShadow="0 0 24px rgba(0,0,0,.05)"
      onClick={onClick}
      bg={"#FCFCFC"}
      border="1px solid #EDF2F7"
      rounded={rounded}
      px={px}
      py={py}
      w={w}
      cursor={typeof onClick === "function" ? "pointer" : "default"}
      _hover={{
        boxShadow:
          typeof onClick === "function" ? "0 0 32px rgba(0,0,0,.1)" : undefined,
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
}

export default CardDefaultTable;
