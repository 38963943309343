import { Select } from "antd";
import SelectBaseAntd, { SelectBaseAntdProps } from "../SelectBaseAntd";

const { Option } = Select;

export interface SelectTipoResponsavelProps<T = any> extends SelectBaseAntdProps<T> { }

function SelectTipoResponsavel({ value, isInvalid, ...rest }: SelectTipoResponsavelProps) {
    return (
        <SelectBaseAntd
            size="large"
            getPopupContainer={(node) => node.parentNode}
            placeholder="Selecione"
            value={value}
            {...rest}
        >
            <Option value={'setor'} key={'setor'}>
                Setor
            </Option>
            <Option value={'usuario'} key={'usuario'}>
                Usuário
            </Option>
        </SelectBaseAntd>
    );
}

export default SelectTipoResponsavel;
