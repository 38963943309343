"use client";
import Pages from "./ui/pages/index";
import GlobalStyle from "assets/styles/global";
import ChakraUiProvider from "./core/infra/packages/@chakra-ui/index";
import { ReactQueryProvider } from "core/infra/packages/@chakra-ui/react-query";
import { useEffect } from "react";
import http from "core/features/auth/hooks/useApi";
import { UserResponse } from "core/features/auth/types";
import { useAuthStore } from "core/features/auth/auth.store";
import { isAuthenticated } from "core/features/auth/hooks/IsAuthenticated";
// import { ErrorBoundary, FallbackProps } from "react-error-boundary";
// import { Box, Text } from "@chakra-ui/react";
// import colors from "./core/resources/theme/colors";
// import OutlineButton from "./ui/components/Buttons/OutlineButton/index";

function App() {
  const { user, setUser, setIsLoading } = useAuthStore();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await isAuthenticated();
        console.log({ session });
        console.log(session?.isValid());
        if (session?.isValid()) {
          const { data } = await http.get<UserResponse>("/v1/auth/me");

          setUser({
            id: data?.id,
            name: data.name,
            email: data.email,
            imagemUrl: data.imagemUrl,
            permissoes: data.permissoes,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (!user) {
      checkSession();
    }
  }, []);

  return (
    // <ErrorBoundary FallbackComponent={ErrorFallback}>
    <ReactQueryProvider>
      <ChakraUiProvider>
        <GlobalStyle />
        <Pages />
      </ChakraUiProvider>
    </ReactQueryProvider>
    // </ErrorBoundary>
  );
}

export default App;

// function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
//   return (
//     <Box>
//       <Text>Alguma coisa deu errado!</Text>
//       <Text color={colors.red600}>{error.message}</Text>
//       <OutlineButton onClick={resetErrorBoundary}>
//         Tentar novamente
//       </OutlineButton>
//     </Box>
//   );
// }
