import { Box, Textarea } from "@chakra-ui/react";
import { Col, Modal, Row } from "antd";
import useSaveAlerta from "core/features/processos/hooks/useSaveAlerta";
import { ProcessoAlertaResponse } from "core/features/processos/typings";
import { showErrorAxios, showSuccess } from "core/infra/helpers/alerta";
import { useForm } from "react-hook-form";
import FooterModalDefault from "ui/components/FooterModal";
import Field from "ui/components/Input/Field";
import SelectSetor from "ui/components/Input/SelectSetor";
import Toolbar from "ui/components/Toolbar";

interface ModalAlertasProps {
  isOpen: boolean;
  onClose: () => any;
  id?: string;
  refetch: any;
}

const defaultValues: ProcessoAlertaResponse = {
  processosAlertaSetorIds: [],
  descricao: "",
  expiracao: "",
};

export default function ModalAlertas({
  isOpen,
  onClose,
  id,
  refetch,
}: ModalAlertasProps) {
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveAlerta(id);
  const { control, handleSubmit, setError } = useForm<ProcessoAlertaResponse>({
    defaultValues,
  });

  const onSubmit = (data: ProcessoAlertaResponse) => {
    handleSave(data, {
      onSuccess: () => {
        showSuccess().then(() => {
          onClose();
          refetch();
        });
      },
      onError: (err) => {
        showErrorAxios(err);
      },
    });
  };

  const validarData = (value: string | number | Date) => {
    const dataAtual = new Date();
    const dataSelecionada = new Date(value);

    if (dataSelecionada <= dataAtual) {
      setError("expiracao", {
        type: "manual",
        message: "Por favor, selecione uma data futura.",
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Salvar"
          onclickPrimaryButton={() => handleSubmit(onSubmit)()}
          saveLoading={isLoadingSave}
        />
      }
      title={<Toolbar title="Alertas" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="700px"
    >
      <form>
        <Box>
          <Box as={Row} gutter={16} mb={8}>
            <Box as={Col} md={24}>
              <Field
                name="processosAlertaSetorIds"
                label="Setor"
                control={control}
                rules={{ required: true }}
                render={({ field, isInvalid }) => (
                  <SelectSetor
                    mode="multiple"
                    isInvalid={isInvalid}
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
          <Box as={Row} gutter={16} mb={8}>
            <Box as={Col} md={12}>
              <Field
                name="expiracao"
                label="Expira em"
                control={control}
                inputProps={{ type: "date" }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  validarData(e.target.value)
                }
              />
            </Box>
          </Box>

          <Box as={Row} gutter={16} mb={6}>
            <Box as={Col} md={24}>
              <Field
                name="descricao"
                label="Descrição"
                control={control}
                rules={{ required: true }}
                render={({ field, isInvalid }) => (
                  <Textarea
                    isInvalid={isInvalid}
                    className="chakra-input"
                    rows={4}
                    resize="none"
                    placeholder="Digite..."
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
