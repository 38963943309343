import { Input, InputProps } from "@chakra-ui/react";
import { InputContainer } from "./InputContainer";

interface InputDefaultProps extends InputProps {
  isInvalid?: boolean;
  ref?: any;
}

function InputDefault({ isInvalid, ref, ...props }: InputDefaultProps) {
  return (
    <InputContainer isInvalid={isInvalid}>
      <Input ref={ref} {...props} />
    </InputContainer>
  );
}

export default InputDefault;
