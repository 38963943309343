import { Select, SelectProps } from "antd";
import SelectBaseAntd from "../SelectBaseAntd";
import { TipoContatoEnum } from "core/resources/enums";

const { Option } = Select;

export interface SelectTipoContatoProps<T = any> extends SelectProps<T> {
  value: any;
  onChange: (v: any) => void;
}

function SelectTipoContato({ value, ...rest }: SelectTipoContatoProps) {
  return (
    <SelectBaseAntd
      size="large"
      getPopupContainer={(node) => node.parentNode}
      placeholder="Selecione"
      value={value}
      {...rest}
    >
      <Option value={TipoContatoEnum.Celular} key={TipoContatoEnum.Celular}>
        Celular
      </Option>
      <Option value={TipoContatoEnum.Email} key={TipoContatoEnum.Email}>
        E-mail
      </Option>
      <Option value={TipoContatoEnum.Telefone} key={TipoContatoEnum.Telefone}>
        Telefone
      </Option>
    </SelectBaseAntd>
  );
}

export default SelectTipoContato;
