import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { ProcessoAlertaResponse } from "../typings";

function useSaveAlerta(id?: string) {
  return useMutation(
    (data: ProcessoAlertaResponse) => {
      return http.post(`/v1/processo/${id}/alerta`, data);
    },
    { retry: false }
  );
}

export default useSaveAlerta;
