import { useQuery } from 'react-query';
import { FiltroFluxoProcesso, FluxoProcessoPaginateResponse } from '../typings/index';
import { PaginateResult } from 'core/resources/types/shared';
import http from 'core/features/auth/hooks/useApi';


export function useListarFluxosProcessos(query: FiltroFluxoProcesso) {
    return useQuery([`/v1/fluxo-processo`, query], async () => {
        const { data } = await http.get<PaginateResult<FluxoProcessoPaginateResponse>>(
            `/v1/fluxo-processo`,
            {
                params: query,
            }
        );

        return data;
    });
}

