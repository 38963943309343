import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { OrganizacaoResponse } from "../typings";


function useSaveOrganizacao() {
    return useMutation(
        (data: OrganizacaoResponse) => {
            if (data.id) {
                return http.put<{ response: string }>(
                    `/v1/organizacao/${data.id}`,
                    data
                );
            }
            return http.post<{ response: string }>(`/v1/organizacao`, data);
        },

        { retry: false }
    );
}

export default useSaveOrganizacao;
