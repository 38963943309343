import { Box, Flex, Text } from "@chakra-ui/react";
import { Checkbox, Col, Modal, Row, Spin } from "antd";
import { useForm } from "react-hook-form";
import FooterModalDefault from "ui/components/FooterModal";
import Toolbar from "ui/components/Toolbar";
import InputSearch from "ui/components/Input/InputSearch";
import colors from "core/resources/theme/colors";
import { useCallback, useMemo, useState } from "react";
import useListarProcessosAssociados from "core/features/processos/hooks/useListarProcessosAssociados";
import RowAssociados from "ui/pages/Admin/Processos/Detalhes/components/EmAndamento/FichaProcesso/components/TabFichaProcesso/ModalProcessosAssociados/RowAssociados";
import useSaveAssociado from "core/features/processos/hooks/useSaveAssociado";
import { ProcessoAssociadoResponse } from "core/features/processos/typings";
import { showErrorAxios, showSuccess } from "core/infra/helpers/alerta";

interface ModalProcessosAssociadosProps {
  isOpen: boolean;
  onClose: () => any;
  id?: string;
  dataAssociados?: {
    id: string;
    createdAt: string;
    numeroProcesso: string;
    assunto: string;
  }[];
  refetch: any;
}

const defaultValues: ProcessoAssociadoResponse = {
  processosAssociadosIds: [],
};

export default function ModalProcessosAssociados({
  isOpen,
  onClose,
  id,
  dataAssociados,
  refetch,
}: ModalProcessosAssociadosProps) {
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveAssociado(id);

  const [filtro, setFiltro] = useState({ search: "" });

  const onChangeFiltro = useCallback((_filtro: Partial<{ search: string }>) => {
    setFiltro((state) => ({ ...state, ..._filtro }));
  }, []);

  const [selectedAssosiadosIds, setSelectedAssosiadosIds] = useState<string[]>(
    []
  );

  const { data, isLoading } = useListarProcessosAssociados(filtro, id!);

  const associadosIds = dataAssociados?.map((objeto) => objeto.id);

  const dataProcessos = data?.items.filter(
    (item) => !associadosIds?.includes(item.id)
  );

  const { handleSubmit } = useForm<ProcessoAssociadoResponse>({
    defaultValues,
  });

  const onSubmit = (data: ProcessoAssociadoResponse) => {
    data.processosAssociadosIds = selectedAssosiadosIds;
    handleSave(data, {
      onSuccess: () => {
        showSuccess().then(() => {
          onClose();
          refetch();
        });
      },
      onError: (err) => {
        showErrorAxios(err);
      },
    });
  };

  const allAssociadosIds = useMemo(
    () => data?.items.map((item) => item.id) ?? [],
    [data]
  );

  const isInderteminate =
    selectedAssosiadosIds.length > 0 &&
    selectedAssosiadosIds.length < allAssociadosIds.length;

  const isChecked = selectedAssosiadosIds.length === allAssociadosIds.length;

  const selectAll = () => {
    if (isChecked) {
      setSelectedAssosiadosIds([]);
    } else setSelectedAssosiadosIds(allAssociadosIds);
  };

  return (
    <Modal
      open={isOpen}
      footer={
        <FooterModalDefault
          titleOutlineButton="Cancelar"
          onclickOutlineButton={onClose}
          titlePrimaryButton="Adicionar selecionados"
          onclickPrimaryButton={() => handleSubmit(onSubmit)()}
          disabledPrimaryButton={!selectedAssosiadosIds.length ?? true}
          saveLoading={isLoadingSave}
        />
      }
      title={<Toolbar title="Processos associado" onClose={onClose} />}
      destroyOnClose
      closable={false}
      maskClosable={false}
      width="762px"
      centered
    >
      <Box minH="632px">
        <Box as={Row} gutter={16} mb={8}>
          <Text mb={1} ml={2}>
            Buscar processo
          </Text>
          <Box as={Col} md={24}>
            <InputSearch
              defaultValue={filtro.search || ""}
              onSearch={(v) => onChangeFiltro({ search: v })}
              maxW={700}
              placeholder="Buscar"
              bg={colors.labelBg}
              isLoading={isLoading}
            />
          </Box>
        </Box>
        {isLoading ? (
          <Flex alignItems="center" justifyContent="center">
            <Spin size="large" spinning />
          </Flex>
        ) : null}

        {dataProcessos?.length ? (
          <Box>
            <Flex fontSize="16px" fontWeight="500" mb={1} ml={3} align="cente">
              <Flex flex={0.1} ml={1} align="center">
                <Checkbox
                  onChange={() => selectAll()}
                  checked={isChecked}
                  indeterminate={isInderteminate}
                />
              </Flex>
              <Flex flex={0.28}>Criado em</Flex>
              <Flex flex={0.38}>N° do processo</Flex>
              <Flex flex={1}>Assunto</Flex>
            </Flex>
          </Box>
        ) : null}

        {dataProcessos?.map((item) => (
          <RowAssociados
            key={item.id}
            item={item}
            selected={selectedAssosiadosIds}
            setSelected={setSelectedAssosiadosIds}
          />
        ))}
      </Box>
    </Modal>
  );
}
