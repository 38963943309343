import { Flex, Text } from "@chakra-ui/react";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { MouseEventHandler } from "react";

interface PageHeaderProps {
  title: string;
  iconButton?: any;
  titleButton?: string;
  onClickButton?: MouseEventHandler<HTMLButtonElement> | undefined;
  midComponent?: any;
  buttonDisabled?: boolean;
}

export default function PageHeader({
  title,
  iconButton,
  onClickButton,
  titleButton,
  midComponent,
  buttonDisabled = false,
}: PageHeaderProps) {
  return (
    <Flex gap={6} mb={4}>
      <Text fontSize="24px" fontWeight="500">
        {title}
      </Text>

      {titleButton ? (
        <PrimaryButton onClick={onClickButton} disabled={buttonDisabled}>
          {iconButton}
          {titleButton}
        </PrimaryButton>
      ) : null}

      {midComponent ? <Flex>{midComponent}</Flex> : null}
    </Flex>
  );
}
