import { Avatar, AvatarProps, Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import styled from "styled-components";

interface CardPerson3Props {
  avatarUrl?: string;
  nome?: string;
  avatarProps?: AvatarProps;
  backgroundColor?: string;
  fontSizeAvatar?: string;
  border?: string;
}

function obterIniciais(nome?: string): string {
  if (!nome) return "";
  const palavras = nome.split(" ");
  const iniciais = palavras.map((palavra) => palavra.charAt(0)).join("");

  return iniciais;
}
export default function CardPerson3({
  avatarUrl,
  nome,
  avatarProps,
  backgroundColor,
  fontSizeAvatar = "",
  border = `2px  solid ${colors.gray50}`,
}: CardPerson3Props) {
  return (
    <Flex borderRadius="full" border={border} fontSize="5px" p="1px">
      <Container fontSizeAvatar={fontSizeAvatar}>
        <Avatar
          name={obterIniciais(nome)}
          src={avatarUrl?.trim()}
          color="white"
          bg={
            backgroundColor
              ? backgroundColor
              : avatarUrl
                ? "none"
                : colors.primary
          }
          {...avatarProps}
        />
      </Container>
    </Flex>
  );
}

interface ContainerProps {
  fontSizeAvatar: string;
}
const Container = styled.div`
  .chakra-avatar__initials {
    font-size: ${(props: ContainerProps) => props.fontSizeAvatar}!important;
  }
`;
