import colors from "core/resources/theme/colors";
import styled from "styled-components";

export const InputContainer = styled.div<any>`
  .chakra-input:hover,
  .chakra-input:focus {
    border-color: ${(props) =>
        props.isInvalid ? colors.red100 : colors.gray300} !important;
    box-shadow: 0 0 0 1px ${(props) => (props.isInvalid ? colors.red100 : colors.gray300)} !important;
    transition: all 0.2s !important;
  }

  .chakra-input {
    font-size: 14px !important;
    background-color: ${colors.labelBg} !important;
    border-radius: 6px;
    padding: 9px 16px;
    width: 100%;
    border-width: ${(props) => (props.isInvalid ? "2px" : "1px")};
    border-style: solid;
    border-color: ${(props) =>
        props.isInvalid ? colors.red100 : colors.labelBg} !important;
  }
`;
