import { Box, Flex, Text } from "@chakra-ui/react";
import { Spin } from "antd";
import { showError } from "core/infra/helpers/alerta";
import colors from "core/resources/theme/colors";
import { IoMdTrash } from "react-icons/io";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu";
import CardDefault from "ui/components/Cards/CardDefault";
import CardPerson3 from "ui/components/Cards/CardPerson3";
import EmptyDefault from "ui/components/Empty";
import { UseFormSetValue } from "react-hook-form";
import { ProcessoDocumentoResponse } from "core/features/processos-documento/typings";

import useFindUsuarios from "core/features/shared/hooks/useFindUsuarios";

interface TablePermitirAcessoProps {
  form: ProcessoDocumentoResponse;
  setValue: UseFormSetValue<ProcessoDocumentoResponse>;
}

export default function TablePermitirAcesso({
  form,
  setValue,
}: TablePermitirAcessoProps) {
  const { data, isLoading } = useFindUsuarios(form.permitirAcessoPessoaIds);

  const onRemoveItem = (permitirAcessoPessoaId: string) => {
    if (form.permitirAcessoPessoaIds!.length <= 1) {
      showError(
        "Não é permitido excluir todos os registros. Deve haver pelo menos um registro."
      );
      return;
    }

    const permitirAcessoPessoaExcluidosIds =
      form.permitirAcessoPessoaExcluidosIds || [];

    const updatedAssinantes = form.permitirAcessoPessoaIds!.filter(
      (item) => item !== permitirAcessoPessoaId
    );

    if (!permitirAcessoPessoaExcluidosIds.includes(permitirAcessoPessoaId)) {
      permitirAcessoPessoaExcluidosIds.push(permitirAcessoPessoaId);
    }

    setValue("permitirAcessoPessoaIds", updatedAssinantes);
    setValue(
      "permitirAcessoPessoaExcluidosIds",
      permitirAcessoPessoaExcluidosIds
    );
  };

  return (
    <>
      {isLoading ? (
        <Flex alignItems="center" justifyContent="center">
          <Spin size="large" spinning />
        </Flex>
      ) : (
        <Flex flexDir="column" gap={4}>
          {data?.length ? (
            <Box>
              <Flex fontSize="16px" fontWeight={500} mb={4}>
                <Flex ml={5}>Usuário</Flex>
              </Flex>
              {data?.map((item, index) => (
                <CardDefault
                  rounded="8px"
                  padding="6px 20px"
                  mb="8px"
                  key={item.id}
                >
                  <Flex align="center" justify="space-between">
                    <Flex gap={2}>
                      <CardPerson3
                        nome={item.nome}
                        avatarUrl={item.imagemUrl}
                        fontSizeAvatar="12px"
                        border="none"
                        avatarProps={{ w: "25px", h: "25px", fontSize: "5px" }}
                      />
                      <Text mt={1}>{item.nome}</Text>
                    </Flex>

                    <Flex h="fit-content" py={4}>
                      <ActionsKebabMenu
                        indexItem={index}
                        item={item}
                        actions={[
                          {
                            title: "Excluir",
                            textColor: `${colors.red500}`,
                            onClick: (v) => onRemoveItem(v.pessoaId),
                            icon: () => <IoMdTrash />,
                          },
                        ]}
                      />
                    </Flex>
                  </Flex>
                </CardDefault>
              ))}
            </Box>
          ) : (
            <EmptyDefault size="sm" fontSize="12px" />
          )}
        </Flex>
      )}
    </>
  );
}
