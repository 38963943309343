import {
  Flex,
  Text,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import CardPerson3 from "ui/components/Cards/CardPerson3";
import { useState } from "react";
import { ProcessoPaginateResponse } from "core/features/processos/typings";

interface TooltipResponsavelProps {
  item: ProcessoPaginateResponse;
  children: React.ReactNode;
}

export default function TooltipResponsavel({
  item,
  children,
}: TooltipResponsavelProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <PopoverTrigger>
        <Flex
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {children}
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        width="100%"
        px={5}
        bg={colors.gray700}
        borderRadius="16px"
      >
        <PopoverArrow bg={colors.gray700} />
        <PopoverBody>
          <Flex align="center" gap={2}>
            <CardPerson3
              nome={item.criador.nome}
              avatarUrl={item.criador.imagemUrl}
              avatarProps={{ width: "50px", height: "50px" }}
            />
            <Flex flexDir="column">
              <Text fontSize="12px" color="white">
                {item.criador.nome}
              </Text>
              {item.criador.perfil ? (
                <Text fontSize="12px" color="white">
                  {item.criador.perfil}
                </Text>
              ) : null}
            </Flex>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
