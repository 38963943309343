import { useQuery } from "react-query";
import { PaginateResult } from "core/resources/types/shared";
import http from "core/features/auth/hooks/useApi";
import { AssuntosPaginatedResponse } from "../typings";

function useAssuntos(
    params?: any,
    onSuccess?: (data: PaginateResult<AssuntosPaginatedResponse>) => any
) {
    return useQuery(
        [`/v1/shared/assunto`, params],
        async () => {
            const { data } = await http.get<PaginateResult<AssuntosPaginatedResponse>>(
                `/v1/shared/assunto`,
                {
                    params: params,
                }
            );

            return data;
        },
        {
            onSuccess,
        }
    );
}

export default useAssuntos;
