import { Select, SelectProps } from "antd";
import SelectBaseAntd from "../SelectBaseAntd";

const { Option } = Select;

export interface SelectStatusProps<T = any> extends SelectProps<T> {
  value: any;
  onChange: (v: any) => void;
}

function SelectStatus({ value, ...rest }: SelectStatusProps) {
  return (
    <SelectBaseAntd
      size="large"
      getPopupContainer={(node) => node.parentNode}
      placeholder="Selecione"
      value={value}
      {...rest}
    >
      <Option value={"ativo"} key={"t"}>
        Ativo
      </Option>
      <Option value={"inativo"} key={"f"}>
        Inativo
      </Option>
    </SelectBaseAntd>
  );
}

export default SelectStatus;
