import { Flex } from "@chakra-ui/react";
import { Spin } from "antd";
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
} from "@chakra-ui/react";
import { Tree, Row, Col, Checkbox } from "antd";
import { useState } from "react";
import { flatten } from "flat";
import _ from "lodash";
import usePermissao from "core/features/permissao/hooks/usePermissao";
import { PermissaoResponse } from "core/features/permissao/typings";
import colors from "core/resources/theme/colors";
export interface InputTablePermissoesProps {
  onChange: (ids: string[]) => void;
  value: string[];
  readonly?: boolean;
  tenantId: string;
}

export const InputTablePermissoes = ({
  onChange,
  value,
  readonly,
  tenantId,
}: InputTablePermissoesProps) => {
  const [checkedKeys, setCheckedKeys] = useState<string[][]>([]);

  const { data, isLoading } = usePermissao(
    { tenantId },
    async (d: PermissaoResponse[]) => {
      d.forEach((item, index: number) => {
        const flatItems = flatten(item.children);

        const keys = Object.keys((flatItems as any) || {}).filter((item) =>
          item.includes(".key")
        );

        const values = keys.map((key) => {
          return (flatItems as any)[key];
        });

        const result: string[] = [];

        value.forEach((v) => {
          if (values.includes(v)) {
            result.push(v);
          }
        });

        checkedKeys[index] = result;
      });

      if (Array.isArray(checkedKeys)) {
        setCheckedKeys([...checkedKeys]);
      } else {
        setCheckedKeys([]);
      }
    }
  );

  const renderTreeData = (data: PermissaoResponse[]): any[] => {
    return data.map((child) => ({
      key: child.key,
      title: child.tag,
      children: renderTreeData(child.children),
    }));
  };

  const onCheck = (checkedKeysValue: string | string[], index: number) => {
    checkedKeys[index] = checkedKeys[index] ?? [];

    if (Array.isArray(checkedKeysValue)) {
      checkedKeys[index] = checkedKeysValue;
    } else {
      checkedKeys[index] = [checkedKeysValue];
    }

    if (Array.isArray(checkedKeys)) {
      setCheckedKeys([...checkedKeys]);
    } else {
      setCheckedKeys([]);
    }

    onChange(
      checkedKeys.reduce((agg, item) => {
        if (Array.isArray(item)) {
          agg.push(...item);
        } else if (typeof item === "string") {
          agg.push(item);
        }
        return agg;
      }, [])
    );
  };

  return (
    <>
      {isLoading ? (
        <Flex alignItems="center" justifyContent="center">
          <Spin size="large" spinning />
        </Flex>
      ) : null}

      {!isLoading ? (
        <Flex flexDir={"column"} gap="16px">
          <Accordion
            defaultIndex={data?.map((_item, index) => index)}
            allowMultiple
            fontFamily={"Poppins,sans-serif"}
          >
            <Row gutter={32}>
              {data?.map((item, index) => {
                const flatItems = flatten(item.children);

                const keys = Object.keys((flatItems as any) || {}).filter(
                  (item) => item.includes(".key")
                );

                const values = keys.map((key) => {
                  return (flatItems as any)[key];
                });

                const checkAll = item.children.every(
                  (child) => checkedKeys[index]?.includes(child.key)
                );

                return (
                  <Col sm={24} key={item.key}>
                    <AccordionItem
                      rounded="8px"
                      bg="white"
                      mb={2}
                      border={`solid 1px ${colors.gray100}`}
                    >
                      <AccordionButton
                        _hover={{ bg: "transparent" }}
                        fontFamily={"Poppins,sans-serif"}
                      >
                        <Flex fontSize="14px" flex="1" textAlign="left">
                          <Flex>
                            <Checkbox
                              checked={checkAll}
                              disabled={readonly}
                              indeterminate={
                                item.children.some(
                                  (child) =>
                                    checkedKeys[index]?.includes(child.key)
                                ) && !checkAll
                              }
                              onClick={(ev) => {
                                ev.stopPropagation();
                              }}
                              onChange={(ev) => {
                                if (!ev.target.checked) {
                                  checkedKeys[index] = checkedKeys[
                                    index
                                  ]?.filter((check) => !values.includes(check));

                                  onCheck(checkedKeys[index] ?? [], index);
                                } else {
                                  const nextValue = _.union(
                                    checkedKeys[index],
                                    values
                                  );

                                  onCheck(nextValue, index);
                                }
                              }}
                            />
                          </Flex>
                          <Flex
                            fontWeight="500"
                            fontSize="14px"
                            flex={1}
                            pl="8px"
                          >
                            {item.tag}
                          </Flex>
                          <Flex fontSize="16px" fontWeight="400">
                            {checkedKeys[index]?.length ?? 0}/{values.length}{" "}
                            permissões concedidas
                          </Flex>
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel>
                        <Tree
                          style={{ fontFamily: "Poppins,sans-serif" }}
                          checkable
                          disabled={readonly}
                          onCheck={(v) => {
                            onCheck(v as string[], index);
                          }}
                          checkedKeys={checkedKeys[index] ?? []}
                          treeData={renderTreeData(item.children)}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Col>
                );
              })}
            </Row>
          </Accordion>
        </Flex>
      ) : null}
    </>
  );
};

export default InputTablePermissoes;
