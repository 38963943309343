import { SelectProps } from "antd";
import SelectBaseFiltro from "../SelectBaseFiltro";
import { useState } from "react";
import useCargos from "core/features/cargos/hooks/useCargos";

export interface SlectFiltroCargosProps<T = any> extends SelectProps<T> {
  value: any;
  onChange: (v: any) => void;
}

function SlectFiltroCargos({ value, ...rest }: SlectFiltroCargosProps) {
  const [search, setSearch] = useState<string>();

  const { isLoading, data } = useCargos();

  return (
    <SelectBaseFiltro
      loading={isLoading}
      size="large"
      searchValue={search}
      onSearch={setSearch}
      getPopupContainer={(node) => node.parentNode}
      placeholder="Todos os cargos"
      value={value}
      options={data?.map((item) => ({
        value: item.id,
        label: item.nome,
      }))}
      {...rest}
    />
  );
}

export default SlectFiltroCargos;
