
import { FiltroFluxoProcesso } from "core/features/tipoprocesso/typings";
import { BaseFiltroPaginate } from "core/resources/enums/paginate";
import { useCallback, useState } from "react";

export const filtroDefaultUsuarios: FiltroFluxoProcesso = {
    limit: BaseFiltroPaginate.Limit,
    search: "",
    page: 1,
};

function useFiltroFluxoProcessos() {
    const [filtro, setFiltro] = useState<FiltroFluxoProcesso>(filtroDefaultUsuarios);

    const onChangeFiltro = useCallback((_filtro: Partial<FiltroFluxoProcesso>) => {
        if ((_filtro.search) !== undefined) {
            _filtro.page = 1;
        }
        setFiltro((state) => ({ ...state, ..._filtro }));
    }, []);

    return {
        filtro,
        setFiltro,
        onChangeFiltro,
    };
}

export default useFiltroFluxoProcessos;
