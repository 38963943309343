import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { NaturezaProcessoResponse } from "../types";

function useNaturezaProcesso(id?: string) {
  return useQuery(
    `/v1/natureza-processo/${id}`,
    async () => {
      const res = await http.get<NaturezaProcessoResponse>(
        `/v1/natureza-processo/${id}`
      );

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useNaturezaProcesso;
