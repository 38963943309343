import { MdSwapVert } from "react-icons/md";
import { useState } from "react";
import ModalOrderBy from "./../ModalOrderBy/index";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import colors from "core/resources/theme/colors";

interface OrderByButtonProps {
  onOrderDirectionChange: (q?: string) => void;
  orderDirection?: string | undefined;
  onOrderByChange: (q?: string) => void;
  orderBy?: string | undefined;
  options: {
    value: string;
    label: string;
  }[];
}

export default function OrderByButton({
  onOrderDirectionChange,
  orderBy,
  orderDirection,
  onOrderByChange,
  options,
}: OrderByButtonProps) {
  const [openModal, SetOpenModal] = useState(false);
  return (
    <>
      <OutlineButton
        bgColor="white"
        borderColor={colors.labelBg}
        onClick={() => SetOpenModal(true)}
        px={4}
        h={10}
      >
        <MdSwapVert size={20} /> Ordenar por
      </OutlineButton>
      <ModalOrderBy
        onClose={() => SetOpenModal(false)}
        openModal={openModal}
        onOrderDirectionChange={onOrderDirectionChange}
        orderDirection={orderDirection}
        orderBy={orderBy}
        onOrderByChange={onOrderByChange}
        options={options}
      />
    </>
  );
}
