import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Box } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";
import { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import styled from "styled-components";

export interface InputSearchProps {
  onSearch: (q?: string) => void;
  defaultValue: string;
  placeholder?: string;
  maxW?: number | string;
  bg?: string;
  isLoading?: boolean;
}

function InputSearch({
  onSearch,
  defaultValue,
  placeholder = "Procurar",
  maxW = 350,
  bg = "white",
  isLoading,
}: InputSearchProps) {
  const [search, update] = useState<string>();

  useEffect(() => {
    update(defaultValue);
  }, [defaultValue]);

  const handleEnder = (ev: any) => {
    if (ev.keyCode === 13) {
      onSearch(search);
    }
  };

  return (
    <>
      <Box maxW={maxW} w="100%" flexDir="row" pos="relative">
        <Container>
          <Input
            value={search}
            onChange={(v) => update(v.target.value)}
            placeholder={placeholder}
            onKeyDown={(ev) => handleEnder(ev)}
            pr="48px"
            rounded="8px"
            border={`1px solid ${colors.labelBg}`}
            box-shadow=" 0px 12px 24px -4px #919EAB1F"
            color={colors.gray700}
            bg={bg}
          />
        </Container>
        <Button
          isLoading={isLoading}
          padding={0}
          right="-1px"
          w="50px"
          h="42px"
          top="-1px"
          pos="absolute"
          zIndex="1"
          rounded="8px"
          bg={colors.primary}
          _hover={{
            opacity: 0.8,
          }}
          _active={{
            bg: colors.primary,
          }}
          color="white"
          onClick={() => onSearch(search)}
        >
          <MdSearch size={25} />
        </Button>
      </Box>
    </>
  );
}

export default InputSearch;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  .chakra-input:hover,
  .chakra-input:focus {
    color: ${colors.gray700};

    border-color: ${colors.gray300};
    box-shadow: 0 0 0 1px ${colors.gray300} !important;
  }
`;
