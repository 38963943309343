import { Flex } from "@chakra-ui/react";
import { AndamentosResponse } from "core/features/tipoprocesso/typings";
import { useWindowSize } from "core/infra/helpers/window";
import colors from "core/resources/theme/colors";
import { GoArrowDown } from "react-icons/go";
import { IoIosArrowRoundForward } from "react-icons/io";

export default function Fluxograma({
  item,
  onClick,
  isSelected,
  data,
}: {
  item: AndamentosResponse;
  onClick?: React.MouseEventHandler;
  isSelected?: boolean;
  data?: {
    id: string;
    nome: string;
  }[];
}) {
  const setor = data?.filter((v: { id: string }) => v.id === item.setorId);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 992;
  return (
    <Flex flexDir="column" align="center" gap={2}>
      <Flex align="center" gap={2} position="relative">
        <Flex
          w={isMobile ? "fit-content" : "400px"}
          minW="200px"
          h="35px"
          rounded="4px"
          align="center"
          justify="center"
          cursor="pointer"
          onClick={onClick}
          position="relative"
          background={isSelected ? colors.primary : colors.gray200}
          color={isSelected ? "white" : ""}
        >
          {setor?.[0].nome}
        </Flex>
        {isSelected ? (
          <Flex position="absolute" left={isMobile ? "250px" : "420px"}>
            <IoIosArrowRoundForward size={50} color={colors.primary} />
          </Flex>
        ) : null}
      </Flex>
      <GoArrowDown color={colors.gray500} size={25} />
    </Flex>
  );
}
