import { Flex, Text, Box } from "@chakra-ui/layout";
import { Divider, MenuItem } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { Menu, MenuButton, MenuList } from "@chakra-ui/menu";
import CardPerson from "ui/components/Cards/CardPerson";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "core/features/auth/auth.store";
import { PerfilTabsRoutes } from "ui/pages/Admin/EditarPerfil/typings";

function MenuUser() {
  const auth = useAuthStore();
  const navigate = useNavigate();

  return (
    <Flex alignItems="center" pr="16px">
      <Menu>
        <MenuButton>
          <Flex
            align="center"
            justifyContent="center"
            margin="auto"
            rounded="full"
            textAlign="center"
            p="4px"
            fontSize="18px"
            w="40px"
            h="40px"
            fontWeight="bold"
            color={colors.primary}
            bg={colors.gray200}
          >
            {auth.user?.imagemUrl ? (
              <CardPerson
                avatarUrl={auth.user?.imagemUrl}
                avatarProps={{ w: 35, h: 35 }}
              />
            ) : (
              (auth.user?.name || auth.user?.email)?.split("")[0]
            )}
          </Flex>
        </MenuButton>

        <MenuList borderRadius="16px" p="40px 35px">
          <Flex alignItems="center" flexDir="column">
            <Box>
              <Flex
                align="center"
                justifyContent="center"
                margin="auto"
                rounded="full"
                textAlign="center"
                p="4px"
                fontSize="18px"
                w="40px"
                h="40px"
                fontWeight="bold"
                color={colors.primary}
                bg={colors.gray200}
              >
                {auth.user?.imagemUrl ? (
                  <CardPerson
                    avatarUrl={auth.user?.imagemUrl}
                    avatarProps={{ w: 50, h: 50 }}
                  />
                ) : (
                  (auth.user?.name || auth.user?.email)?.split("")[0]
                )}
              </Flex>
            </Box>

            <Text color={colors.primary} mt="16px">
              {` Olá,${" "}${auth.user?.name
                ?.split(" ")
                .slice(0, 2)
                .join(" ")}!`}
            </Text>
            <Divider border={`solid 1px ${colors.gray300}`} my={2} />
          </Flex>
          <Box>
            <MenuItem
              bg="none"
              p={0}
              _hover={{ bg: "none", opacity: 0.6 }}
              onClick={() =>
                navigate(
                  `/editar-perfil?opcao=${PerfilTabsRoutes.InformacoesBasicas}`
                )
              }
            >
              Editar meu perfil
            </MenuItem>
            <MenuItem
              p={0}
              _hover={{ bg: "none", opacity: 0.6 }}
              onClick={() =>
                navigate(`/editar-perfil?opcao=${PerfilTabsRoutes.TrocarSenha}`)
              }
            >
              Trocar senha
            </MenuItem>
            <MenuItem
              p={0}
              _hover={{ bg: "none", opacity: 0.6 }}
              color={colors.red600}
              onClick={auth.signout}
            >
              Sair
            </MenuItem>
          </Box>
        </MenuList>
      </Menu>
    </Flex>
  );
}

export default MenuUser;
