import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { OrganizacaoResponse } from "../typings";


function useOrganizacao(id?: string) {
    return useQuery(
        `/v1/organizacao/${id}`,
        async () => {
            const res = await http.get<OrganizacaoResponse>(`/v1/organizacao/${id}`);

            return res.data;
        },
        {
            enabled: !!id,
        }
    );
}

export default useOrganizacao;
