import http from "core/features/auth/hooks/useApi";
import { useQuery } from "react-query";
import { FluxoProcessoResponse } from "../typings";


function useFluxoProcesso(id?: string) {
  return useQuery(
    `/v1/fluxo-processo/${id}`,
    async () => {
      const res = await http.get<FluxoProcessoResponse>(`/v1/fluxo-processo/${id}`);

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useFluxoProcesso;
