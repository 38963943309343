const primary = "#8204D2";

const labelBg = "#EDF2F7";

const smoke = "#DEDFE0";

const gray900 = "#171923";
const gray800 = "#1A202C";
const gray700 = "#2D3748";
const gray600 = "#171923";
const gray500 = "#4A5568";
const gray400 = "#718096";
const gray300 = "#CBD5E0";
const gray200 = "#E2E8F0";
const gray100 = "#EDF2F7";
const gray50 = "#F7FAFC";

const red600 = "#C53030";
const red500 = "#E53E3E";
const red400 = "#F56565";
const red200 = "#FEB2B2";
const red100 = "#FED7D7";
const red50 ='#FFF5F5'

const orange600 = "#C05621";
const orange500 = "#DD6B20";
const orange400 = "#ED8936";
const orange200 = "#FBD38D";
const orange100 = "#FEEBC8";

const green600 = "#2F855A";
const green500 = "#38A169";
const green400 = "#48BB78";
const green200 = "#9AE6B4";
const green100 = "#C6F6D5";

const cyan600 = "#00A3C4";
const cyan500 = "#00B5D8";
const cyan400 = "#0BC5EA";
const cyan200 = "#9DECF9";
const cyan100 = "#C4F1F9";

const blue800 = "#153E75";
const blue600 = "#2A69AC";
const blue300 = "#63B3ED";
const blue100 = "#CEEDFF";

const porple50 =  '#F1DCFE'
const purple100 = "#E9D8FD";
const purple600 = "#6B46C1";

const colors = {
  primary,

  labelBg,
  gray50,
  gray100,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
  gray800,
  gray700,
  gray900,

  red50,
  red100,
  red200,
  red400,
  red500,
  red600,

  green100,
  green200,
  green400,
  green500,
  green600,

  orange100,
  orange200,
  orange400,
  orange500,
  orange600,

  cyan100,
  cyan200,
  cyan400,
  cyan500,
  cyan600,

  blue100,
  blue300,
  blue600,
  blue800,

  porple50,
  purple100,
  purple600,

  smoke


};

export default colors;
