import { Box, Flex, Text } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { useBreadcrumb } from "core/features/breadcrumbs";
import { useForm } from "react-hook-form";
import CardDefault from "ui/components/Cards/CardDefault";
import Field from "ui/components/Input/Field";
import { useNavigate, useParams } from "react-router-dom";
import SelectStatusUsuario from "ui/components/Input/SelectStatus/SelectStatus";
import useSaveCargo from "core/features/cargos/hooks/useSaveCargo";
import { CargoResponse } from "core/features/cargos/typings";
import {
  showErrorAxios,
  showSuccess,
  useLoading,
} from "core/infra/helpers/alerta";
import OutlineButton from "ui/components/Buttons/OutlineButton";
import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { useCurrentTenant } from "core/features/auth/tenant.store";
import useCargo from "core/features/cargos/hooks/useCargo";
import { useEffect } from "react";
import InputTablePermissoes from "ui/components/Input/InputPermissoes";

export default function CargoDetalhes() {
  const { id } = useParams() as {
    id: string;
  };

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Cargos", url: "/cargo" },
    { name: id ? "Editar cargo" : "Cadastro de cargo" },
  ]);

  const { tenant } = useCurrentTenant();

  const navigate = useNavigate();

  const { isLoading: isLoadingSave, mutate: handleSave } = useSaveCargo(
    tenant.id
  );

  const { data, isLoading, refetch } = useCargo(id);

  const defaultValues: Partial<CargoResponse> = {
    permissaoIds: [],
    ativo: undefined,
    nome: "",
  };

  const { control, handleSubmit, reset, watch } = useForm<CargoResponse>({
    defaultValues,
  });

  const nome = watch("nome");

  const onSubmit = async (data: CargoResponse) => {
    handleSave(
      {
        ...data,
        ativo: data.ativo === "ativo" ? true : false,
      },
      {
        onSuccess: (response) => {
          showSuccess().then(async () => {
            if (!id && response.data) {
              window.location.replace(`/cargo/${response.data}`);
            }
          });
        },
        onError: (err) => {
          showErrorAxios(err);
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  useEffect(() => {
    refetch();
  }, [id, data, refetch]);

  useLoading(isLoading);

  return (
    <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
      <Box>
        <Flex justifyContent="space-between">
          <Text
            fontWeight="500"
            fontSize="24px"
            color="black"
            flexWrap="wrap"
            mb={4}
          >
            {id ? "Editar cargo" : "Cadastro de cargo"}
          </Text>
          <Flex>
            <Flex flex={1} justifyContent="flex-end" gap={4}>
              <OutlineButton onClick={() => navigate("/cargo")}>
                Cancelar
              </OutlineButton>
              <PrimaryButton type="submit" isLoading={isLoadingSave}>
                Salvar
              </PrimaryButton>
            </Flex>
          </Flex>
        </Flex>

        <CardDefault mb={4}>
          <Box as={Row} gutter={16}>
            <Box as={Col} sm={18}>
              <Field
                name="nome"
                label="Nome do cargo"
                control={control}
                rules={{ required: true }}
              />
            </Box>
            <Box as={Col} sm={6}>
              <Field
                label="Status"
                control={control}
                name={"ativo"}
                rules={{ required: true }}
                render={({ field }) => <SelectStatusUsuario {...field} />}
              />
            </Box>
          </Box>
        </CardDefault>
        <CardDefault>
          <Text fontWeight="semibold" fontSize="18px">
            Permissão concedidas
          </Text>
          <Box mt="16px">
            {(nome && !isLoading) || !id ? (
              <Field
                shouldUnregister
                control={control}
                name="permissaoIds"
                render={({ field }) => (
                  <InputTablePermissoes
                    tenantId={tenant.id}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            ) : null}
          </Box>
        </CardDefault>
      </Box>
    </form>
  );
}
