import { Box, Flex, Text } from "@chakra-ui/react";
import { Spin } from "antd";
import {
  showConfirm3,
  showSuccess,
  useLoading,
  showError,
} from "core/infra/helpers/alerta";
import colors from "core/resources/theme/colors";
import { IoMdTrash } from "react-icons/io";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu";
import CardDefault from "ui/components/Cards/CardDefault";
import CardPerson3 from "ui/components/Cards/CardPerson3";
import EmptyDefault from "ui/components/Empty";
import useDeletePermitirAcesso from "core/features/processos/hooks/useDeletePermitirAcesso";

interface TablePermitirAcessoProps {
  items?: {
    id: string;
    usuario: {
      id: string;
      pessoaId: string;
      nome: string;
      imagemUrl?: string;
    };
  }[];
  refetch?: any;
  isFetching: boolean;
  isLoading: boolean;
}

export default function TablePermitirAcesso({
  items,
  refetch,
  isFetching,
  isLoading,
}: TablePermitirAcessoProps) {
  const { mutate: deletePermitirAcesso, isLoading: isLoadingDelete } =
    useDeletePermitirAcesso();

  const handleDelete = async (id: string) => {
    if (items && items.length <= 1) {
      showError(
        "Não é permitido excluir todos os registros. Deve haver pelo menos um registro."
      );
      return;
    }

    const { isConfirmed } = await showConfirm3({
      mensagem:
        "Você tem certeza que deseja excluir o registro definitivamente?",
    });

    if (isConfirmed) {
      deletePermitirAcesso(id, {
        onSuccess: () => {
          refetch();
          setTimeout(() => {
            showSuccess("Seu registro foi excluido com sucesso!");
          }, 200);
        },
      });
    }
  };

  useLoading(isLoadingDelete);

  return (
    <>
      {isFetching || isLoading || isLoadingDelete ? (
        <Flex alignItems="center" justifyContent="center">
          <Spin size="large" spinning />
        </Flex>
      ) : (
        <Flex flexDir="column" gap={4}>
          {items?.length ? (
            <Box>
              <Flex fontSize="16px" fontWeight={500} mb={4}>
                <Flex ml={5}>Usuário</Flex>
              </Flex>
              {items?.map((item, index) => (
                <CardDefault
                  rounded="8px"
                  padding="6px 20px"
                  mb="8px"
                  key={item.id}
                >
                  <Flex align="center" justify="space-between">
                    <Flex gap={2}>
                      <CardPerson3
                        nome={item.usuario.nome}
                        avatarUrl={item.usuario.imagemUrl}
                        fontSizeAvatar="12px"
                        border="none"
                        avatarProps={{ w: "25px", h: "25px", fontSize: "5px" }}
                      />
                      <Text mt={1}>{item.usuario.nome}</Text>
                    </Flex>

                    <Flex h="fit-content" py={4}>
                      <ActionsKebabMenu
                        indexItem={index}
                        item={item}
                        actions={[
                          {
                            title: "Excluir",
                            textColor: `${colors.red500}`,
                            onClick: (v) => handleDelete(v.id),
                            icon: () => <IoMdTrash />,
                          },
                        ]}
                      />
                    </Flex>
                  </Flex>
                </CardDefault>
              ))}
            </Box>
          ) : (
            <EmptyDefault size="sm" fontSize="12px" />
          )}
        </Flex>
      )}
    </>
  );
}
