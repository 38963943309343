import { Flex, Skeleton } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { useState } from "react";
import { MdDraw, MdTaskAlt } from "react-icons/md";
import { TbProgress } from "react-icons/tb";
import { FiltrosProcessos } from "core/features/processos/typings";

interface DashboardProcessosProps {
  setFiltro: React.Dispatch<React.SetStateAction<FiltrosProcessos>>;
  data?: {
    totalVinculado: number;
    totalAssinar: number;
  };
  isLoading: boolean;
}

export default function DashboardProcessos({
  setFiltro,
  isLoading,
  data,
}: DashboardProcessosProps) {
  const [valores, setValores] = useState({
    valorA: false,
    valorB: false,
  });

  const selecionarValor = (valorSelecionado: string) => {
    if (valorSelecionado === "A") {
      setValores((prevState) => ({
        valorA: !prevState.valorA,
        valorB: false,
      }));
      setFiltro({ vinculado: !valores.valorA });
    } else if (valorSelecionado === "B") {
      setValores((prevState) => ({
        valorA: false,
        valorB: !prevState.valorB,
      }));
      setFiltro({ assinar: !valores.valorB });
    }
  };

  return (
    <Flex
      border={`solid 1px ${colors.labelBg}`}
      rounded={12}
      w="max-content"
      boxShadow="0 0 24px rgba(0,0,0,.05)"
      bg={"#FCFCFC"}
      mb={4}
    >
      <Flex
        onClick={() => selecionarValor("A")}
        bg={valores.valorA ? colors.primary : ""}
        color={valores.valorA ? "white" : ""}
        rounded={12}
        cursor="pointer"
        px={4}
        py={3}
      >
        {isLoading ? (
          <Skeleton h={8} flex={1} w="211px" />
        ) : (
          <Flex align="center" gap={2}>
            <TbProgress size={20} />
            Vinculados a mim
            <Flex
              bg={valores.valorA ? "white" : colors.gray100}
              color={valores.valorA ? colors.primary : ""}
              rounded={6}
              px={3}
            >
              {data?.totalVinculado}
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex
        onClick={() => selecionarValor("B")}
        bg={valores.valorB ? colors.primary : ""}
        color={valores.valorB ? "white" : ""}
        rounded={12}
        cursor="pointer"
        px={4}
        py={3}
      >
        {isLoading ? (
          <Skeleton h={8} flex={1} w="165px" />
        ) : (
          <Flex align="center" gap={2}>
            <MdDraw size={20} />
            Para assinar
            <Flex
              bg={valores.valorB ? "white" : colors.gray100}
              color={valores.valorB ? colors.primary : ""}
              rounded={6}
              px={3}
            >
              {data?.totalAssinar}
            </Flex>
          </Flex>
        )}
      </Flex>
      {/* <Flex
        onClick={() => selecionarValor("C")}
        bg={valores.valorC ? colors.primary : ""}
        color={valores.valorC ? "white" : ""}
        rounded={12}
        cursor="pointer"
        px={4}
      >
        <Flex align="center" gap={2}>
          <MdTaskAlt size={20} />
          Para autorizar
          <Flex
            bg={valores.valorC ? "white" : colors.gray100}
            color={valores.valorC ? colors.primary : ""}
            rounded={6}
            px={3}
          >
            100
          </Flex>
        </Flex>
      </Flex> */}
    </Flex>
  );
}
