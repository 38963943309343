import {
  Text,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PopoverHeader,
} from "@chakra-ui/react";
import colors from "core/resources/theme/colors";

interface PoppoverAssuntoProps {
  assunto: string;
  children: React.ReactNode;
}

export default function PoppoverAssunto({
  assunto,
  children,
}: PoppoverAssuntoProps) {
  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        maxW="400px"
        width="100%"
        px={5}
        bg={colors.gray700}
        borderRadius="16px"
        color="white"
        zIndex={9999}
      >
        <PopoverArrow bg={colors.gray700} />
        <PopoverHeader>Assunto</PopoverHeader>
        <PopoverBody>
          <Text>{assunto}</Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
