import { Text } from "@chakra-ui/react";
import { Empty, EmptyProps } from "antd";
import empty from "assets/images/svg/empyt.svg";
import colors from "core/resources/theme/colors";

interface EmptyDefaultProps extends EmptyProps {
  fontSize?: string;
  size?: "md" | "sm";
}

export default function EmptyDefault({
  description = "Nenhum registro encontrado",
  fontSize = "18px",
  size = "md",
  ...rest
}: EmptyDefaultProps) {
  return (
    <Empty
      description={
        <Text color={colors.gray400} fontSize={fontSize}>
          {description}
        </Text>
      }
      image={empty}
      imageStyle={{
        height: size === "md" ? "100px" : "60px",
        width: size === "md" ? "60.63px" : "40.30px",
        margin: "auto",
      }}
      {...rest}
    />
  );
}
