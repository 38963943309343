import { Button, Flex } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";

interface Option {
  label: string;
  icon?: any;
  route: any;
  count?: number;
  permissions: string[];
}

interface MenuDetalhesButtonProps {
  onChangeOption: (option: string) => any;
  option: Option;
  isSelected?: boolean;
  isDisabled?: boolean;
  light?: boolean;
}

function MenuDetalhesButton({
  option,
  isSelected,
  isDisabled,
  light,
  onChangeOption,
}: MenuDetalhesButtonProps) {
  return (
    <Button
      key={option.route}
      w={light ? undefined : "100%"}
      py={light ? "12px" : "15px"}
      h="auto"
      gap={2}
      rounded={light ? 8 : 0}
      px={"16px"}
      boxShadow="md"
      fontSize="16px"
      fontWeight="400"
      wordBreak="break-all"
      onClick={isDisabled ? ()=>'' : () => onChangeOption(option.route)}
      disabled={isDisabled}
      _disabled={{ backgroundColor: "white" }}
      cursor={isDisabled ? "not-allowed" : undefined}
      mx={light ? 4 : 0}
      bg={isSelected ? (light ? colors.primary : colors.primary) : "white"}
      color={
        isSelected
          ? light
            ? colors.primary
            : "white"
          : isDisabled
          ? colors.smoke
          : undefined
      }
      textAlign="left"
      justifyContent="flex-start"
      _hover={
        isDisabled
          ? { backgroundColor: "white" }
          : isSelected
          ? { backgroundColor: colors.primary }
          : undefined
      }
    >
      <Flex justify="space-between" align="center" w="100%">
        <Flex w='100%' justifyContent='space-between' align='center'>
          <Flex align='center' gap={2}>{option.icon}{option.label}</Flex>
          <Flex>{option.count}</Flex>
        </Flex>
      </Flex>
    </Button>
  );
}

export default MenuDetalhesButton;
