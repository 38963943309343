import { Flex, Text } from "@chakra-ui/react";
import { ConfigProvider, Select, SelectProps, Spin } from "antd";
import { useEffect, useRef } from "react";
import colors from "core/resources/theme/colors";
import { Global } from "./styles";
import EmptyDefault from "ui/components/Empty";

const { Option } = Select;

export interface SelectBaseFiltroProps<T = any> extends SelectProps<T> {
  isInvalid?: boolean;
  bg?: string;
}

function SelectBaseFiltro({ isInvalid, ...props }: SelectBaseFiltroProps) {
  const { onChange, defaultValue } = props;
  const options = props.options?.map((d) => (
    <Option value={d.value} key={d.value}>
      {d.label}
    </Option>
  ));
  const init = useRef(false);

  useEffect(() => {
    if (defaultValue && !init.current) {
      init.current = true;
      onChange?.(defaultValue, { value: defaultValue } as any);
    }
  }, [defaultValue, onChange]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorderBg: colors.labelBg,
          colorBgSpotlight: "red",
          colorPrimary: "white",
          boxShadow: "0 0 24px rgba(0,0,0,.05)",
          colorBorder: colors.labelBg,
          fontFamily: "Poppins,sans-serif",
          fontSize: 14,
        },
      }}
    >
      <Global isInvalid={isInvalid} />
      <Select
        getPopupContainer={(triggerNode: any) => {
          return triggerNode.parentNode;
        }}
        allowClear
        {...props}
        placeholder={props.placeholder ?? "--selecione--"}
        value={props.value || undefined}
        defaultValue={props.value || undefined}
        autoClearSearchValue
        style={{
          width: "100%",
          ...(props.style ?? {}),
        }}
        onClear={() => {
          if (typeof onChange === "function") {
            if (props.mode === "tags" || props.mode === "multiple") {
              onChange([], [] as any);
            } else {
              onChange(undefined, undefined as any);
            }
          }
        }}
        options={props.options}
        notFoundContent={
          props.loading ? (
            <Flex
              flex={1}
              py="32px"
              justifyContent={"center"}
              alignItems={"center"}
              flexDir={"column"}
            >
              <Spin spinning />
              <Text>Carregando...</Text>
            </Flex>
          ) : props.notFoundContent ? (
            props.notFoundContent
          ) : (
            <EmptyDefault />
          )
        }
      >
        {props.children ?? options}
      </Select>
    </ConfigProvider>
  );
}

export default SelectBaseFiltro;
