import { Box, Flex, Text } from "@chakra-ui/react";
import { ProcessoDocumentoResponse } from "core/features/processos-documento/typings";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import ActionsKebabMenu from "ui/components/ActionsKebabMenu";
import CardDefault from "ui/components/Cards/CardDefault";
import EmptyDefault from "ui/components/Empty";
import DocumentoIcone from "ui/pages/Admin/Processos/Detalhes/components/TabDocumentos/components/DocumentoIcone";

interface TableDocumentosProps {
  form: ProcessoDocumentoResponse;
}

export default function TableDocumentos({ form }: TableDocumentosProps) {
  return (
    <>
      <Flex flexDir="column" gap={4}>
        {form.arquivos?.length ? (
          <Box>
            {form.arquivos.map((item: any, index) => (
              <CardDefault rounded="8px" padding="6px 20px" mb={4} key={index}>
                <Flex align="center">
                  <Flex flex={2} align="center" gap={2}>
                    <DocumentoIcone mimetype={item.type} />
                    <Text
                      fontSize="14px"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      mr={6}
                    >
                      {item.name.split(".")[0]}
                    </Text>
                  </Flex>
                  <Flex h="fit-content" py={4}>
                    <ActionsKebabMenu
                      indexItem={index}
                      item={item}
                      actions={[
                        {
                          title: "Visualizar",
                          onClick: (v) =>
                            v instanceof File
                              ? window.open(URL.createObjectURL(v), "_blank")
                              : window.open(v.referencia, "_blank"),
                          icon: () => <RiInboxUnarchiveFill />,
                        },
                      ]}
                    />
                  </Flex>
                </Flex>
              </CardDefault>
            ))}
          </Box>
        ) : (
          <EmptyDefault size="sm" fontSize="12px" />
        )}
      </Flex>
    </>
  );
}
