import { useMutation } from "react-query";
import http from "core/features/auth/hooks/useApi";
import { ProcessoPermitirAcessoResponse } from "../typings";

function usePermitirAcesso(id?: string) {
  return useMutation(
    (data: ProcessoPermitirAcessoResponse) => {
      return http.post(`/v1/processo/${id}/permitir-acesso`, data);
    },
    { retry: false }
  );
}

export default usePermitirAcesso;
