import { Box } from "@chakra-ui/react";
import ContentTags from "ui/components/ContentTags";
import { useEffect, useState } from "react";
import ModalPalavrasChave from "./ModalPalavrasChave";
import ModalAlertas from "./ModalAlertas";
import ModalAlertaDetalhes from "./ModalAlertaDetalhes";
import ModalObservacoesDetalhes from "./ModalObservacoesDetalhes";
import TableProcessosAssociados from "./TableProcessosAssociados";
import TableAlertas from "./TableAlertas";
import { PiStackSimpleFill } from "react-icons/pi";
import colors from "core/resources/theme/colors";
import CollapseDefault from "ui/pages/Admin/Processos/Detalhes/components/EmAndamento/FichaProcesso/components/TabFichaProcesso/CollapseDefault";
import { MdKey, MdLibraryAdd } from "react-icons/md";
import { RiAlarmFill } from "react-icons/ri";
import { GoPasskeyFill } from "react-icons/go";
import ModalProcessosAssociados from "./ModalProcessosAssociados";
import ModalObservacao from "ui/pages/Admin/Processos/Detalhes/components/EmAndamento/FichaProcesso/components/TabFichaProcesso/ModalObservacao";
import TableObservacoes from "ui/pages/Admin/Processos/Detalhes/components/EmAndamento/FichaProcesso/components/TabFichaProcesso/TableObservacaoes";
import useFichaProcesso from "core/features/processos/hooks/useFichaProcesso";
import { PermissaoEnum } from "core/resources/enums/permissao";
import ModalPermitirAcesso from "ui/pages/Admin/Processos/Detalhes/components/EmAndamento/FichaProcesso/components/TabFichaProcesso/ModalPermissaoAcesso";
import TablePermitirAcesso from "ui/pages/Admin/Processos/Detalhes/components/EmAndamento/FichaProcesso/components/TabFichaProcesso/TablePermitirAcesso";
import { useAuthStore } from "core/features/auth/auth.store";
import { useNavigate } from "react-router-dom";

export default function TabFichaProcesso({ id }: { id: string }) {
  const { user } = useAuthStore();

  const navigate = useNavigate();

  const { data, isLoading, refetch, isFetching } = useFichaProcesso(id);

  const [indexEditProcessos, setIndexEditProcessos] = useState<
    number | undefined
  >();
  const [isOpenModalProcessos, setIsOpenModalProcessos] = useState(false);

  const [idPalavrasChave, setIdPalavrasChave] = useState<string | undefined>();

  const [isOpenModalPalavrasChave, setIsOpenModalPalavrasChave] =
    useState(false);

  const [isOpenModalAlertas, setIsOpenModalAlertas] = useState(false);

  const [idAlerta, setIdAlerta] = useState<string | undefined>();

  const [isOpenModalObservacao, setIsOpenModalObservacao] = useState(false);

  const [idObservacao, setIIdObservacao] = useState<string | undefined>();

  const [isOpenModalPermitirAcesso, setIsOpenModalPermitirAcesso] =
    useState(false);

  const [idPermitirAcesso, setIdPermitirAcesso] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (indexEditProcessos !== undefined) {
      setIsOpenModalProcessos(true);
    }
  }, [indexEditProcessos]);

  useEffect(() => {
    if (!isOpenModalProcessos) {
      setIndexEditProcessos(undefined);
    }
  }, [isOpenModalProcessos]);

  useEffect(() => {
    if (idPalavrasChave !== undefined) {
      setIsOpenModalPalavrasChave(true);
    }
  }, [idPalavrasChave]);

  useEffect(() => {
    if (!isOpenModalPalavrasChave) {
      setIdPalavrasChave(undefined);
    }
  }, [isOpenModalPalavrasChave]);

  useEffect(() => {
    if (idPermitirAcesso !== undefined) {
      setIsOpenModalPermitirAcesso(true);
    }
  }, [idPermitirAcesso]);

  useEffect(() => {
    if (!isOpenModalPermitirAcesso) {
      setIdPermitirAcesso(undefined);
    }
  }, [isOpenModalPermitirAcesso]);

  useEffect(() => {
    if (data && user) {
      const viewPermission = data?.permitirAcesso.some(
        (v) => user?.id === v.usuario.id
      );
      if (!viewPermission) {
        navigate("/errors/sem-permissao");
      }
    }
  }, [data, user, navigate]);

  return (
    <>
      <Box mt={4}>
        <CollapseDefault
          title="Processos associados"
          addButton
          icon={<PiStackSimpleFill color={colors.gray500} size={20} />}
          permissionShow={[
            PermissaoEnum.Fluir.Processo.ProcessoAssociado.Visualizar,
          ]}
          permissionCreate={[
            PermissaoEnum.Fluir.Processo.ProcessoAssociado.Criar,
          ]}
          onClick={(ev) => {
            setIsOpenModalProcessos(true);
            ev.stopPropagation();
          }}
          children={
            <TableProcessosAssociados
              items={data?.associados}
              refetch={refetch}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          }
        />

        <CollapseDefault
          title="Palavras chaves"
          addButton
          onClick={(ev) => {
            setIsOpenModalPalavrasChave(true);
            ev.stopPropagation();
          }}
          icon={<MdKey color={colors.gray500} size={20} />}
          permissionShow={[
            PermissaoEnum.Fluir.Processo.PalavraChave.Visualizar,
          ]}
          permissionCreate={[PermissaoEnum.Fluir.Processo.PalavraChave.Criar]}
          children={
            <ContentTags
              item={data?.palavrasChaves}
              setIdPalavrasChave={setIdPalavrasChave}
              refetch={refetch}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          }
        />

        <CollapseDefault
          title="Alertas"
          addButton
          onClick={(ev) => {
            setIsOpenModalAlertas(true);
            ev.stopPropagation();
          }}
          icon={<RiAlarmFill color={colors.gray500} size={20} />}
          permissionShow={[PermissaoEnum.Fluir.Processo.Alerta.Visualizar]}
          permissionCreate={[PermissaoEnum.Fluir.Processo.Alerta.Criar]}
          children={
            <TableAlertas
              items={data?.alertas}
              setIdAlerta={setIdAlerta}
              refetch={refetch}
              isFetching={isFetching}
              isLoading={isLoading}
            />
          }
        />

        <CollapseDefault
          title="Observações"
          addButton
          onClick={(ev) => {
            setIsOpenModalObservacao(true);
            ev.stopPropagation();
          }}
          icon={<MdLibraryAdd color={colors.gray500} size={20} />}
          permissionShow={[PermissaoEnum.Fluir.Processo.Observacao.Visualizar]}
          permissionCreate={[PermissaoEnum.Fluir.Processo.Observacao.Criar]}
          children={
            <TableObservacoes
              items={data?.observacoes}
              setIIdObservacao={setIIdObservacao}
              refetch={refetch}
              isFetching={isFetching}
              isLoading={isLoading}
            />
          }
        />

        <CollapseDefault
          title="Permitir acesso"
          addButton
          onClick={(ev) => {
            setIsOpenModalPermitirAcesso(true);
            ev.stopPropagation();
          }}
          icon={<GoPasskeyFill color={colors.gray500} size={20} />}
          permissionShow={[PermissaoEnum.Fluir.Processo.Alerta.Visualizar]}
          permissionCreate={[PermissaoEnum.Fluir.Processo.Alerta.Criar]}
          children={
            <TablePermitirAcesso
              items={data?.permitirAcesso}
              refetch={refetch}
              isFetching={isFetching}
              isLoading={isLoading}
            />
          }
        />
      </Box>
      {isOpenModalProcessos ? (
        <ModalProcessosAssociados
          isOpen={isOpenModalProcessos}
          onClose={() => setIsOpenModalProcessos(false)}
          id={id}
          dataAssociados={data?.associados}
          refetch={refetch}
        />
      ) : null}

      {isOpenModalPalavrasChave ? (
        <ModalPalavrasChave
          isOpen={isOpenModalPalavrasChave}
          onClose={() => setIsOpenModalPalavrasChave(false)}
          idProcesso={id}
          id={idPalavrasChave}
          refetch={refetch}
          data={data}
        />
      ) : null}

      {isOpenModalAlertas ? (
        <ModalAlertas
          isOpen={isOpenModalAlertas}
          onClose={() => setIsOpenModalAlertas(false)}
          id={id}
          refetch={refetch}
        />
      ) : null}

      {isOpenModalObservacao ? (
        <ModalObservacao
          isOpen={isOpenModalObservacao}
          onClose={() => setIsOpenModalObservacao(false)}
          id={id}
          refetch={refetch}
        />
      ) : null}

      <ModalAlertaDetalhes
        isOpen={!!idAlerta}
        onClose={() => setIdAlerta(undefined)}
        items={data?.alertas}
        id={idAlerta}
      />

      <ModalObservacoesDetalhes
        isOpen={!!idObservacao}
        onClose={() => setIIdObservacao(undefined)}
        items={data?.observacoes}
        id={idAlerta}
      />

      {isOpenModalPermitirAcesso ? (
        <ModalPermitirAcesso
          isOpen={isOpenModalPermitirAcesso}
          onClose={() => setIsOpenModalPermitirAcesso(false)}
          id={id}
          refetch={refetch}
          permitirAcesso={data?.permitirAcesso}
        />
      ) : null}
    </>
  );
}
