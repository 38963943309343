import PrimaryButton from "ui/components/Buttons/PrimaryButton";
import { useForm } from "react-hook-form";
import Field from "ui/components/Input/Field";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { isValidEmail } from "@brazilian-utils/brazilian-utils";
import colors from "core/resources/theme/colors";
import useRecoverPassword from "core/features/auth/hooks/useRecoverPassword";
import { showErrorAxios } from "core/infra/helpers/alerta";
import { useState } from "react";
import { Link } from "react-router-dom";
import EmailConfirm from "./EmailConfirm";

export default function ResetPassword() {
  const [send, setSend] = useState(false);

  const { isLoading: isLoadingSave, mutate: handleSave } = useRecoverPassword();
  const { control, handleSubmit, watch } = useForm<{
    email: string;
    code: string;
  }>({
    reValidateMode: "onChange",
  });

  const email = watch("email");

  const onSubmit = async (data: { email: string; code: string }) => {
    handleSave(data, {
      onSuccess: () => {
        setSend(true);
      },
      onError: (err) => {
        showErrorAxios(err);
      },
    });
  };

  return (
    <>
      {!send ? (
        <Box w="417px" margin="auto auto auto 80px">
          <form onSubmit={(ev) => handleSubmit(onSubmit)(ev)}>
            <Text fontSize={24} fontWeight={500} mb={4}>
              Redefinir senha
            </Text>
            <Box as={Row}>
              <Box as={Col} md={24} mb={4}>
                <Field
                  name="email"
                  label="Email"
                  rules={{
                    required: true,
                    validate: (v) => isValidEmail(v) || "Email inválido",
                  }}
                  inputProps={{
                    type: "email",
                  }}
                  control={control}
                />
              </Box>
              <Box as={Col} md={24} mb={4}>
                <PrimaryButton w="100%" type="submit" isLoading={isLoadingSave}>
                  Enviar
                </PrimaryButton>
              </Box>
            </Box>
            <Flex flexDir="column" align="center" justify="center" gap={2}>
              <Text fontSize={14}>Mudou de ideia?</Text>
              <Text
                cursor="pointer"
                fontSize={14}
                color={colors.primary}
                as={Link}
                to={"/auth/signin"}
              >
                Entrar
              </Text>
            </Flex>
          </form>
        </Box>
      ) : (
        <EmailConfirm email={email} setSend={setSend} />
      )}
    </>
  );
}
