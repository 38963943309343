import RInputMask, { Props } from "react-input-mask";
import { InputContainer } from "./styles";

interface InputMaskProps extends Props {
  value?: any;
  isInvalid?: boolean;
  onChange?: (v: any) => void;
  maskChar?: string | null;
  ref?: any;
}

function InputMask({
  value,
  onChange,
  isInvalid,
  ref,
  ...props
}: InputMaskProps) {
  return (
    <InputContainer isInvalid={isInvalid}>
      <RInputMask
        className="chakra-input"
        value={value}
        ref={ref}
        onChange={(event: any) => {
          if (typeof onChange === "function") {
            onChange(event.target.value);
          }
        }}
        {...props}
      />
    </InputContainer>
  );
}

export default InputMask;
