import { Flex, Text } from "@chakra-ui/react";
import OutlineButton from "../Buttons/OutlineButton";
import { BsPlusLg } from "react-icons/bs";

interface HeaderTableProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  title: string;
  addButton?: boolean;
  icon?: any;
  dislabledButton?: boolean;
}

export default function HeaderTable({
  onClick,
  title,
  addButton,
  icon,
  dislabledButton = false,
}: HeaderTableProps) {
  return (
    <Flex gap={4} align="center">
      <Flex gap={2} align="center">
        {icon}
        <Text fontWeight="500" fontSize="18px">
          {title}
        </Text>
      </Flex>

      {addButton ? (
        <OutlineButton onClick={onClick} disabled={dislabledButton}>
          <Flex gap={2} fontSize="14px" align="center">
            <BsPlusLg />
            Adicionar
          </Flex>
        </OutlineButton>
      ) : null}
    </Flex>
  );
}
