import { Navigate } from "react-router-dom";
import useHasPermission from "core/features/permissao/hooks/useHasPermission";
import { useAuthStore } from "core/features/auth/auth.store";
import { useCurrentTenant } from "core/features/auth/tenant.store";

export interface AuthRouteProps {
  permissions?: string[];
  children: JSX.Element;
}

const AuthRoute = ({ permissions, children }: AuthRouteProps) => {
  const { user } = useAuthStore();
  const { tenant } = useCurrentTenant();
  const hasPermission = useHasPermission(permissions ?? []);

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  if (!tenant?.id) {
    return <Navigate to="/auth/selecionar-empresa" />;
  }

  if (permissions !== undefined && !hasPermission) {
    return <Navigate to="/errors/sem-permissao" />;
  }

  return children;
};

export default AuthRoute;
