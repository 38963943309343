import { useQuery } from "react-query";
import { PaginateResult } from "core/resources/types/shared";
import http from "core/features/auth/hooks/useApi";
import { NaturezaProcessoPaginateResponse } from "../types";
import { PaginateQuery } from "core/resources/types/shared";

function useListarNaturezasProcesso(query: PaginateQuery) {
  return useQuery([`/v1/natureza-processo`, query], async () => {
    const { data } = await http.get<
      PaginateResult<NaturezaProcessoPaginateResponse>
    >(`/v1/natureza-processo`, {
      params: query,
    });

    return data;
  });
}

export default useListarNaturezasProcesso;
