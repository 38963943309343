import { Box } from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import PageHeader from "ui/components/Page/PageHeader";
import { useBreadcrumb } from "core/features/breadcrumbs";
import SearchHeaderBar from "ui/components/SearchHeaderBar/index";
import { useNavigate } from "react-router-dom";
import { PermissaoEnum } from "core/resources/enums/permissao";
import { checkPermission } from "core/infra/helpers/permission";
import AssuntoProcessoTable from "./components/AssuntoProcessoTable";
import useFiltroAssuntoProcesso from "./hooks/useFiltroAssuntoProcesso";
import useListarAssuntoProcesso from "core/features/assunto-processo/hooks/useListarAssuntosProcesso";

export default function ListagemAssuntoProcessoPage() {
  const { filtro, onChangeFiltro } = useFiltroAssuntoProcesso();
  const { data, isLoading, refetch } = useListarAssuntoProcesso(filtro);

  const navigate = useNavigate();

  useBreadcrumb([
    { name: "Cadastro" },
    { name: "Assunto do Processo", url: "/assunto-processo" },
  ]);

  const createPermission = [
    PermissaoEnum.Fluir.Administrativo.AssuntoProcesso.Criar,
  ];

  const hasPermission = checkPermission(createPermission ?? []);

  return (
    <Box>
      <Box mb={10}>
        <PageHeader
          title="Natureza do Processo"
          titleButton="Adicionar"
          onClickButton={() => navigate("/assunto-processo/novo")}
          iconButton={<BsPlus size={25} />}
          buttonDisabled={!hasPermission}
        />

        <SearchHeaderBar
          defaultValue={filtro.search || ""}
          onSearch={(v) => onChangeFiltro({ search: v })}
          maxWInputSearch={700}
          placeholder="Buscar"
        />
      </Box>

      <AssuntoProcessoTable
        data={data}
        isLoading={isLoading}
        onChangeFiltro={onChangeFiltro}
        refetch={refetch}
      />
    </Box>
  );
}
